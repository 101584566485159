import React from "react";

const AssistantDropDown = ({ selectedAssistant, assistants, handleAssistantChange }) => {
  return (
    <>
      {assistants && assistants.length > 0 ? (
        <div className="">
          <select id="assistantsdropdown" className="text-white  block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm" value={selectedAssistant} onChange={handleAssistantChange}>
            <option value="all">All Assistants</option>
            {assistants.map((oneAssist) => (
              <option key={oneAssist.id} value={oneAssist.id} className="py-2 my-2">
                {oneAssist.name}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AssistantDropDown;
