import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";

const Header = () => {
  const auth = useAuth();
  const currentUser = auth.getCurrentUser();

  if (currentUser) {
    return <></>;
  }

  return (
    <nav className="bg-black fixed w-full z-50 top-0 start-0 border-b border-gray-200 dark:border-gray-600 min-h-[80px]">
      <div className="px-10 flex flex-wrap items-center justify-between mx-auto py-4">
        <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">AI Calls</span>
        </Link>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse w-full md:w-auto justify-between gap-4">
          {!currentUser && (
            <>
              <Link to="/signin" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Sign In
              </Link>

              <Link to="/signup" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Sign Up
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
