import React, { useEffect, useState } from "react";
import Twilio from "./Twilio";
import Vonage from "./Vonage";
import { fetchAllImportedPhonesAPI, deleteNumberAPI } from "../../../services/sms_provider";
import DeleteConfirmation from "../../../components/DeleteConfirmation";

const SMSproviders = () => {
  const [importedNumbers, setImportedNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchAllImportedPhonesAPI();
      // console.log("responseData", responseData);
      setImportedNumbers(responseData.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const toggleDeletePopup = (id) => {
    setSelectedNumber(id);
    setIsDeleteOpen(!isDeleteOpen);
  };

  return (
    <div>
      <div className="w-full flex justify-start">
        <div className="py-5 flex gap-3 px-5 font-bold text-xl"></div>
      </div>
      <div className="flex justify-center px-5 gap-4">
        {/* Left Div */}
        <div className="w-1/2">
          <Twilio reloadTop={fetchDataFromApi} />
        </div>
        {/* Right Div */}
        <div className="w-1/2">
          <Vonage reloadTop={fetchDataFromApi} />
        </div>
      </div>

      <div className="mb-4 bg-back-dark p-5 mx-5 rounded-lg">
        <h2 className="text-xl pb-2 flex">Imported Phone Numbers</h2>

        {importedNumbers && importedNumbers.length > 0 ? (
          <>
            {/* Display prompt listing here */}
            <div className="px-5">
              <table className="min-w-full divide-y bg-back-dark divide-color-lines rounded-lg">
                <thead className="min-w-full rounded-md divide-color-lines">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                      Provider
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                      Phone Number
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap"></th>
                  </tr>
                </thead>
                <tbody className="bg-back-dark divide-y divide-color-lines">
                  {importedNumbers.map((oneNumber) => (
                    <tr key={oneNumber.id} className="hover:bg-gray-900 text-sm">
                      <td className="px-6 py-4 whitespace-wrap capitalize">{oneNumber.provider}</td>
                      <td className="px-6 py-4 whitespace-wrap">{oneNumber.phone_number}</td>
                      <td className="px-6 py-4 whitespace-wrap text-right">
                        <button className="text-white bg-red-700 hover:bg-red-800 focus:ring-4  font-medium rounded-lg text-sm px-3  py-2  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" type="button" onClick={() => toggleDeletePopup(oneNumber.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center text-center text-xl pt-10">
            <div>No imported numbers.</div>
          </div>
        )}
      </div>

      {selectedNumber && <DeleteConfirmation isOpen={isDeleteOpen} onClose={toggleDeletePopup} title={"Are you sure, you want to remove this number from imported list?"} id={selectedNumber} fetchDataFromApi={fetchDataFromApi} deleteMethod={deleteNumberAPI} />}
    </div>
  );
};

export default SMSproviders;
