import { getAccessToken, logOut } from "./token";

export const fetchContacts = async () => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/contacts`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};
export const createContacts = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/contacts`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};
export const importCSVContacts = async (postData) => {
  let token = await getAccessToken();

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/contacts/import_contacts`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: postData,
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    // console.log("error", error.message);
    throw error;
  }
};

export const deleteContactAPI = async (id) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/contacts/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};
