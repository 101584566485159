import { Loader, Pause, Play } from "lucide-react";
import React, { useState } from "react";
import ReactHowler from "react-howler";

const AudioPlayer = ({ url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handlePlayAutioClick = () => {
    setIsPlaying(!isPlaying);
  };

  const handleLoad = () => {
    setIsLoaded(true);
  };
  if (!url) {
    return <></>;
  }
  return (
    <div className="inline-block" onClick={handlePlayAutioClick}>
      {isPlaying && !isLoaded ? <Loader /> : isPlaying && isLoaded ? <Pause /> : <Play />}

      {isPlaying ? (
        <ReactHowler
          src={url}
          playing={isPlaying}
          onLoad={handleLoad}
          onPlay={() => {
            setIsPlaying(true);
          }}
          onPause={() => {
            setIsPlaying(false);
          }}
          onStop={() => {
            setIsPlaying(false);
          }}
          onEnd={() => {
            setIsPlaying(false);
          }}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default AudioPlayer;
