import React, { useState, useEffect } from "react";
import { deleteContactAPI, fetchContacts } from "../../../services/contact";
import Popup from "../../../components/Popup";
import CreateContact from "./CreateContact";
import ImportContacts from "./ImportContacts";
import { UserPlus, Contact, Import } from "lucide-react";
import DeleteConfirmation from "../../../components/DeleteConfirmation";

const Contacts = () => {
  const [contacts, setContacts] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isPickOpen, setIsPickOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const togglePopup = () => {
    setIsCreateOpen(!isCreateOpen);
  };
  const togglePickPopup = () => {
    setIsPickOpen(!isPickOpen);
  };

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchContacts();
      setContacts(responseData.data);
      setIsLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const colorStatus = (status) => {
    switch (status) {
      case "pending":
        return <div className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-800 text-gray-400">Pending</div>;
      case "interested":
        return <div className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Interested</div>;
      case "rejected":
        return <div className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Rejected</div>;
      default:
        return <div></div>;
    }
  };

  const toggleDeletePopup = (id) => {
    setSelectedContact(id);
    setIsDeleteOpen(!isDeleteOpen);
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90"></div>
        </div>
      ) : (
        <div>
          {contacts && contacts.length > 0 ? (
            <>
              <div className="w-full flex justify-end">
                <div className="py-5 flex gap-3 px-5">
                  <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={togglePickPopup}>
                    <UserPlus className="inline mb-1 mr-1" size="16" />
                    New Contact
                  </button>
                </div>
              </div>

              <div className="px-5">
                <table className="min-w-full divide-y bg-back-dark divide-color-lines rounded-lg">
                  <thead className="min-w-full rounded-md divide-color-lines">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Phone
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Address
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Last Contacted
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-back-dark divide-y divide-color-lines">
                    {contacts.map((contact) => (
                      <tr key={contact.id} className="hover:bg-gray-900 text-sm">
                        <td className="px-6 py-4 whitespace-wrap">
                          {contact.fname} {contact.lname}
                        </td>
                        <td className="px-6 py-4 whitespace-wrap">{contact.phone}</td>
                        <td className="px-6 py-4 whitespace-wrap">{contact.address}</td>
                        <td className="px-6 py-4 whitespace-wrap">{contact.lastContacted ? contact.lastContacted : "Never"}</td>
                        <td className="px-6 py-4 whitespace-wrap">{colorStatus(contact.status)}</td>
                        <td className="px-6 py-4 whitespace-wrap">
                          <button className="text-white bg-red-700 hover:bg-red-800 focus:ring-4  font-medium rounded-lg text-sm px-3  py-2  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" type="button" onClick={() => toggleDeletePopup(contact.id)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center text-center text-xl pt-10">
              <div>
                No Contacts found.
                <div className="block text-base mt-5 mb-10">Start by creating new contact.</div>
                <div className="flex">
                  <div onClick={togglePopup} className="rounded-lg text-center overflow-hidden mr-4 bg-blue-700 p-4 hover:bg-blue-900 cursor-pointer">
                    <Contact className="inline mb-2" size="30" />
                    <p className="text-base">Create New Contact</p>
                  </div>
                  <div className="relative rounded-lg text-center  overflow-hidden bg-blue-700 p-4 hover:bg-blue-900 cursor-pointer">
                    <Import className="inline mb-2" size="30" />
                    <div>
                      <ImportContacts fetchDataFromApi={fetchDataFromApi} classStyle="text-base" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <Popup isOpen={isPickOpen} onClose={togglePickPopup} title="How would you like to create contacts?" mdWidth="max-w-md">
        <div className="flex items-center justify-center text-center my-5">
          <div
            onClick={() => {
              togglePickPopup();
              togglePopup();
            }}
            className="rounded-lg text-center overflow-hidden mr-4 bg-blue-700 p-4 hover:bg-blue-900 cursor-pointer"
          >
            <Contact className="inline mb-2" size="30" />
            <p className="text-base">Create New Contact</p>
          </div>
          <div className="relative rounded-lg text-center  overflow-hidden bg-blue-700 p-4 hover:bg-blue-900 cursor-pointer">
            <Import className="inline mb-2" size="30" />
            <div>
              <ImportContacts doClose={togglePickPopup} fetchDataFromApi={fetchDataFromApi} classStyle="text-base" />
            </div>
          </div>
        </div>
      </Popup>

      {/* create  contact popup */}
      <CreateContact isOpen={isCreateOpen} onClose={togglePopup} fetchDataFromApi={fetchDataFromApi} />

      {/* Delete contact popup */}
      {selectedContact && <DeleteConfirmation isOpen={isDeleteOpen} onClose={toggleDeletePopup} title={"Are you sure, you want to delete this contact?"} id={selectedContact} fetchDataFromApi={fetchDataFromApi} deleteMethod={deleteContactAPI} />}
    </div>
  );
};

export default Contacts;
