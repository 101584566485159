import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import StripePayment from "./StripePayment";
import { fetchPyamentMethodAPI } from "../../../../services/payment";
import PaymentTopUp from "./PaymentTopUp";
const Payment = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isTopUpOpen, setIsTopUpOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isPaymentExists, setIsPaymentExists] = useState(false);
  const [isPaymentExistsLoading, setIsPaymentExistsLoading] = useState(false);

  const fetchPaymentMethod = async () => {
    setIsPaymentExistsLoading(true);
    try {
      const responseData = await fetchPyamentMethodAPI();
      if (responseData && responseData.status === "success") {
        setIsPaymentExists(true);
      } else {
        setIsPaymentExists(false);
      }
    } catch (error) {
    } finally {
      setIsPaymentExistsLoading(false);
    }
  };
  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  const { register, handleSubmit } = useForm();
  const togglePopup = () => {
    setIsCreateOpen(!isCreateOpen);
  };

  const toggleTopUpPopup = () => {
    setIsTopUpOpen(!isTopUpOpen);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    setSuccess(null);
    setError(null);

    // Get the current value of amount from the form data
    // Check if the amount  is empty or unchanged
    if (!data || !data.amount || data.amount < 10) {
      // If it's empty or unchanged, set the error message and return
      setError({ message: "Minimum amount should be $10" });
      setIsLoading(false);
      return;
    }
    setAmount(data.amount);
    togglePopup();
  };

  return (
    <>
      {isPaymentExistsLoading ? (
        <div className="mb-4 bg-back-dark p-5 rounded-lg">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90 mt-10"></div>
        </div>
      ) : isPaymentExists ? (
        <div className="mb-4 bg-back-dark p-5 rounded-lg">
          <h2 className="text-xl pb-2">Payment</h2>

          <button type="submit" className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-600`} onClick={toggleTopUpPopup}>
            Top Up
          </button>

          {isTopUpOpen && <PaymentTopUp isOpen={isTopUpOpen} onClose={toggleTopUpPopup} />}
        </div>
      ) : (
        <div className="mb-4 bg-back-dark p-5 rounded-lg">
          <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="text-xl pb-2">Payment</h2>
            <div className="">
              <input id="amount" name="amount" type="number" min="10" autoComplete="amount" required {...register("amount")} className="relative block w-full px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="$ 10" />
              <div>
                <button type="submit" disabled={isLoading} className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
                  Add Payment Method
                </button>
              </div>
            </div>
          </form>
          <div className="pt-2 text-red-600">{error?.message}</div>
          <div className="pt-2 text-green-600">{success?.message}</div>
          {isCreateOpen && <StripePayment isOpen={isCreateOpen} onClose={togglePopup} amount={amount} />}
        </div>
      )}
    </>
  );
};

export default Payment;
