import { useForm } from "react-hook-form";
import { useState } from "react";
import { createPromptMeta, createSimplePromptMeta } from "../../../../services/prompt_meta";
import AssistantDropDown from "./AssistantDropdown";
import PromptMetaSuggest from "./PromptMetaSuggest";
import { Zap } from "lucide-react";

function PromptMetaForm({ singlePrompt }) {
  const [loading, setLoading] = useState(false);
  const [secondCallPrompt, setSecondCallPrompt] = useState(singlePrompt.second_call);

  const [suggestOpen, setSuggestOpen] = useState(false);

  const { register, handleSubmit, setValue } = useForm({
    // Set initial values for your form fields here
    defaultValues: {
      prompt1: {
        id: singlePrompt?.prompt_meta[0]?.id,
        assistant_goal: singlePrompt?.prompt_meta[0]?.assistant_goal,
        assistant_voicemail: singlePrompt?.prompt_meta[0]?.assistant_voicemail,
        assistant_backstory: singlePrompt?.prompt_meta[0]?.assistant_backstory,
        assistant_act: singlePrompt?.prompt_meta[0]?.assistant_act,
        assistant_style: singlePrompt?.prompt_meta[0]?.assistant_style,
      },
      type: singlePrompt?.type,

      simple_prompt: {
        id: singlePrompt?.prompt_meta[0]?.id,
        simple_prompt_text: singlePrompt?.prompt_meta[0]?.simple_prompt_text,
        assistant_voicemail: singlePrompt?.prompt_meta[0]?.assistant_voicemail,
      },
    },
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const onSubmit = async (data) => {
    data.prompt1.prompt_id = singlePrompt?.id;
    data.second_call = !!secondCallPrompt;
    data.simple_prompt.prompt_id = singlePrompt?.id;
    // console.log("data to send...", data);
    setLoading(true);
    // call server api here
    try {
      let responseData = null;
      if (singlePrompt.type === "simple_prompt") {
        responseData = await createSimplePromptMeta(data);
      } else {
        responseData = await createPromptMeta(data);
      }
      if (responseData && responseData.status === "success") {
        setSuccess(responseData);

        if (singlePrompt.type === "simple_prompt") {
          setValue("simple_prompt", { id: responseData.createdPromptMetaID, simple_prompt_text: data.simple_prompt?.simple_prompt_text, assistant_voicemail: data.simple_prompt?.assistant_voicemail });
        } else {
          setValue("prompt1", {
            id: responseData.createdPromptMetaID,
            assistant_goal: data.prompt1?.assistant_goal,
            assistant_voicemail: data.prompt1?.assistant_voicemail,
            assistant_backstory: data.prompt1?.assistant_backstory,
            assistant_act: data.prompt1?.assistant_act,
            assistant_style: data.prompt1?.assistant_style,
          });
        }
        // onClose();
        // fetchDataFromApi();
        // window.location.reload();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (checked) => {
    setSecondCallPrompt(!!checked);
  };

  const applySuggestions = (prompts) => {
    setValue("prompt1", {
      id: singlePrompt?.prompt_meta[0]?.id,
      assistant_goal: prompts.assistant_goal,
      assistant_voicemail: singlePrompt?.prompt_meta[0]?.assistant_voicemail,
      assistant_backstory: prompts.assistant_backstory,
      assistant_act: prompts.assistant_act,
      assistant_style: prompts.assistant_style,
    });
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="w-full">
        <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex pt-7">
            <div className="flex-1 w-1/2">
              <div className="flex">
                <AssistantDropDown singlePrompt={singlePrompt} />
              </div>

              <div className="space-y-2">
                <label className="flex items-center">
                  <input type="checkbox" checked={!!secondCallPrompt} onChange={(e) => handleCheckboxChange(e.target.checked)} className="mr-2" />
                  Use this for subsequent calls
                </label>
              </div>
            </div>

            <div className="flex-1 w-1/2">
              <div className="flex justify-end">
                <div className="flex flex-col">
                  <button
                    type="submit"
                    className={`group flex justify-center py-2 mb-4 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
  ${loading ? "bg-gray-400 cursor-not-allowed" : "hover:bg-indigo-700 focus:ring-indigo-500"}`}
                  >
                    Save Changes
                  </button>
                  <div className="flex justify-center">
                    {!!loading && <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90  mt-4"></div>}
                    {!!error && !loading && <div className="text-center text-red-500">{error?.message}</div>}
                    {!!success && !loading && <div className="pt-2 text-green-600">{success?.message}</div>}
                  </div>
                  {/* <div>
                <AssistantDropDown singlePrompt={singlePrompt} />
              </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row w-full gap-4">
            {/* Prompt 1 form */}

            {singlePrompt.type === "structural_prompt" ? (
              <div className="w-full">
                <div className="space-y-2 mt-6 relative">
                  <label htmlFor="" className="">
                    Identity: Who will assitant act as?
                  </label>
                  <textarea id="prompt1.assistant_backstory" name="prompt1.assistant_backstory" type="text" required {...register("prompt1.assistant_backstory")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="ex: You are a helpful and knowledgeable virtual assistant for a travel booking platform." cols={4} rows={6} />

                  <Zap
                    onClick={() => {
                      setSuggestOpen(!suggestOpen);
                    }}
                    className="absolute -top-2 right-2 mt-0 cursor-pointer"
                    size="24"
                  />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Style: What should conversation style be?
                  </label>
                  <textarea
                    id="prompt1.assistant_style"
                    name="prompt1.assistant_style"
                    type="text"
                    required
                    {...register("prompt1.assistant_style")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  - Be informative and comprehensive.
                - Maintain a professional and polite tone.
                - Be concise, as you are currently operating as a Voice Conversation."
                    cols={4}
                    rows={6}
                  />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Response Guideline: How should assistant response on certain questions?
                  </label>
                  <textarea
                    id="prompt1.assistant_act"
                    name="prompt1.assistant_act"
                    type="text"
                    required
                    {...register("prompt1.assistant_act")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  - Present dates in a clear format (e.g., January 15, 2024).
                - Offer up to three travel options based on user preferences."
                    cols={4}
                    rows={6}
                  />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Task: What is the goal of the call?
                  </label>
                  <textarea
                    id="prompt1.assistant_goal"
                    name="prompt1.assistant_goal"
                    type="text"
                    required
                    {...register("prompt1.assistant_goal")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  1. Greet the user and inquire about their desired travel destination.
                2. Ask about travel dates and preferences (e.g., budget, interests).
                3. Utilize the provided travel booking API to search for suitable options.
                4. Present the top three options to the user, highlighting key features."
                    cols={4}
                    rows={6}
                  />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    If contact does not answer and voicemail starts, assistant will say this as voicemail message (leave empty to hang up without message):
                  </label>
                  <textarea
                    id="prompt1.assistant_voicemail"
                    name="prompt1.assistant_voicemail"
                    type="text"
                    {...register("prompt1.assistant_voicemail")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  I have a great proposition for you, call me back."
                    cols={4}
                    rows={3}
                  />
                </div>
              </div>
            ) : (
              <div className="w-full">
                <div className="space-y-2 mt-6 relative">
                  <label htmlFor="" className="">
                    Simple Prompt text
                  </label>
                  <textarea id="simple_prompt.simple_prompt_text" name="simple_prompt.simple_prompt_text" type="text" required {...register("simple_prompt.simple_prompt_text")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="ex: You are a helpful and knowledgeable virtual assistant for a travel booking platform." cols={4} rows={6} />

                  {/* <Zap
                      onClick={() => {
                        setSuggestOpen(!suggestOpen);
                      }}
                      className="absolute -top-2 right-2 mt-0 cursor-pointer"
                      size="24"
                    /> */}
                </div>
                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    If contact does not answer and voicemail starts, assistant will say this as voicemail message (leave empty to hang up without message):
                  </label>
                  <textarea
                    id="simple_prompt.assistant_voicemail"
                    name="simple_prompt.assistant_voicemail"
                    type="text"
                    {...register("simple_prompt.assistant_voicemail")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  I have a great proposition for you, call me back."
                    cols={4}
                    rows={3}
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      </div>

      <PromptMetaSuggest
        isOpen={suggestOpen}
        onClose={() => {
          setSuggestOpen(!suggestOpen);
        }}
        id={singlePrompt?.id}
        onApply={applySuggestions}
      />
    </div>
  );
}

export default PromptMetaForm;
