import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { fetchAssistantMeta } from "../../../../services/assistant_meta";
import { assistantVoices } from "../../../../constants/assistant_voices";
import { areaCodes } from "../../../../constants/area_codes";
import { createAssistantAPI, fetchStaffUsers } from "../../../../services/assistant";
import { BadgePlus, ChevronDown, ChevronUp } from "lucide-react";
import { fetchPrompts } from "../../../../services/prompt";
import CreatePromptWithMeta from "./CreatePromptWithMeta";
import NumberSlider from "./NumberSlider";
import TestAssistant from "./TestAssistant";
import AudioPlayer from "../../../../components/AudioPlayer";

const CreateNewAssistant = ({ existingAssistant = null, title = "Let's hire a new assistant!" }) => {
  const [openItem, setOpenItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [assistantMeta, setAssistantMeta] = useState([]);
  // const initialVoices = assistantVoices.filter((voice) => voice.provider === "rime-ai");
  const initialVoices = assistantVoices;
  const [voices, setVoices] = useState(initialVoices);
  const [languages, setLanguages] = useState([]);
  const [accents, setAccents] = useState([]);
  const [genders, setGenders] = useState([]);
  const [selectedAreaCode, setSelectedAreaCode] = useState(0);
  const [inboundPhone, setInboundPhone] = useState();
  const [inboundMessage, setInboundMessage] = useState("");
  const [forwardCalling, setForwardCalling] = useState(0);
  const [areaCodeError, setAreaCodeError] = useState("Provide a valid area code.");

  const [selectedPackage, setselectedPackage] = useState(null);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [staffUsers, setStaffUsers] = useState([]);
  const [prompts, setPrompts] = useState([]);
  const [isPromptCreateOpen, setIsPromptCreateOpen] = useState(false);
  const [isTestAssistantOpen, setIsTestAssistantOpen] = useState(false);
  const [temperature, setTemperature] = useState(1);
  const [speed, setSpeed] = useState(1);

  const voiceCategories = {
    neets: "basic",
    azure: "standard",
    "rime-ai": "standard",
    deepgram: "premium",
    openai: "premium",
    cartesia: "premium",
    "11labs": "exclusive",
    playht: "exclusive",
    lmnt: "exclusive",
  };

  // search form data and functionality
  const [searchFormData, setSearchFormData] = useState({
    provider: "standard",
    gender: "",
    accent: "",
    languageCode: "",
  });

  const handleProviderChange = (event) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      provider: event.target.value,
      gender: "",
      accent: "",
      languageCode: "",
    }));
  };

  const setInitialProvider = (provider) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      provider: provider,
    }));
  };
  const [formData, setFormData] = useState({
    assistant_id: "",
    meta_assistant_id: "",
    assistant_voice: {},
    area_code: 0,
    assistant_name: "",
    staff_id: "",
    prompt_ids: [],
    bg_sound: "off",
    phone_provider: "buy_vapi",
    calendar_id: "",
  });

  // set empty or prefilled initial state depending on provided data
  useEffect(() => {
    if (existingAssistant) {
      // console.log("existingAssistant", existingAssistant);
      setFormData({
        assistant_id: existingAssistant.assistant_id || "",
        meta_assistant_id: existingAssistant.meta_assistant_id || "",
        assistant_voice: existingAssistant.assistant_voice || {},
        area_code: existingAssistant.area_code || 0,
        assistant_name: existingAssistant.assistant_name || "",
        staff_id: existingAssistant.staff_id || "",
        prompt_ids: existingAssistant.prompt_ids || [],
        bg_sound: existingAssistant.bg_sound || "off",
        phone_provider: existingAssistant.phone_provider || "buy_vapi",
        calendar_id: existingAssistant.calendar_id || "",
      });
      setTemperature(Number(existingAssistant.temperature));
      setSpeed(Number(existingAssistant.speed));
      let initialMeta = assistantMeta.find((oneMeta) => oneMeta.id === Number(formData.meta_assistant_id));
      setselectedPackage(initialMeta);

      setInitialProvider(voiceCategories[existingAssistant.assistant_voice.provider]);
      if (existingAssistant.inboundPhone) {
        setInboundPhone(existingAssistant.inboundPhone);
        setForwardCalling(1);
      } else if (existingAssistant.inboundMessage) {
        setInboundMessage(existingAssistant.inboundMessage);
        setForwardCalling(2);
      }

      if (existingAssistant.phone_provider) {
        setSelectedBuyNumOption(existingAssistant.phone_provider);
      }
      if (existingAssistant.phone_provider === "import_twilio") {
        setTwilioData({
          twilioPhoneNumber: existingAssistant.phone_provider_data?.twilioPhoneNumber || "",
          twilioAccountSid: existingAssistant.phone_provider_data?.twilioAccountSid || "",
          twilioAuthToken: existingAssistant.phone_provider_data?.twilioAuthToken || "",
        });
      } else if (existingAssistant.phone_provider === "import_vonage") {
        setVonageData({
          vonagePhoneNumber: existingAssistant.phone_provider_data?.vonagePhoneNumber || "",
          vonageCredentialId: existingAssistant.phone_provider_data?.credentialId || "",
        });
      }
    } else {
      setFormData({
        assistant_id: "",
        meta_assistant_id: "",
        assistant_voice: {},
        area_code: 0,
        assistant_name: "",
        first_message: "",
        staff_id: "",
        prompt_ids: [],
        bg_sound: "off",
        phone_provider: "buy_vapi",
      });
      setInitialProvider("standard");
    }
  }, [existingAssistant]);

  const handleGenderChange = (event) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      gender: event.target.value,
    }));
  };

  const handleAccentChange = (event) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      accent: event.target.value,
    }));
  };

  const handleLanguageChange = (event) => {
    setSearchFormData((prevState) => ({
      ...prevState,
      languageCode: event.target.value,
    }));
  };

  const searchFunction = (formData) => {
    // Construct filter conditions for non-empty fields
    const filterConditions = [];
    const filterConditionsList = [];
    filterConditions.push((obj) => !!obj.previewUrl);
    filterConditionsList.push((obj) => !!obj.previewUrl);

    // Check each field and add a condition if it's not empty
    if (formData.provider !== "") {
      filterConditions.push((obj) => voiceCategories[obj.provider] === formData.provider);
      filterConditionsList.push((obj) => voiceCategories[obj.provider] === formData.provider);
    }
    if (formData.gender !== "") {
      filterConditions.push((obj) => obj.gender === formData.gender);
    }
    if (formData.accent !== "") {
      filterConditions.push((obj) => obj.accent === formData.accent);
    }
    if (formData.languageCode !== "") {
      filterConditions.push((obj) => obj.languageCode === formData.languageCode);
    }

    // Apply filter conditions
    const filteredResults = assistantVoices.filter((obj) => {
      // If no filter conditions are added, return true (no filtering)
      if (filterConditions.length === 0) {
        return true;
      }
      // Otherwise, check if all conditions are satisfied
      return filterConditions.every((condition) => condition(obj));
    });

    // Apply filter conditions
    const filteredResultsList = assistantVoices.filter((obj) => {
      // If no filter conditions are added, return true (no filtering)
      if (filterConditionsList.length === 0) {
        return true;
      }
      // Otherwise, check if all conditions are satisfied
      return filterConditionsList.every((condition) => condition(obj));
    });

    filteredResults.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setVoices(filteredResults);

    // Set languages based on selected provider and filter out duplicates and sort
    var listLanguages = filteredResultsList.map((itm) => {
      return { language: itm.language, languageCode: itm.languageCode };
    });
    listLanguages = listLanguages.filter((item, index, self) => {
      if (!item || !item.language) return false;
      return index === self.findIndex((obj) => obj.languageCode === item.languageCode);
    });
    listLanguages.sort((a, b) => {
      if (a.languageCode === "en-US" && b.languageCode !== "en-US") {
        return -1; // Put English first
      } else if (a.languageCode !== "en-US" && b.languageCode === "en-US") {
        return 1; // Put English first
      }

      return a.language.localeCompare(b.language);
    });

    if (listLanguages.length > 0) {
      listLanguages.splice(1, 0, { languageCode: "disabled", language: "_________" });
    }

    setLanguages(listLanguages);

    // Set accents based on selected provider and filter out duplicates and sort
    var listAccents = filteredResultsList.map((itm) => {
      return { accent: itm.accent };
    });
    listAccents = listAccents.filter((item, index, self) => {
      if (!item || !item.accent) return false;
      return index === self.findIndex((obj) => obj.accent === item.accent);
    });
    listAccents.sort((a, b) => {
      return a.accent.localeCompare(b.accent);
    });

    setAccents(listAccents);

    // Set genders based on selected provider and filter out duplicates and sort
    var listGenders = filteredResultsList.map((itm) => {
      return { gender: itm.gender };
    });
    listGenders = listGenders.filter((item, index, self) => {
      if (!item || !item.gender) return false;
      return index === self.findIndex((obj) => obj.gender === item.gender);
    });
    listGenders.sort((a, b) => {
      return a.gender.localeCompare(b.gender);
    });

    setGenders(listGenders);
  };

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchAssistantMeta();
      // console.log("responseData", responseData);
      setAssistantMeta(responseData.data);
    } catch (error) {
      setError(error);
    } finally {
      // setIsLoading(false);
    }
  };

  const fetchStaffUsersFromApi = async () => {
    try {
      const responseData = await fetchStaffUsers();
      // console.log("responseData", responseData);
      setStaffUsers(responseData?.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchPromptsDataFromApi = async () => {
    try {
      const responseData = await fetchPrompts();
      // console.log("responseData", responseData);
      if (responseData && responseData.status === "success") {
        setPrompts(responseData.data);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    searchFunction(searchFormData);
  }, [searchFormData]);

  useEffect(() => {
    fetchDataFromApi();
    fetchStaffUsersFromApi();
    fetchPromptsDataFromApi();
  }, []);

  // runs after assistant meta data has been fetched form api
  useEffect(() => {
    let initialMeta = assistantMeta.find((oneMeta) => oneMeta.id === Number(formData.meta_assistant_id));
    setselectedPackage(initialMeta);
  }, [assistantMeta]);

  const handleMetaAssistantClick = (selectedPackage) => {
    setselectedPackage(selectedPackage);
    setFormData({
      ...formData,
      meta_assistant_id: selectedPackage.id,
    });
  };

  const handleAssistantVoiceClick = (voiceObj) => {
    setFormData({
      ...formData,
      assistant_voice: voiceObj,
    });
  };

  const doesAreaCodeExist = (areaCode) => {
    return areaCodes.some((area) => area.area_code === areaCode);
  };

  const handleAreaCodeChange = (event) => {
    let areacode = event.target.value;
    setSelectedAreaCode(areacode);
    let checkAreaCode = doesAreaCodeExist(Number(areacode));
    if (!checkAreaCode) {
      setAreaCodeError("Provide a valid area code.");
    } else {
      setAreaCodeError("");
      // setFormData({
      //   ...formData,
      //   area_code: areacode,
      // });
    }
  };

  const handleAssistantNameChange = (event) => {
    let assistant_name = event.target.value;
    setFormData({
      ...formData,
      assistant_name: assistant_name,
    });
  };

  const handleFirstMessageChange = (event) => {
    setInboundMessage(event.target.value);
  };

  // set assistant name as selected staff user name
  const handleStaffChange = async (event) => {
    let staff_user_id = event.target.value;
    let foundStaffUser = staffUsers.find((oneStaff) => oneStaff.id === Number(staff_user_id));

    if (foundStaffUser) {
      setFormData({
        ...formData,
        staff_id: foundStaffUser?.id,
        assistant_name: foundStaffUser?.staff_name,
      });
    }
  };

  const handlePromptChange = (event) => {
    const prompt_id = event.target.value;
    if (formData.prompt_ids.includes(prompt_id)) {
      // If checkbox is already checked, uncheck it and remove from formData
      setFormData((prevState) => ({
        ...prevState,
        prompt_ids: prevState.prompt_ids.filter((id) => id !== prompt_id),
      }));
    } else if (formData.prompt_ids.length < 2) {
      // If less than two checkboxes are checked, check the checkbox and add to formData
      setFormData((prevState) => ({
        ...prevState,
        prompt_ids: [...prevState.prompt_ids, prompt_id],
      }));
    }
  };

  const handleBgSoundChange = async (event) => {
    let bg_sound = event.target.value;
    setFormData({
      ...formData,
      bg_sound: bg_sound,
    });
  };

  const handleCalendarIdChange = async (event) => {
    let calendar_id = event.target.value;
    console.log("calendar_id", calendar_id);
    setFormData({
      ...formData,
      calendar_id: calendar_id,
    });
  };

  const toggleAccordion = (item) => {
    setOpenItem(openItem === item ? null : item);
  };

  const togglePromptPopup = () => {
    setIsPromptCreateOpen(!isPromptCreateOpen);
  };

  const toggleTestAssistantPopup = () => {
    setError(null);
    setSuccess(null);
    formData.temperature = temperature;
    formData.speed = speed;

    if (!formData.assistant_name || !formData.assistant_voice || !formData.meta_assistant_id || !formData.prompt_ids || formData.prompt_ids.length < 1) {
      setError({ message: "Assistant name, voice, smartness level and prompt are required." });
      return;
    }
    setIsTestAssistantOpen(!isTestAssistantOpen);
  };

  const handleSubmitClick = async () => {
    setError(null);
    setSuccess(null);
    setLoading(true);
    setSubmitButtonDisabled(true);
    formData.temperature = temperature;
    formData.speed = speed;
    // formData.inboundPhone = inboundPhone ? inboundPhone : "";
    formData.inboundPhone = inboundPhone ? (inboundPhone.startsWith("+") ? inboundPhone : "+" + inboundPhone) : "";
    formData.inboundMessage = inboundMessage;
    formData.area_code = selectedAreaCode ? selectedAreaCode : 0;
    if (!formData.assistant_name || !formData.assistant_voice || !formData.meta_assistant_id || !formData.prompt_ids || formData.prompt_ids.length < 1) {
      setError({ message: "Assistant name, voice, smartness level and prompt are required." });
      setLoading(false);
      setSubmitButtonDisabled(false);
      return;
    }

    if (formData.phone_provider === "import_twilio") {
      formData.phoneProviderData = twilioData;
    }

    if (formData.phone_provider === "import_vonage") {
      formData.phoneProviderData = vonageData;
    }
    console.log("formData", formData);
    // call server api here
    try {
      const responseData = await createAssistantAPI(formData);
      // console.log("responseData", responseData);
      if (responseData && responseData.status === "success") {
        setFormData({
          ...formData,
          assistant_id: responseData?.data?.id,
        });
        setSuccess(responseData);
      }
    } catch (error) {
      // console.log("error", error);
      setError(error);
    } finally {
      setLoading(false);
      setSubmitButtonDisabled(false);
    }
  };

  const handForwardCalling = (event) => {
    setForwardCalling(event.target.value);
    setInboundPhone("");
    setInboundMessage("");
  };

  const [selectedBuyNumOption, setSelectedBuyNumOption] = useState(formData.phone_provider);

  const toggleNumberOption = (option) => {
    setSelectedBuyNumOption(option);
    setFormData({
      ...formData,
      phoneProviderData: null,
      phone_provider: option,
    });
  };

  const [twilioData, setTwilioData] = useState({
    twilioPhoneNumber: "",
    twilioAccountSid: "",
    twilioAuthToken: "",
  });

  const handleTwilioDataChange = (e) => {
    const { name, value } = e.target;
    setTwilioData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTwilioPhoneChange = (e) => {
    setTwilioData((prevData) => ({
      ...prevData,
      twilioPhoneNumber: "+" + e,
    }));
  };

  const [vonageData, setVonageData] = useState({
    vonagePhoneNumber: "",
    vonageCredentialId: "",
  });

  const handleVonageDataChange = (e) => {
    const { name, value } = e.target;
    setVonageData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleVonagePhoneChange = (e) => {
    setVonageData((prevData) => ({
      ...prevData,
      vonagePhoneNumber: "+" + e,
    }));
  };

  return (
    <div className="p-5">
      <h2 className="text-lg font-semibold mb-5 text-center">{title}</h2>
      <form>
        <div id="accordion-collapse" data-accordion="collapse">
          {/* Assistant Name  */}
          <div>
            <h2 id="accordion-collapse-heading-1">
              <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right  border border-b-0 border-gray-700 rounded-t-xl focus:bg-gray-700 dark:focus:bg-gray-800  dark:border-gray-700  hover:bg-gray-700 dark:hover:bg-gray-800 gap-3" onClick={() => toggleAccordion(1)} aria-expanded={openItem === 1} aria-controls="accordion-collapse-body-1">
                <span>Name of Assistant</span>
                <div className="flex flex-row">
                  <div className="mr-5 text-green-500">{formData.assistant_name}</div>
                  {openItem !== 1 ? <ChevronDown /> : <ChevronUp />}
                </div>
              </button>
            </h2>
            <div id="accordion-collapse-body-1" className={`${openItem === 1 ? "" : "hidden"}`} aria-labelledby="accordion-collapse-heading-1">
              <div className="p-5 border border-b-0 border-gray-200 bg-gray-800 dark:border-gray-700 dark:bg-gray-800">
                {staffUsers && staffUsers.length > 0 ? (
                  <div className="flex flex-col mt-2">
                    <select id="staffusersdropdown" className="border rounded px-4 py-3 bg-black w-full text-white text-sm" value={formData.staff_id} onChange={handleStaffChange}>
                      <option value="">Assign To</option>
                      {staffUsers.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.staff_name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <></>
                )}
                <div className="py-5">
                  <label htmlFor="area_code" className="sr-only">
                    Assistant Name
                  </label>
                  <input id="assistant_name" name="assistant_name" type="text" autoComplete="assistant_name" required className="relative block w-full px-3 py-3 border  bg-gray-950  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Assistant Name" value={formData.assistant_name} onChange={handleAssistantNameChange} />
                </div>
              </div>
            </div>
          </div>
          {/* Assistant Voice */}
          <div>
            <h2 id="accordion-collapse-heading-2">
              <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right  border border-b-0 border-gray-200  focus:bg-gray-700 dark:focus:bg-gray-800 dark:border-gray-700  hover:bg-gray-800 dark:hover:bg-gray-800 gap-3" onClick={() => toggleAccordion(2)} aria-expanded={openItem === 2} aria-controls="accordion-collapse-body-2">
                <span>Assistant Voice</span>
                <div className="flex flex-row">
                  <div className="mr-5 text-green-500 capitalize">{formData.assistant_voice?.name}</div>
                  {openItem !== 2 ? <ChevronDown /> : <ChevronUp />}
                </div>
              </button>
            </h2>
            <div id="accordion-collapse-body-2" className={`${openItem === 2 ? "" : "hidden"}`} aria-labelledby="accordion-collapse-heading-2">
              <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 bg-gray-800">
                <div className="text-white">
                  <div className="py-4 text-gray-300">Select a voice for your assistant.</div>

                  {/* Search form here  */}
                  <div className="flex flex-row gap-3 rounded overflow-hidden shadow-lg py-7 px-2">
                    <div className="flex flex-col">
                      <label className="pb-1">Voice Category</label>
                      <select id="modeldropdown" className="border rounded px-4 py-2 bg-black w-full text-white" value={searchFormData.provider} onChange={handleProviderChange}>
                        <option value="basic">Basic</option>
                        <option value="standard">Standard</option>
                        <option value="premium">Premium</option>
                        <option value="exclusive">Exclusive</option>
                      </select>
                    </div>

                    {!!genders && genders.length > 0 && (
                      <div className="flex flex-col pl-4">
                        <label className="pb-1">Gender</label>{" "}
                        <select id="genderdropdown" className="border rounded px-4 py-2 bg-black w-full text-white capitalize" value={searchFormData.gender} onChange={handleGenderChange}>
                          <option value="">All</option>
                          {genders &&
                            genders.map((oneAcc) => (
                              <option key={oneAcc.gender} value={oneAcc.gender}>
                                {oneAcc.gender}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}

                    {!!accents && accents.length > 0 && (
                      <div className="flex flex-col pl-4">
                        <label className="pb-1">Accent</label>{" "}
                        <select id="accentdropdown" className="border rounded px-4 py-2 bg-black w-full text-white capitalize" value={searchFormData.accent} onChange={handleAccentChange}>
                          <option value="">All</option>
                          {accents &&
                            accents.map((oneAcc) => (
                              <option key={oneAcc.accent} value={oneAcc.accent}>
                                {oneAcc.accent}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}

                    {!!languages && languages.length > 0 && (
                      <div className="flex flex-col pl-4">
                        <label className="pb-1">Language</label>{" "}
                        <select id="languagedropdown" className="border rounded px-4 py-2 bg-black w-full text-white" value={searchFormData.languageCode} onChange={handleLanguageChange}>
                          <option value="">All</option>
                          {languages &&
                            languages.map((oneLang) => (
                              <option disabled={oneLang.languageCode == "disabled"} key={oneLang.languageCode} value={oneLang.languageCode}>
                                {oneLang.language}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="h-80 w-full overflow-auto">
                    <div className="grid grid-cols-3 gap-4 pt-5">
                      {voices &&
                        voices.map(
                          (oneVoice) =>
                            oneVoice.previewUrl && (
                              <div key={oneVoice.id} className={`flex rounded relative overflow-hidden shadow-xl hover:cursor-pointer hover:bg-black ${formData.assistant_voice.id === oneVoice.id ? "bg-black" : "bg-gray-600"}`}>
                                <div className="flex-1 px-6 py-4" onClick={() => handleAssistantVoiceClick(oneVoice)}>
                                  {formData.assistant_voice.id === oneVoice.id && (
                                    <svg className="absolute top-1 right-1 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enableBackground="new 0 0 64 64">
                                      <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50 l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                    </svg>
                                  )}
                                  <div className="font-bold mb-2 capitalize">{oneVoice.name}</div>
                                  <p className="text-sm mt-2 capitalize">
                                    {oneVoice?.gender} {oneVoice?.gender && oneVoice?.accent && "|"} {oneVoice?.accent} {(oneVoice?.gender || oneVoice?.accent) && oneVoice?.language && "|"} {oneVoice?.language}
                                  </p>
                                </div>

                                <div className="w-25 px-6 flex items-center justify-center hover:cursor-pointer">
                                  <AudioPlayer url={oneVoice.previewUrl} />
                                </div>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Assistant Smartness Level */}
          <div>
            <h2 id="accordion-collapse-heading-3">
              <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right  border border-gray-200 focus:bg-gray-700 dark:focus:bg-gray-800 dark:border-gray-700  hover:bg-gray-800 dark:hover:bg-gray-800 gap-3" onClick={() => toggleAccordion(3)} aria-expanded={openItem === 3} aria-controls="accordion-collapse-body-3">
                <span>Smartness Level</span>
                <div className="flex flex-row">
                  <div className="mr-5 text-green-500">{selectedPackage?.title}</div>
                  {openItem !== 3 ? <ChevronDown /> : <ChevronUp />}
                </div>
              </button>
            </h2>
            <div id="accordion-collapse-body-3" className={`${openItem === 3 ? "" : "hidden"}`} aria-labelledby="accordion-collapse-heading-3">
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 bg-gray-800">
                <div className="py-4 text-gray-300">Select how smart your assistant should be.</div>

                <div className="grid grid-cols-4 gap-2">
                  {assistantMeta &&
                    assistantMeta.map((assistant_meta) => (
                      <div key={assistant_meta.id} className={`w-4/5 overflow-hidden shadow-lg hover:cursor-pointer hover:bg-black hover:rounded-md ${selectedPackage?.id === assistant_meta?.id ? "bg-black rounded-md" : ""} p-2 mx-auto`} onClick={() => handleMetaAssistantClick(assistant_meta)}>
                        <img className="w-full h-30 object-cover rounded-md" src="https://via.placeholder.com/150" alt="Card" />
                        <div className="py-4 text-start">
                          <div className="font-bold mb-2">{assistant_meta.title}</div>
                          <p className="text-sm mt-2">Price: $ {assistant_meta.start_price} / min</p>
                          {/* <p className="text-xs mt-2">{assistant_meta.subtitle}</p> */}
                        </div>
                      </div>
                    ))}
                </div>
                {selectedPackage && (
                  <div className="px-5 py-8 flex flex-col">
                    <h3 className="text-md"> {selectedPackage?.title}</h3>
                    <p className="text-sm pt-2 text-gray-300 break-all">{selectedPackage?.subtitle}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Inbound and Outbound call Forwarding */}
          <div>
            <h2 id="accordion-collapse-heading-7">
              <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right  border border-gray-200 focus:bg-gray-700 dark:focus:bg-gray-800 dark:border-gray-700  hover:bg-gray-800 dark:hover:bg-gray-800 gap-3" onClick={() => toggleAccordion(7)} aria-expanded={openItem === 7} aria-controls="accordion-collapse-body-7">
                <span>Incoming Call Handling</span>
                <div className="flex flex-row">
                  <div className="mr-5 ">
                    {!!inboundPhone ? (
                      <>
                        <span>Forward to Phone:</span> <span className="text-green-500"> {inboundPhone} </span>
                      </>
                    ) : (
                      ""
                    )}
                    {!!inboundMessage ? (
                      <>
                        <span>Incoming Call Message:</span> <span className="text-green-500"> {inboundMessage.substr(0, 10)}... </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {openItem !== 7 ? <ChevronDown /> : <ChevronUp />}
                </div>
              </button>
            </h2>
            <div id="accordion-collapse-body-7" className={`${openItem === 7 ? "" : "hidden"}`} aria-labelledby="accordion-collapse-heading-7">
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 bg-gray-800">
                <div className="text-white">
                  {/* Provide a valid area code for phone number. */}
                  <div className="">
                    <div className="py-5">
                      <label className="flex items-center">
                        <input type="radio" name="forwardphone" value="0" checked={forwardCalling == 0} onChange={handForwardCalling} className="mr-2" />
                        Disable Incoming calls
                      </label>
                      <label className="flex items-center mt-5">
                        <input type="radio" name="forwardphone" value="1" checked={forwardCalling == 1} onChange={handForwardCalling} className="mr-2" />
                        Forward purchased number incoming calls
                      </label>

                      {forwardCalling == 1 && (
                        <div className="mt-5">
                          <div className="phone-input-container">
                            <PhoneInput copyNumbersOnly={false} placeholder="Enter call forwarding phone number" country={"us"} value={inboundPhone} onChange={setInboundPhone} />
                          </div>
                        </div>
                      )}

                      <label className="flex items-center mt-5">
                        <input type="radio" name="forwardphone" value="2" checked={forwardCalling == 2} onChange={handForwardCalling} className="mr-2" />
                        Start assistant for incoming calls
                      </label>

                      {forwardCalling == 2 && (
                        <div className="mt-5">
                          <div className="phone-input-container">
                            <input id="first_message" name="first_message" type="text" autoComplete="first_message" required className="relative block w-full px-3 py-3 border  bg-gray-950  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="First Message" value={inboundMessage} onChange={handleFirstMessageChange} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Prompt Selection */}
          <div>
            <h2 id="accordion-collapse-heading-4">
              <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right  border border-gray-200 focus:bg-gray-700 dark:focus:bg-gray-800 dark:border-gray-700  hover:bg-gray-800 dark:hover:bg-gray-800 gap-3" onClick={() => toggleAccordion(4)} aria-expanded={openItem === 4} aria-controls="accordion-collapse-body-4">
                <span>Call Script</span>
                <div className="flex flex-row">
                  <div className="mr-5 text-green-500">
                    {formData?.prompt_ids
                      ?.map((id) => {
                        const prompt = prompts.find((p) => p.id === Number(id));
                        return prompt ? prompt.name : "";
                      })
                      .filter((name) => name !== "")
                      .join(", ")}
                  </div>
                  {openItem !== 4 ? <ChevronDown /> : <ChevronUp />}
                </div>
              </button>
            </h2>
            <div id="accordion-collapse-body-4" className={`${openItem === 4 ? "" : "hidden"}`} aria-labelledby="accordion-collapse-heading-4">
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 bg-gray-800">
                <div className="py-4 text-gray-300 flex justify-between">
                  <div>Select a prompt for your assistant.</div>
                  <div>
                    <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={togglePromptPopup}>
                      <BadgePlus className="inline mb-1 mr-1" size="16" />
                      New Prompt
                    </button>
                  </div>
                </div>

                {prompts && prompts.length > 0 ? (
                  <div className=" bg-black border rounded shadow mt-1 max-h-60 overflow-y-auto w-full">
                    {prompts.map((prompt) => (
                      <div key={prompt.id} className="px-4 py-2">
                        <label className="flex items-center">
                          <input type="checkbox" value={prompt.id} checked={formData.prompt_ids.includes(String(prompt.id))} onChange={handlePromptChange} className="mr-2" disabled={formData.prompt_ids.length === 2 && !formData.prompt_ids.includes(String(prompt.id))} />
                          {prompt.name} - {prompt.second_call ? "Next Call" : "First Call"}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex items-center justify-center text-center">No prompts found</div>
                )}
              </div>
            </div>
          </div>
          {/* Advanced options */}
          <div>
            <h2 id="accordion-collapse-heading-5">
              <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right  border border-gray-200 focus:bg-gray-700 dark:focus:bg-gray-800 dark:border-gray-700  hover:bg-gray-800 dark:hover:bg-gray-800 gap-3" onClick={() => toggleAccordion(5)} aria-expanded={openItem === 5} aria-controls="accordion-collapse-body-5">
                <span>Advanced Options</span>
                <div className="flex flex-row">
                  <div className="mr-5">
                    Speed: <span className="text-green-500">{speed}, </span>
                    Creative Level: <span className="text-green-500">{temperature}, </span>
                    Background Sound: <span className="text-green-500">{formData.bg_sound}</span>
                  </div>
                  {openItem !== 5 ? <ChevronDown /> : <ChevronUp />}
                </div>
              </button>
            </h2>
            <div id="accordion-collapse-body-5" className={`${openItem === 5 ? "" : "hidden"}`} aria-labelledby="accordion-collapse-heading-5">
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 bg-gray-800">
                <div>Advanced options</div>
                <div className="py-2 grid grid-cols-3">
                  <div>Creative Level</div>
                  <NumberSlider min={0.1} max={2.0} step={0.1} handleChange={setTemperature} defaultValue={temperature} />
                </div>

                <div className="py-2 grid grid-cols-3">
                  <div>Speed</div>
                  <NumberSlider min={0.1} max={2} step={0.1} handleChange={setSpeed} defaultValue={speed} />
                </div>

                <div className="py-2 grid grid-cols-3">
                  <div>Background sound</div>

                  <div className="flex flex-col mt-2 space-y-4">
                    <select id="bgSoundDropdown" className="border rounded px-2 py-1 bg-black w-full text-white text-sm" value={formData.bg_sound} onChange={handleBgSoundChange}>
                      {/* <option value="off">Default</option> */}
                      <option value="off">Off</option>
                      <option value="office">Office</option>
                    </select>
                  </div>
                </div>
                <div className="py-2 grid grid-cols-3">
                  <div>GHL Calendar ID (Optional)</div>
                  <input id="calendar_id" name="calendar_id" type="text" autoComplete="calendar_id" required className="relative block w-full px-3 py-3 border  bg-gray-950  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Enter GHL Calendar ID" value={formData.calendar_id} onChange={handleCalendarIdChange} />
                </div>
              </div>
            </div>
          </div>
          {/* Purchase a number (optional) */}
          <div>
            <h2 id="accordion-collapse-heading-6">
              <button type="button" className="flex items-center justify-between w-full p-5 font-medium rtl:text-right  border border-gray-200 focus:bg-gray-700 dark:focus:bg-gray-800 dark:border-gray-700  hover:bg-gray-800 dark:hover:bg-gray-800 gap-3" onClick={() => toggleAccordion(6)} aria-expanded={openItem === 6} aria-controls="accordion-collapse-body-6">
                <span>Purchase a Number (Optional)</span>
                <div className="flex flex-row">
                  <div className="mr-5 ">
                    {/* {selectedAreaCode > 0 ? (
                      <>
                        <span>Area Code:</span> <span className="text-green-500"> {selectedAreaCode} </span>
                      </>
                    ) : (
                      ""
                    )} */}
                    {formData.phone_provider ? (
                      <>
                        <span>Phone Number Option:</span> <span className="text-green-500"> {formData.phone_provider === "import_twilio" ? "Import from Twilio" : formData.phone_provider === "import_vonage" ? "Import from Vonage" : "Purchase new Number"} </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {openItem !== 6 ? <ChevronDown /> : <ChevronUp />}
                </div>
              </button>
            </h2>
            <div id="accordion-collapse-body-6" className={`${openItem === 6 ? "" : "hidden"}`} aria-labelledby="accordion-collapse-heading-6">
              <div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700 bg-gray-800">
                {/* Buy Phone number options */}
                <div className="flex flex-row justify-center gap-4">
                  <div
                    className={`bg-blue-600 rounded-md p-2 cursor-pointer hover:bg-green-600 ${selectedBuyNumOption === "buy_vapi" ? "bg-green-600" : ""}`}
                    onClick={() => {
                      toggleNumberOption("buy_vapi");
                    }}
                  >
                    Purchase Number
                  </div>
                  <div
                    className={`bg-blue-600 rounded-md p-2 cursor-pointer hover:bg-green-600 ${selectedBuyNumOption === "import_twilio" ? "bg-green-600" : ""}`}
                    onClick={() => {
                      toggleNumberOption("import_twilio");
                    }}
                  >
                    Import Twilio Number
                  </div>
                  <div
                    className={`bg-blue-600 rounded-md p-2 cursor-pointer hover:bg-green-600 ${selectedBuyNumOption === "import_vonage" ? "bg-green-600" : ""}`}
                    onClick={() => {
                      toggleNumberOption("import_vonage");
                    }}
                  >
                    Import Vonage Number
                  </div>
                </div>

                <div className="text-white">
                  {selectedBuyNumOption === "buy_vapi" && (
                    <div>
                      Provide a valid area code for phone number.
                      <div className="">
                        <div className="py-5">
                          <label htmlFor="area_code" className="sr-only">
                            Area code
                          </label>
                          <input id="area_code" name="area_code" type="number" autoComplete="area_code" required className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Area code" value={selectedAreaCode} onChange={handleAreaCodeChange} />
                        </div>
                      </div>
                      <div className="flex text-red-600 justify-center">{areaCodeError}</div>
                    </div>
                  )}
                  {selectedBuyNumOption === "import_twilio" && (
                    <div>
                      Provide Twilio phone number to import.
                      <div className="">
                        <div className="py-5">
                          {/* <input id="twilioPhoneNumber" name="twilioPhoneNumber" type="string" autoComplete="twilioPhoneNumber" className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Twilio PhoneNumber" value={twilioData.twilioPhoneNumber} onChange={handleTwilioDataChange} /> */}
                          <div className="mt-5">
                            <div className="phone-input-container">
                              <PhoneInput name="twilioPhoneNumber" copyNumbersOnly={false} placeholder="Enter twilio phone number" country={"us"} value={twilioData.twilioPhoneNumber} onChange={handleTwilioPhoneChange} />
                            </div>
                          </div>{" "}
                        </div>
                        <div className="py-5">
                          <input id="twilioAccountSid" name="twilioAccountSid" type="string" autoComplete="twilioAccountSid" className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Twilio AccountSid" value={twilioData.twilioAccountSid} onChange={handleTwilioDataChange} />
                        </div>
                        <div className="py-5">
                          <input id="twilioAuthToken" name="twilioAuthToken" type="string" autoComplete="twilioAuthToken" className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Twilio Auth Token" value={twilioData.twilioAuthToken} onChange={handleTwilioDataChange} />
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedBuyNumOption === "import_vonage" && (
                    <div>
                      Provide Vonage phone number to import.
                      <div className="">
                        <div className="py-5">
                          <div className="mt-5">
                            <div className="phone-input-container">
                              <PhoneInput name="vonagePhoneNumber" copyNumbersOnly={false} placeholder="Enter vonage phone number" country={"us"} value={vonageData.vonagePhoneNumber} onChange={handleVonagePhoneChange} />
                            </div>
                          </div>
                        </div>
                        <div className="py-5">
                          <input id="vonageCredentialId" name="vonageCredentialId" type="string" autoComplete="vonageCredentialId" className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Vonage CredentialId" value={vonageData.vonageCredentialId} onChange={handleVonageDataChange} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {isPromptCreateOpen && <CreatePromptWithMeta isOpen={isPromptCreateOpen} onClose={togglePromptPopup} fetchPromptsDataFromApi={fetchPromptsDataFromApi} />}

      {isTestAssistantOpen && <TestAssistant isOpen={isTestAssistantOpen} onClose={toggleTestAssistantPopup} formData={formData} />}

      <div className="flex flex-column">
        <div className="w-1/2 mr-5 text-white pt-5">{selectedPackage?.start_price ? "Price per minute: $ " + selectedPackage?.start_price : ""}</div>
        <div className="flex flex-row w-1/2  gap-4 justify-end">
          {/* submit assistant button */}
          <button disabled={submitButtonDisabled} className={`text-white justify-end mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  ${submitButtonDisabled ? "bg-blue-900 cursor-not-allowed" : " focus:ring-indigo-500"} `} type="button" onClick={handleSubmitClick}>
            {loading ? "Loading..." : "Save Assistant"}
          </button>
          {/* test assistant button */}
          <button type="button" className={`text-white justify-end mt-5 hover:bg-green-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2 bg-green-600 cursor-not-allowed"`} onClick={toggleTestAssistantPopup}>
            Test Assistant
          </button>
        </div>
      </div>
      <div>
        <div className="flex text-red-600 justify-center">{error?.message}</div>
        <div className="flex text-green-600 justify-center">{success?.message}</div>
      </div>
    </div>
  );
};

export default CreateNewAssistant;
