import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { updatePassword } from "../../../services/user";
import { Eye, EyeOff } from "lucide-react";

const UpdatePassword = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    setSuccess(null);
    setError(null);
    // call server api here
    try {
      const responseData = await updatePassword(data);
      // console.log("responseData", responseData);
      setSuccess(responseData);
      reset();
    } catch (error) {
      // console.log("errorData", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="mb-4 bg-back-dark p-5 rounded-lg">
      <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-xl pb-2">Password</h2>
        <div className="relative">
          <input
            id="newPassword"
            name="newPassword"
            type={showPassword ? "text" : "password"} // Toggle password visibility
            autoComplete="newPassword"
            required
            {...register("newPassword")}
            className="block w-full px-3 py-2 border bg-gray-900 rounded-md outline-none border-gray-800 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm pr-10" // Adjusted for icon
            placeholder="New Password"
          />
          <button type="button" className="absolute inset-y-0 right-0 pr-3 flex items-center" onClick={togglePasswordVisibility}>
            {showPassword ? <EyeOff /> : <Eye />} {/* Visibility toggle icon */}
          </button>
        </div>

        <div>
          <button type="submit" className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
            Update New Password
          </button>
        </div>
      </form>
      <div className="pt-2 text-red-600">{error?.message}</div>
      <div className="pt-2 text-green-600">{success?.message}</div>
    </div>
  );
};

export default UpdatePassword;
