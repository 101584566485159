import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchSingleAssistantAPI } from "../../../../services/assistant";
import CreateNewAssistant from "./CreateNewAssistant";

const CopyAssistant = () => {
  const { assistant_id } = useParams();
  const [existingAssistant, setExistingAssistant] = useState(null);
  const [error, setError] = useState(null);

  const fetchSingleAssistantFromAPI = async (assistant_id) => {
    try {
      const responseData = await fetchSingleAssistantAPI(assistant_id);
      if (responseData && responseData.status === "success") {
        let data = {
          assistant_id: "",
          meta_assistant_id: responseData.data.assistant_meta_id,
          assistant_voice: responseData.data.assistant_voice,
          assistant_name: responseData.data.name,
          staff_id: responseData.data.staff_id,
          prompt_ids: responseData.data.prompt_ids,
          bg_sound: responseData.data.bg_sound,
          temperature: responseData.data.temperature,
          speed: responseData.data.speed,
          inboundPhone: responseData.data.inboundPhone,
          inboundMessage: responseData.data.inboundMessage,
          phone_provider: responseData.data.phone_provider,
          phone_provider_data: responseData.data.phone_provider_data,
          calendar_id: responseData.data.calendar_id,
        };

        setExistingAssistant(data);
      }
    } catch (error) {
      console.log("error", error);
      setError(error);
    } finally {
    }
  };

  useEffect(() => {
    if (assistant_id) {
      // call api to get data
      fetchSingleAssistantFromAPI(assistant_id);
    }
  }, []);

  if (error) {
    return <div>Something went wrong</div>;
  }
  return <div>{existingAssistant && <CreateNewAssistant existingAssistant={existingAssistant} />}</div>;
};

export default CopyAssistant;
