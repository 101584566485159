import { getAccessToken, logOut } from "./token";

export const fetchAllCallsAPI = async () => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/calls`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchSingleCallAPI = async (call_id) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/calls/${call_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCallsPerDateAPI = async (postData) => {
  let { startDate, endDate, assistant_id } = postData;
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/calls/call_per_date?startDate=${startDate}&endDate=${endDate}&assistant_id=${assistant_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};
