import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Popup from "../../../components/Popup";
import { createPrompt } from "../../../services/prompt";

const CreatePrompt = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm({
    defaultValues: {
      type: "simple_prompt",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    // call server api here
    try {
      const responseData = await createPrompt(data);
      if (responseData && responseData.status === "success") {
        return navigate(`/dashboard/prompt-meta/${responseData?.data?.id}`);
        // onClose();
        // fetchDataFromApi();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title="Create New Prompt" mdWidth="max-w-md">
      <form className="mt-6 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="rounded-md shadow-sm -space-y-px">
          <div className="pb-5">
            <label htmlFor="name" className="sr-only">
              Name
            </label>
            <input id="name" name="name" type="text" autoComplete="name" required {...register("name")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Name" />
          </div>
          <div className="pb-5">
            <label className="block text-sm font-medium text-gray-300">Select Option</label>
            <div className="flex items-center space-x-4 mt-2">
              <label className="inline-flex items-center">
                <input type="radio" name="type" value="simple_prompt" {...register("type")} className="form-radio text-indigo-600 focus:ring-indigo-500 border-gray-300" />
                <span className="ml-2">Simple Prompt</span>
              </label>
              <label className="inline-flex items-center">
                <input type="radio" name="type" value="structural_prompt" {...register("type")} className="form-radio text-indigo-600 focus:ring-indigo-500 border-gray-300" defaultChecked />
                <span className="ml-2">Structural Prompt</span>
              </label>
            </div>
          </div>
        </div>
        <div>
          <button
            type="submit"
            disabled={loading}
            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                      ${loading ? "bg-gray-400 cursor-not-allowed" : "hover:bg-indigo-700 focus:ring-indigo-500"}`}
          >
            Submit
          </button>
          <div className="mt-5 text-center text-red-500">{error?.message}</div>
        </div>
      </form>
    </Popup>
  );
};

export default CreatePrompt;
