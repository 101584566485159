import React, { useEffect, useState } from "react";
import { fetchAllCallsAPI } from "../../../services/call";
import AudioPlayer from "../../../components/AudioPlayer";
import { useNavigate } from "react-router-dom";

const Calls = () => {
  const [calls, setCalls] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchAllCallsAPI();
      // console.log("responseData", responseData);
      setCalls(responseData.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();

    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes; // adding leading zero if needed

    const formattedDate = `${month} ${day}, ${year} @ ${hours}:${minutesStr} ${ampm}`;

    return formattedDate;
  };

  const handleCallTranscriptClick = (call_id) => {
    navigate(`/dashboard/call-transcript/${call_id}`);
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90"></div>
        </div>
      ) : (
        <div>
          {calls && calls.length > 0 ? (
            <>
              <div className="w-full flex justify-end">
                <div className="py-5 flex gap-3 px-5"></div>
              </div>

              <div className="px-5">
                <table className="min-w-full divide-y bg-back-dark divide-color-lines rounded-lg">
                  <thead className="min-w-full rounded-md divide-color-lines">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Assistant
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Phone Number
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        End Reason
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Call Result
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Call Recording
                      </th>
                      <th scope="col" className="px-6 py-3 text-center text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Transcript
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-back-dark divide-y divide-color-lines">
                    {calls.map((call) => (
                      <tr key={call.id} className="hover:bg-gray-900 text-sm">
                        <td className="px-6 py-4 whitespace-wrap">{formatDate(call.createdAt)}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{call.assistant?.name}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{call.status}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{call.phone_number}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{call.endedReason}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">{call.gpt_analysis}</td>
                        <td className="px-6 py-4 whitespace-wrap text-center">
                          <AudioPlayer url={call.recordingUrl} />
                        </td>
                        <td className="px-6 py-4 whitespace-wrap text-center">
                          <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={() => handleCallTranscriptClick(call.id)}>
                            Transcript
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center text-center text-xl pt-10">
              <div>No Calls found.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Calls;
