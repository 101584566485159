import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { createNewIntentPromptsAPI, fetchSingleIntentPromptsAPI } from "../../../services/intent_prompt";

const CreateIntentPrompt = () => {
  const { id } = useParams();
  // console.log("id-------------", id);
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [intentPrompt, setIntentPrompt] = useState(null);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      id: intentPrompt?.id,
      name: "",
      type: "call",
      prompt_text: "",
    },
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const fetchDataFromApi = async () => {
    try {
      if (id) {
        const responseData = await fetchSingleIntentPromptsAPI(id);
        // console.log("responseData", responseData.data);
        setIntentPrompt(responseData.data);
      }
    } catch (error) {
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, [id]);

  useEffect(() => {
    reset({
      id: id,
      name: intentPrompt?.name,
      type: intentPrompt?.type,
      prompt_text: intentPrompt?.prompt_text,
    });
  }, [intentPrompt, reset]);

  const onSubmit = async (data) => {
    // console.log("data to submit", data);
    setLoading(true);
    // call server api here
    try {
      const responseData = await createNewIntentPromptsAPI(data);
      if (responseData && responseData.status === "success") {
        setSuccess(responseData);
        // window.location.reload();
        return navigate(`/dashboard/intent-prompt/save/${responseData?.data?.id}`);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-row gap-4 px-10 pb-24">
      <div className=" flex items-center justify-center w-full">
        <div className=" w-full">
          <form className="space-y-4 " onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-end">
              <div className="flex flex-col mt-5">
                {/* <div>
                <AssistantDropDown singlePrompt={singlePrompt} />
              </div> */}
                <button
                  type="submit"
                  className={`group flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
  ${loading ? "bg-gray-400 cursor-not-allowed" : "hover:bg-blue-700 focus:ring-blue-500"}`}
                >
                  Save Changes
                </button>
                <div className="flex justify-center">
                  {!!loading && <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90  mt-4"></div>}
                  {!!error && !loading && <div className="text-center text-red-500">{error?.message}</div>}
                  {!loading && <div className="pt-2 text-green-600">{success?.message}</div>}
                </div>
                {/* <div>
                <AssistantDropDown singlePrompt={singlePrompt} />
              </div> */}
              </div>
            </div>

            <div className="flex flex-row w-full gap-4">
              <div className="w-full">
                <div className="">
                  <label htmlFor="" className="">
                    Type
                  </label>
                  <select id="typedropdown" className="text-white  block w-full px-3 py-3  my-4 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm" {...register("type")}>
                    <option value="call">Call</option>
                    <option value="sms">SMS</option>
                    <option value="auto_reply_sms">AutoReply SMS</option>
                  </select>
                </div>
                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Name
                  </label>
                  <input id="name" name="name" type="text" required {...register("name")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Intent Prompt Text
                  </label>
                  <textarea id="prompt_text" name="prompt_text" type="text" required {...register("prompt_text")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" cols={4} rows={6} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateIntentPrompt;
