import React, { useEffect, useState } from "react";
import Popup from "../../../../components/Popup";
// import Vapi from "@vapi-ai/web";
import { vapi } from "../../../../hooks/vapi.sdk";

import { createTestCallAPI, getTestAssistantWebCallAPI } from "../../../../services/assistant";
import Loader from "../../../../components/Loader";

const TestAssistant = ({ isOpen, onClose, formData }) => {
  // const vapi = new Vapi(process.env.REACT_APP_VAPI_PUBLICK_KEY);

  const usPhoneRegex = /^(\+1|1)?\s*\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  // call from web sdk states
  const [usingWebLoading, setUsingWebLoading] = useState(false);
  const [webCallStarted, setWebCallStarted] = useState(false);
  const [webCallEndLoading, setWebCallEndLoading] = useState(false);

  // assistant states
  const [assistant, setAssistant] = useState(null);
  const [assistantLoading, setAssistantLoading] = useState(false);
  const [assistantError, setAssistantError] = useState(null);

  // call form phone states
  const [usingPhoneLoading, setUsingPhoneLoading] = useState(false);
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [targetPhone, setTargetPhone] = useState("");
  const [phoneCallStarted, setPhoneCallStarted] = useState(false);

  const fetchAssistantDataFromApi = async () => {
    setAssistantLoading(true);
    try {
      const responseData = await getTestAssistantWebCallAPI(formData);
      // console.log("responseData", responseData);
      setAssistant(responseData.data);
    } catch (error) {
      setAssistantError(error);
    } finally {
      setAssistantLoading(false);
    }
  };

  useEffect(() => {
    fetchAssistantDataFromApi();
  }, []);

  const handleUsingWebClick = () => {
    setAssistantError(null);

    try {
      if (!assistant) {
        setAssistantError({ message: "Assistant not found." });
        return;
      }
      setUsingWebLoading(!usingWebLoading);
      // vapi.start("7bcdd33f-2cf9-4a05-b2cb-b26c6a098a3a");
      let postData = {
        transcriber: assistant.transcriber,
        model: assistant.model,
        // voice: assistant.voice,
        voice: {
          // production
          provider: assistant.voice.provider,
          voiceId: assistant.voice.providerId,
          // testing
          // provider: "playht",
          // voiceId: "s3://voice-cloning-zero-shot/4c627545-b9c0-4791-ae8e-f48f5475247c/bryansaad/manifest.json",
        },

        name: assistant.name,
        firstMessage: assistant.firstMessage || "",
        endCallMessage: assistant.endCallMessage || "",
        endCallPhrases: assistant.endCallPhrases || "",
        backgroundSound: assistant.backgroundSound,
        recordingEnabled: assistant.recordingEnabled,
        voicemailMessage: assistant.voicemailMessage,
        maxDurationSeconds: assistant.maxDurationSeconds,
        responseDelaySeconds: assistant.responseDelaySeconds,
        silenceTimeoutSeconds: assistant.silenceTimeoutSeconds,
        endCallFunctionEnabled: assistant.endCallFunctionEnabled,
        llmRequestDelaySeconds: assistant.llmRequestDelaySeconds,
        dialKeypadFunctionEnabled: assistant.dialKeypadFunctionEnabled,
        numWordsToInterruptAssistant: assistant.numWordsToInterruptAssistant,
        analysisPlan: assistant?.analysisPlan,
      };

      // console.log("postData", postData);
      vapi.start(postData);
    } catch (error) {
      setAssistantError(error);
    }
  };

  useEffect(() => {
    vapi.on("call-start", () => {
      console.log("Call has started.");
      setWebCallStarted(!webCallStarted);
    });
  }, []);

  useEffect(() => {
    vapi.on("call-end", () => {
      console.log("Call has ended.");
      setWebCallStarted(false);
      setUsingWebLoading(false);
    });
  }, []);

  const handleEndWebClick = () => {
    try {
      setWebCallEndLoading(true);
      vapi.stop();
    } catch (error) {
      console.log("error in stopping call");
    }
  };
  useEffect(() => {
    vapi.on("error", (e) => {
      console.error(e);
      setAssistantError({ message: e?.errorMsg });

      setWebCallStarted(false);
      setUsingWebLoading(false);
      vapi.stop();
    });
  }, []);

  const handleUsingPhoneClick = () => {
    // setUsingPhoneLoading(!usingPhoneLoading);
    setShowPhoneInput(!showPhoneInput);
  };

  const handleInputChange = (event) => {
    setTargetPhone(event.target.value);
  };

  const handleCallUsingPhoneClick = async () => {
    formData.target_phone = targetPhone;

    if (!formData.target_phone || !usPhoneRegex.test(formData.target_phone)) {
      setAssistantError({ message: "Provide a valid phone usa number." });
      return;
    }

    try {
      setUsingPhoneLoading(true);

      const responseData = await createTestCallAPI(formData);
      // console.log("responseData", responseData);
      if (responseData && responseData.status === "success") {
        setPhoneCallStarted(true);
      }
    } catch (error) {
      setAssistantError(error);
    } finally {
      setUsingPhoneLoading(false);
    }
  };

  const handleEndCallUsingPhoneClick = async () => {
    console.log("logic to end call here ");
    setPhoneCallStarted(false);
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title="Test Assistant" mdWidth="max-w-md">
      {assistantLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex flex-row gap-4 justify-center">
            {/* call from vapi web sdk button */}
            {webCallStarted ? (
              <button type="button" className={`text-white justify-end mt-5 bg-red-700 hover:bg-red-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2   `} onClick={handleEndWebClick}>
                {webCallEndLoading ? "Ending call..." : "End Call"}
              </button>
            ) : (
              <button disabled={!assistant} type="button" className={`text-white justify-end mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  ${usingWebLoading ? "bg-blue-900 cursor-not-allowed" : ""} `} onClick={handleUsingWebClick}>
                {usingWebLoading ? "Starting call..." : "Test using web"}
              </button>
            )}

            {/* call from number button */}
            {/* disabled={submitButtonDisabled} */}
            <button disabled={!assistant} className={`text-white justify-end mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2 `} type="button" onClick={handleUsingPhoneClick}>
              {/* {usingPhoneLoading ? "Loading..." : "Test using phone"} */}
              Test using phone
            </button>
          </div>

          {/* Target phone field */}
          {showPhoneInput && (
            <div className="flex flex-col mt-5">
              <div className="py-2">
                <input id="target_phone" name="target_phone" type="text" value={targetPhone} onChange={handleInputChange} required className="relative block w-full px-3 py-3 border  bg-gray-950  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Target phone number" />
              </div>

              {phoneCallStarted ? (
                <button className={`flex text-white justify-center mt-5 bg-red-700 hover:bg-red-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2 `} type="button" onClick={handleEndCallUsingPhoneClick}>
                  {/* {usingPhoneLoading ? "Loading..." : "Start call"} */}
                  Call Stareted
                </button>
              ) : (
                <button disabled={usingPhoneLoading} className={`flex text-white justify-center mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  ${usingPhoneLoading ? "bg-blue-900 cursor-not-allowed" : ""} `} type="button" onClick={handleCallUsingPhoneClick}>
                  {usingPhoneLoading ? "Loading..." : "Start call"}
                </button>
              )}
            </div>
          )}

          <div className="flex text-red-600 justify-center mt-4">{assistantError?.message}</div>
        </div>
      )}
    </Popup>
  );
};

export default TestAssistant;
