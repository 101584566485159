import React from "react";
import Calls from "./dashboardpages/Calls";

const Dashboard = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      {/* <h1 className="text-3xl font-bold text-center py-5">Coming soon...</h1> */}
      <div className="w-3/4">
        <Calls />
      </div>
    </div>
  );
};

export default Dashboard;
