// Sidebar.js
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { CircleGauge, CircleUser, Contact, LayoutDashboard, MessageSquareText, SquareUser, LogOut, ShieldQuestion, ShieldAlert, PhoneCall, MessageSquareCode } from "lucide-react";
import { useAuth } from "../hooks/AuthProvider";

function Sidebar() {
  const [selectedItem, setSelectedItem] = useState(1);
  const auth = useAuth();
  const currentPath = useLocation().pathname;
  console.log(currentPath);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  let items = [
    {
      id: 1,
      name: "Dashboard",
      link: "/dashboard",
      icon: <LayoutDashboard />,
    },
    {
      id: 2,
      name: "Campaigns",
      link: "campaigns",
      icon: <CircleGauge />,
    },
    {
      id: 9,
      name: "Call Logs",
      link: "calls",
      icon: <PhoneCall />,
    },
    {
      id: 3,
      name: "Assistants",
      link: "assistants",
      icon: <SquareUser />,
    },
    {
      id: 4,
      name: "Prompts",
      link: "prompts",
      icon: <MessageSquareText />,
    },
    {
      id: 5,
      name: "Intent Prompt",
      link: "intent-prompt",
      icon: <MessageSquareText />,
    },
    {
      id: 6,
      name: "FAQ",
      link: "faqs",
      icon: <ShieldQuestion />,
    },
    {
      id: 7,
      name: "Objections",
      link: "objections",
      icon: <ShieldAlert />,
    },
    {
      id: 8,
      name: "Contacts",
      link: "contacts",
      icon: <Contact />,
    },
    {
      id: 10,
      name: "SMS Providers",
      link: "sms-providers",
      icon: <MessageSquareCode />,
    },
    {
      id: 11,
      name: "Account",
      link: "accounts",
      icon: <CircleUser />,
    },
  ];

  useEffect(() => {
    items.forEach((itm) => {
      if (currentPath === "/dashboard/" + itm.link && selectedItem !== itm) {
        console.log("match", itm);
        setSelectedItem(itm);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <div>
      <div className="bg-slate-950 text-white h-full fixed top-0 left-0 bottom-0 overflow-y-auto w-sidebar">
        <div className="noise-overlay"></div>
        <div className="self-center text-2xl font-semibold whitespace-nowrap text-center dark:text-white pt-5">AI Calls</div>
        <ul className="pl-5 pt-5">
          {items.map((item) => (
            <li key={item.id} className="py-1">
              <NavLink to={item.link} className={`block px-4 bg-gradient-to-r hover:from-blue-700 hover:to-blue-900 hover:rounded-l-md  ${selectedItem.id === item.id ? "from-blue-700 to-blue-900 rounded-l-md" : ""}`} onClick={() => handleItemClick(item)}>
                <div className="flex flex-row gap-2 py-2">
                  {item.icon}
                  {item.name}
                </div>
              </NavLink>
            </li>
          ))}
          <li className="py-1 absolute bottom-5 left-5 right-0">
            <div onClick={() => auth.logOut()} className={`block cursor-pointer px-4 bg-gradient-to-r hover:from-blue-700 hover:to-blue-900 hover:rounded-l-md`}>
              <div className="flex flex-row gap-2 py-2">
                <LogOut />
                Logout
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className="bg-slate-950 fixed w-dashboard left-dashboard border-b border-gray-200 dark:border-gray-600 flex justify-start p-5">
        {selectedItem.icon}
        <span className="inline-block ml-2">{selectedItem.name}</span>
      </div>
    </div>
  );
}

export default Sidebar;
