import React from "react";
import UpdateEmail from "./UpdateEmail";
import UpdateGHLKey from "./UpdateGHLKey";
import UpdatePassword from "./UpdatePassword";
import Payment from "./payments/Payment";
import Availability from "./availability/availability";

const Accounts = () => {
  return (
    <div>
      <div className="w-full flex justify-start">
        <div className="py-5 flex gap-3 px-5 font-bold text-xl"></div>
      </div>
      <div className="flex justify-center px-5 gap-4">
        {/* Left Div */}
        <div className="w-1/2">
          <UpdateEmail />
          <UpdatePassword />
          <Availability />
        </div>
        {/* Right Div */}
        <div className="w-1/2">
          <UpdateGHLKey />
          <Payment />
        </div>
      </div>
    </div>
  );
};

export default Accounts;
