export const areaCodes = [
  {
    area_code: 201,
    city: "Bayonne",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Bergenfield",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Cliffside Park",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Englewood",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Fair Lawn",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Fort Lee",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Hackensack",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Hoboken",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Jersey City",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Kearny",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "North Bergen",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Paramus",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Ridgewood",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Teaneck",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "Union City",
    state: "New Jersey",
  },
  {
    area_code: 201,
    city: "West New York",
    state: "New Jersey",
  },
  {
    area_code: 202,
    city: "Washington",
    state: "District of Columbia",
  },
  {
    area_code: 203,
    city: "Bridgeport",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Danbury",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "East Haven",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Meriden",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Milford",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Naugatuck",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "New Haven",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "North Haven",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Norwalk",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Shelton",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Stamford",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Stratford",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Trumbull",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Waterbury",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "West Haven",
    state: "Connecticut",
  },
  {
    area_code: 203,
    city: "Westport",
    state: "Connecticut",
  },
  {
    area_code: 205,
    city: "Alabaster",
    state: "Alabama",
  },
  {
    area_code: 205,
    city: "Bessemer",
    state: "Alabama",
  },
  {
    area_code: 205,
    city: "Birmingham",
    state: "Alabama",
  },
  {
    area_code: 205,
    city: "Center Point",
    state: "Alabama",
  },
  {
    area_code: 205,
    city: "Homewood",
    state: "Alabama",
  },
  {
    area_code: 205,
    city: "Hoover",
    state: "Alabama",
  },
  {
    area_code: 205,
    city: "Mountain Brook",
    state: "Alabama",
  },
  {
    area_code: 205,
    city: "Tuscaloosa",
    state: "Alabama",
  },
  {
    area_code: 205,
    city: "Vestavia Hills",
    state: "Alabama",
  },
  {
    area_code: 206,
    city: "Bainbridge Island",
    state: "Washington",
  },
  {
    area_code: 206,
    city: "Burien",
    state: "Washington",
  },
  {
    area_code: 206,
    city: "Des Moines",
    state: "Washington",
  },
  {
    area_code: 206,
    city: "Mercer Island",
    state: "Washington",
  },
  {
    area_code: 206,
    city: "Seattle",
    state: "Washington",
  },
  {
    area_code: 206,
    city: "Shoreline",
    state: "Washington",
  },
  {
    area_code: 206,
    city: "White Center",
    state: "Washington",
  },
  {
    area_code: 207,
    city: "Auburn",
    state: "Maine",
  },
  {
    area_code: 207,
    city: "Augusta",
    state: "Maine",
  },
  {
    area_code: 207,
    city: "Bangor",
    state: "Maine",
  },
  {
    area_code: 207,
    city: "Biddeford",
    state: "Maine",
  },
  {
    area_code: 207,
    city: "Brunswick",
    state: "Maine",
  },
  {
    area_code: 207,
    city: "Lewiston",
    state: "Maine",
  },
  {
    area_code: 207,
    city: "South Portland",
    state: "Maine",
  },
  {
    area_code: 207,
    city: "South Portland",
    state: "Maine",
  },
  {
    area_code: 208,
    city: "Boise",
    state: "Idaho",
  },
  {
    area_code: 208,
    city: "Caldwell",
    state: "Idaho",
  },
  {
    area_code: 208,
    city: "Coeur d'Alene",
    state: "Idaho",
  },
  {
    area_code: 208,
    city: "Idaho Falls",
    state: "Idaho",
  },
  {
    area_code: 208,
    city: "Lewiston",
    state: "Idaho",
  },
  {
    area_code: 208,
    city: "Meridian",
    state: "Idaho",
  },
  {
    area_code: 208,
    city: "Moscow",
    state: "Idaho",
  },
  {
    area_code: 208,
    city: "Nampa",
    state: "Idaho",
  },
  {
    area_code: 208,
    city: "Pocatello",
    state: "Idaho",
  },
  {
    area_code: 208,
    city: "Twin Falls",
    state: "Idaho",
  },
  {
    area_code: 209,
    city: "Atwater",
    state: "California",
  },
  {
    area_code: 209,
    city: "Ceres",
    state: "California",
  },
  {
    area_code: 209,
    city: "Lodi",
    state: "California",
  },
  {
    area_code: 209,
    city: "Los Banos",
    state: "California",
  },
  {
    area_code: 209,
    city: "Manteca",
    state: "California",
  },
  {
    area_code: 209,
    city: "Merced",
    state: "California",
  },
  {
    area_code: 209,
    city: "West Modesto",
    state: "California",
  },
  {
    area_code: 209,
    city: "Stockton",
    state: "California",
  },
  {
    area_code: 209,
    city: "Tracy",
    state: "California",
  },
  {
    area_code: 209,
    city: "Turlock",
    state: "California",
  },
  {
    area_code: 210,
    city: "San Antonio",
    state: "Texas",
  },
  {
    area_code: 212,
    city: "New York City",
    state: "New York",
  },
  {
    area_code: 212,
    city: "New York City",
    state: "New York",
  },
  {
    area_code: 213,
    city: "Los Angeles",
    state: "California",
  },
  {
    area_code: 214,
    city: "Allen",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Arlington",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Carrollton",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Cedar Hill",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Coppell",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Dallas",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "DeSoto",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Duncanville",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Farmers Branch",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Flower Mound",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Frisco",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Garland",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Grand Prairie",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Irving",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Lancaster",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Lewisville",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "McKinney",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Mesquite",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Plano",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Richardson",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Rowlett",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "The Colony",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "University Park",
    state: "Texas",
  },
  {
    area_code: 214,
    city: "Waxahachie",
    state: "Texas",
  },
  {
    area_code: 215,
    city: "Levittown",
    state: "Pennsylvania",
  },
  {
    area_code: 215,
    city: "Philadelphia",
    state: "Pennsylvania",
  },
  {
    area_code: 216,
    city: "Brook Park",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "East Cleveland",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "Cleveland Heights",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "East Cleveland",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "South Euclid",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "Garfield Heights",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "Lakewood",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "Maple Heights",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "Parma",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "Shaker Heights",
    state: "Ohio",
  },
  {
    area_code: 216,
    city: "South Euclid",
    state: "Ohio",
  },
  {
    area_code: 217,
    city: "Champaign",
    state: "Illinois",
  },
  {
    area_code: 217,
    city: "Charleston",
    state: "Illinois",
  },
  {
    area_code: 217,
    city: "Danville",
    state: "Illinois",
  },
  {
    area_code: 217,
    city: "Decatur",
    state: "Illinois",
  },
  {
    area_code: 217,
    city: "Quincy",
    state: "Illinois",
  },
  {
    area_code: 217,
    city: "Springfield",
    state: "Illinois",
  },
  {
    area_code: 217,
    city: "Urbana",
    state: "Illinois",
  },
  {
    area_code: 218,
    city: "Andover",
    state: "Minnesota",
  },
  {
    area_code: 218,
    city: "Duluth",
    state: "Minnesota",
  },
  {
    area_code: 218,
    city: "Moorhead",
    state: "Minnesota",
  },
  {
    area_code: 219,
    city: "East Chicago",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Gary",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Hammond",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Highland",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Hobart",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "LaPorte",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Merrillville",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Michigan City",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Munster",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Portage",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Schererville",
    state: "Indiana",
  },
  {
    area_code: 219,
    city: "Valparaiso",
    state: "Indiana",
  },
  {
    area_code: 220,
    city: "Athens",
    state: "Ohio",
  },
  {
    area_code: 220,
    city: "Chillicothe",
    state: "Ohio",
  },
  {
    area_code: 220,
    city: "Delaware",
    state: "Ohio",
  },
  {
    area_code: 220,
    city: "Lancaster",
    state: "Ohio",
  },
  {
    area_code: 220,
    city: "Marion",
    state: "Ohio",
  },
  {
    area_code: 220,
    city: "Newark",
    state: "Ohio",
  },
  {
    area_code: 220,
    city: "Portsmouth",
    state: "Ohio",
  },
  {
    area_code: 220,
    city: "Zanesville",
    state: "Ohio",
  },
  {
    area_code: 224,
    city: "Algonquin",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Arlington Heights",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Buffalo Grove",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Carpentersville",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Des Plaines",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "South Elgin",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Elk Grove Village",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Evanston",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Glenview",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Gurnee",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Highland Park",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Hoffman Estates",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Lake Forest",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Lake in the Hills",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Libertyville",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Morton Grove",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Mount Prospect",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Mundelein",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Niles",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "North Chicago",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Northbrook",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Palatine",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Park Ridge",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Rolling Meadows",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Round Lake Beach",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Schaumburg",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Skokie",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Vernon Hills",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Waukegan",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Wheeling",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Wilmette",
    state: "Illinois",
  },
  {
    area_code: 224,
    city: "Zion",
    state: "Illinois",
  },
  {
    area_code: 225,
    city: "Baton Rouge",
    state: "Louisiana",
  },
  {
    area_code: 228,
    city: "Biloxi",
    state: "Mississippi",
  },
  {
    area_code: 228,
    city: "West Gulfport",
    state: "Mississippi",
  },
  {
    area_code: 228,
    city: "Pascagoula",
    state: "Mississippi",
  },
  {
    area_code: 229,
    city: "Albany",
    state: "Georgia",
  },
  {
    area_code: 229,
    city: "Valdosta",
    state: "Georgia",
  },
  {
    area_code: 231,
    city: "Muskegon",
    state: "Michigan",
  },
  {
    area_code: 231,
    city: "Norton Shores",
    state: "Michigan",
  },
  {
    area_code: 231,
    city: "Shelby",
    state: "Michigan",
  },
  {
    area_code: 234,
    city: "Akron",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Alliance",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Austintown",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Barberton",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Boardman",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Brunswick",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "North Canton",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Cuyahoga Falls",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Green",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Hudson",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Kent",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Massillon",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Medina",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Niles",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Stow",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Warren",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Wooster",
    state: "Ohio",
  },
  {
    area_code: 234,
    city: "Youngstown",
    state: "Ohio",
  },
  {
    area_code: 239,
    city: "Bonita Springs",
    state: "Florida",
  },
  {
    area_code: 239,
    city: "Cape Coral",
    state: "Florida",
  },
  {
    area_code: 239,
    city: "North Fort Myers",
    state: "Florida",
  },
  {
    area_code: 239,
    city: "Golden Gate",
    state: "Florida",
  },
  {
    area_code: 239,
    city: "Lehigh Acres",
    state: "Florida",
  },
  {
    area_code: 239,
    city: "Naples",
    state: "Florida",
  },
  {
    area_code: 239,
    city: "North Fort Myers",
    state: "Florida",
  },
  {
    area_code: 240,
    city: "Aspen Hill",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "North Bethesda",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Bowie",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Chillum",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Clinton",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "College Park",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Cumberland",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Fairland",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Fort Washington",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Frederick",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Gaithersburg",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Germantown",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Greenbelt",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Hagerstown",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Landover",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Montgomery Village",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "North Bethesda",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "South Laurel",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "North Potomac",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Olney",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Potomac",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Rockville",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Silver Spring",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "South Laurel",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Saint Charles",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Suitland",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "Waldorf",
    state: "Maryland",
  },
  {
    area_code: 240,
    city: "White Oak",
    state: "Maryland",
  },
  {
    area_code: 248,
    city: "West Bloomfield Township",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Farmington Hills",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Ferndale",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Madison Heights",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Novi",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Oak Park",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Pontiac",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Rochester Hills",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Royal Oak",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Southfield",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Troy",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "Waterford",
    state: "Michigan",
  },
  {
    area_code: 248,
    city: "West Bloomfield Township",
    state: "Michigan",
  },
  {
    area_code: 251,
    city: "Mobile",
    state: "Alabama",
  },
  {
    area_code: 251,
    city: "Prichard",
    state: "Alabama",
  },
  {
    area_code: 252,
    city: "Greenville",
    state: "North Carolina",
  },
  {
    area_code: 252,
    city: "Havelock",
    state: "North Carolina",
  },
  {
    area_code: 252,
    city: "Kinston",
    state: "North Carolina",
  },
  {
    area_code: 252,
    city: "New Bern",
    state: "North Carolina",
  },
  {
    area_code: 252,
    city: "Rocky Mount",
    state: "North Carolina",
  },
  {
    area_code: 252,
    city: "Wilson",
    state: "North Carolina",
  },
  {
    area_code: 253,
    city: "Auburn",
    state: "Washington",
  },
  {
    area_code: 253,
    city: "East Hill-Meridian",
    state: "Washington",
  },
  {
    area_code: 253,
    city: "Federal Way",
    state: "Washington",
  },
  {
    area_code: 253,
    city: "Lakewood",
    state: "Washington",
  },
  {
    area_code: 253,
    city: "Parkland",
    state: "Washington",
  },
  {
    area_code: 253,
    city: "Puyallup",
    state: "Washington",
  },
  {
    area_code: 253,
    city: "South Hill",
    state: "Washington",
  },
  {
    area_code: 253,
    city: "Spanaway",
    state: "Washington",
  },
  {
    area_code: 253,
    city: "Tacoma",
    state: "Washington",
  },
  {
    area_code: 253,
    city: "University Place",
    state: "Washington",
  },
  {
    area_code: 254,
    city: "Copperas Cove",
    state: "Texas",
  },
  {
    area_code: 254,
    city: "Fort Hood",
    state: "Texas",
  },
  {
    area_code: 254,
    city: "Killeen",
    state: "Texas",
  },
  {
    area_code: 254,
    city: "Temple",
    state: "Texas",
  },
  {
    area_code: 254,
    city: "Waco",
    state: "Texas",
  },
  {
    area_code: 256,
    city: "Anniston",
    state: "Alabama",
  },
  {
    area_code: 256,
    city: "Decatur",
    state: "Alabama",
  },
  {
    area_code: 256,
    city: "Florence",
    state: "Alabama",
  },
  {
    area_code: 256,
    city: "Gadsden",
    state: "Alabama",
  },
  {
    area_code: 256,
    city: "Huntsville",
    state: "Alabama",
  },
  {
    area_code: 256,
    city: "Madison",
    state: "Alabama",
  },
  {
    area_code: 260,
    city: "Fort Wayne",
    state: "Indiana",
  },
  {
    area_code: 262,
    city: "Brookfield",
    state: "Wisconsin",
  },
  {
    area_code: 262,
    city: "Caledonia",
    state: "Wisconsin",
  },
  {
    area_code: 262,
    city: "Kenosha",
    state: "Wisconsin",
  },
  {
    area_code: 262,
    city: "Menomonee Falls",
    state: "Wisconsin",
  },
  {
    area_code: 262,
    city: "Mequon",
    state: "Wisconsin",
  },
  {
    area_code: 262,
    city: "Muskego",
    state: "Wisconsin",
  },
  {
    area_code: 262,
    city: "New Berlin",
    state: "Wisconsin",
  },
  {
    area_code: 262,
    city: "Racine",
    state: "Wisconsin",
  },
  {
    area_code: 262,
    city: "Waukesha",
    state: "Wisconsin",
  },
  {
    area_code: 262,
    city: "West Bend",
    state: "Wisconsin",
  },
  {
    area_code: 267,
    city: "Levittown",
    state: "Pennsylvania",
  },
  {
    area_code: 267,
    city: "Philadelphia",
    state: "Pennsylvania",
  },
  {
    area_code: 269,
    city: "Battle Creek",
    state: "Michigan",
  },
  {
    area_code: 269,
    city: "Kalamazoo",
    state: "Michigan",
  },
  {
    area_code: 269,
    city: "Portage",
    state: "Michigan",
  },
  {
    area_code: 270,
    city: "Bowling Green",
    state: "Kentucky",
  },
  {
    area_code: 270,
    city: "Elizabethtown",
    state: "Kentucky",
  },
  {
    area_code: 270,
    city: "Henderson",
    state: "Kentucky",
  },
  {
    area_code: 270,
    city: "Hopkinsville",
    state: "Kentucky",
  },
  {
    area_code: 270,
    city: "Owensboro",
    state: "Kentucky",
  },
  {
    area_code: 270,
    city: "Paducah",
    state: "Kentucky",
  },
  {
    area_code: 270,
    city: "Radcliff",
    state: "Kentucky",
  },
  {
    area_code: 272,
    city: "Back Mountain",
    state: "Pennsylvania",
  },
  {
    area_code: 272,
    city: "Hazleton",
    state: "Pennsylvania",
  },
  {
    area_code: 272,
    city: "Scranton",
    state: "Pennsylvania",
  },
  {
    area_code: 272,
    city: "Wilkes-Barre",
    state: "Pennsylvania",
  },
  {
    area_code: 272,
    city: "Williamsport",
    state: "Pennsylvania",
  },
  {
    area_code: 276,
    city: "Richmond",
    state: "Virginia",
  },
  {
    area_code: 281,
    city: "Alvin",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Atascocita",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Baytown",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Channelview",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Cloverleaf",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Deer Park",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Friendswood",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "South Houston",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "La Porte",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "League City",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Mission Bend",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Missouri City",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Pasadena",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Pearland",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Rosenberg",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Spring",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "Sugar Land",
    state: "Texas",
  },
  {
    area_code: 281,
    city: "The Woodlands",
    state: "Texas",
  },
  {
    area_code: 301,
    city: "Aspen Hill",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "North Bethesda",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Bowie",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Chillum",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Clinton",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "College Park",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Cumberland",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Fairland",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Fort Washington",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Frederick",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Gaithersburg",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Germantown",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Greenbelt",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Hagerstown",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Landover",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Montgomery Village",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "North Bethesda",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "South Laurel",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "North Potomac",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Olney",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Potomac",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Rockville",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Silver Spring",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "South Laurel",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Saint Charles",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Suitland",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "Waldorf",
    state: "Maryland",
  },
  {
    area_code: 301,
    city: "White Oak",
    state: "Maryland",
  },
  {
    area_code: 302,
    city: "Dover",
    state: "Delaware",
  },
  {
    area_code: 302,
    city: "Newark",
    state: "Delaware",
  },
  {
    area_code: 302,
    city: "Wilmington",
    state: "Delaware",
  },
  {
    area_code: 303,
    city: "Arvada",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Aurora",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Boulder",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Brighton",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Broomfield",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Castle Rock",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Castlewood",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Columbine",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Commerce City",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Denver",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Englewood",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Highlands Ranch",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Ken Caryl",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Lafayette",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Lakewood",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Littleton",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Longmont",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Loveland",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Northglenn",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Parker",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Southglenn",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Thornton",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Westminster",
    state: "Colorado",
  },
  {
    area_code: 303,
    city: "Wheat Ridge",
    state: "Colorado",
  },
  {
    area_code: 304,
    city: "South Charleston",
    state: "West Virginia",
  },
  {
    area_code: 304,
    city: "Huntington",
    state: "West Virginia",
  },
  {
    area_code: 304,
    city: "Morgantown",
    state: "West Virginia",
  },
  {
    area_code: 304,
    city: "Parkersburg",
    state: "West Virginia",
  },
  {
    area_code: 304,
    city: "Weirton",
    state: "West Virginia",
  },
  {
    area_code: 304,
    city: "Wheeling",
    state: "West Virginia",
  },
  {
    area_code: 305,
    city: "Aventura",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Carol City",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Coral Gables",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Coral Terrace",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Miami",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Cutler Ridge",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Doral",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Golden Glades",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Hialeah",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Homestead",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Kendale Lakes",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Kendall West",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Key West",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Leisure City",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "West Miami",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "North Miami Beach",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Miami Lakes",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Miramar",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Norland",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "North Miami",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "North Miami Beach",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Richmond West",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Richmond Heights",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "South Miami Heights",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Tamiami",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "West Little River",
    state: "Florida",
  },
  {
    area_code: 305,
    city: "Westchester",
    state: "Florida",
  },
  {
    area_code: 307,
    city: "Casper",
    state: "Wyoming",
  },
  {
    area_code: 307,
    city: "Cheyenne",
    state: "Wyoming",
  },
  {
    area_code: 307,
    city: "Gillette",
    state: "Wyoming",
  },
  {
    area_code: 307,
    city: "Laramie",
    state: "Wyoming",
  },
  {
    area_code: 308,
    city: "Grand Island",
    state: "Nebraska",
  },
  {
    area_code: 308,
    city: "Kearney",
    state: "Nebraska",
  },
  {
    area_code: 308,
    city: "North Platte",
    state: "Nebraska",
  },
  {
    area_code: 309,
    city: "Bloomington",
    state: "Illinois",
  },
  {
    area_code: 309,
    city: "East Moline",
    state: "Illinois",
  },
  {
    area_code: 309,
    city: "East Peoria",
    state: "Illinois",
  },
  {
    area_code: 309,
    city: "Galesburg",
    state: "Illinois",
  },
  {
    area_code: 309,
    city: "Moline",
    state: "Illinois",
  },
  {
    area_code: 309,
    city: "Normal",
    state: "Illinois",
  },
  {
    area_code: 309,
    city: "Pekin",
    state: "Illinois",
  },
  {
    area_code: 309,
    city: "Peoria",
    state: "Illinois",
  },
  {
    area_code: 309,
    city: "Rock Island",
    state: "Illinois",
  },
  {
    area_code: 310,
    city: "Beverly Hills",
    state: "California",
  },
  {
    area_code: 310,
    city: "West Carson",
    state: "California",
  },
  {
    area_code: 310,
    city: "Culver City",
    state: "California",
  },
  {
    area_code: 310,
    city: "Gardena",
    state: "California",
  },
  {
    area_code: 310,
    city: "Hawthorne",
    state: "California",
  },
  {
    area_code: 310,
    city: "Inglewood",
    state: "California",
  },
  {
    area_code: 310,
    city: "Lawndale",
    state: "California",
  },
  {
    area_code: 310,
    city: "Lennox",
    state: "California",
  },
  {
    area_code: 310,
    city: "Lomita",
    state: "California",
  },
  {
    area_code: 310,
    city: "Lynwood",
    state: "California",
  },
  {
    area_code: 310,
    city: "Manhattan Beach",
    state: "California",
  },
  {
    area_code: 310,
    city: "Rancho Palos Verdes",
    state: "California",
  },
  {
    area_code: 310,
    city: "Redondo Beach",
    state: "California",
  },
  {
    area_code: 310,
    city: "Santa Monica",
    state: "California",
  },
  {
    area_code: 310,
    city: "Torrance",
    state: "California",
  },
  {
    area_code: 310,
    city: "West Carson",
    state: "California",
  },
  {
    area_code: 312,
    city: "West Chicago",
    state: "Illinois",
  },
  {
    area_code: 313,
    city: "Allen Park",
    state: "Michigan",
  },
  {
    area_code: 313,
    city: "Dearborn",
    state: "Michigan",
  },
  {
    area_code: 313,
    city: "Dearborn Heights",
    state: "Michigan",
  },
  {
    area_code: 313,
    city: "Detroit",
    state: "Michigan",
  },
  {
    area_code: 313,
    city: "Hamtramck",
    state: "Michigan",
  },
  {
    area_code: 313,
    city: "Inkster",
    state: "Michigan",
  },
  {
    area_code: 313,
    city: "Lincoln Park",
    state: "Michigan",
  },
  {
    area_code: 313,
    city: "Redford",
    state: "Michigan",
  },
  {
    area_code: 313,
    city: "Taylor",
    state: "Michigan",
  },
  {
    area_code: 314,
    city: "Kirkwood",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "Maryland Heights",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "Mehlville",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "Oakville",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "Spanish Lake",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "St. Louis",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "University City",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "Webster Groves",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "Affton",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "Ferguson",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "Florissant",
    state: "Missouri",
  },
  {
    area_code: 314,
    city: "Hazelwood",
    state: "Missouri",
  },
  {
    area_code: 315,
    city: "Auburn",
    state: "New York",
  },
  {
    area_code: 315,
    city: "Rome",
    state: "New York",
  },
  {
    area_code: 315,
    city: "Syracuse",
    state: "New York",
  },
  {
    area_code: 315,
    city: "Utica",
    state: "New York",
  },
  {
    area_code: 315,
    city: "Watertown",
    state: "New York",
  },
  {
    area_code: 316,
    city: "Wichita",
    state: "Kansas",
  },
  {
    area_code: 317,
    city: "Carmel",
    state: "Indiana",
  },
  {
    area_code: 317,
    city: "Fishers",
    state: "Indiana",
  },
  {
    area_code: 317,
    city: "Greenwood",
    state: "Indiana",
  },
  {
    area_code: 317,
    city: "Indianapolis",
    state: "Indiana",
  },
  {
    area_code: 317,
    city: "Lawrence",
    state: "Indiana",
  },
  {
    area_code: 317,
    city: "Noblesville",
    state: "Indiana",
  },
  {
    area_code: 318,
    city: "Alexandria",
    state: "Louisiana",
  },
  {
    area_code: 318,
    city: "Bossier City",
    state: "Louisiana",
  },
  {
    area_code: 318,
    city: "West Monroe",
    state: "Louisiana",
  },
  {
    area_code: 318,
    city: "Ruston",
    state: "Louisiana",
  },
  {
    area_code: 318,
    city: "Shreveport",
    state: "Louisiana",
  },
  {
    area_code: 319,
    city: "Burlington",
    state: "Iowa",
  },
  {
    area_code: 319,
    city: "Cedar Falls",
    state: "Iowa",
  },
  {
    area_code: 319,
    city: "Cedar Rapids",
    state: "Iowa",
  },
  {
    area_code: 319,
    city: "Iowa City",
    state: "Iowa",
  },
  {
    area_code: 319,
    city: "Marion",
    state: "Iowa",
  },
  {
    area_code: 319,
    city: "Waterloo",
    state: "Iowa",
  },
  {
    area_code: 320,
    city: "Saint Cloud",
    state: "Minnesota",
  },
  {
    area_code: 321,
    city: "Altamonte Springs",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "South Apopka",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Casselberry",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Kissimmee",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "West Melbourne",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Merritt Island",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Oak Ridge",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Ocoee",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Orlando",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Oviedo",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Palm Bay",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Pine Hills",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Rockledge",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Sanford",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Saint Cloud",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Titusville",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "University Park",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Wekiwa Springs",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Winter Park",
    state: "Florida",
  },
  {
    area_code: 321,
    city: "Winter Springs",
    state: "Florida",
  },
  {
    area_code: 323,
    city: "Bell",
    state: "California",
  },
  {
    area_code: 323,
    city: "Bell Gardens",
    state: "California",
  },
  {
    area_code: 323,
    city: "Cudahy",
    state: "California",
  },
  {
    area_code: 323,
    city: "East Los Angeles",
    state: "California",
  },
  {
    area_code: 323,
    city: "Florence-Graham",
    state: "California",
  },
  {
    area_code: 323,
    city: "Huntington Park",
    state: "California",
  },
  {
    area_code: 323,
    city: "Maywood",
    state: "California",
  },
  {
    area_code: 323,
    city: "Montebello",
    state: "California",
  },
  {
    area_code: 323,
    city: "South Gate",
    state: "California",
  },
  {
    area_code: 323,
    city: "West Hollywood",
    state: "California",
  },
  {
    area_code: 323,
    city: "Westmont",
    state: "California",
  },
  {
    area_code: 325,
    city: "Abilene",
    state: "Texas",
  },
  {
    area_code: 325,
    city: "San Angelo",
    state: "Texas",
  },
  {
    area_code: 330,
    city: "Akron",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Alliance",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Austintown",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Barberton",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Boardman",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Brunswick",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "North Canton",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Cuyahoga Falls",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Green",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Hudson",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Kent",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Massillon",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Medina",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Niles",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Stow",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Warren",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Wooster",
    state: "Ohio",
  },
  {
    area_code: 330,
    city: "Youngstown",
    state: "Ohio",
  },
  {
    area_code: 331,
    city: "Addison",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "North Aurora",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Bartlett",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Batavia",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Bensenville",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Bloomingdale",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Bolingbrook",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Carol Stream",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Darien",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Downers Grove",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Elmhurst",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Glen Ellyn",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Glendale Heights",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Hanover Park",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Lisle",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Lombard",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Naperville",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Roselle",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Saint Charles",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Streamwood",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Villa Park",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "West Chicago",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Westmont",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Wheaton",
    state: "Illinois",
  },
  {
    area_code: 331,
    city: "Woodridge",
    state: "Illinois",
  },
  {
    area_code: 334,
    city: "Auburn",
    state: "Alabama",
  },
  {
    area_code: 334,
    city: "Dothan",
    state: "Alabama",
  },
  {
    area_code: 334,
    city: "Enterprise",
    state: "Alabama",
  },
  {
    area_code: 334,
    city: "Montgomery",
    state: "Alabama",
  },
  {
    area_code: 334,
    city: "Opelika",
    state: "Alabama",
  },
  {
    area_code: 334,
    city: "Phenix City",
    state: "Alabama",
  },
  {
    area_code: 334,
    city: "Prattville",
    state: "Alabama",
  },
  {
    area_code: 334,
    city: "Selma",
    state: "Alabama",
  },
  {
    area_code: 336,
    city: "Asheboro",
    state: "North Carolina",
  },
  {
    area_code: 336,
    city: "Burlington",
    state: "North Carolina",
  },
  {
    area_code: 336,
    city: "Greensboro",
    state: "North Carolina",
  },
  {
    area_code: 336,
    city: "High Point",
    state: "North Carolina",
  },
  {
    area_code: 336,
    city: "Winston-Salem",
    state: "North Carolina",
  },
  {
    area_code: 337,
    city: "Lafayette",
    state: "Louisiana",
  },
  {
    area_code: 337,
    city: "Lake Charles",
    state: "Louisiana",
  },
  {
    area_code: 337,
    city: "New Iberia",
    state: "Louisiana",
  },
  {
    area_code: 337,
    city: "Opelousas",
    state: "Louisiana",
  },
  {
    area_code: 337,
    city: "Sulphur",
    state: "Louisiana",
  },
  {
    area_code: 339,
    city: "Arlington",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Braintree",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Burlington",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Dedham",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Lexington",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Lynn",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Malden",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Marblehead",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Medford",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Melrose",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Needham",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Norwood",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Randolph",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Reading",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Revere",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Saugus",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Stoneham",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Wakefield",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Waltham",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Wellesley",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Weymouth",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Winchester",
    state: "Massachusetts",
  },
  {
    area_code: 339,
    city: "Woburn",
    state: "Massachusetts",
  },
  {
    area_code: 346,
    city: "Alvin",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Atascocita",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Baytown",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Channelview",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Cloverleaf",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Deer Park",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Friendswood",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "South Houston",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "La Porte",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "League City",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Mission Bend",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Missouri City",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Pasadena",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Pearland",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Rosenberg",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Spring",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "Sugar Land",
    state: "Texas",
  },
  {
    area_code: 346,
    city: "The Woodlands",
    state: "Texas",
  },
  {
    area_code: 347,
    city: "The Bronx",
    state: "New York",
  },
  {
    area_code: 347,
    city: "Brooklyn",
    state: "New York",
  },
  {
    area_code: 347,
    city: "New York City",
    state: "New York",
  },
  {
    area_code: 347,
    city: "Borough of Queens",
    state: "New York",
  },
  {
    area_code: 347,
    city: "Staten Island",
    state: "New York",
  },
  {
    area_code: 351,
    city: "Beverly",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Chelmsford",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Danvers",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Fitchburg",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Gardner",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Gloucester",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Haverhill",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Lawrence",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Leominster",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Lowell",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Methuen",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "South Peabody",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Salem",
    state: "Massachusetts",
  },
  {
    area_code: 351,
    city: "Wilmington",
    state: "Massachusetts",
  },
  {
    area_code: 352,
    city: "Gainesville",
    state: "Florida",
  },
  {
    area_code: 352,
    city: "Oakland Park",
    state: "Florida",
  },
  {
    area_code: 352,
    city: "Ocala",
    state: "Florida",
  },
  {
    area_code: 352,
    city: "Spring Hill",
    state: "Florida",
  },
  {
    area_code: 360,
    city: "Bellingham",
    state: "Washington",
  },
  {
    area_code: 360,
    city: "Bremerton",
    state: "Washington",
  },
  {
    area_code: 360,
    city: "Lacey",
    state: "Washington",
  },
  {
    area_code: 360,
    city: "Longview",
    state: "Washington",
  },
  {
    area_code: 360,
    city: "Marysville",
    state: "Washington",
  },
  {
    area_code: 360,
    city: "Mount Vernon",
    state: "Washington",
  },
  {
    area_code: 360,
    city: "Marysville",
    state: "Washington",
  },
  {
    area_code: 360,
    city: "Olympia",
    state: "Washington",
  },
  {
    area_code: 360,
    city: "Vancouver",
    state: "Washington",
  },
  {
    area_code: 361,
    city: "Corpus Christi",
    state: "Texas",
  },
  {
    area_code: 361,
    city: "Kingsville",
    state: "Texas",
  },
  {
    area_code: 361,
    city: "Victoria",
    state: "Texas",
  },
  {
    area_code: 364,
    city: "Bowling Green",
    state: "Kentucky",
  },
  {
    area_code: 364,
    city: "Elizabethtown",
    state: "Kentucky",
  },
  {
    area_code: 364,
    city: "Henderson",
    state: "Kentucky",
  },
  {
    area_code: 364,
    city: "Hopkinsville",
    state: "Kentucky",
  },
  {
    area_code: 364,
    city: "Owensboro",
    state: "Kentucky",
  },
  {
    area_code: 364,
    city: "Paducah",
    state: "Kentucky",
  },
  {
    area_code: 364,
    city: "Radcliff",
    state: "Kentucky",
  },
  {
    area_code: 385,
    city: "American Fork",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "West Bountiful",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Clearfield",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Cottonwood Heights",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Draper",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "East Millcreek",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Kaysville",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Kearns",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Layton",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Magna",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Midvale",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Millcreek",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Murray",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "South Ogden",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Orem",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Pleasant Grove",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Provo",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Riverton",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Roy",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Salt Lake City",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Sandy City",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "South Jordan",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "South Salt Lake",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Spanish Fork",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Springville",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "Taylorsville",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "West Jordan",
    state: "Utah",
  },
  {
    area_code: 385,
    city: "West Valley City",
    state: "Utah",
  },
  {
    area_code: 386,
    city: "Daytona Beach",
    state: "Florida",
  },
  {
    area_code: 386,
    city: "DeLand",
    state: "Florida",
  },
  {
    area_code: 386,
    city: "Deltona",
    state: "Florida",
  },
  {
    area_code: 386,
    city: "New Smyrna Beach",
    state: "Florida",
  },
  {
    area_code: 386,
    city: "Ormond Beach",
    state: "Florida",
  },
  {
    area_code: 386,
    city: "Palm Coast",
    state: "Florida",
  },
  {
    area_code: 386,
    city: "Port Orange",
    state: "Florida",
  },
  {
    area_code: 401,
    city: "Bristol",
    state: "Rhode Island",
  },
  {
    area_code: 401,
    city: "Cranston",
    state: "Rhode Island",
  },
  {
    area_code: 401,
    city: "East Providence",
    state: "Rhode Island",
  },
  {
    area_code: 401,
    city: "Newport East",
    state: "Rhode Island",
  },
  {
    area_code: 401,
    city: "North Providence",
    state: "Rhode Island",
  },
  {
    area_code: 401,
    city: "Pawtucket",
    state: "Rhode Island",
  },
  {
    area_code: 401,
    city: "Providence",
    state: "Rhode Island",
  },
  {
    area_code: 401,
    city: "West Warwick",
    state: "Rhode Island",
  },
  {
    area_code: 401,
    city: "West Warwick",
    state: "Rhode Island",
  },
  {
    area_code: 401,
    city: "Woonsocket",
    state: "Rhode Island",
  },
  {
    area_code: 402,
    city: "Bellevue",
    state: "Nebraska",
  },
  {
    area_code: 402,
    city: "Columbus",
    state: "Nebraska",
  },
  {
    area_code: 402,
    city: "Fremont",
    state: "Nebraska",
  },
  {
    area_code: 402,
    city: "Hastings",
    state: "Nebraska",
  },
  {
    area_code: 402,
    city: "Lincoln",
    state: "Nebraska",
  },
  {
    area_code: 402,
    city: "Norfolk",
    state: "Nebraska",
  },
  {
    area_code: 402,
    city: "Omaha",
    state: "Nebraska",
  },
  {
    area_code: 404,
    city: "Atlanta",
    state: "Georgia",
  },
  {
    area_code: 404,
    city: "Candler-McAfee",
    state: "Georgia",
  },
  {
    area_code: 404,
    city: "College Park",
    state: "Georgia",
  },
  {
    area_code: 404,
    city: "College Park",
    state: "Georgia",
  },
  {
    area_code: 404,
    city: "East Point",
    state: "Georgia",
  },
  {
    area_code: 404,
    city: "Forest Park",
    state: "Georgia",
  },
  {
    area_code: 404,
    city: "Atlanta",
    state: "Georgia",
  },
  {
    area_code: 404,
    city: "Sandy Springs",
    state: "Georgia",
  },
  {
    area_code: 404,
    city: "Tucker",
    state: "Georgia",
  },
  {
    area_code: 405,
    city: "Bethany",
    state: "Oklahoma",
  },
  {
    area_code: 405,
    city: "Del City",
    state: "Oklahoma",
  },
  {
    area_code: 405,
    city: "Edmond",
    state: "Oklahoma",
  },
  {
    area_code: 405,
    city: "Midwest City",
    state: "Oklahoma",
  },
  {
    area_code: 405,
    city: "Moore",
    state: "Oklahoma",
  },
  {
    area_code: 405,
    city: "Norman",
    state: "Oklahoma",
  },
  {
    area_code: 405,
    city: "Oklahoma City",
    state: "Oklahoma",
  },
  {
    area_code: 405,
    city: "Shawnee",
    state: "Oklahoma",
  },
  {
    area_code: 405,
    city: "Stillwater",
    state: "Oklahoma",
  },
  {
    area_code: 405,
    city: "Yukon",
    state: "Oklahoma",
  },
  {
    area_code: 406,
    city: "Billings",
    state: "Montana",
  },
  {
    area_code: 406,
    city: "Bozeman",
    state: "Montana",
  },
  {
    area_code: 406,
    city: "Butte",
    state: "Montana",
  },
  {
    area_code: 406,
    city: "Great Falls",
    state: "Montana",
  },
  {
    area_code: 406,
    city: "Helena",
    state: "Montana",
  },
  {
    area_code: 406,
    city: "Missoula",
    state: "Montana",
  },
  {
    area_code: 407,
    city: "Altamonte Springs",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "South Apopka",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Casselberry",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Kissimmee",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Oak Ridge",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Ocoee",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Orlando",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Oviedo",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Pine Hills",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Sanford",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Saint Cloud",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "University Park",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Wekiwa Springs",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Winter Park",
    state: "Florida",
  },
  {
    area_code: 407,
    city: "Winter Springs",
    state: "Florida",
  },
  {
    area_code: 408,
    city: "Campbell",
    state: "California",
  },
  {
    area_code: 408,
    city: "Gilroy",
    state: "California",
  },
  {
    area_code: 408,
    city: "Los Gatos",
    state: "California",
  },
  {
    area_code: 408,
    city: "Milpitas",
    state: "California",
  },
  {
    area_code: 408,
    city: "Morgan Hill",
    state: "California",
  },
  {
    area_code: 408,
    city: "San Jose",
    state: "California",
  },
  {
    area_code: 408,
    city: "Santa Clara",
    state: "California",
  },
  {
    area_code: 408,
    city: "Saratoga",
    state: "California",
  },
  {
    area_code: 408,
    city: "Sunnyvale",
    state: "California",
  },
  {
    area_code: 409,
    city: "Beaumont",
    state: "Texas",
  },
  {
    area_code: 409,
    city: "Galveston",
    state: "Texas",
  },
  {
    area_code: 409,
    city: "Port Arthur",
    state: "Texas",
  },
  {
    area_code: 409,
    city: "Texas City",
    state: "Texas",
  },
  {
    area_code: 410,
    city: "Annapolis",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Arbutus",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Arnold",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Baltimore",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Bel Air North",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Bel Air South",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Carney",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Catonsville",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Columbia",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Crofton",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Dundalk",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Edgewood",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Eldersburg",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "West Elkridge",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Ellicott City",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Essex",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Glen Burnie",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Lochearn",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Middle River",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Milford Mill",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Odenton",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Parkville",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Perry Hall",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Pikesville",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Randallstown",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Reisterstown",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Salisbury",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Severn",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Severna Park",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "South Gate",
    state: "Maryland",
  },
  {
    area_code: 410,
    city: "Towson",
    state: "Maryland",
  },
  {
    area_code: 412,
    city: "Bethel Park",
    state: "Pennsylvania",
  },
  {
    area_code: 412,
    city: "McKeesport",
    state: "Pennsylvania",
  },
  {
    area_code: 412,
    city: "Mount Lebanon",
    state: "Pennsylvania",
  },
  {
    area_code: 412,
    city: "Monroeville",
    state: "Pennsylvania",
  },
  {
    area_code: 412,
    city: "Penn Hills",
    state: "Pennsylvania",
  },
  {
    area_code: 412,
    city: "Pittsburgh",
    state: "Pennsylvania",
  },
  {
    area_code: 412,
    city: "Plum",
    state: "Pennsylvania",
  },
  {
    area_code: 412,
    city: "Upper Saint Clair",
    state: "Pennsylvania",
  },
  {
    area_code: 412,
    city: "West Mifflin",
    state: "Pennsylvania",
  },
  {
    area_code: 413,
    city: "Agawam",
    state: "Massachusetts",
  },
  {
    area_code: 413,
    city: "North Chicopee",
    state: "Massachusetts",
  },
  {
    area_code: 413,
    city: "Holyoke",
    state: "Massachusetts",
  },
  {
    area_code: 413,
    city: "Northampton",
    state: "Massachusetts",
  },
  {
    area_code: 413,
    city: "Pittsfield",
    state: "Massachusetts",
  },
  {
    area_code: 413,
    city: "West Springfield",
    state: "Massachusetts",
  },
  {
    area_code: 413,
    city: "West Springfield",
    state: "Massachusetts",
  },
  {
    area_code: 413,
    city: "Westfield",
    state: "Massachusetts",
  },
  {
    area_code: 414,
    city: "Franklin",
    state: "Wisconsin",
  },
  {
    area_code: 414,
    city: "Greenfield",
    state: "Wisconsin",
  },
  {
    area_code: 414,
    city: "South Milwaukee",
    state: "Wisconsin",
  },
  {
    area_code: 414,
    city: "Oak Creek",
    state: "Wisconsin",
  },
  {
    area_code: 414,
    city: "South Milwaukee",
    state: "Wisconsin",
  },
  {
    area_code: 414,
    city: "Wauwatosa",
    state: "Wisconsin",
  },
  {
    area_code: 414,
    city: "West Allis",
    state: "Wisconsin",
  },
  {
    area_code: 415,
    city: "Novato",
    state: "California",
  },
  {
    area_code: 415,
    city: "San Anselmo",
    state: "California",
  },
  {
    area_code: 415,
    city: "South San Francisco",
    state: "California",
  },
  {
    area_code: 415,
    city: "San Rafael",
    state: "California",
  },
  {
    area_code: 417,
    city: "Joplin",
    state: "Missouri",
  },
  {
    area_code: 417,
    city: "Springfield",
    state: "Missouri",
  },
  {
    area_code: 419,
    city: "Ashland",
    state: "Ohio",
  },
  {
    area_code: 419,
    city: "Bowling Green",
    state: "Ohio",
  },
  {
    area_code: 419,
    city: "Findlay",
    state: "Ohio",
  },
  {
    area_code: 419,
    city: "Lima",
    state: "Ohio",
  },
  {
    area_code: 419,
    city: "Mansfield",
    state: "Ohio",
  },
  {
    area_code: 419,
    city: "Sandusky",
    state: "Ohio",
  },
  {
    area_code: 419,
    city: "Toledo",
    state: "Ohio",
  },
  {
    area_code: 423,
    city: "Bristol",
    state: "Tennessee",
  },
  {
    area_code: 423,
    city: "East Chattanooga",
    state: "Tennessee",
  },
  {
    area_code: 423,
    city: "South Cleveland",
    state: "Tennessee",
  },
  {
    area_code: 423,
    city: "East Ridge",
    state: "Tennessee",
  },
  {
    area_code: 423,
    city: "Johnson City",
    state: "Tennessee",
  },
  {
    area_code: 423,
    city: "Kingsport",
    state: "Tennessee",
  },
  {
    area_code: 423,
    city: "Morristown",
    state: "Tennessee",
  },
  {
    area_code: 424,
    city: "Beverly Hills",
    state: "California",
  },
  {
    area_code: 424,
    city: "West Carson",
    state: "California",
  },
  {
    area_code: 424,
    city: "Culver City",
    state: "California",
  },
  {
    area_code: 424,
    city: "Gardena",
    state: "California",
  },
  {
    area_code: 424,
    city: "Hawthorne",
    state: "California",
  },
  {
    area_code: 424,
    city: "Inglewood",
    state: "California",
  },
  {
    area_code: 424,
    city: "Lawndale",
    state: "California",
  },
  {
    area_code: 424,
    city: "Lennox",
    state: "California",
  },
  {
    area_code: 424,
    city: "Lomita",
    state: "California",
  },
  {
    area_code: 424,
    city: "Lynwood",
    state: "California",
  },
  {
    area_code: 424,
    city: "Manhattan Beach",
    state: "California",
  },
  {
    area_code: 424,
    city: "Rancho Palos Verdes",
    state: "California",
  },
  {
    area_code: 424,
    city: "Redondo Beach",
    state: "California",
  },
  {
    area_code: 424,
    city: "Santa Monica",
    state: "California",
  },
  {
    area_code: 424,
    city: "Torrance",
    state: "California",
  },
  {
    area_code: 424,
    city: "West Carson",
    state: "California",
  },
  {
    area_code: 425,
    city: "Bellevue",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Bothell",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Fairwood",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Cottage Lake",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Edmonds",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Everett",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Inglewood-Finn Hill",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Kirkland",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Lynnwood",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Mountlake Terrace",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "North Creek",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Redmond",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "Renton",
    state: "Washington",
  },
  {
    area_code: 425,
    city: "City of Sammamish",
    state: "Washington",
  },
  {
    area_code: 432,
    city: "Big Spring",
    state: "Texas",
  },
  {
    area_code: 432,
    city: "Midland",
    state: "Texas",
  },
  {
    area_code: 432,
    city: "West Odessa",
    state: "Texas",
  },
  {
    area_code: 434,
    city: "Charlottesville",
    state: "Virginia",
  },
  {
    area_code: 434,
    city: "Danville",
    state: "Virginia",
  },
  {
    area_code: 434,
    city: "West Lynchburg",
    state: "Virginia",
  },
  {
    area_code: 435,
    city: "Cedar City",
    state: "Utah",
  },
  {
    area_code: 435,
    city: "North Logan",
    state: "Utah",
  },
  {
    area_code: 435,
    city: "Saint George",
    state: "Utah",
  },
  {
    area_code: 435,
    city: "Tooele",
    state: "Utah",
  },
  {
    area_code: 440,
    city: "Ashtabula",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Eastlake",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Elyria",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Lorain",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Mentor",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "North Olmsted",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "North Ridgeville",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "North Royalton",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Parma",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Parma Heights",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Rocky River",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Solon",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Strongsville",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Westlake",
    state: "Ohio",
  },
  {
    area_code: 440,
    city: "Willoughby",
    state: "Ohio",
  },
  {
    area_code: 442,
    city: "La Quinta",
    state: "California",
  },
  {
    area_code: 442,
    city: "Oceanside",
    state: "California",
  },
  {
    area_code: 442,
    city: "Palm Desert",
    state: "California",
  },
  {
    area_code: 442,
    city: "Palm Springs",
    state: "California",
  },
  {
    area_code: 442,
    city: "Ridgecrest",
    state: "California",
  },
  {
    area_code: 442,
    city: "San Marcos",
    state: "California",
  },
  {
    area_code: 442,
    city: "Victorville",
    state: "California",
  },
  {
    area_code: 442,
    city: "Vista",
    state: "California",
  },
  {
    area_code: 442,
    city: "Apple Valley",
    state: "California",
  },
  {
    area_code: 442,
    city: "Barstow",
    state: "California",
  },
  {
    area_code: 442,
    city: "Brawley",
    state: "California",
  },
  {
    area_code: 442,
    city: "Calexico",
    state: "California",
  },
  {
    area_code: 442,
    city: "Carlsbad",
    state: "California",
  },
  {
    area_code: 442,
    city: "Cathedral City",
    state: "California",
  },
  {
    area_code: 442,
    city: "Coachella",
    state: "California",
  },
  {
    area_code: 442,
    city: "El Centro",
    state: "California",
  },
  {
    area_code: 442,
    city: "Encinitas",
    state: "California",
  },
  {
    area_code: 442,
    city: "Escondido",
    state: "California",
  },
  {
    area_code: 442,
    city: "Fallbrook",
    state: "California",
  },
  {
    area_code: 442,
    city: "Hesperia",
    state: "California",
  },
  {
    area_code: 442,
    city: "Indio",
    state: "California",
  },
  {
    area_code: 443,
    city: "Annapolis",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Arbutus",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Arnold",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Baltimore",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Bel Air North",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Bel Air South",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Carney",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Catonsville",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Columbia",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Crofton",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Dundalk",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Edgewood",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Eldersburg",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "West Elkridge",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Ellicott City",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Essex",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Glen Burnie",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Lochearn",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Middle River",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Milford Mill",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Odenton",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Parkville",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Perry Hall",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Pikesville",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Randallstown",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Reisterstown",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Salisbury",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Severn",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Severna Park",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "South Gate",
    state: "Maryland",
  },
  {
    area_code: 443,
    city: "Towson",
    state: "Maryland",
  },
  {
    area_code: 458,
    city: "Albany",
    state: "Oregon",
  },
  {
    area_code: 458,
    city: "North Bend",
    state: "Oregon",
  },
  {
    area_code: 458,
    city: "Corvallis",
    state: "Oregon",
  },
  {
    area_code: 458,
    city: "Gresham",
    state: "Oregon",
  },
  {
    area_code: 458,
    city: "Grants Pass",
    state: "Oregon",
  },
  {
    area_code: 458,
    city: "Medford",
    state: "Oregon",
  },
  {
    area_code: 458,
    city: "Roseburg North",
    state: "Oregon",
  },
  {
    area_code: 458,
    city: "Springfield",
    state: "Oregon",
  },
  {
    area_code: 469,
    city: "Allen",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Carrollton",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Cedar Hill",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Coppell",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Dallas",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "DeSoto",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Duncanville",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Farmers Branch",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Flower Mound",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Frisco",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Garland",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Grand Prairie",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Irving",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Lancaster",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Lewisville",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "McKinney",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Mesquite",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Plano",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Richardson",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Rowlett",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "The Colony",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "University Park",
    state: "Texas",
  },
  {
    area_code: 469,
    city: "Waxahachie",
    state: "Texas",
  },
  {
    area_code: 478,
    city: "Macon",
    state: "Georgia",
  },
  {
    area_code: 478,
    city: "Warner Robins",
    state: "Georgia",
  },
  {
    area_code: 479,
    city: "Fayetteville",
    state: "Arkansas",
  },
  {
    area_code: 479,
    city: "Fort Smith",
    state: "Arkansas",
  },
  {
    area_code: 479,
    city: "Rogers",
    state: "Arkansas",
  },
  {
    area_code: 479,
    city: "Russellville",
    state: "Arkansas",
  },
  {
    area_code: 479,
    city: "Springdale",
    state: "Arkansas",
  },
  {
    area_code: 480,
    city: "Apache Junction",
    state: "Arizona",
  },
  {
    area_code: 480,
    city: "Chandler",
    state: "Arizona",
  },
  {
    area_code: 480,
    city: "Fountain Hills",
    state: "Arizona",
  },
  {
    area_code: 480,
    city: "Gilbert",
    state: "Arizona",
  },
  {
    area_code: 480,
    city: "Mesa",
    state: "Arizona",
  },
  {
    area_code: 480,
    city: "Scottsdale",
    state: "Arizona",
  },
  {
    area_code: 480,
    city: "Tempe",
    state: "Arizona",
  },
  {
    area_code: 484,
    city: "Allentown",
    state: "Pennsylvania",
  },
  {
    area_code: 484,
    city: "Bethlehem",
    state: "Pennsylvania",
  },
  {
    area_code: 484,
    city: "West Chester",
    state: "Pennsylvania",
  },
  {
    area_code: 484,
    city: "Drexel Hill",
    state: "Pennsylvania",
  },
  {
    area_code: 484,
    city: "Easton",
    state: "Pennsylvania",
  },
  {
    area_code: 484,
    city: "Norristown",
    state: "Pennsylvania",
  },
  {
    area_code: 484,
    city: "Pottstown",
    state: "Pennsylvania",
  },
  {
    area_code: 484,
    city: "Radnor",
    state: "Pennsylvania",
  },
  {
    area_code: 484,
    city: "Reading",
    state: "Pennsylvania",
  },
  {
    area_code: 484,
    city: "Springfield",
    state: "Pennsylvania",
  },
  {
    area_code: 501,
    city: "Benton",
    state: "Arkansas",
  },
  {
    area_code: 501,
    city: "Conway",
    state: "Arkansas",
  },
  {
    area_code: 501,
    city: "Hot Springs",
    state: "Arkansas",
  },
  {
    area_code: 501,
    city: "Jacksonville",
    state: "Arkansas",
  },
  {
    area_code: 501,
    city: "North Little Rock",
    state: "Arkansas",
  },
  {
    area_code: 501,
    city: "North Little Rock",
    state: "Arkansas",
  },
  {
    area_code: 501,
    city: "Sherwood",
    state: "Arkansas",
  },
  {
    area_code: 502,
    city: "Frankfort",
    state: "Kentucky",
  },
  {
    area_code: 502,
    city: "Louisville",
    state: "Kentucky",
  },
  {
    area_code: 503,
    city: "Aloha",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Beaverton",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Gresham",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Hillsboro",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Keizer",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Lake Oswego",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "McMinnville",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Milwaukie",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Oregon City",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Portland",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Salem",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Tigard",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Tualatin",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "West Linn",
    state: "Oregon",
  },
  {
    area_code: 503,
    city: "Woodburn",
    state: "Oregon",
  },
  {
    area_code: 504,
    city: "Chalmette",
    state: "Louisiana",
  },
  {
    area_code: 504,
    city: "Harvey",
    state: "Louisiana",
  },
  {
    area_code: 504,
    city: "Kenner",
    state: "Louisiana",
  },
  {
    area_code: 504,
    city: "Marrero",
    state: "Louisiana",
  },
  {
    area_code: 504,
    city: "Metairie",
    state: "Louisiana",
  },
  {
    area_code: 504,
    city: "New Orleans",
    state: "Louisiana",
  },
  {
    area_code: 504,
    city: "Terrytown",
    state: "Louisiana",
  },
  {
    area_code: 505,
    city: "Albuquerque",
    state: "New Mexico",
  },
  {
    area_code: 505,
    city: "Farmington",
    state: "New Mexico",
  },
  {
    area_code: 505,
    city: "Gallup",
    state: "New Mexico",
  },
  {
    area_code: 505,
    city: "Rio Rancho",
    state: "New Mexico",
  },
  {
    area_code: 505,
    city: "Santa Fe",
    state: "New Mexico",
  },
  {
    area_code: 505,
    city: "South Valley",
    state: "New Mexico",
  },
  {
    area_code: 507,
    city: "Austin",
    state: "Minnesota",
  },
  {
    area_code: 507,
    city: "Faribault",
    state: "Minnesota",
  },
  {
    area_code: 507,
    city: "North Mankato",
    state: "Minnesota",
  },
  {
    area_code: 507,
    city: "Owatonna",
    state: "Minnesota",
  },
  {
    area_code: 507,
    city: "Rochester",
    state: "Minnesota",
  },
  {
    area_code: 507,
    city: "Winona",
    state: "Minnesota",
  },
  {
    area_code: 508,
    city: "Attleboro",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "Barnstable",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "Brockton",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "Fall River",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "Framingham",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "Franklin",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "Marlborough",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "Milford",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "New Bedford",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "Taunton",
    state: "Massachusetts",
  },
  {
    area_code: 508,
    city: "Worcester",
    state: "Massachusetts",
  },
  {
    area_code: 509,
    city: "Kennewick",
    state: "Washington",
  },
  {
    area_code: 509,
    city: "Opportunity",
    state: "Washington",
  },
  {
    area_code: 509,
    city: "Pasco",
    state: "Washington",
  },
  {
    area_code: 509,
    city: "Pullman",
    state: "Washington",
  },
  {
    area_code: 509,
    city: "West Richland",
    state: "Washington",
  },
  {
    area_code: 509,
    city: "Spokane",
    state: "Washington",
  },
  {
    area_code: 509,
    city: "Walla Walla",
    state: "Washington",
  },
  {
    area_code: 509,
    city: "Wenatchee",
    state: "Washington",
  },
  {
    area_code: 509,
    city: "Yakima",
    state: "Washington",
  },
  {
    area_code: 510,
    city: "Alameda",
    state: "California",
  },
  {
    area_code: 510,
    city: "Berkeley",
    state: "California",
  },
  {
    area_code: 510,
    city: "Castro Valley",
    state: "California",
  },
  {
    area_code: 510,
    city: "El Cerrito",
    state: "California",
  },
  {
    area_code: 510,
    city: "Fremont",
    state: "California",
  },
  {
    area_code: 510,
    city: "Hayward",
    state: "California",
  },
  {
    area_code: 510,
    city: "Newark",
    state: "California",
  },
  {
    area_code: 510,
    city: "Oakland",
    state: "California",
  },
  {
    area_code: 510,
    city: "Richmond",
    state: "California",
  },
  {
    area_code: 510,
    city: "San Leandro",
    state: "California",
  },
  {
    area_code: 510,
    city: "San Lorenzo",
    state: "California",
  },
  {
    area_code: 510,
    city: "San Pablo",
    state: "California",
  },
  {
    area_code: 510,
    city: "Union City",
    state: "California",
  },
  {
    area_code: 512,
    city: "Austin",
    state: "Texas",
  },
  {
    area_code: 512,
    city: "Cedar Park",
    state: "Texas",
  },
  {
    area_code: 512,
    city: "Georgetown",
    state: "Texas",
  },
  {
    area_code: 512,
    city: "Round Rock",
    state: "Texas",
  },
  {
    area_code: 512,
    city: "San Marcos",
    state: "Texas",
  },
  {
    area_code: 513,
    city: "Cincinnati",
    state: "Ohio",
  },
  {
    area_code: 513,
    city: "Fairfield",
    state: "Ohio",
  },
  {
    area_code: 513,
    city: "Hamilton",
    state: "Ohio",
  },
  {
    area_code: 513,
    city: "Mason",
    state: "Ohio",
  },
  {
    area_code: 513,
    city: "Middletown",
    state: "Ohio",
  },
  {
    area_code: 513,
    city: "Norwood",
    state: "Ohio",
  },
  {
    area_code: 513,
    city: "Oxford",
    state: "Ohio",
  },
  {
    area_code: 515,
    city: "Ames",
    state: "Iowa",
  },
  {
    area_code: 515,
    city: "Ankeny",
    state: "Iowa",
  },
  {
    area_code: 515,
    city: "West Des Moines",
    state: "Iowa",
  },
  {
    area_code: 515,
    city: "Fort Dodge",
    state: "Iowa",
  },
  {
    area_code: 515,
    city: "Urbandale",
    state: "Iowa",
  },
  {
    area_code: 515,
    city: "West Des Moines",
    state: "Iowa",
  },
  {
    area_code: 516,
    city: "Baldwin",
    state: "New York",
  },
  {
    area_code: 516,
    city: "East Meadow",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Elmont",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Franklin Square",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Freeport",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Garden City",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Glen Cove",
    state: "New York",
  },
  {
    area_code: 516,
    city: "West Hempstead",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Hicksville",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Levittown",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Long Beach",
    state: "New York",
  },
  {
    area_code: 516,
    city: "North Massapequa",
    state: "New York",
  },
  {
    area_code: 516,
    city: "North Merrick",
    state: "New York",
  },
  {
    area_code: 516,
    city: "North Bellmore",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Oceanside",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Plainview",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Rockville Centre",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Uniondale",
    state: "New York",
  },
  {
    area_code: 516,
    city: "Valley Stream",
    state: "New York",
  },
  {
    area_code: 517,
    city: "Adrian",
    state: "Michigan",
  },
  {
    area_code: 517,
    city: "Clinton",
    state: "Michigan",
  },
  {
    area_code: 517,
    city: "East Lansing",
    state: "Michigan",
  },
  {
    area_code: 517,
    city: "Jackson",
    state: "Michigan",
  },
  {
    area_code: 517,
    city: "Lansing",
    state: "Michigan",
  },
  {
    area_code: 517,
    city: "Okemos",
    state: "Michigan",
  },
  {
    area_code: 518,
    city: "West Albany",
    state: "New York",
  },
  {
    area_code: 518,
    city: "Rotterdam",
    state: "New York",
  },
  {
    area_code: 518,
    city: "Saratoga Springs",
    state: "New York",
  },
  {
    area_code: 518,
    city: "Schenectady",
    state: "New York",
  },
  {
    area_code: 518,
    city: "Troy",
    state: "New York",
  },
  {
    area_code: 520,
    city: "Casa Grande",
    state: "Arizona",
  },
  {
    area_code: 520,
    city: "Catalina Foothills",
    state: "Arizona",
  },
  {
    area_code: 520,
    city: "Drexel Heights",
    state: "Arizona",
  },
  {
    area_code: 520,
    city: "Nogales",
    state: "Arizona",
  },
  {
    area_code: 520,
    city: "Oro Valley",
    state: "Arizona",
  },
  {
    area_code: 520,
    city: "Sierra Vista",
    state: "Arizona",
  },
  {
    area_code: 520,
    city: "Tucson",
    state: "Arizona",
  },
  {
    area_code: 530,
    city: "Chico",
    state: "California",
  },
  {
    area_code: 530,
    city: "Davis",
    state: "California",
  },
  {
    area_code: 530,
    city: "Paradise",
    state: "California",
  },
  {
    area_code: 530,
    city: "Redding",
    state: "California",
  },
  {
    area_code: 530,
    city: "South Lake Tahoe",
    state: "California",
  },
  {
    area_code: 530,
    city: "Woodland",
    state: "California",
  },
  {
    area_code: 530,
    city: "Yuba City",
    state: "California",
  },
  {
    area_code: 531,
    city: "Bellevue",
    state: "Nebraska",
  },
  {
    area_code: 531,
    city: "Columbus",
    state: "Nebraska",
  },
  {
    area_code: 531,
    city: "Fremont",
    state: "Nebraska",
  },
  {
    area_code: 531,
    city: "Hastings",
    state: "Nebraska",
  },
  {
    area_code: 531,
    city: "Lincoln",
    state: "Nebraska",
  },
  {
    area_code: 531,
    city: "Norfolk",
    state: "Nebraska",
  },
  {
    area_code: 531,
    city: "Omaha",
    state: "Nebraska",
  },
  {
    area_code: 539,
    city: "Bartlesville",
    state: "Oklahoma",
  },
  {
    area_code: 539,
    city: "Broken Arrow",
    state: "Oklahoma",
  },
  {
    area_code: 539,
    city: "Muskogee",
    state: "Oklahoma",
  },
  {
    area_code: 539,
    city: "Tulsa",
    state: "Oklahoma",
  },
  {
    area_code: 540,
    city: "Blacksburg",
    state: "Virginia",
  },
  {
    area_code: 540,
    city: "Cave Spring",
    state: "Virginia",
  },
  {
    area_code: 540,
    city: "Harrisonburg",
    state: "Virginia",
  },
  {
    area_code: 540,
    city: "Roanoke",
    state: "Virginia",
  },
  {
    area_code: 540,
    city: "Salem",
    state: "Virginia",
  },
  {
    area_code: 540,
    city: "Staunton",
    state: "Virginia",
  },
  {
    area_code: 540,
    city: "Winchester",
    state: "Virginia",
  },
  {
    area_code: 541,
    city: "Albany",
    state: "Oregon",
  },
  {
    area_code: 541,
    city: "North Bend",
    state: "Oregon",
  },
  {
    area_code: 541,
    city: "Corvallis",
    state: "Oregon",
  },
  {
    area_code: 541,
    city: "Gresham",
    state: "Oregon",
  },
  {
    area_code: 541,
    city: "Grants Pass",
    state: "Oregon",
  },
  {
    area_code: 541,
    city: "Medford",
    state: "Oregon",
  },
  {
    area_code: 541,
    city: "Roseburg North",
    state: "Oregon",
  },
  {
    area_code: 541,
    city: "Springfield",
    state: "Oregon",
  },
  {
    area_code: 551,
    city: "Bayonne",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Bergenfield",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Cliffside Park",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Englewood",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Fair Lawn",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Fort Lee",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Hackensack",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Hoboken",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Jersey City",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Kearny",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "North Bergen",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Paramus",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Ridgewood",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Teaneck",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "Union City",
    state: "New Jersey",
  },
  {
    area_code: 551,
    city: "West New York",
    state: "New Jersey",
  },
  {
    area_code: 559,
    city: "Clovis",
    state: "California",
  },
  {
    area_code: 559,
    city: "Fresno",
    state: "California",
  },
  {
    area_code: 559,
    city: "Hanford",
    state: "California",
  },
  {
    area_code: 559,
    city: "Madera",
    state: "California",
  },
  {
    area_code: 559,
    city: "Porterville",
    state: "California",
  },
  {
    area_code: 559,
    city: "Reedley",
    state: "California",
  },
  {
    area_code: 559,
    city: "Tulare",
    state: "California",
  },
  {
    area_code: 559,
    city: "Visalia",
    state: "California",
  },
  {
    area_code: 561,
    city: "Boca Del Mar",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "Boca Raton",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "Boynton Beach",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "Delray Beach",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "Jupiter",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "Lake Worth",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "Palm Beach Gardens",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "Riviera Beach",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "Royal Palm Beach",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "University Park",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "Wellington",
    state: "Florida",
  },
  {
    area_code: 561,
    city: "West Palm Beach",
    state: "Florida",
  },
  {
    area_code: 562,
    city: "Bellflower",
    state: "California",
  },
  {
    area_code: 562,
    city: "Cerritos",
    state: "California",
  },
  {
    area_code: 562,
    city: "Downey",
    state: "California",
  },
  {
    area_code: 562,
    city: "La Habra",
    state: "California",
  },
  {
    area_code: 562,
    city: "La Mirada",
    state: "California",
  },
  {
    area_code: 562,
    city: "Lakewood",
    state: "California",
  },
  {
    area_code: 562,
    city: "Long Beach",
    state: "California",
  },
  {
    area_code: 562,
    city: "Norwalk",
    state: "California",
  },
  {
    area_code: 562,
    city: "Paramount",
    state: "California",
  },
  {
    area_code: 562,
    city: "Pico Rivera",
    state: "California",
  },
  {
    area_code: 562,
    city: "Seal Beach",
    state: "California",
  },
  {
    area_code: 562,
    city: "South Whittier",
    state: "California",
  },
  {
    area_code: 562,
    city: "West Whittier-Los Nietos",
    state: "California",
  },
  {
    area_code: 562,
    city: "Whittier",
    state: "California",
  },
  {
    area_code: 563,
    city: "Bettendorf",
    state: "Iowa",
  },
  {
    area_code: 563,
    city: "Clinton",
    state: "Iowa",
  },
  {
    area_code: 563,
    city: "Davenport",
    state: "Iowa",
  },
  {
    area_code: 563,
    city: "Dubuque",
    state: "Iowa",
  },
  {
    area_code: 563,
    city: "Muscatine",
    state: "Iowa",
  },
  {
    area_code: 567,
    city: "Ashland",
    state: "Ohio",
  },
  {
    area_code: 567,
    city: "Bowling Green",
    state: "Ohio",
  },
  {
    area_code: 567,
    city: "Findlay",
    state: "Ohio",
  },
  {
    area_code: 567,
    city: "Lima",
    state: "Ohio",
  },
  {
    area_code: 567,
    city: "Mansfield",
    state: "Ohio",
  },
  {
    area_code: 567,
    city: "Sandusky",
    state: "Ohio",
  },
  {
    area_code: 567,
    city: "Toledo",
    state: "Ohio",
  },
  {
    area_code: 570,
    city: "Back Mountain",
    state: "Pennsylvania",
  },
  {
    area_code: 570,
    city: "Hazleton",
    state: "Pennsylvania",
  },
  {
    area_code: 570,
    city: "Scranton",
    state: "Pennsylvania",
  },
  {
    area_code: 570,
    city: "Wilkes-Barre",
    state: "Pennsylvania",
  },
  {
    area_code: 570,
    city: "Williamsport",
    state: "Pennsylvania",
  },
  {
    area_code: 571,
    city: "Alexandria",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Annandale",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Arlington",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Burke",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Centreville",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Chantilly",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Dale City",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Fairfax",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Franconia",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Groveton",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Herndon",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Lake Ridge",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Leesburg",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Manassas",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "McLean",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Mount Vernon",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Oakton",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "Reston",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "West Springfield",
    state: "Virginia",
  },
  {
    area_code: 571,
    city: "West Springfield",
    state: "Virginia",
  },
  {
    area_code: 573,
    city: "Cape Girardeau",
    state: "Missouri",
  },
  {
    area_code: 573,
    city: "Columbia",
    state: "Missouri",
  },
  {
    area_code: 573,
    city: "Jefferson City",
    state: "Missouri",
  },
  {
    area_code: 574,
    city: "Elkhart",
    state: "Indiana",
  },
  {
    area_code: 574,
    city: "Goshen",
    state: "Indiana",
  },
  {
    area_code: 574,
    city: "Granger",
    state: "Indiana",
  },
  {
    area_code: 574,
    city: "Mishawaka",
    state: "Indiana",
  },
  {
    area_code: 574,
    city: "South Bend",
    state: "Indiana",
  },
  {
    area_code: 575,
    city: "Alamogordo",
    state: "New Mexico",
  },
  {
    area_code: 575,
    city: "Carlsbad",
    state: "New Mexico",
  },
  {
    area_code: 575,
    city: "Clovis",
    state: "New Mexico",
  },
  {
    area_code: 575,
    city: "Hobbs",
    state: "New Mexico",
  },
  {
    area_code: 575,
    city: "Las Cruces",
    state: "New Mexico",
  },
  {
    area_code: 575,
    city: "Roswell",
    state: "New Mexico",
  },
  {
    area_code: 580,
    city: "Altus",
    state: "Oklahoma",
  },
  {
    area_code: 580,
    city: "Ardmore",
    state: "Oklahoma",
  },
  {
    area_code: 580,
    city: "Duncan",
    state: "Oklahoma",
  },
  {
    area_code: 580,
    city: "Enid",
    state: "Oklahoma",
  },
  {
    area_code: 580,
    city: "Lawton",
    state: "Oklahoma",
  },
  {
    area_code: 580,
    city: "Ponca City",
    state: "Oklahoma",
  },
  {
    area_code: 585,
    city: "Brighton",
    state: "New York",
  },
  {
    area_code: 585,
    city: "Irondequoit",
    state: "New York",
  },
  {
    area_code: 585,
    city: "Rochester",
    state: "New York",
  },
  {
    area_code: 586,
    city: "Clinton",
    state: "Michigan",
  },
  {
    area_code: 586,
    city: "Eastpointe",
    state: "Michigan",
  },
  {
    area_code: 586,
    city: "Roseville",
    state: "Michigan",
  },
  {
    area_code: 586,
    city: "Shelby",
    state: "Michigan",
  },
  {
    area_code: 586,
    city: "Saint Clair Shores",
    state: "Michigan",
  },
  {
    area_code: 586,
    city: "Sterling Heights",
    state: "Michigan",
  },
  {
    area_code: 586,
    city: "Warren",
    state: "Michigan",
  },
  {
    area_code: 601,
    city: "Clinton",
    state: "Mississippi",
  },
  {
    area_code: 601,
    city: "West Hattiesburg",
    state: "Mississippi",
  },
  {
    area_code: 601,
    city: "Jackson",
    state: "Mississippi",
  },
  {
    area_code: 601,
    city: "Meridian",
    state: "Mississippi",
  },
  {
    area_code: 601,
    city: "Pearl",
    state: "Mississippi",
  },
  {
    area_code: 601,
    city: "Ridgeland",
    state: "Mississippi",
  },
  {
    area_code: 601,
    city: "Vicksburg",
    state: "Mississippi",
  },
  {
    area_code: 602,
    city: "Phoenix",
    state: "Arizona",
  },
  {
    area_code: 603,
    city: "East Concord",
    state: "New Hampshire",
  },
  {
    area_code: 603,
    city: "Derry",
    state: "New Hampshire",
  },
  {
    area_code: 603,
    city: "Dover",
    state: "New Hampshire",
  },
  {
    area_code: 603,
    city: "Keene",
    state: "New Hampshire",
  },
  {
    area_code: 603,
    city: "Manchester",
    state: "New Hampshire",
  },
  {
    area_code: 603,
    city: "Nashua",
    state: "New Hampshire",
  },
  {
    area_code: 603,
    city: "Portsmouth",
    state: "New Hampshire",
  },
  {
    area_code: 603,
    city: "Rochester",
    state: "New Hampshire",
  },
  {
    area_code: 605,
    city: "Aberdeen",
    state: "South Dakota",
  },
  {
    area_code: 605,
    city: "Pierre",
    state: "South Dakota",
  },
  {
    area_code: 605,
    city: "Rapid City",
    state: "South Dakota",
  },
  {
    area_code: 605,
    city: "Sioux Falls",
    state: "South Dakota",
  },
  {
    area_code: 605,
    city: "Watertown",
    state: "South Dakota",
  },
  {
    area_code: 606,
    city: "Ashland",
    state: "Kentucky",
  },
  {
    area_code: 607,
    city: "Binghamton",
    state: "New York",
  },
  {
    area_code: 607,
    city: "Elmira",
    state: "New York",
  },
  {
    area_code: 607,
    city: "Ithaca",
    state: "New York",
  },
  {
    area_code: 608,
    city: "Beloit",
    state: "Wisconsin",
  },
  {
    area_code: 608,
    city: "Fitchburg",
    state: "Wisconsin",
  },
  {
    area_code: 608,
    city: "Janesville",
    state: "Wisconsin",
  },
  {
    area_code: 608,
    city: "North La Crosse",
    state: "Wisconsin",
  },
  {
    area_code: 608,
    city: "Madison",
    state: "Wisconsin",
  },
  {
    area_code: 608,
    city: "Sun Prairie",
    state: "Wisconsin",
  },
  {
    area_code: 609,
    city: "Atlantic City",
    state: "New Jersey",
  },
  {
    area_code: 609,
    city: "Ewing",
    state: "New Jersey",
  },
  {
    area_code: 609,
    city: "Trenton",
    state: "New Jersey",
  },
  {
    area_code: 609,
    city: "Willingboro",
    state: "New Jersey",
  },
  {
    area_code: 610,
    city: "Allentown",
    state: "Pennsylvania",
  },
  {
    area_code: 610,
    city: "Bethlehem",
    state: "Pennsylvania",
  },
  {
    area_code: 610,
    city: "West Chester",
    state: "Pennsylvania",
  },
  {
    area_code: 610,
    city: "Drexel Hill",
    state: "Pennsylvania",
  },
  {
    area_code: 610,
    city: "Easton",
    state: "Pennsylvania",
  },
  {
    area_code: 610,
    city: "Norristown",
    state: "Pennsylvania",
  },
  {
    area_code: 610,
    city: "Pottstown",
    state: "Pennsylvania",
  },
  {
    area_code: 610,
    city: "Radnor",
    state: "Pennsylvania",
  },
  {
    area_code: 610,
    city: "Reading",
    state: "Pennsylvania",
  },
  {
    area_code: 610,
    city: "Springfield",
    state: "Pennsylvania",
  },
  {
    area_code: 612,
    city: "Crystal",
    state: "Minnesota",
  },
  {
    area_code: 612,
    city: "Golden Valley",
    state: "Minnesota",
  },
  {
    area_code: 612,
    city: "Minneapolis",
    state: "Minnesota",
  },
  {
    area_code: 612,
    city: "Richfield",
    state: "Minnesota",
  },
  {
    area_code: 612,
    city: "Saint Louis Park",
    state: "Minnesota",
  },
  {
    area_code: 614,
    city: "Columbus",
    state: "Ohio",
  },
  {
    area_code: 614,
    city: "Dublin",
    state: "Ohio",
  },
  {
    area_code: 614,
    city: "Gahanna",
    state: "Ohio",
  },
  {
    area_code: 614,
    city: "Grove City",
    state: "Ohio",
  },
  {
    area_code: 614,
    city: "Hilliard",
    state: "Ohio",
  },
  {
    area_code: 614,
    city: "Reynoldsburg",
    state: "Ohio",
  },
  {
    area_code: 614,
    city: "Upper Arlington",
    state: "Ohio",
  },
  {
    area_code: 614,
    city: "Westerville",
    state: "Ohio",
  },
  {
    area_code: 615,
    city: "Brentwood",
    state: "Tennessee",
  },
  {
    area_code: 615,
    city: "Franklin",
    state: "Tennessee",
  },
  {
    area_code: 615,
    city: "Gallatin",
    state: "Tennessee",
  },
  {
    area_code: 615,
    city: "Hendersonville",
    state: "Tennessee",
  },
  {
    area_code: 615,
    city: "Lebanon",
    state: "Tennessee",
  },
  {
    area_code: 615,
    city: "Murfreesboro",
    state: "Tennessee",
  },
  {
    area_code: 615,
    city: "Nashville",
    state: "Tennessee",
  },
  {
    area_code: 615,
    city: "Smyrna",
    state: "Tennessee",
  },
  {
    area_code: 616,
    city: "Forest Hills",
    state: "Michigan",
  },
  {
    area_code: 616,
    city: "Grand Rapids",
    state: "Michigan",
  },
  {
    area_code: 616,
    city: "Holland",
    state: "Michigan",
  },
  {
    area_code: 616,
    city: "Kentwood",
    state: "Michigan",
  },
  {
    area_code: 616,
    city: "Walker",
    state: "Michigan",
  },
  {
    area_code: 616,
    city: "Wyoming",
    state: "Michigan",
  },
  {
    area_code: 617,
    city: "Belmont",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "South Boston",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "Brookline",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "Cambridge",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "Chelsea",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "Everett",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "Milton",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "Newton",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "Quincy",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "Somerville",
    state: "Massachusetts",
  },
  {
    area_code: 617,
    city: "Watertown",
    state: "Massachusetts",
  },
  {
    area_code: 618,
    city: "East Alton",
    state: "Illinois",
  },
  {
    area_code: 618,
    city: "Belleville",
    state: "Illinois",
  },
  {
    area_code: 618,
    city: "Carbondale",
    state: "Illinois",
  },
  {
    area_code: 618,
    city: "Collinsville",
    state: "Illinois",
  },
  {
    area_code: 618,
    city: "East Saint Louis",
    state: "Illinois",
  },
  {
    area_code: 618,
    city: "Edwardsville",
    state: "Illinois",
  },
  {
    area_code: 618,
    city: "Granite City",
    state: "Illinois",
  },
  {
    area_code: 618,
    city: "O'Fallon",
    state: "Illinois",
  },
  {
    area_code: 619,
    city: "Chula Vista",
    state: "California",
  },
  {
    area_code: 619,
    city: "Coronado",
    state: "California",
  },
  {
    area_code: 619,
    city: "El Cajon",
    state: "California",
  },
  {
    area_code: 619,
    city: "Imperial Beach",
    state: "California",
  },
  {
    area_code: 619,
    city: "La Mesa",
    state: "California",
  },
  {
    area_code: 619,
    city: "La Presa",
    state: "California",
  },
  {
    area_code: 619,
    city: "Lemon Grove",
    state: "California",
  },
  {
    area_code: 619,
    city: "National City",
    state: "California",
  },
  {
    area_code: 619,
    city: "Rancho San Diego",
    state: "California",
  },
  {
    area_code: 619,
    city: "San Diego",
    state: "California",
  },
  {
    area_code: 619,
    city: "Santee",
    state: "California",
  },
  {
    area_code: 619,
    city: "Spring Valley",
    state: "California",
  },
  {
    area_code: 620,
    city: "Dodge City",
    state: "Kansas",
  },
  {
    area_code: 620,
    city: "Emporia",
    state: "Kansas",
  },
  {
    area_code: 620,
    city: "Garden City",
    state: "Kansas",
  },
  {
    area_code: 620,
    city: "Hutchinson",
    state: "Kansas",
  },
  {
    area_code: 623,
    city: "Avondale",
    state: "Arizona",
  },
  {
    area_code: 623,
    city: "Glendale",
    state: "Arizona",
  },
  {
    area_code: 623,
    city: "Peoria",
    state: "Arizona",
  },
  {
    area_code: 623,
    city: "Sun City West",
    state: "Arizona",
  },
  {
    area_code: 623,
    city: "Sun City West",
    state: "Arizona",
  },
  {
    area_code: 623,
    city: "Surprise",
    state: "Arizona",
  },
  {
    area_code: 626,
    city: "Alhambra",
    state: "California",
  },
  {
    area_code: 626,
    city: "Altadena",
    state: "California",
  },
  {
    area_code: 626,
    city: "Arcadia",
    state: "California",
  },
  {
    area_code: 626,
    city: "Azusa",
    state: "California",
  },
  {
    area_code: 626,
    city: "Baldwin Park",
    state: "California",
  },
  {
    area_code: 626,
    city: "West Covina",
    state: "California",
  },
  {
    area_code: 626,
    city: "Duarte",
    state: "California",
  },
  {
    area_code: 626,
    city: "South El Monte",
    state: "California",
  },
  {
    area_code: 626,
    city: "Glendora",
    state: "California",
  },
  {
    area_code: 626,
    city: "Hacienda Heights",
    state: "California",
  },
  {
    area_code: 626,
    city: "La Puente",
    state: "California",
  },
  {
    area_code: 626,
    city: "Monrovia",
    state: "California",
  },
  {
    area_code: 626,
    city: "Monterey Park",
    state: "California",
  },
  {
    area_code: 626,
    city: "South Pasadena",
    state: "California",
  },
  {
    area_code: 626,
    city: "Rosemead",
    state: "California",
  },
  {
    area_code: 626,
    city: "Rowland Heights",
    state: "California",
  },
  {
    area_code: 626,
    city: "South San Gabriel",
    state: "California",
  },
  {
    area_code: 626,
    city: "South El Monte",
    state: "California",
  },
  {
    area_code: 626,
    city: "South Pasadena",
    state: "California",
  },
  {
    area_code: 626,
    city: "South San Jose Hills",
    state: "California",
  },
  {
    area_code: 626,
    city: "Temple City",
    state: "California",
  },
  {
    area_code: 626,
    city: "Valinda",
    state: "California",
  },
  {
    area_code: 626,
    city: "Walnut",
    state: "California",
  },
  {
    area_code: 626,
    city: "West Covina",
    state: "California",
  },
  {
    area_code: 626,
    city: "West Puente Valley",
    state: "California",
  },
  {
    area_code: 628,
    city: "Novato",
    state: "California",
  },
  {
    area_code: 628,
    city: "San Anselmo",
    state: "California",
  },
  {
    area_code: 628,
    city: "South San Francisco",
    state: "California",
  },
  {
    area_code: 628,
    city: "San Rafael",
    state: "California",
  },
  {
    area_code: 629,
    city: "Brentwood",
    state: "Tennessee",
  },
  {
    area_code: 629,
    city: "Franklin",
    state: "Tennessee",
  },
  {
    area_code: 629,
    city: "Gallatin",
    state: "Tennessee",
  },
  {
    area_code: 629,
    city: "Hendersonville",
    state: "Tennessee",
  },
  {
    area_code: 629,
    city: "Lebanon",
    state: "Tennessee",
  },
  {
    area_code: 629,
    city: "Murfreesboro",
    state: "Tennessee",
  },
  {
    area_code: 629,
    city: "Nashville",
    state: "Tennessee",
  },
  {
    area_code: 629,
    city: "Smyrna",
    state: "Tennessee",
  },
  {
    area_code: 630,
    city: "Addison",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "North Aurora",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Bartlett",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Batavia",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Bensenville",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Bloomingdale",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Bolingbrook",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Carol Stream",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Darien",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Downers Grove",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Elmhurst",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Glen Ellyn",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Glendale Heights",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Hanover Park",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Lisle",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Lombard",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Naperville",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Roselle",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Saint Charles",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Streamwood",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Villa Park",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "West Chicago",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Westmont",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Wheaton",
    state: "Illinois",
  },
  {
    area_code: 630,
    city: "Woodridge",
    state: "Illinois",
  },
  {
    area_code: 631,
    city: "North Bay Shore",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Brentwood",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Centereach",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Central Islip",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Commack",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Copiague",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Coram",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Deer Park",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Dix Hills",
    state: "New York",
  },
  {
    area_code: 631,
    city: "East Northport",
    state: "New York",
  },
  {
    area_code: 631,
    city: "East Patchogue",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Hauppauge",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Holbrook",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Huntington Station",
    state: "New York",
  },
  {
    area_code: 631,
    city: "West Islip",
    state: "New York",
  },
  {
    area_code: 631,
    city: "North Lindenhurst",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Medford",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Ronkonkoma",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Selden",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Shirley",
    state: "New York",
  },
  {
    area_code: 631,
    city: "Smithtown",
    state: "New York",
  },
  {
    area_code: 631,
    city: "West Babylon",
    state: "New York",
  },
  {
    area_code: 631,
    city: "West Islip",
    state: "New York",
  },
  {
    area_code: 636,
    city: "Ballwin",
    state: "Missouri",
  },
  {
    area_code: 636,
    city: "Chesterfield",
    state: "Missouri",
  },
  {
    area_code: 636,
    city: "O'Fallon",
    state: "Missouri",
  },
  {
    area_code: 636,
    city: "Saint Charles",
    state: "Missouri",
  },
  {
    area_code: 636,
    city: "Saint Peters",
    state: "Missouri",
  },
  {
    area_code: 636,
    city: "Wildwood",
    state: "Missouri",
  },
  {
    area_code: 641,
    city: "Marshalltown",
    state: "Iowa",
  },
  {
    area_code: 641,
    city: "Mason City",
    state: "Iowa",
  },
  {
    area_code: 641,
    city: "Ottumwa",
    state: "Iowa",
  },
  {
    area_code: 646,
    city: "New York City",
    state: "New York",
  },
  {
    area_code: 646,
    city: "New York City",
    state: "New York",
  },
  {
    area_code: 650,
    city: "Belmont",
    state: "California",
  },
  {
    area_code: 650,
    city: "Burlingame",
    state: "California",
  },
  {
    area_code: 650,
    city: "Daly City",
    state: "California",
  },
  {
    area_code: 650,
    city: "East Palo Alto",
    state: "California",
  },
  {
    area_code: 650,
    city: "Foster City",
    state: "California",
  },
  {
    area_code: 650,
    city: "Los Altos",
    state: "California",
  },
  {
    area_code: 650,
    city: "Menlo Park",
    state: "California",
  },
  {
    area_code: 650,
    city: "Millbrae",
    state: "California",
  },
  {
    area_code: 650,
    city: "Mountain View",
    state: "California",
  },
  {
    area_code: 650,
    city: "Pacifica",
    state: "California",
  },
  {
    area_code: 650,
    city: "Palo Alto",
    state: "California",
  },
  {
    area_code: 650,
    city: "Redwood City",
    state: "California",
  },
  {
    area_code: 650,
    city: "San Bruno",
    state: "California",
  },
  {
    area_code: 650,
    city: "San Carlos",
    state: "California",
  },
  {
    area_code: 650,
    city: "San Mateo",
    state: "California",
  },
  {
    area_code: 650,
    city: "South San Francisco",
    state: "California",
  },
  {
    area_code: 651,
    city: "Apple Valley",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Cottage Grove",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Eagan",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Inver Grove Heights",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Lakeville",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Maplewood",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "New Brighton",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Oakdale",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Roseville",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Shoreview",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "South Saint Paul",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Saint Paul",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "White Bear Lake",
    state: "Minnesota",
  },
  {
    area_code: 651,
    city: "Woodbury",
    state: "Minnesota",
  },
  {
    area_code: 657,
    city: "Anaheim",
    state: "California",
  },
  {
    area_code: 657,
    city: "Brea",
    state: "California",
  },
  {
    area_code: 657,
    city: "Buena Park",
    state: "California",
  },
  {
    area_code: 657,
    city: "Cypress",
    state: "California",
  },
  {
    area_code: 657,
    city: "Fountain Valley",
    state: "California",
  },
  {
    area_code: 657,
    city: "Fullerton",
    state: "California",
  },
  {
    area_code: 657,
    city: "Garden Grove",
    state: "California",
  },
  {
    area_code: 657,
    city: "Huntington Beach",
    state: "California",
  },
  {
    area_code: 657,
    city: "Orange",
    state: "California",
  },
  {
    area_code: 657,
    city: "Placentia",
    state: "California",
  },
  {
    area_code: 657,
    city: "Santa Ana",
    state: "California",
  },
  {
    area_code: 657,
    city: "Stanton",
    state: "California",
  },
  {
    area_code: 657,
    city: "North Tustin",
    state: "California",
  },
  {
    area_code: 657,
    city: "Westminster",
    state: "California",
  },
  {
    area_code: 657,
    city: "Yorba Linda",
    state: "California",
  },
  {
    area_code: 660,
    city: "Sedalia",
    state: "Missouri",
  },
  {
    area_code: 661,
    city: "Bakersfield",
    state: "California",
  },
  {
    area_code: 661,
    city: "Delano",
    state: "California",
  },
  {
    area_code: 661,
    city: "Lancaster",
    state: "California",
  },
  {
    area_code: 661,
    city: "Oildale",
    state: "California",
  },
  {
    area_code: 661,
    city: "Palmdale",
    state: "California",
  },
  {
    area_code: 661,
    city: "Santa Clarita",
    state: "California",
  },
  {
    area_code: 661,
    city: "Wasco",
    state: "California",
  },
  {
    area_code: 662,
    city: "Clarksdale",
    state: "Mississippi",
  },
  {
    area_code: 662,
    city: "Columbus",
    state: "Mississippi",
  },
  {
    area_code: 662,
    city: "Greenville",
    state: "Mississippi",
  },
  {
    area_code: 662,
    city: "Olive Branch",
    state: "Mississippi",
  },
  {
    area_code: 662,
    city: "Southaven",
    state: "Mississippi",
  },
  {
    area_code: 662,
    city: "Starkville",
    state: "Mississippi",
  },
  {
    area_code: 662,
    city: "Tupelo",
    state: "Mississippi",
  },
  {
    area_code: 667,
    city: "Annapolis",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Arbutus",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Arnold",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Baltimore",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Bel Air North",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Bel Air South",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Carney",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Catonsville",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Columbia",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Crofton",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Dundalk",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Edgewood",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Eldersburg",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "West Elkridge",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Ellicott City",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Essex",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Glen Burnie",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Lochearn",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Middle River",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Milford Mill",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Odenton",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Parkville",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Perry Hall",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Pikesville",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Randallstown",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Reisterstown",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Salisbury",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Severn",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Severna Park",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "South Gate",
    state: "Maryland",
  },
  {
    area_code: 667,
    city: "Towson",
    state: "Maryland",
  },
  {
    area_code: 669,
    city: "Campbell",
    state: "California",
  },
  {
    area_code: 669,
    city: "Gilroy",
    state: "California",
  },
  {
    area_code: 669,
    city: "Los Gatos",
    state: "California",
  },
  {
    area_code: 669,
    city: "Milpitas",
    state: "California",
  },
  {
    area_code: 669,
    city: "Morgan Hill",
    state: "California",
  },
  {
    area_code: 669,
    city: "San Jose",
    state: "California",
  },
  {
    area_code: 669,
    city: "Santa Clara",
    state: "California",
  },
  {
    area_code: 669,
    city: "Saratoga",
    state: "California",
  },
  {
    area_code: 669,
    city: "Sunnyvale",
    state: "California",
  },
  {
    area_code: 678,
    city: "Alpharetta",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Atlanta",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Candler-McAfee",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "College Park",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "College Park",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Douglasville",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Duluth",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Dunwoody",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "East Point",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Forest Park",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Gainesville",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Griffin",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Kennesaw",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Lawrenceville",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Mableton",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Marietta",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Atlanta",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Peachtree City",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Redan",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Roswell",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Sandy Springs",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Smyrna",
    state: "Georgia",
  },
  {
    area_code: 678,
    city: "Tucker",
    state: "Georgia",
  },
  {
    area_code: 681,
    city: "South Charleston",
    state: "West Virginia",
  },
  {
    area_code: 681,
    city: "Huntington",
    state: "West Virginia",
  },
  {
    area_code: 681,
    city: "Morgantown",
    state: "West Virginia",
  },
  {
    area_code: 681,
    city: "Parkersburg",
    state: "West Virginia",
  },
  {
    area_code: 681,
    city: "Weirton",
    state: "West Virginia",
  },
  {
    area_code: 681,
    city: "Wheeling",
    state: "West Virginia",
  },
  {
    area_code: 682,
    city: "Arlington",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Bedford",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Benbrook",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Burleson",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Cleburne",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Euless",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Fort Worth",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Grapevine",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Haltom City",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Hurst",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Keller",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Mansfield",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "North Richland Hills",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Southlake",
    state: "Texas",
  },
  {
    area_code: 682,
    city: "Watauga",
    state: "Texas",
  },
  {
    area_code: 701,
    city: "Bismarck",
    state: "North Dakota",
  },
  {
    area_code: 701,
    city: "West Fargo",
    state: "North Dakota",
  },
  {
    area_code: 701,
    city: "Grand Forks",
    state: "North Dakota",
  },
  {
    area_code: 701,
    city: "Minot",
    state: "North Dakota",
  },
  {
    area_code: 702,
    city: "Henderson",
    state: "Nevada",
  },
  {
    area_code: 702,
    city: "North Las Vegas",
    state: "Nevada",
  },
  {
    area_code: 702,
    city: "North Las Vegas",
    state: "Nevada",
  },
  {
    area_code: 702,
    city: "Paradise",
    state: "Nevada",
  },
  {
    area_code: 702,
    city: "Spring Valley",
    state: "Nevada",
  },
  {
    area_code: 702,
    city: "Sunrise Manor",
    state: "Nevada",
  },
  {
    area_code: 702,
    city: "Winchester",
    state: "Nevada",
  },
  {
    area_code: 703,
    city: "Alexandria",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Annandale",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Arlington",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Burke",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Centreville",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Chantilly",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Dale City",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Fairfax",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Franconia",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Groveton",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Herndon",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Lake Ridge",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Leesburg",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Manassas",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "McLean",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Mount Vernon",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Oakton",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "Reston",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "West Springfield",
    state: "Virginia",
  },
  {
    area_code: 703,
    city: "West Springfield",
    state: "Virginia",
  },
  {
    area_code: 704,
    city: "Charlotte",
    state: "North Carolina",
  },
  {
    area_code: 704,
    city: "Concord",
    state: "North Carolina",
  },
  {
    area_code: 704,
    city: "South Gastonia",
    state: "North Carolina",
  },
  {
    area_code: 704,
    city: "Huntersville",
    state: "North Carolina",
  },
  {
    area_code: 704,
    city: "Kannapolis",
    state: "North Carolina",
  },
  {
    area_code: 704,
    city: "Matthews",
    state: "North Carolina",
  },
  {
    area_code: 704,
    city: "Monroe",
    state: "North Carolina",
  },
  {
    area_code: 704,
    city: "Salisbury",
    state: "North Carolina",
  },
  {
    area_code: 704,
    city: "Statesville",
    state: "North Carolina",
  },
  {
    area_code: 706,
    city: "Athens",
    state: "Georgia",
  },
  {
    area_code: 706,
    city: "Augusta",
    state: "Georgia",
  },
  {
    area_code: 706,
    city: "Columbus",
    state: "Georgia",
  },
  {
    area_code: 706,
    city: "Dalton",
    state: "Georgia",
  },
  {
    area_code: 706,
    city: "La Grange",
    state: "Georgia",
  },
  {
    area_code: 706,
    city: "Martinez",
    state: "Georgia",
  },
  {
    area_code: 706,
    city: "Rome",
    state: "Georgia",
  },
  {
    area_code: 707,
    city: "Benicia",
    state: "California",
  },
  {
    area_code: 707,
    city: "Eureka",
    state: "California",
  },
  {
    area_code: 707,
    city: "Fairfield",
    state: "California",
  },
  {
    area_code: 707,
    city: "Napa",
    state: "California",
  },
  {
    area_code: 707,
    city: "Petaluma",
    state: "California",
  },
  {
    area_code: 707,
    city: "Rohnert Park",
    state: "California",
  },
  {
    area_code: 707,
    city: "Santa Rosa",
    state: "California",
  },
  {
    area_code: 707,
    city: "Suisun",
    state: "California",
  },
  {
    area_code: 707,
    city: "Vacaville",
    state: "California",
  },
  {
    area_code: 707,
    city: "Vallejo",
    state: "California",
  },
  {
    area_code: 707,
    city: "Windsor",
    state: "California",
  },
  {
    area_code: 708,
    city: "Bellwood",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Berwyn",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Blue Island",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Burbank",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Calumet City",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Chicago Heights",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Cicero",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Dolton",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Elmwood Park",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Evergreen Park",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Harvey",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Lansing",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Maywood",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Melrose Park",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Oak Forest",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Oak Lawn",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Oak Park",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Orland Park",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Park Forest",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "South Holland",
    state: "Illinois",
  },
  {
    area_code: 708,
    city: "Tinley Park",
    state: "Illinois",
  },
  {
    area_code: 712,
    city: "Council Bluffs",
    state: "Iowa",
  },
  {
    area_code: 712,
    city: "Sioux City",
    state: "Iowa",
  },
  {
    area_code: 713,
    city: "Alvin",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Atascocita",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Baytown",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Channelview",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Cloverleaf",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Deer Park",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Friendswood",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "South Houston",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "La Porte",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "League City",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Mission Bend",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Missouri City",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Pasadena",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Pearland",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Rosenberg",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Spring",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "Sugar Land",
    state: "Texas",
  },
  {
    area_code: 713,
    city: "The Woodlands",
    state: "Texas",
  },
  {
    area_code: 714,
    city: "Anaheim",
    state: "California",
  },
  {
    area_code: 714,
    city: "Brea",
    state: "California",
  },
  {
    area_code: 714,
    city: "Buena Park",
    state: "California",
  },
  {
    area_code: 714,
    city: "Cypress",
    state: "California",
  },
  {
    area_code: 714,
    city: "Fountain Valley",
    state: "California",
  },
  {
    area_code: 714,
    city: "Fullerton",
    state: "California",
  },
  {
    area_code: 714,
    city: "Garden Grove",
    state: "California",
  },
  {
    area_code: 714,
    city: "Huntington Beach",
    state: "California",
  },
  {
    area_code: 714,
    city: "Orange",
    state: "California",
  },
  {
    area_code: 714,
    city: "Placentia",
    state: "California",
  },
  {
    area_code: 714,
    city: "Santa Ana",
    state: "California",
  },
  {
    area_code: 714,
    city: "Stanton",
    state: "California",
  },
  {
    area_code: 714,
    city: "North Tustin",
    state: "California",
  },
  {
    area_code: 714,
    city: "Westminster",
    state: "California",
  },
  {
    area_code: 714,
    city: "Yorba Linda",
    state: "California",
  },
  {
    area_code: 715,
    city: "Eau Claire",
    state: "Wisconsin",
  },
  {
    area_code: 715,
    city: "Stevens Point",
    state: "Wisconsin",
  },
  {
    area_code: 715,
    city: "Superior",
    state: "Wisconsin",
  },
  {
    area_code: 715,
    city: "Wausau",
    state: "Wisconsin",
  },
  {
    area_code: 716,
    city: "Buffalo",
    state: "New York",
  },
  {
    area_code: 716,
    city: "Cheektowaga",
    state: "New York",
  },
  {
    area_code: 716,
    city: "Jamestown",
    state: "New York",
  },
  {
    area_code: 716,
    city: "South Lockport",
    state: "New York",
  },
  {
    area_code: 716,
    city: "Niagara Falls",
    state: "New York",
  },
  {
    area_code: 716,
    city: "North Tonawanda",
    state: "New York",
  },
  {
    area_code: 716,
    city: "Tonawanda",
    state: "New York",
  },
  {
    area_code: 716,
    city: "West Seneca",
    state: "New York",
  },
  {
    area_code: 717,
    city: "Harrisburg",
    state: "Pennsylvania",
  },
  {
    area_code: 717,
    city: "Lancaster",
    state: "Pennsylvania",
  },
  {
    area_code: 717,
    city: "Lebanon",
    state: "Pennsylvania",
  },
  {
    area_code: 717,
    city: "York",
    state: "Pennsylvania",
  },
  {
    area_code: 718,
    city: "The Bronx",
    state: "New York",
  },
  {
    area_code: 718,
    city: "Brooklyn",
    state: "New York",
  },
  {
    area_code: 718,
    city: "New York City",
    state: "New York",
  },
  {
    area_code: 718,
    city: "Borough of Queens",
    state: "New York",
  },
  {
    area_code: 718,
    city: "Staten Island",
    state: "New York",
  },
  {
    area_code: 719,
    city: "Colorado Springs",
    state: "Colorado",
  },
  {
    area_code: 719,
    city: "Pueblo West",
    state: "Colorado",
  },
  {
    area_code: 719,
    city: "Security-Widefield",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Arvada",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Aurora",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Boulder",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Brighton",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Broomfield",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Castle Rock",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Castlewood",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Columbine",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Commerce City",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Denver",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Englewood",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Highlands Ranch",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Ken Caryl",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Lafayette",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Lakewood",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Littleton",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Longmont",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Loveland",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Northglenn",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Parker",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Southglenn",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Thornton",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Westminster",
    state: "Colorado",
  },
  {
    area_code: 720,
    city: "Wheat Ridge",
    state: "Colorado",
  },
  {
    area_code: 724,
    city: "Monroeville",
    state: "Pennsylvania",
  },
  {
    area_code: 724,
    city: "New Castle",
    state: "Pennsylvania",
  },
  {
    area_code: 724,
    city: "Plum",
    state: "Pennsylvania",
  },
  {
    area_code: 724,
    city: "Upper Saint Clair",
    state: "Pennsylvania",
  },
  {
    area_code: 725,
    city: "Henderson",
    state: "Nevada",
  },
  {
    area_code: 725,
    city: "North Las Vegas",
    state: "Nevada",
  },
  {
    area_code: 725,
    city: "North Las Vegas",
    state: "Nevada",
  },
  {
    area_code: 725,
    city: "Paradise",
    state: "Nevada",
  },
  {
    area_code: 725,
    city: "Spring Valley",
    state: "Nevada",
  },
  {
    area_code: 725,
    city: "Sunrise Manor",
    state: "Nevada",
  },
  {
    area_code: 725,
    city: "Winchester",
    state: "Nevada",
  },
  {
    area_code: 727,
    city: "Bayonet Point",
    state: "Florida",
  },
  {
    area_code: 727,
    city: "Clearwater",
    state: "Florida",
  },
  {
    area_code: 727,
    city: "Dunedin",
    state: "Florida",
  },
  {
    area_code: 727,
    city: "Holiday",
    state: "Florida",
  },
  {
    area_code: 727,
    city: "Largo",
    state: "Florida",
  },
  {
    area_code: 727,
    city: "Lealman",
    state: "Florida",
  },
  {
    area_code: 727,
    city: "Palm Harbor",
    state: "Florida",
  },
  {
    area_code: 727,
    city: "Pinellas Park",
    state: "Florida",
  },
  {
    area_code: 727,
    city: "Saint Petersburg",
    state: "Florida",
  },
  {
    area_code: 727,
    city: "Tarpon Springs",
    state: "Florida",
  },
  {
    area_code: 731,
    city: "Jackson",
    state: "Tennessee",
  },
  {
    area_code: 732,
    city: "Carteret",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "East Brunswick",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "Edison",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "Lakewood",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "West Long Branch",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "New Brunswick",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "East Brunswick",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "North Plainfield",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "South Old Bridge",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "Perth Amboy",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "Rahway",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "Sayreville",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "Somerset",
    state: "New Jersey",
  },
  {
    area_code: 732,
    city: "Toms River",
    state: "New Jersey",
  },
  {
    area_code: 734,
    city: "Ann Arbor",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "Canton",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "Garden City",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "Livonia",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "South Monroe",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "Plymouth",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "Romulus",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "Southgate",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "Westland",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "Wyandotte",
    state: "Michigan",
  },
  {
    area_code: 734,
    city: "Ypsilanti",
    state: "Michigan",
  },
  {
    area_code: 737,
    city: "Austin",
    state: "Texas",
  },
  {
    area_code: 737,
    city: "Cedar Park",
    state: "Texas",
  },
  {
    area_code: 737,
    city: "Georgetown",
    state: "Texas",
  },
  {
    area_code: 737,
    city: "Round Rock",
    state: "Texas",
  },
  {
    area_code: 737,
    city: "San Marcos",
    state: "Texas",
  },
  {
    area_code: 740,
    city: "Athens",
    state: "Ohio",
  },
  {
    area_code: 740,
    city: "Chillicothe",
    state: "Ohio",
  },
  {
    area_code: 740,
    city: "Delaware",
    state: "Ohio",
  },
  {
    area_code: 740,
    city: "Lancaster",
    state: "Ohio",
  },
  {
    area_code: 740,
    city: "Marion",
    state: "Ohio",
  },
  {
    area_code: 740,
    city: "Newark",
    state: "Ohio",
  },
  {
    area_code: 740,
    city: "Portsmouth",
    state: "Ohio",
  },
  {
    area_code: 740,
    city: "Zanesville",
    state: "Ohio",
  },
  {
    area_code: 743,
    city: "Asheboro",
    state: "North Carolina",
  },
  {
    area_code: 743,
    city: "Burlington",
    state: "North Carolina",
  },
  {
    area_code: 743,
    city: "Greensboro",
    state: "North Carolina",
  },
  {
    area_code: 743,
    city: "High Point",
    state: "North Carolina",
  },
  {
    area_code: 743,
    city: "Winston-Salem",
    state: "North Carolina",
  },
  {
    area_code: 747,
    city: "Agoura Hills",
    state: "California",
  },
  {
    area_code: 747,
    city: "Burbank",
    state: "California",
  },
  {
    area_code: 747,
    city: "Calabasas",
    state: "California",
  },
  {
    area_code: 747,
    city: "North Glendale",
    state: "California",
  },
  {
    area_code: 747,
    city: "La Cañada Flintridge",
    state: "California",
  },
  {
    area_code: 747,
    city: "San Fernando",
    state: "California",
  },
  {
    area_code: 754,
    city: "Coconut Creek",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Cooper City",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Coral Springs",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Dania Beach",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Davie",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Deerfield Beach",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Fort Lauderdale",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Hallandale Beach",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Hollywood",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Lauderdale Lakes",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Lauderhill",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Margate",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "North Lauderdale",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Pembroke Pines",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Plantation",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Pompano Beach",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Sunrise",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Tamarac",
    state: "Florida",
  },
  {
    area_code: 754,
    city: "Weston",
    state: "Florida",
  },
  {
    area_code: 757,
    city: "Chesapeake",
    state: "Virginia",
  },
  {
    area_code: 757,
    city: "Hampton",
    state: "Virginia",
  },
  {
    area_code: 757,
    city: "Newport News",
    state: "Virginia",
  },
  {
    area_code: 757,
    city: "Norfolk",
    state: "Virginia",
  },
  {
    area_code: 757,
    city: "Portsmouth",
    state: "Virginia",
  },
  {
    area_code: 757,
    city: "Suffolk",
    state: "Virginia",
  },
  {
    area_code: 757,
    city: "Virginia Beach",
    state: "Virginia",
  },
  {
    area_code: 760,
    city: "Apple Valley",
    state: "California",
  },
  {
    area_code: 760,
    city: "Barstow",
    state: "California",
  },
  {
    area_code: 760,
    city: "Brawley",
    state: "California",
  },
  {
    area_code: 760,
    city: "Calexico",
    state: "California",
  },
  {
    area_code: 760,
    city: "Carlsbad",
    state: "California",
  },
  {
    area_code: 760,
    city: "Cathedral City",
    state: "California",
  },
  {
    area_code: 760,
    city: "Coachella",
    state: "California",
  },
  {
    area_code: 760,
    city: "El Centro",
    state: "California",
  },
  {
    area_code: 760,
    city: "Encinitas",
    state: "California",
  },
  {
    area_code: 760,
    city: "Escondido",
    state: "California",
  },
  {
    area_code: 760,
    city: "Fallbrook",
    state: "California",
  },
  {
    area_code: 760,
    city: "Hesperia",
    state: "California",
  },
  {
    area_code: 760,
    city: "Indio",
    state: "California",
  },
  {
    area_code: 760,
    city: "La Quinta",
    state: "California",
  },
  {
    area_code: 760,
    city: "Oceanside",
    state: "California",
  },
  {
    area_code: 760,
    city: "Palm Desert",
    state: "California",
  },
  {
    area_code: 760,
    city: "Palm Springs",
    state: "California",
  },
  {
    area_code: 760,
    city: "Ridgecrest",
    state: "California",
  },
  {
    area_code: 760,
    city: "San Marcos",
    state: "California",
  },
  {
    area_code: 760,
    city: "Victorville",
    state: "California",
  },
  {
    area_code: 760,
    city: "Vista",
    state: "California",
  },
  {
    area_code: 762,
    city: "Athens",
    state: "Georgia",
  },
  {
    area_code: 762,
    city: "Augusta",
    state: "Georgia",
  },
  {
    area_code: 762,
    city: "Columbus",
    state: "Georgia",
  },
  {
    area_code: 762,
    city: "Dalton",
    state: "Georgia",
  },
  {
    area_code: 762,
    city: "La Grange",
    state: "Georgia",
  },
  {
    area_code: 762,
    city: "Martinez",
    state: "Georgia",
  },
  {
    area_code: 762,
    city: "Rome",
    state: "Georgia",
  },
  {
    area_code: 763,
    city: "Blaine",
    state: "Minnesota",
  },
  {
    area_code: 763,
    city: "Brooklyn Center",
    state: "Minnesota",
  },
  {
    area_code: 763,
    city: "Brooklyn Park",
    state: "Minnesota",
  },
  {
    area_code: 763,
    city: "Champlin",
    state: "Minnesota",
  },
  {
    area_code: 763,
    city: "West Coon Rapids",
    state: "Minnesota",
  },
  {
    area_code: 763,
    city: "Fridley",
    state: "Minnesota",
  },
  {
    area_code: 763,
    city: "Maple Grove",
    state: "Minnesota",
  },
  {
    area_code: 763,
    city: "New Hope",
    state: "Minnesota",
  },
  {
    area_code: 763,
    city: "Plymouth",
    state: "Minnesota",
  },
  {
    area_code: 765,
    city: "Anderson",
    state: "Indiana",
  },
  {
    area_code: 765,
    city: "Kokomo",
    state: "Indiana",
  },
  {
    area_code: 765,
    city: "West Lafayette",
    state: "Indiana",
  },
  {
    area_code: 765,
    city: "Marion",
    state: "Indiana",
  },
  {
    area_code: 765,
    city: "Muncie",
    state: "Indiana",
  },
  {
    area_code: 765,
    city: "Richmond",
    state: "Indiana",
  },
  {
    area_code: 765,
    city: "West Lafayette",
    state: "Indiana",
  },
  {
    area_code: 769,
    city: "Clinton",
    state: "Mississippi",
  },
  {
    area_code: 769,
    city: "West Hattiesburg",
    state: "Mississippi",
  },
  {
    area_code: 769,
    city: "Jackson",
    state: "Mississippi",
  },
  {
    area_code: 769,
    city: "Meridian",
    state: "Mississippi",
  },
  {
    area_code: 769,
    city: "Pearl",
    state: "Mississippi",
  },
  {
    area_code: 769,
    city: "Ridgeland",
    state: "Mississippi",
  },
  {
    area_code: 769,
    city: "Vicksburg",
    state: "Mississippi",
  },
  {
    area_code: 770,
    city: "Alpharetta",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Atlanta",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "College Park",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Douglasville",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Duluth",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Dunwoody",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Forest Park",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Gainesville",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Griffin",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Kennesaw",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Lawrenceville",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Mableton",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Marietta",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Atlanta",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Peachtree City",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Redan",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Roswell",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Sandy Springs",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Smyrna",
    state: "Georgia",
  },
  {
    area_code: 770,
    city: "Tucker",
    state: "Georgia",
  },
  {
    area_code: 772,
    city: "Fort Pierce South",
    state: "Florida",
  },
  {
    area_code: 772,
    city: "Palm City",
    state: "Florida",
  },
  {
    area_code: 772,
    city: "Port Saint Lucie",
    state: "Florida",
  },
  {
    area_code: 772,
    city: "Vero Beach South",
    state: "Florida",
  },
  {
    area_code: 773,
    city: "West Chicago",
    state: "Illinois",
  },
  {
    area_code: 774,
    city: "Attleboro",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "Barnstable",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "Brockton",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "Fall River",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "Framingham",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "Franklin",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "Marlborough",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "Milford",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "New Bedford",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "Taunton",
    state: "Massachusetts",
  },
  {
    area_code: 774,
    city: "Worcester",
    state: "Massachusetts",
  },
  {
    area_code: 775,
    city: "Carson City",
    state: "Nevada",
  },
  {
    area_code: 775,
    city: "Pahrump",
    state: "Nevada",
  },
  {
    area_code: 775,
    city: "Reno",
    state: "Nevada",
  },
  {
    area_code: 775,
    city: "Sparks",
    state: "Nevada",
  },
  {
    area_code: 779,
    city: "Belvidere",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "Crystal Lake",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "DeKalb",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "Freeport",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "Joliet",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "Kankakee",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "Loves Park",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "Machesney Park",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "McHenry",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "Rockford",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "Romeoville",
    state: "Illinois",
  },
  {
    area_code: 779,
    city: "Woodstock",
    state: "Illinois",
  },
  {
    area_code: 781,
    city: "Arlington",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Braintree",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Burlington",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Dedham",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Lexington",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Lynn",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Malden",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Marblehead",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Medford",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Melrose",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Needham",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Norwood",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Randolph",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Reading",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Revere",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Saugus",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Stoneham",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Wakefield",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Waltham",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Wellesley",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Weymouth",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Winchester",
    state: "Massachusetts",
  },
  {
    area_code: 781,
    city: "Woburn",
    state: "Massachusetts",
  },
  {
    area_code: 785,
    city: "Hays",
    state: "Kansas",
  },
  {
    area_code: 785,
    city: "Lawrence",
    state: "Kansas",
  },
  {
    area_code: 785,
    city: "Manhattan",
    state: "Kansas",
  },
  {
    area_code: 785,
    city: "Salina",
    state: "Kansas",
  },
  {
    area_code: 785,
    city: "Topeka",
    state: "Kansas",
  },
  {
    area_code: 786,
    city: "Aventura",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Carol City",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Coral Gables",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Coral Terrace",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Country Club",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Cutler Ridge",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Doral",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Golden Glades",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Hialeah",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Homestead",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Kendale Lakes",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Kendall West",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Key West",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Leisure City",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "West Miami",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "North Miami Beach",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Miami Lakes",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Miramar",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Norland",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "North Miami",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "North Miami Beach",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Richmond West",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Richmond Heights",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "South Miami Heights",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Tamiami",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "West Little River",
    state: "Florida",
  },
  {
    area_code: 786,
    city: "Westchester",
    state: "Florida",
  },
  {
    area_code: 801,
    city: "American Fork",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "West Bountiful",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Clearfield",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Cottonwood Heights",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Draper",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "East Millcreek",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Kaysville",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Kearns",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Layton",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Magna",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Midvale",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Millcreek",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Murray",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "South Ogden",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Orem",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Pleasant Grove",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Provo",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Riverton",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Roy",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Salt Lake City",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Sandy City",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "South Jordan",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "South Salt Lake",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Spanish Fork",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Springville",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "Taylorsville",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "West Jordan",
    state: "Utah",
  },
  {
    area_code: 801,
    city: "West Valley City",
    state: "Utah",
  },
  {
    area_code: 802,
    city: "South Burlington",
    state: "Vermont",
  },
  {
    area_code: 802,
    city: "Montpelier",
    state: "Vermont",
  },
  {
    area_code: 803,
    city: "Aiken",
    state: "South Carolina",
  },
  {
    area_code: 803,
    city: "West Columbia",
    state: "South Carolina",
  },
  {
    area_code: 803,
    city: "Rock Hill",
    state: "South Carolina",
  },
  {
    area_code: 803,
    city: "Saint Andrews",
    state: "South Carolina",
  },
  {
    area_code: 803,
    city: "Sumter",
    state: "South Carolina",
  },
  {
    area_code: 804,
    city: "Hopewell",
    state: "Virginia",
  },
  {
    area_code: 804,
    city: "Mechanicsville",
    state: "Virginia",
  },
  {
    area_code: 804,
    city: "Petersburg",
    state: "Virginia",
  },
  {
    area_code: 804,
    city: "Richmond",
    state: "Virginia",
  },
  {
    area_code: 804,
    city: "Tuckahoe",
    state: "Virginia",
  },
  {
    area_code: 805,
    city: "Atascadero",
    state: "California",
  },
  {
    area_code: 805,
    city: "Camarillo",
    state: "California",
  },
  {
    area_code: 805,
    city: "Paso Robles",
    state: "California",
  },
  {
    area_code: 805,
    city: "Goleta",
    state: "California",
  },
  {
    area_code: 805,
    city: "Lompoc",
    state: "California",
  },
  {
    area_code: 805,
    city: "Moorpark",
    state: "California",
  },
  {
    area_code: 805,
    city: "Orcutt",
    state: "California",
  },
  {
    area_code: 805,
    city: "Oxnard",
    state: "California",
  },
  {
    area_code: 805,
    city: "Port Hueneme",
    state: "California",
  },
  {
    area_code: 805,
    city: "Ventura",
    state: "California",
  },
  {
    area_code: 805,
    city: "San Luis Obispo",
    state: "California",
  },
  {
    area_code: 805,
    city: "Santa Barbara",
    state: "California",
  },
  {
    area_code: 805,
    city: "Santa Maria",
    state: "California",
  },
  {
    area_code: 805,
    city: "Santa Paula",
    state: "California",
  },
  {
    area_code: 805,
    city: "Simi Valley",
    state: "California",
  },
  {
    area_code: 805,
    city: "Thousand Oaks",
    state: "California",
  },
  {
    area_code: 806,
    city: "Amarillo",
    state: "Texas",
  },
  {
    area_code: 806,
    city: "Lubbock",
    state: "Texas",
  },
  {
    area_code: 806,
    city: "Plainview",
    state: "Texas",
  },
  {
    area_code: 808,
    city: "Hilo",
    state: "Hawaii",
  },
  {
    area_code: 808,
    city: "Honolulu",
    state: "Hawaii",
  },
  {
    area_code: 808,
    city: "Kahului",
    state: "Hawaii",
  },
  {
    area_code: 808,
    city: "Kailua-Kona",
    state: "Hawaii",
  },
  {
    area_code: 808,
    city: "Kāne‘ohe",
    state: "Hawaii",
  },
  {
    area_code: 808,
    city: "Mililani Town",
    state: "Hawaii",
  },
  {
    area_code: 808,
    city: "Pearl City",
    state: "Hawaii",
  },
  {
    area_code: 808,
    city: "Waimalu",
    state: "Hawaii",
  },
  {
    area_code: 808,
    city: "Waipahu",
    state: "Hawaii",
  },
  {
    area_code: 810,
    city: "Burton",
    state: "Michigan",
  },
  {
    area_code: 810,
    city: "Flint",
    state: "Michigan",
  },
  {
    area_code: 810,
    city: "Port Huron",
    state: "Michigan",
  },
  {
    area_code: 812,
    city: "Bloomington",
    state: "Indiana",
  },
  {
    area_code: 812,
    city: "Clarksville",
    state: "Indiana",
  },
  {
    area_code: 812,
    city: "Columbus",
    state: "Indiana",
  },
  {
    area_code: 812,
    city: "Evansville",
    state: "Indiana",
  },
  {
    area_code: 812,
    city: "Jeffersonville",
    state: "Indiana",
  },
  {
    area_code: 812,
    city: "New Albany",
    state: "Indiana",
  },
  {
    area_code: 812,
    city: "Terre Haute",
    state: "Indiana",
  },
  {
    area_code: 813,
    city: "Brandon",
    state: "Florida",
  },
  {
    area_code: 813,
    city: "Citrus Park",
    state: "Florida",
  },
  {
    area_code: 813,
    city: "Greater Northdale",
    state: "Florida",
  },
  {
    area_code: 813,
    city: "Lake Magdalene",
    state: "Florida",
  },
  {
    area_code: 813,
    city: "Land O' Lakes",
    state: "Florida",
  },
  {
    area_code: 813,
    city: "Plant City",
    state: "Florida",
  },
  {
    area_code: 813,
    city: "Tampa",
    state: "Florida",
  },
  {
    area_code: 813,
    city: "Temple Terrace",
    state: "Florida",
  },
  {
    area_code: 813,
    city: "Town 'n' Country",
    state: "Florida",
  },
  {
    area_code: 814,
    city: "Altoona",
    state: "Pennsylvania",
  },
  {
    area_code: 814,
    city: "Erie",
    state: "Pennsylvania",
  },
  {
    area_code: 814,
    city: "Johnstown",
    state: "Pennsylvania",
  },
  {
    area_code: 814,
    city: "State College",
    state: "Pennsylvania",
  },
  {
    area_code: 815,
    city: "Belvidere",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "Crystal Lake",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "DeKalb",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "Freeport",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "Joliet",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "Kankakee",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "Loves Park",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "Machesney Park",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "McHenry",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "Rockford",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "Romeoville",
    state: "Illinois",
  },
  {
    area_code: 815,
    city: "Woodstock",
    state: "Illinois",
  },
  {
    area_code: 816,
    city: "Belton",
    state: "Missouri",
  },
  {
    area_code: 816,
    city: "Blue Springs",
    state: "Missouri",
  },
  {
    area_code: 816,
    city: "Gladstone",
    state: "Missouri",
  },
  {
    area_code: 816,
    city: "Grandview",
    state: "Missouri",
  },
  {
    area_code: 816,
    city: "Independence",
    state: "Missouri",
  },
  {
    area_code: 816,
    city: "Kansas City",
    state: "Missouri",
  },
  {
    area_code: 816,
    city: "Lee's Summit",
    state: "Missouri",
  },
  {
    area_code: 816,
    city: "Liberty",
    state: "Missouri",
  },
  {
    area_code: 816,
    city: "Raytown",
    state: "Missouri",
  },
  {
    area_code: 816,
    city: "Saint Joseph",
    state: "Missouri",
  },
  {
    area_code: 817,
    city: "Arlington",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Bedford",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Benbrook",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Burleson",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Cleburne",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Euless",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Fort Worth",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Grapevine",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Haltom City",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Hurst",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Keller",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Mansfield",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "North Richland Hills",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Southlake",
    state: "Texas",
  },
  {
    area_code: 817,
    city: "Watauga",
    state: "Texas",
  },
  {
    area_code: 818,
    city: "Agoura Hills",
    state: "California",
  },
  {
    area_code: 818,
    city: "Burbank",
    state: "California",
  },
  {
    area_code: 818,
    city: "Calabasas",
    state: "California",
  },
  {
    area_code: 818,
    city: "North Glendale",
    state: "California",
  },
  {
    area_code: 818,
    city: "La Cañada Flintridge",
    state: "California",
  },
  {
    area_code: 818,
    city: "San Fernando",
    state: "California",
  },
  {
    area_code: 828,
    city: "Asheville",
    state: "North Carolina",
  },
  {
    area_code: 828,
    city: "Hickory",
    state: "North Carolina",
  },
  {
    area_code: 830,
    city: "Del Rio",
    state: "Texas",
  },
  {
    area_code: 830,
    city: "Eagle Pass",
    state: "Texas",
  },
  {
    area_code: 830,
    city: "Kerrville",
    state: "Texas",
  },
  {
    area_code: 830,
    city: "New Braunfels",
    state: "Texas",
  },
  {
    area_code: 830,
    city: "Seguin",
    state: "Texas",
  },
  {
    area_code: 831,
    city: "Hollister",
    state: "California",
  },
  {
    area_code: 831,
    city: "Marina",
    state: "California",
  },
  {
    area_code: 831,
    city: "Monterey",
    state: "California",
  },
  {
    area_code: 831,
    city: "Salinas",
    state: "California",
  },
  {
    area_code: 831,
    city: "Santa Cruz",
    state: "California",
  },
  {
    area_code: 831,
    city: "Seaside",
    state: "California",
  },
  {
    area_code: 831,
    city: "Watsonville",
    state: "California",
  },
  {
    area_code: 832,
    city: "Alvin",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Atascocita",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Baytown",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Channelview",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Cloverleaf",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Deer Park",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Friendswood",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "South Houston",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "La Porte",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "League City",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Mission Bend",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Missouri City",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Pasadena",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Pearland",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Rosenberg",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Spring",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "Sugar Land",
    state: "Texas",
  },
  {
    area_code: 832,
    city: "The Woodlands",
    state: "Texas",
  },
  {
    area_code: 843,
    city: "North Charleston",
    state: "South Carolina",
  },
  {
    area_code: 843,
    city: "Florence",
    state: "South Carolina",
  },
  {
    area_code: 843,
    city: "Goose Creek",
    state: "South Carolina",
  },
  {
    area_code: 843,
    city: "Hilton Head Island",
    state: "South Carolina",
  },
  {
    area_code: 843,
    city: "Mount Pleasant",
    state: "South Carolina",
  },
  {
    area_code: 843,
    city: "North Myrtle Beach",
    state: "South Carolina",
  },
  {
    area_code: 843,
    city: "North Charleston",
    state: "South Carolina",
  },
  {
    area_code: 843,
    city: "Summerville",
    state: "South Carolina",
  },
  {
    area_code: 845,
    city: "Kingston",
    state: "New York",
  },
  {
    area_code: 845,
    city: "Middletown",
    state: "New York",
  },
  {
    area_code: 845,
    city: "New City",
    state: "New York",
  },
  {
    area_code: 845,
    city: "Newburgh",
    state: "New York",
  },
  {
    area_code: 845,
    city: "Poughkeepsie",
    state: "New York",
  },
  {
    area_code: 845,
    city: "Spring Valley",
    state: "New York",
  },
  {
    area_code: 847,
    city: "Algonquin",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Arlington Heights",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Buffalo Grove",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Carpentersville",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Des Plaines",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "South Elgin",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Elk Grove Village",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Evanston",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Glenview",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Gurnee",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Highland Park",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Hoffman Estates",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Lake Forest",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Lake in the Hills",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Libertyville",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Morton Grove",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Mount Prospect",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Mundelein",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Niles",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "North Chicago",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Northbrook",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Palatine",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Park Ridge",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Rolling Meadows",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Round Lake Beach",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Schaumburg",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Skokie",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Vernon Hills",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Waukegan",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Wheeling",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Wilmette",
    state: "Illinois",
  },
  {
    area_code: 847,
    city: "Zion",
    state: "Illinois",
  },
  {
    area_code: 848,
    city: "Carteret",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "East Brunswick",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "Edison",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "Lakewood",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "West Long Branch",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "New Brunswick",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "East Brunswick",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "North Plainfield",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "South Old Bridge",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "Perth Amboy",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "Rahway",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "Sayreville",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "Somerset",
    state: "New Jersey",
  },
  {
    area_code: 848,
    city: "Toms River",
    state: "New Jersey",
  },
  {
    area_code: 850,
    city: "Bellview",
    state: "Florida",
  },
  {
    area_code: 850,
    city: "Brent",
    state: "Florida",
  },
  {
    area_code: 850,
    city: "Ferry Pass",
    state: "Florida",
  },
  {
    area_code: 850,
    city: "Fort Walton Beach",
    state: "Florida",
  },
  {
    area_code: 850,
    city: "Panama City",
    state: "Florida",
  },
  {
    area_code: 850,
    city: "West Pensacola",
    state: "Florida",
  },
  {
    area_code: 850,
    city: "Tallahassee",
    state: "Florida",
  },
  {
    area_code: 850,
    city: "West Pensacola",
    state: "Florida",
  },
  {
    area_code: 850,
    city: "Wright",
    state: "Florida",
  },
  {
    area_code: 856,
    city: "Bridgeton",
    state: "New Jersey",
  },
  {
    area_code: 856,
    city: "Camden",
    state: "New Jersey",
  },
  {
    area_code: 856,
    city: "Cherry Hill",
    state: "New Jersey",
  },
  {
    area_code: 856,
    city: "Millville",
    state: "New Jersey",
  },
  {
    area_code: 856,
    city: "Pennsauken",
    state: "New Jersey",
  },
  {
    area_code: 856,
    city: "Vineland",
    state: "New Jersey",
  },
  {
    area_code: 856,
    city: "Willingboro",
    state: "New Jersey",
  },
  {
    area_code: 857,
    city: "Belmont",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "South Boston",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "Brookline",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "Cambridge",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "Chelsea",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "Everett",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "Milton",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "Newton",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "Quincy",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "Somerville",
    state: "Massachusetts",
  },
  {
    area_code: 857,
    city: "Watertown",
    state: "Massachusetts",
  },
  {
    area_code: 858,
    city: "Poway",
    state: "California",
  },
  {
    area_code: 859,
    city: "Covington",
    state: "Kentucky",
  },
  {
    area_code: 859,
    city: "Florence",
    state: "Kentucky",
  },
  {
    area_code: 859,
    city: "Lexington",
    state: "Kentucky",
  },
  {
    area_code: 859,
    city: "Richmond",
    state: "Kentucky",
  },
  {
    area_code: 860,
    city: "Bristol",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "Manchester",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "East Hartford",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "West Hartford",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "Middletown",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "New Britain",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "New London",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "Newington",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "Norwich",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "West Torrington",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "West Hartford",
    state: "Connecticut",
  },
  {
    area_code: 860,
    city: "Wethersfield",
    state: "Connecticut",
  },
  {
    area_code: 862,
    city: "Belleville",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Bloomfield",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Clifton",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "East Orange",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Garfield",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Hillside",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Irvington",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Kearny",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Livingston",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Lodi",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Maplewood",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Montclair",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Morristown",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Newark",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Nutley",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "West Orange",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Passaic",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Paterson",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "Wayne",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "West Milford",
    state: "New Jersey",
  },
  {
    area_code: 862,
    city: "West Orange",
    state: "New Jersey",
  },
  {
    area_code: 863,
    city: "Lakeland",
    state: "Florida",
  },
  {
    area_code: 863,
    city: "Winter Haven",
    state: "Florida",
  },
  {
    area_code: 864,
    city: "Anderson",
    state: "South Carolina",
  },
  {
    area_code: 864,
    city: "Greenville",
    state: "South Carolina",
  },
  {
    area_code: 864,
    city: "Greenwood",
    state: "South Carolina",
  },
  {
    area_code: 864,
    city: "Spartanburg",
    state: "South Carolina",
  },
  {
    area_code: 864,
    city: "Taylors",
    state: "South Carolina",
  },
  {
    area_code: 864,
    city: "Wade Hampton",
    state: "South Carolina",
  },
  {
    area_code: 865,
    city: "Knoxville",
    state: "Tennessee",
  },
  {
    area_code: 865,
    city: "Maryville",
    state: "Tennessee",
  },
  {
    area_code: 865,
    city: "Oak Ridge",
    state: "Tennessee",
  },
  {
    area_code: 865,
    city: "Oak Ridge",
    state: "Tennessee",
  },
  {
    area_code: 870,
    city: "El Dorado",
    state: "Arkansas",
  },
  {
    area_code: 870,
    city: "Jonesboro",
    state: "Arkansas",
  },
  {
    area_code: 870,
    city: "Paragould",
    state: "Arkansas",
  },
  {
    area_code: 870,
    city: "Pine Bluff",
    state: "Arkansas",
  },
  {
    area_code: 870,
    city: "Texarkana",
    state: "Arkansas",
  },
  {
    area_code: 870,
    city: "West Memphis",
    state: "Arkansas",
  },
  {
    area_code: 878,
    city: "Bethel Park",
    state: "Pennsylvania",
  },
  {
    area_code: 878,
    city: "McKeesport",
    state: "Pennsylvania",
  },
  {
    area_code: 878,
    city: "Mount Lebanon",
    state: "Pennsylvania",
  },
  {
    area_code: 878,
    city: "Monroeville",
    state: "Pennsylvania",
  },
  {
    area_code: 878,
    city: "New Castle",
    state: "Pennsylvania",
  },
  {
    area_code: 878,
    city: "Penn Hills",
    state: "Pennsylvania",
  },
  {
    area_code: 878,
    city: "Pittsburgh",
    state: "Pennsylvania",
  },
  {
    area_code: 878,
    city: "Plum",
    state: "Pennsylvania",
  },
  {
    area_code: 878,
    city: "Upper Saint Clair",
    state: "Pennsylvania",
  },
  {
    area_code: 878,
    city: "West Mifflin",
    state: "Pennsylvania",
  },
  {
    area_code: 901,
    city: "Bartlett",
    state: "Tennessee",
  },
  {
    area_code: 901,
    city: "Collierville",
    state: "Tennessee",
  },
  {
    area_code: 901,
    city: "Germantown",
    state: "Tennessee",
  },
  {
    area_code: 901,
    city: "Memphis",
    state: "Tennessee",
  },
  {
    area_code: 903,
    city: "Corsicana",
    state: "Texas",
  },
  {
    area_code: 903,
    city: "Denison",
    state: "Texas",
  },
  {
    area_code: 903,
    city: "Greenville",
    state: "Texas",
  },
  {
    area_code: 903,
    city: "Longview",
    state: "Texas",
  },
  {
    area_code: 903,
    city: "Marshall",
    state: "Texas",
  },
  {
    area_code: 903,
    city: "Paris",
    state: "Texas",
  },
  {
    area_code: 903,
    city: "Sherman",
    state: "Texas",
  },
  {
    area_code: 903,
    city: "Texarkana",
    state: "Texas",
  },
  {
    area_code: 903,
    city: "Tyler",
    state: "Texas",
  },
  {
    area_code: 904,
    city: "Jacksonville",
    state: "Florida",
  },
  {
    area_code: 904,
    city: "Jacksonville Beach",
    state: "Florida",
  },
  {
    area_code: 904,
    city: "Lakeside",
    state: "Florida",
  },
  {
    area_code: 907,
    city: "Anchorage",
    state: "Alaska",
  },
  {
    area_code: 907,
    city: "College",
    state: "Alaska",
  },
  {
    area_code: 907,
    city: "Fairbanks",
    state: "Alaska",
  },
  {
    area_code: 907,
    city: "Juneau",
    state: "Alaska",
  },
  {
    area_code: 907,
    city: "Ketchikan",
    state: "Alaska",
  },
  {
    area_code: 907,
    city: "Sitka",
    state: "Alaska",
  },
  {
    area_code: 908,
    city: "Cranford",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "Elizabeth",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "Hillside",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "Linden",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "South Plainfield",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "Roselle",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "Scotch Plains",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "South Plainfield",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "Summit",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "Union City",
    state: "New Jersey",
  },
  {
    area_code: 908,
    city: "Westfield",
    state: "New Jersey",
  },
  {
    area_code: 909,
    city: "Chino",
    state: "California",
  },
  {
    area_code: 909,
    city: "Chino Hills",
    state: "California",
  },
  {
    area_code: 909,
    city: "Claremont",
    state: "California",
  },
  {
    area_code: 909,
    city: "Colton",
    state: "California",
  },
  {
    area_code: 909,
    city: "Diamond Bar",
    state: "California",
  },
  {
    area_code: 909,
    city: "Fontana",
    state: "California",
  },
  {
    area_code: 909,
    city: "Highland",
    state: "California",
  },
  {
    area_code: 909,
    city: "La Verne",
    state: "California",
  },
  {
    area_code: 909,
    city: "Montclair",
    state: "California",
  },
  {
    area_code: 909,
    city: "Ontario",
    state: "California",
  },
  {
    area_code: 909,
    city: "Pomona",
    state: "California",
  },
  {
    area_code: 909,
    city: "Rancho Cucamonga",
    state: "California",
  },
  {
    area_code: 909,
    city: "Redlands",
    state: "California",
  },
  {
    area_code: 909,
    city: "Rialto",
    state: "California",
  },
  {
    area_code: 909,
    city: "San Bernardino",
    state: "California",
  },
  {
    area_code: 909,
    city: "San Dimas",
    state: "California",
  },
  {
    area_code: 909,
    city: "Upland",
    state: "California",
  },
  {
    area_code: 909,
    city: "Yucaipa",
    state: "California",
  },
  {
    area_code: 910,
    city: "Fayetteville",
    state: "North Carolina",
  },
  {
    area_code: 910,
    city: "Fort Bragg",
    state: "North Carolina",
  },
  {
    area_code: 910,
    city: "Jacksonville",
    state: "North Carolina",
  },
  {
    area_code: 910,
    city: "Lumberton",
    state: "North Carolina",
  },
  {
    area_code: 910,
    city: "Wilmington",
    state: "North Carolina",
  },
  {
    area_code: 912,
    city: "Hinesville",
    state: "Georgia",
  },
  {
    area_code: 912,
    city: "Savannah",
    state: "Georgia",
  },
  {
    area_code: 912,
    city: "Statesboro",
    state: "Georgia",
  },
  {
    area_code: 913,
    city: "Kansas City",
    state: "Kansas",
  },
  {
    area_code: 913,
    city: "Leavenworth",
    state: "Kansas",
  },
  {
    area_code: 913,
    city: "Leawood",
    state: "Kansas",
  },
  {
    area_code: 913,
    city: "Lenexa",
    state: "Kansas",
  },
  {
    area_code: 913,
    city: "Olathe",
    state: "Kansas",
  },
  {
    area_code: 913,
    city: "Overland Park",
    state: "Kansas",
  },
  {
    area_code: 913,
    city: "Prairie Village",
    state: "Kansas",
  },
  {
    area_code: 913,
    city: "Shawnee",
    state: "Kansas",
  },
  {
    area_code: 914,
    city: "Harrison",
    state: "New York",
  },
  {
    area_code: 914,
    city: "Mount Vernon",
    state: "New York",
  },
  {
    area_code: 914,
    city: "New Rochelle",
    state: "New York",
  },
  {
    area_code: 914,
    city: "Ossining",
    state: "New York",
  },
  {
    area_code: 914,
    city: "Peekskill",
    state: "New York",
  },
  {
    area_code: 914,
    city: "Port Chester",
    state: "New York",
  },
  {
    area_code: 914,
    city: "White Plains",
    state: "New York",
  },
  {
    area_code: 914,
    city: "Yonkers",
    state: "New York",
  },
  {
    area_code: 915,
    city: "El Paso",
    state: "Texas",
  },
  {
    area_code: 915,
    city: "Socorro",
    state: "Texas",
  },
  {
    area_code: 916,
    city: "Arden-Arcade",
    state: "California",
  },
  {
    area_code: 916,
    city: "Carmichael",
    state: "California",
  },
  {
    area_code: 916,
    city: "Citrus Heights",
    state: "California",
  },
  {
    area_code: 916,
    city: "Elk Grove",
    state: "California",
  },
  {
    area_code: 916,
    city: "North Fair Oaks",
    state: "California",
  },
  {
    area_code: 916,
    city: "Florin",
    state: "California",
  },
  {
    area_code: 916,
    city: "Folsom",
    state: "California",
  },
  {
    area_code: 916,
    city: "La Riviera",
    state: "California",
  },
  {
    area_code: 916,
    city: "Laguna",
    state: "California",
  },
  {
    area_code: 916,
    city: "North Highlands",
    state: "California",
  },
  {
    area_code: 916,
    city: "Orangevale",
    state: "California",
  },
  {
    area_code: 916,
    city: "Rancho Cordova",
    state: "California",
  },
  {
    area_code: 916,
    city: "Rocklin",
    state: "California",
  },
  {
    area_code: 916,
    city: "Rosemont",
    state: "California",
  },
  {
    area_code: 916,
    city: "Roseville",
    state: "California",
  },
  {
    area_code: 916,
    city: "West Sacramento",
    state: "California",
  },
  {
    area_code: 916,
    city: "West Sacramento",
    state: "California",
  },
  {
    area_code: 917,
    city: "The Bronx",
    state: "New York",
  },
  {
    area_code: 917,
    city: "Brooklyn",
    state: "New York",
  },
  {
    area_code: 917,
    city: "New York City",
    state: "New York",
  },
  {
    area_code: 917,
    city: "New York City",
    state: "New York",
  },
  {
    area_code: 917,
    city: "Borough of Queens",
    state: "New York",
  },
  {
    area_code: 917,
    city: "Staten Island",
    state: "New York",
  },
  {
    area_code: 918,
    city: "Bartlesville",
    state: "Oklahoma",
  },
  {
    area_code: 918,
    city: "Broken Arrow",
    state: "Oklahoma",
  },
  {
    area_code: 918,
    city: "Muskogee",
    state: "Oklahoma",
  },
  {
    area_code: 918,
    city: "Tulsa",
    state: "Oklahoma",
  },
  {
    area_code: 919,
    city: "Apex",
    state: "North Carolina",
  },
  {
    area_code: 919,
    city: "Cary",
    state: "North Carolina",
  },
  {
    area_code: 919,
    city: "Chapel Hill",
    state: "North Carolina",
  },
  {
    area_code: 919,
    city: "Durham",
    state: "North Carolina",
  },
  {
    area_code: 919,
    city: "Goldsboro",
    state: "North Carolina",
  },
  {
    area_code: 919,
    city: "West Raleigh",
    state: "North Carolina",
  },
  {
    area_code: 919,
    city: "Sanford",
    state: "North Carolina",
  },
  {
    area_code: 920,
    city: "Appleton",
    state: "Wisconsin",
  },
  {
    area_code: 920,
    city: "De Pere",
    state: "Wisconsin",
  },
  {
    area_code: 920,
    city: "North Fond du Lac",
    state: "Wisconsin",
  },
  {
    area_code: 920,
    city: "Green Bay",
    state: "Wisconsin",
  },
  {
    area_code: 920,
    city: "Manitowoc",
    state: "Wisconsin",
  },
  {
    area_code: 920,
    city: "Neenah",
    state: "Wisconsin",
  },
  {
    area_code: 920,
    city: "Oshkosh",
    state: "Wisconsin",
  },
  {
    area_code: 920,
    city: "Sheboygan",
    state: "Wisconsin",
  },
  {
    area_code: 920,
    city: "Watertown",
    state: "Wisconsin",
  },
  {
    area_code: 920,
    city: "Watertown",
    state: "Wisconsin",
  },
  {
    area_code: 925,
    city: "Antioch",
    state: "California",
  },
  {
    area_code: 925,
    city: "Bay Point",
    state: "California",
  },
  {
    area_code: 925,
    city: "Brentwood",
    state: "California",
  },
  {
    area_code: 925,
    city: "Concord",
    state: "California",
  },
  {
    area_code: 925,
    city: "Danville",
    state: "California",
  },
  {
    area_code: 925,
    city: "Dublin",
    state: "California",
  },
  {
    area_code: 925,
    city: "Lafayette",
    state: "California",
  },
  {
    area_code: 925,
    city: "Livermore",
    state: "California",
  },
  {
    area_code: 925,
    city: "Martinez",
    state: "California",
  },
  {
    area_code: 925,
    city: "Oakley",
    state: "California",
  },
  {
    area_code: 925,
    city: "Pittsburg",
    state: "California",
  },
  {
    area_code: 925,
    city: "Pleasant Hill",
    state: "California",
  },
  {
    area_code: 925,
    city: "Pleasanton",
    state: "California",
  },
  {
    area_code: 925,
    city: "San Ramon",
    state: "California",
  },
  {
    area_code: 925,
    city: "Walnut Creek",
    state: "California",
  },
  {
    area_code: 928,
    city: "Bullhead City",
    state: "Arizona",
  },
  {
    area_code: 928,
    city: "Flagstaff",
    state: "Arizona",
  },
  {
    area_code: 928,
    city: "Fortuna Foothills",
    state: "Arizona",
  },
  {
    area_code: 928,
    city: "Kingman",
    state: "Arizona",
  },
  {
    area_code: 928,
    city: "Lake Havasu City",
    state: "Arizona",
  },
  {
    area_code: 928,
    city: "Prescott",
    state: "Arizona",
  },
  {
    area_code: 928,
    city: "Prescott Valley",
    state: "Arizona",
  },
  {
    area_code: 928,
    city: "Yuma",
    state: "Arizona",
  },
  {
    area_code: 929,
    city: "The Bronx",
    state: "New York",
  },
  {
    area_code: 929,
    city: "Brooklyn",
    state: "New York",
  },
  {
    area_code: 929,
    city: "New York City",
    state: "New York",
  },
  {
    area_code: 929,
    city: "Borough of Queens",
    state: "New York",
  },
  {
    area_code: 929,
    city: "Staten Island",
    state: "New York",
  },
  {
    area_code: 930,
    city: "Bloomington",
    state: "Indiana",
  },
  {
    area_code: 930,
    city: "Clarksville",
    state: "Indiana",
  },
  {
    area_code: 930,
    city: "Columbus",
    state: "Indiana",
  },
  {
    area_code: 930,
    city: "Evansville",
    state: "Indiana",
  },
  {
    area_code: 930,
    city: "Jeffersonville",
    state: "Indiana",
  },
  {
    area_code: 930,
    city: "New Albany",
    state: "Indiana",
  },
  {
    area_code: 930,
    city: "Terre Haute",
    state: "Indiana",
  },
  {
    area_code: 931,
    city: "Clarksville",
    state: "Tennessee",
  },
  {
    area_code: 931,
    city: "Columbia",
    state: "Tennessee",
  },
  {
    area_code: 931,
    city: "Cookeville",
    state: "Tennessee",
  },
  {
    area_code: 936,
    city: "Conroe",
    state: "Texas",
  },
  {
    area_code: 936,
    city: "Huntsville",
    state: "Texas",
  },
  {
    area_code: 936,
    city: "Lufkin",
    state: "Texas",
  },
  {
    area_code: 936,
    city: "Nacogdoches",
    state: "Texas",
  },
  {
    area_code: 937,
    city: "Beavercreek",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Centerville",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Dayton",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Fairborn",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Huber Heights",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Kettering",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Piqua",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Riverside",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Sidney",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Springfield",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Trotwood",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Troy",
    state: "Ohio",
  },
  {
    area_code: 937,
    city: "Xenia",
    state: "Ohio",
  },
  {
    area_code: 940,
    city: "Denton",
    state: "Texas",
  },
  {
    area_code: 940,
    city: "Lewisville",
    state: "Texas",
  },
  {
    area_code: 940,
    city: "Wichita Falls",
    state: "Texas",
  },
  {
    area_code: 941,
    city: "South Bradenton",
    state: "Florida",
  },
  {
    area_code: 941,
    city: "North Port",
    state: "Florida",
  },
  {
    area_code: 941,
    city: "Port Charlotte",
    state: "Florida",
  },
  {
    area_code: 941,
    city: "Punta Gorda",
    state: "Florida",
  },
  {
    area_code: 941,
    city: "Sarasota",
    state: "Florida",
  },
  {
    area_code: 941,
    city: "South Bradenton",
    state: "Florida",
  },
  {
    area_code: 947,
    city: "West Bloomfield Township",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Farmington Hills",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Ferndale",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Madison Heights",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Novi",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Oak Park",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Pontiac",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Rochester Hills",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Royal Oak",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Southfield",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Troy",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "Waterford",
    state: "Michigan",
  },
  {
    area_code: 947,
    city: "West Bloomfield Township",
    state: "Michigan",
  },
  {
    area_code: 949,
    city: "Aliso Viejo",
    state: "California",
  },
  {
    area_code: 949,
    city: "Costa Mesa",
    state: "California",
  },
  {
    area_code: 949,
    city: "Dana Point",
    state: "California",
  },
  {
    area_code: 949,
    city: "Irvine",
    state: "California",
  },
  {
    area_code: 949,
    city: "Laguna Beach",
    state: "California",
  },
  {
    area_code: 949,
    city: "Laguna Hills",
    state: "California",
  },
  {
    area_code: 949,
    city: "Laguna Niguel",
    state: "California",
  },
  {
    area_code: 949,
    city: "Lake Forest",
    state: "California",
  },
  {
    area_code: 949,
    city: "Mission Viejo",
    state: "California",
  },
  {
    area_code: 949,
    city: "Newport Beach",
    state: "California",
  },
  {
    area_code: 949,
    city: "Rancho Santa Margarita",
    state: "California",
  },
  {
    area_code: 949,
    city: "San Clemente",
    state: "California",
  },
  {
    area_code: 949,
    city: "San Juan Capistrano",
    state: "California",
  },
  {
    area_code: 951,
    city: "Banning",
    state: "California",
  },
  {
    area_code: 951,
    city: "Corona",
    state: "California",
  },
  {
    area_code: 951,
    city: "Hemet",
    state: "California",
  },
  {
    area_code: 951,
    city: "Moreno Valley",
    state: "California",
  },
  {
    area_code: 951,
    city: "Murrieta",
    state: "California",
  },
  {
    area_code: 951,
    city: "Norco",
    state: "California",
  },
  {
    area_code: 951,
    city: "Perris",
    state: "California",
  },
  {
    area_code: 951,
    city: "Riverside",
    state: "California",
  },
  {
    area_code: 951,
    city: "Rubidoux",
    state: "California",
  },
  {
    area_code: 951,
    city: "Temecula",
    state: "California",
  },
  {
    area_code: 952,
    city: "Bloomington",
    state: "Minnesota",
  },
  {
    area_code: 952,
    city: "Burnsville",
    state: "Minnesota",
  },
  {
    area_code: 952,
    city: "Chanhassen",
    state: "Minnesota",
  },
  {
    area_code: 952,
    city: "Eden Prairie",
    state: "Minnesota",
  },
  {
    area_code: 952,
    city: "Edina",
    state: "Minnesota",
  },
  {
    area_code: 952,
    city: "Minnetonka",
    state: "Minnesota",
  },
  {
    area_code: 952,
    city: "Savage",
    state: "Minnesota",
  },
  {
    area_code: 952,
    city: "Shakopee",
    state: "Minnesota",
  },
  {
    area_code: 954,
    city: "Coconut Creek",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Cooper City",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Coral Springs",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Dania Beach",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Davie",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Deerfield Beach",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Fort Lauderdale",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Hallandale Beach",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Hollywood",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Lauderdale Lakes",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Lauderhill",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Margate",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "North Lauderdale",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Pembroke Pines",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Plantation",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Pompano Beach",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Sunrise",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Tamarac",
    state: "Florida",
  },
  {
    area_code: 954,
    city: "Weston",
    state: "Florida",
  },
  {
    area_code: 956,
    city: "Brownsville",
    state: "Texas",
  },
  {
    area_code: 956,
    city: "Edinburg",
    state: "Texas",
  },
  {
    area_code: 956,
    city: "Harlingen",
    state: "Texas",
  },
  {
    area_code: 956,
    city: "Laredo",
    state: "Texas",
  },
  {
    area_code: 956,
    city: "McAllen",
    state: "Texas",
  },
  {
    area_code: 956,
    city: "Mission",
    state: "Texas",
  },
  {
    area_code: 956,
    city: "Pharr",
    state: "Texas",
  },
  {
    area_code: 956,
    city: "San Benito",
    state: "Texas",
  },
  {
    area_code: 956,
    city: "San Juan",
    state: "Texas",
  },
  {
    area_code: 956,
    city: "Weslaco",
    state: "Texas",
  },
  {
    area_code: 959,
    city: "Bristol",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "Manchester",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "East Hartford",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "West Hartford",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "Middletown",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "New Britain",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "New London",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "Newington",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "Norwich",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "West Torrington",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "West Hartford",
    state: "Connecticut",
  },
  {
    area_code: 959,
    city: "Wethersfield",
    state: "Connecticut",
  },
  {
    area_code: 970,
    city: "Fort Collins",
    state: "Colorado",
  },
  {
    area_code: 970,
    city: "Grand Junction",
    state: "Colorado",
  },
  {
    area_code: 970,
    city: "Greeley",
    state: "Colorado",
  },
  {
    area_code: 970,
    city: "Loveland",
    state: "Colorado",
  },
  {
    area_code: 971,
    city: "Aloha",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Beaverton",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Gresham",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Hillsboro",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Keizer",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Lake Oswego",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "McMinnville",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Milwaukie",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Oregon City",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Portland",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Salem",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Tigard",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Tualatin",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "West Linn",
    state: "Oregon",
  },
  {
    area_code: 971,
    city: "Woodburn",
    state: "Oregon",
  },
  {
    area_code: 972,
    city: "Allen",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Carrollton",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Cedar Hill",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Coppell",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Dallas",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "DeSoto",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Duncanville",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Farmers Branch",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Flower Mound",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Frisco",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Garland",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Grand Prairie",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Irving",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Lancaster",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Lewisville",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "McKinney",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Mesquite",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Plano",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Richardson",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Rowlett",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "The Colony",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "University Park",
    state: "Texas",
  },
  {
    area_code: 972,
    city: "Waxahachie",
    state: "Texas",
  },
  {
    area_code: 973,
    city: "Belleville",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Bloomfield",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Clifton",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "East Orange",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Garfield",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Irvington",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Livingston",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Lodi",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Maplewood",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Montclair",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Morristown",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Newark",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Nutley",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "West Orange",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Passaic",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Paterson",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "Wayne",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "West Milford",
    state: "New Jersey",
  },
  {
    area_code: 973,
    city: "West Orange",
    state: "New Jersey",
  },
  {
    area_code: 978,
    city: "Beverly",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Chelmsford",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Danvers",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Fitchburg",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Gardner",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Gloucester",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Haverhill",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Lawrence",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Leominster",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Lowell",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Methuen",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "South Peabody",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Salem",
    state: "Massachusetts",
  },
  {
    area_code: 978,
    city: "Wilmington",
    state: "Massachusetts",
  },
  {
    area_code: 979,
    city: "Bryan",
    state: "Texas",
  },
  {
    area_code: 979,
    city: "College Station",
    state: "Texas",
  },
  {
    area_code: 979,
    city: "Lake Jackson",
    state: "Texas",
  },
  {
    area_code: 980,
    city: "Charlotte",
    state: "North Carolina",
  },
  {
    area_code: 980,
    city: "Concord",
    state: "North Carolina",
  },
  {
    area_code: 980,
    city: "South Gastonia",
    state: "North Carolina",
  },
  {
    area_code: 980,
    city: "Huntersville",
    state: "North Carolina",
  },
  {
    area_code: 980,
    city: "Kannapolis",
    state: "North Carolina",
  },
  {
    area_code: 980,
    city: "Matthews",
    state: "North Carolina",
  },
  {
    area_code: 980,
    city: "Monroe",
    state: "North Carolina",
  },
  {
    area_code: 980,
    city: "Salisbury",
    state: "North Carolina",
  },
  {
    area_code: 980,
    city: "Statesville",
    state: "North Carolina",
  },
  {
    area_code: 984,
    city: "Apex",
    state: "North Carolina",
  },
  {
    area_code: 984,
    city: "Cary",
    state: "North Carolina",
  },
  {
    area_code: 984,
    city: "Chapel Hill",
    state: "North Carolina",
  },
  {
    area_code: 984,
    city: "Durham",
    state: "North Carolina",
  },
  {
    area_code: 984,
    city: "Goldsboro",
    state: "North Carolina",
  },
  {
    area_code: 984,
    city: "West Raleigh",
    state: "North Carolina",
  },
  {
    area_code: 984,
    city: "Sanford",
    state: "North Carolina",
  },
  {
    area_code: 985,
    city: "Houma",
    state: "Louisiana",
  },
  {
    area_code: 985,
    city: "Laplace",
    state: "Louisiana",
  },
  {
    area_code: 985,
    city: "Slidell",
    state: "Louisiana",
  },
  {
    area_code: 989,
    city: "Bay City",
    state: "Michigan",
  },
  {
    area_code: 989,
    city: "Midland",
    state: "Michigan",
  },
  {
    area_code: 989,
    city: "Mount Pleasant",
    state: "Michigan",
  },
  {
    area_code: 989,
    city: "Saginaw",
    state: "Michigan",
  },
  {
    area_code: 989,
    city: "Saginaw",
    state: "Michigan",
  },
  {
    area_code: 854,
    city: "North Charleston",
    state: "South Carolina",
  },
  {
    area_code: 854,
    city: "Florence",
    state: "South Carolina",
  },
  {
    area_code: 854,
    city: "Goose Creek",
    state: "South Carolina",
  },
  {
    area_code: 854,
    city: "Hilton Head Island",
    state: "South Carolina",
  },
  {
    area_code: 854,
    city: "Mount Pleasant",
    state: "South Carolina",
  },
  {
    area_code: 854,
    city: "North Myrtle Beach",
    state: "South Carolina",
  },
  {
    area_code: 854,
    city: "North Charleston",
    state: "South Carolina",
  },
  {
    area_code: 854,
    city: "Summerville",
    state: "South Carolina",
  },
];
