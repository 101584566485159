import React, { useState, useEffect } from "react";
import { fetchAssistants, fetchStaffUsers } from "../../../services/assistant";
import { ShieldPlus } from "lucide-react";
import OneAssistant from "./OneAssistant";
// import CreateAssistant from "./CreateAssistant";
import { useNavigate } from "react-router-dom";

const Assistants = () => {
  const navigate = useNavigate();

  const [assistants, setAssistants] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [isCreateOpen, setIsCreateOpen] = useState(false);

  const [staffUsers, setStaffUsers] = useState([]);

  const togglePopup = () => {
    // setIsCreateOpen(!isCreateOpen);
    navigate("/dashboard/create-assistant");
  };

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchAssistants();
      // console.log("responseData", responseData);
      setAssistants(responseData.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStaffUsersFromApi = async () => {
    try {
      const responseData = await fetchStaffUsers();
      // console.log("responseData", responseData);
      setStaffUsers(responseData?.data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchDataFromApi();

    fetchStaffUsersFromApi();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90"></div>
        </div>
      ) : (
        <div>
          {assistants && assistants.length > 0 ? (
            <>
              <div className="w-full flex justify-end">
                <div className="py-5 flex gap-3 px-5">
                  <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={togglePopup}>
                    <ShieldPlus className="inline mb-1 mr-1" size="16" />
                    Hire New Assistant
                  </button>
                </div>
              </div>

              <div className="px-5">
                <table className="min-w-full divide-y bg-back-dark divide-color-lines rounded-lg">
                  <thead className="min-w-full rounded-md divide-color-lines">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Active Assistants
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-back-dark divide-y divide-color-lines">
                    {assistants.map((assistant) => (
                      <tr key={assistant.id} className="hover:bg-gray-900 text-sm">
                        <td className="px-6 py-4 whitespace-wrap">
                          <OneAssistant assistant={assistant} staffUsers={staffUsers} fetchDataFromApi={fetchDataFromApi} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center text-center text-xl pt-10">
              <div>
                No Assistants found.
                <div className="block text-base mt-5 mb-10">Start by hiring new assistant.</div>
                <div className="flex items-center justify-center">
                  <div onClick={togglePopup} className="rounded-lg text-center overflow-hidden bg-blue-700 p-4 hover:bg-blue-900 cursor-pointer">
                    <ShieldPlus className="inline mb-2" size="30" />
                    <p className="text-base">Hire New Assistant</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {/* {isCreateOpen && <CreateAssistant isOpen={isCreateOpen} onClose={togglePopup} fetchAssistants={fetchDataFromApi} />} */}
    </div>
  );
};

export default Assistants;
