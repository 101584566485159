// Sidebar.js
import React from "react";
import { X } from "lucide-react";

function Popup({ isOpen, onClose, title, children, mdWidth }) {
  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 transition-opacity" onClick={onClose}>
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* <div className="bg-gray-700 rounded-lg shadow-lg relative w-full md:w-1/2"> */}
            <div className={`bg-gray-700 rounded-lg shadow-lg relative w-full ${mdWidth}`}>
              <div className="p-4">
                <div className="p-4">
                  <h2 className="text-lg font-semibold mb-0 text-center">{title}</h2>
                </div>

                {children}
              </div>
              <button onClick={onClose} className="absolute top-0 right-0 mt-4 mr-4 text-gray-100 hover:text-gray-800 focus:outline-none">
                <X size={32} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Popup;
