import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import Home from "./pages/home/Home";
import Signup from "./pages/auth/Signup";
import Signin from "./pages/auth/Signin";
import AuthProvider from "./hooks/AuthProvider";
import DashboardLayout from "./pages/dashboard/DashboardLayout";
import PrivateRoute from "./router/PrivateRoute";
import PubliceRoute from "./router/PublicRoute";
import Campaigns from "./pages/dashboard/campaigns/Campaigns";
import Assistants from "./pages/dashboard/assistants/Assistants";
import Prompts from "./pages/dashboard/prompts/Prompts";
import Accounts from "./pages/dashboard/accounts/Accounts";
import Dashboard from "./pages/dashboard/Dashboard";
import Contacts from "./pages/dashboard/contacts/Contacts";
import PromptMeta from "./pages/dashboard/prompts/promptmeta/PromptMeta";
import StripePayment from "./pages/dashboard/accounts/payments/StripePayment";
import PaymentSuccess from "./pages/dashboard/accounts/payments/PaymentSuccess";
import TermsCondions from "./pages/staticpages/TermsCondions";
import IntentPrompt from "./pages/dashboard/intentprompts/IntentPrompt";
import CreateNewAssistant from "./pages/dashboard/assistants/createNewAssistant/CreateNewAssistant";
import UpdateNewAssistant from "./pages/dashboard/assistants/createNewAssistant/UpdateNewAssistant";
import CopyAssistant from "./pages/dashboard/assistants/createNewAssistant/CopyAssistant";
import ThankYouPage from "./pages/staticpages/ThankYouPage";
import Faqs from "./pages/dashboard/faqs/Faqs";
import Objections from "./pages/dashboard/objections/Objections";
import Calls from "./pages/dashboard/calls/Calls";
import CallTranscript from "./pages/dashboard/calls/CallTranscript";
import CreateIntentPrompt from "./pages/dashboard/intentprompts/CreateIntentPrompt";
import SMSproviders from "./pages/dashboard/smsproviders/SMSproviders";

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App text-white">
          <Header />
          <div>
            <Routes>
              {/* Available only when logged out */}
              <Route element={<PubliceRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/signin" element={<Signin />} />
              </Route>
              {/* Available only when logged out OR logged In */}

              <Route path="/terms-and-conditions" element={<TermsCondions />} />
              <Route path="/thank-you" element={<ThankYouPage />} />

              {/* Available only when logged In */}

              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<DashboardLayout />}>
                  <Route index element={<Dashboard />} />
                  <Route path="campaigns" element={<Campaigns />} />
                  <Route path="assistants" element={<Assistants />} />
                  <Route path="copy-assistant/:assistant_id" element={<CopyAssistant />} />
                  <Route path="save-assistant/:assistant_id" element={<UpdateNewAssistant />} />
                  <Route path="create-assistant" element={<CreateNewAssistant />} />
                  <Route path="prompts" element={<Prompts />} />
                  <Route path="intent-prompt" element={<IntentPrompt />} />
                  <Route path="intent-prompt/create" element={<CreateIntentPrompt />} />
                  <Route path="intent-prompt/save/:id" element={<CreateIntentPrompt />} />
                  <Route path="prompt-meta/:promptId" element={<PromptMeta />} />
                  <Route path="accounts" element={<Accounts />} />
                  <Route path="contacts" element={<Contacts />} />
                  <Route path="payment" element={<StripePayment />} />
                  <Route path="success" element={<PaymentSuccess />} />
                  <Route path="faqs" element={<Faqs />} />
                  <Route path="objections" element={<Objections />} />
                  <Route path="calls" element={<Calls />} />
                  <Route path="call-transcript/:call_id" element={<CallTranscript />} />
                  <Route path="sms-providers" element={<SMSproviders />} />
                </Route>
              </Route>
            </Routes>
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
