import React from "react";

const ThankYouPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-slate-950">
      <div className="bg-slate-950 p-8 rounded-lg shadow-md text-center">
        <h1 className="text-4xl font-bold text-white mb-4">Thank You!</h1>
        <p className="text-md text-white mb-6">
          Thank you for installing <span className="text-xl"> Ai cold call</span>. We hope you have a great experience!
        </p>

        {/* <button onClick={() => (window.location.href = "/")} className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition duration-300">
          Go to Home
        </button> */}
      </div>
    </div>
  );
};

export default ThankYouPage;
