import React, { useState } from "react";
import { importCSVContacts } from "../../../services/contact";

const ImportContacts = ({ fetchDataFromApi, doClose, classStyle }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = async (event) => {
    if (doClose !== undefined) {
      doClose();
    }
    setLoading(true);

    let file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("csv", file);
    try {
      const responseData = await importCSVContacts(formData);
      if (responseData.status === "success") {
        fetchDataFromApi();
      }
    } catch (error) {
      // console.log("error", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className=" text-white bg-blue-700 p-2 rounded-md">Loading Contacts ...</div>;
  }

  if (error) {
    return <div className=" text-white bg-blue-700 p-2 rounded-md">Error: {error?.message}</div>;
  }

  return (
    <label className={classStyle}>
      Import From CSV
      <input id="file-upload" type="file" accept=".csv" onChange={handleFileChange} className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer" />
    </label>
  );
};

export default ImportContacts;
