import React, { useState, useEffect } from "react";
import Popup from "../../../../components/Popup";
import { getSuggestPromptAPI } from "../../../../services/prompt";

const PromptMetaSuggest = ({ isOpen, onClose, id, onApply }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [prompts, setPrompts] = useState({
    assistant_backstory: "",
    assistant_style: "",
    assistant_act: "",
    assistant_goal: "",
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const getSuggestPrompt = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const responseData = await getSuggestPromptAPI(id);
        if (responseData && responseData.status === "success" && responseData.data && responseData.data.assistant_goal) {
          setPrompts({
            assistant_backstory: responseData.data.assistant_backstory,
            assistant_style: responseData.data.assistant_style,
            assistant_act: responseData.data.assistant_act,
            assistant_goal: responseData.data.assistant_goal,
          });
        }
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setError(error);
        setIsLoading(false);
      }
    };
    getSuggestPrompt();
  }, [isOpen, id]);

  const handleNoClick = async () => {
    onClose();
  };

  const handleYesClick = async () => {
    onApply(prompts);
    onClose();
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title={"AI Generate Call Prompts"} mdWidth="max-w-4xl">
      <div>
        <div className="flex flex-row w-full gap-4">
          {/* Prompt 1 form */}
          <div className="w-full">
            <div className="space-y-2 mt-6 relative">
              <label htmlFor="" className="">
                Identity: Who will assitant act as?
              </label>
              <div className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">{!!isLoading ? "Loading..." : prompts.assistant_backstory}</div>
            </div>

            <div className="space-y-2 mt-6 relative">
              <label htmlFor="" className="">
                Style: What should conversation style be?
              </label>
              <div className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">{!!isLoading ? "Loading..." : prompts.assistant_style}</div>
            </div>

            <div className="space-y-2 mt-6 relative">
              <label htmlFor="" className="">
                Response Guideline: How should assistant response on certain questions?
              </label>
              <div className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">{!!isLoading ? "Loading..." : prompts.assistant_act}</div>
            </div>

            <div className="space-y-2 mt-6 relative">
              <label htmlFor="" className="">
                Task: What is the goal of the call?
              </label>
              <div className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">{!!isLoading ? "Loading..." : prompts.assistant_goal}</div>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-4 justify-center">
          <button className={`text-white justify-end mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2 `} type="button" onClick={handleYesClick}>
            Apply Suggestion
          </button>
          <button type="button" className={`text-white justify-end mt-5 bg-red-700 hover:bg-red-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2   `} onClick={handleNoClick}>
            Discard
          </button>
        </div>
      </div>
      <div className="flex text-red-600 justify-center mt-4">{error?.message}</div>
    </Popup>
  );
};

export default PromptMetaSuggest;
