import { getAccessToken, logOut } from "./token";

export const createSMSProviderAPI = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/sms_provider`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const getSMSProviderByTypeAPI = async (type) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/sms_provider/get_provider_by_type/${type}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const importSMSProviderPhone = async (postData) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/sms_provider/phone_import`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAllImportedPhonesAPI = async (type) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/sms_provider/get_provider_numbers`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};

export const deleteNumberAPI = async (id) => {
  try {
    let token = await getAccessToken();
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/sms_provider/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.statusText === "Unauthorized") {
        logOut();
      }
      const data = await response.json();
      throw new Error(data?.message);
    }
    const data = await response.json();
    if (data && data.status === "success") {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw error;
  }
};
