import React, { useState, useEffect } from "react";
import { BadgePlus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { fetchAllIntentPromptsAPI } from "../../../services/intent_prompt";
// import DeleteConfirmation from "../../../components/DeleteConfirmation";

const IntentPrompt = () => {
  const [intentPrompts, setIntentPrompts] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  // const [selectedPrompt, setSelectedPrompt] = useState(null);

  let navigate = useNavigate();

  const handleCreateClick = () => {
    return navigate(`/dashboard/intent-prompt/create`);
  };

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchAllIntentPromptsAPI();
      // console.log("responseData", responseData);
      setIntentPrompts(responseData.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const handleViewClick = (promptId) => {
    // console.log("prompt", promptId);
    return navigate(`/dashboard/intent-prompt/save/${promptId}`);
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // const toggleDeletePopup = (id) => {
  //   setSelectedPrompt(id);
  //   setIsDeleteOpen(!isDeleteOpen);
  // };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90"></div>
        </div>
      ) : (
        <div>
          <div className="w-full flex justify-end">
            <div className="py-5 flex gap-3 px-5">
              <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={handleCreateClick}>
                <BadgePlus className="inline mb-1 mr-1" size="16" />
                New Intent Prompt
              </button>
            </div>
          </div>
          {intentPrompts && intentPrompts.length > 0 ? (
            <>
              {/* Display prompt listing here */}
              <div className="px-5">
                <table className="min-w-full divide-y bg-back-dark divide-color-lines rounded-lg">
                  <thead className="min-w-full rounded-md divide-color-lines">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        ID
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-sm text-slate-400 font-medium tracking-wider whitespace-nowrap">
                        Type
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-back-dark divide-y divide-color-lines">
                    {intentPrompts.map((onePrompt) => (
                      <tr key={onePrompt.id} className="hover:bg-gray-900 text-sm">
                        <td className="px-6 py-4 whitespace-wrap">
                          {onePrompt.id}{" "}
                          <svg
                            className="inline-block cursor-pointer"
                            onClick={() => {
                              navigator.clipboard.writeText(onePrompt.id).then(
                                function () {},
                                function (err) {}
                              );
                            }}
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            focusable="false"
                          >
                            <path d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
                          </svg>
                        </td>
                        <td className="px-6 py-4 whitespace-wrap">{onePrompt.name}</td>
                        <td className="px-6 py-4 whitespace-wrap">{onePrompt.type}</td>
                        <td className="px-6 py-4 whitespace-wrap">
                          <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={() => handleViewClick(onePrompt.id)}>
                            View
                          </button>
                        </td>
                        {/* <td className="px-6 py-4 whitespace-wrap">
                          <button className="text-white bg-red-700 hover:bg-red-800 focus:ring-4  font-medium rounded-lg text-sm px-3  py-2  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" type="button" onClick={() => toggleDeletePopup(onePrompt.id)}>
                            Delete
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center text-center text-xl pt-10">
              <div>
                No Intent Prompt found.
                <div className="block text-base mt-5 mb-10">Start by creating new Intent prompt.</div>
                <div className="flex items-center justify-center">
                  <div onClick={handleCreateClick} className="rounded-lg text-center overflow-hidden bg-blue-700 p-4 hover:bg-blue-900 cursor-pointer">
                    <BadgePlus className="inline mb-2" size="30" />
                    <p className="text-base">Create New Intent Prompt</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Delete prompt popup */}
      {/* {selectedPrompt && <DeleteConfirmation isOpen={isDeleteOpen} onClose={toggleDeletePopup} title={"Are you sure, you want to delete this prompt?"} id={selectedPrompt} fetchDataFromApi={fetchDataFromApi} deleteMethod={deletePromptAPI} />} */}
    </div>
  );
};

export default IntentPrompt;
