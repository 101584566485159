import React, { useEffect, useState } from "react";
import DeleteConfirmation from "../../../components/DeleteConfirmation";
import { BadgePlus } from "lucide-react";
import CreateObjection from "./CreateObjection";
import { deleteObjectionAPI, fetchObjectionsAPI, updateObjectionsAPI } from "../../../services/objection";

const Objections = () => {
  const [objections, setObjections] = useState([]);
  const [objectionsText, setObjectionsText] = useState("");

  const [answers, setAnswers] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [successSave, setSuccessSave] = useState(null);

  const [selectedObjection, setSelectedObjection] = useState(0);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [fullText, setFullText] = useState("");
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchObjectionsAPI();
      // console.log("responseData", responseData);
      setObjections(responseData.data.objections);
      setObjectionsText(responseData.data.objection_text);
      // Initialize answers state with null values
      const initialAnswers = responseData.data.objections.reduce((acc, objection) => {
        acc[objection.id] = objection.answer || "";
        return acc;
      }, {});
      setAnswers(initialAnswers);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const handleInputChange = (id, value) => {
    setErrorSave(null);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: value,
    }));
  };

  useEffect(() => {
    setFullText(objections.map((oneObjection) => (answers[oneObjection.id] ? oneObjection.objection + ": " + answers[oneObjection.id] : "")));
  }, [answers]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // handle the form submission
    console.log("Submitted answers:", answers);
    setLoading(true);
    // call server api here
    try {
      var resp = await updateObjectionsAPI({ answers: answers, full_text: fullText });
      setSuccessSave(resp.message);
      setObjectionsText(resp.objection_content);
    } catch (error) {
      setErrorSave(error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDeletePopup = (id) => {
    setSelectedObjection(id);
    setIsDeleteOpen(!isDeleteOpen);
    console.log("delete id", id);
  };

  const togglePopup = () => {
    setIsCreateOpen(!isCreateOpen);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div>
        {isLoading ? (
          <div className="flex justify-center mt-10">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90"></div>
          </div>
        ) : (
          <div>
            <form onSubmit={handleSubmit}>
              <div className="flex justify-end py-5 px-5">
                {!!errorSave && !loading && <div className="mr-7 py-2 text-right text-red-500">{errorSave?.message}</div>}
                {!!successSave && !loading && <div className="mr-7 py-2 text-right text-green">{errorSave?.successSave}</div>}
                {!!loading && <div className="mr-7 py-2 text-right text-white">Saving...</div>}
                <button
                  type="submit"
                  disabled={loading}
                  className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
                      ${loading ? "bg-gray-400 cursor-not-allowed" : "hover:bg-indigo-700 focus:ring-indigo-500"}`}
                >
                  Save
                </button>
              </div>
              {objections && objections.length > 0 ? (
                <div className="px-5">
                  <div className="min-w-full p-5 divide-y bg-back-dark divide-color-lines rounded-lg">
                    {objections.map((oneObjection) => (
                      <div key={oneObjection.id} className="flex flex-row gap-5 mb-7">
                        {/* objection and answer div */}
                        <div className="w-full">
                          <div className="mb-2">{oneObjection.objection}</div>
                          <input type="text" value={answers[oneObjection.id] || ""} onChange={(e) => handleInputChange(oneObjection.id, e.target.value)} onBlur={(e) => handleInputChange(oneObjection.id, e.target.value)} className="relative block w-full px-3 py-3 border  bg-gray-950  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Enter answer" />
                        </div>
                        {/* Button div */}
                        <div className="flex justify-center items-center">
                          <button className="text-white bg-red-700 hover:bg-red-800 focus:ring-4  font-medium rounded-lg text-sm px-3 py-2  mt-7  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" type="button" onClick={() => toggleDeletePopup(oneObjection.id)}>
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="min-w-full p-5 divide-y bg-back-dark divide-color-lines rounded-lg">
                    <div className="mr-7 py-2 text-left text-white">
                      {objectionsText ? (
                        <div>
                          Objection Summary:
                          <br />
                          <br />
                          {objectionsText}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                // <div className="flex items-center justify-center text-center text-xl pt-10">
                //   <div>No objections found.</div>
                // </div>
                <div className="flex items-center justify-center text-center text-xl pt-10">
                  <div>
                    No objections found.
                    <div className="block text-base mt-5">Start by adding new objection.</div>
                  </div>
                </div>
              )}
              <div className="flex items-center justify-center mt-10">
                <div onClick={togglePopup} className="rounded-lg text-center overflow-hidden bg-blue-700 p-4 hover:bg-blue-900 cursor-pointer">
                  <BadgePlus className="inline mb-2" size="30" />
                  <p className="text-base">Add New objection</p>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>

      {isCreateOpen && <CreateObjection isOpen={isCreateOpen} onClose={togglePopup} fetchDataFromApi={fetchDataFromApi} />}
      {!!selectedObjection && <DeleteConfirmation isOpen={isDeleteOpen} onClose={toggleDeletePopup} title={"Are you sure, you want to delete this objection?"} id={selectedObjection} fetchDataFromApi={fetchDataFromApi} deleteMethod={deleteObjectionAPI} />}
    </div>
  );
};

export default Objections;
