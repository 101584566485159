export const assistantVoices = [
  {
    id: "22ea0c36-10bd-4ff0-8d66-0f37cc3dbabd",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/7c339a9d-370f-4643-adf5-4134e3ec9886/mlae02/manifest.json",
    slug: "michael",
    name: "Michael",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/michael.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "de4e73db-a298-4253-b91c-64be3c259abe",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/285900ed-b758-4abb-a4ef-e7295741d97d/erasmosaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/285900ed-b758-4abb-a4ef-e7295741d97d/erasmosaad/manifest.json",
    name: "Erasmo",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/TxPAks6Vpg0kGy9k71.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "03c9c21d-87bc-4f50-a38a-110a5236a6b8",
    provider: "playht",
    providerId: "s3://peregrine-voices/mel28/manifest.json",
    slug: "jack",
    name: "Jack",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "f9bd792a-df17-46a7-bc0d-9c12dc06ba49",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/a2852064-4b50-41a8-82f4-0ef0aae4601c/clarksaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/a2852064-4b50-41a8-82f4-0ef0aae4601c/clarksaad/manifest.json",
    name: "Clark",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/GEFxhw1xJIoHksvjuY.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "bfc6b61c-206f-4d65-af9a-c2192f3a26c4",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/09b5c0cc-a8f4-4450-aaab-3657b9965d0b/podcaster/manifest.json",
    slug: "matt",
    name: "Matt",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/matt.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "4cdbfc95-e062-4982-a3b3-93cfd2cedecc",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/3b74b785-e06e-4a53-a9c4-ebed08a70ef2/susannarrativesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/3b74b785-e06e-4a53-a9c4-ebed08a70ef2/susannarrativesaad/manifest.json",
    name: "Susan (Narrative)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/bFkK0I8guuf4M10UYL.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "5b5ddf30-009c-4199-a493-d8c391d2211b",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/928ed0a0-2271-4710-a7c9-1711d36b9897/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/928ed0a0-2271-4710-a7c9-1711d36b9897/original/manifest.json",
    name: "Niamh",
    language: "English (IE)",
    languageCode: "en-IE",
    gender: "Female",
    accent: "irish",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Niamh.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "07398974-7122-4514-ae51-08d430b58042",
    provider: "playht",
    providerId: "s3://peregrine-voices/mel21/manifest.json",
    slug: "melissa",
    name: "Melissa",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "44680f8c-1d30-4a90-a14b-af44f72ce22b",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f9bf96ae-19ef-491f-ae69-644448800566/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f9bf96ae-19ef-491f-ae69-644448800566/original/manifest.json",
    name: "Adelaide",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Female",
    accent: "australian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Adelaide.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "5341bbd4-3074-4d0e-8ff9-7216875e049d",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/72c255f8-00af-4c48-b0de-ad471baa3f52/alexsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/72c255f8-00af-4c48-b0de-ad471baa3f52/alexsaad/manifest.json",
    name: "Alex",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/24TPOJX58Stixzl7GN.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "8abd5f70-cdd4-4817-9cc2-fdb85ec540f1",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/40738a3a-34bb-4ac3-97c5-aed7b31ccf1d/chucksaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/40738a3a-34bb-4ac3-97c5-aed7b31ccf1d/chucksaad/manifest.json",
    name: "Chuck",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/1kOpQr0eTDPFGlmFkL.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "d5875330-c4db-4363-bc2d-7f919d964cf9",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/5d243997-dcf6-4895-b504-eb5ce95a043e/alphonsosaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/5d243997-dcf6-4895-b504-eb5ce95a043e/alphonsosaad/manifest.json",
    name: "Alfonso",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/XXjozLI2ibCbMGqb3X.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3f743f6b-1ba2-44a5-9ea5-d22cac75267b",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/2cbffa49-5dfe-4378-a54f-b824f7bbb032/theodoresaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/2cbffa49-5dfe-4378-a54f-b824f7bbb032/theodoresaad/manifest.json",
    name: "Theodore",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/theodore.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "f823bc5a-ad2f-41b8-ae83-da70f2901f46",
    provider: "playht",
    providerId: "s3://mockingbird-prod/olivia_vo_commercials_6e3c384f-15d6-4fe7-b9a4-0cb1d69daeba/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/olivia_vo_commercials_6e3c384f-15d6-4fe7-b9a4-0cb1d69daeba/voices/speaker/manifest.json",
    name: "Olivia (Advertising)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/olivia_vo_commercials.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "6945817d-cd1b-44c9-821c-22846c91b023",
    provider: "playht",
    providerId: "s3://mockingbird-prod/william_vo_narrative_0eacdff5-6243-4e26-8b3b-66e03458c1d1/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/william_vo_narrative_0eacdff5-6243-4e26-8b3b-66e03458c1d1/voices/speaker/manifest.json",
    name: "William (Narrative)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/william_vo_narrative.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "1748894b-5f5f-4242-948f-f67f1ceb85bd",
    provider: "playht",
    providerId: "s3://peregrine-voices/arthur ads parrot saad/manifest.json",
    slug: "s3://peregrine-voices/arthur ads parrot saad/manifest.json",
    name: "Arthur (Advertising)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/arthur+ads.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "39d14658-965c-46bd-aa90-b190ecb66a9c",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f6594c50-e59b-492c-bac2-047d57f8bdd8/susanadvertisingsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f6594c50-e59b-492c-bac2-047d57f8bdd8/susanadvertisingsaad/manifest.json",
    name: "Susan (Advertising)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/susan+(advertising).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "31d0cd45-fad2-413f-9973-d282f1cb64c6",
    provider: "playht",
    providerId: "s3://mockingbird-prod/ayla_vo_expressive_16095e08-b9e8-429b-947c-47a75e41053b/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/ayla_vo_expressive_16095e08-b9e8-429b-947c-47a75e41053b/voices/speaker/manifest.json",
    name: "Ayla (Expressive)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "c9551cfa-415f-4152-bc9d-510ee968c596",
    provider: "playht",
    providerId: "s3://peregrine-voices/larry_ads3_parrot_saad/manifest.json",
    slug: "s3://peregrine-voices/larry_ads3_parrot_saad/manifest.json",
    name: "Larry (Advertising)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/larry+ads.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "6e660ec2-0516-4569-8e8b-73acd011df63",
    provider: "playht",
    providerId: "s3://peregrine-voices/barry ads parrot saad/manifest.json",
    slug: "s3://peregrine-voices/barry ads parrot saad/manifest.json",
    name: "Barry (Advertising)",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Male",
    accent: "australian",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/barry+ads.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3a8f3a7f-3ab4-4b9e-80ab-b656bd0fe270",
    provider: "playht",
    providerId: "s3://peregrine-voices/barry narrative parrot saad/manifest.json",
    slug: "s3://peregrine-voices/barry narrative parrot saad/manifest.json",
    name: "Barry (Narrative)",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Male",
    accent: "australian",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/barry+narrative.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "5243d46d-dbf5-4d8c-bac0-28dd2696d995",
    provider: "playht",
    providerId: "s3://peregrine-voices/hudson saad parrot/manifest.json",
    slug: "s3://peregrine-voices/hudson saad parrot/manifest.json",
    name: "Hudson",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/hudson.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "e9601292-3a9e-40ec-b2c2-1c7ded7a0516",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/7ced805f-611e-433c-8c43-568f48a8af4e/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/7ced805f-611e-433c-8c43-568f48a8af4e/original/manifest.json",
    name: "Alessandro",
    language: "English (IT)",
    languageCode: "en-IT",
    gender: "Male",
    accent: "italian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Alessandro.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3583a732-c8f7-4a23-acee-77504b9e76b2",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/8218bea1-aad9-49cc-95b3-e9234e28d4a6/wilbursaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/8218bea1-aad9-49cc-95b3-e9234e28d4a6/wilbursaad/manifest.json",
    name: "Wilbur",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/WSY7IVkGYJlv1z0c4u.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "62d0da2b-8bb4-4839-be63-9f54822f4621",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/abc2d0e6-9433-4dcc-b416-0b035169f37e/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/abc2d0e6-9433-4dcc-b416-0b035169f37e/original/manifest.json",
    name: "Ranger",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Ranger.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "76b5264e-9cde-41b5-a074-66ed5d98306c",
    provider: "playht",
    providerId: "s3://peregrine-voices/charlotte ads parrot saad/manifest.json",
    slug: "s3://peregrine-voices/charlotte ads parrot saad/manifest.json",
    name: "Charlotte (Advertising)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/charlotte+ads.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "f4e2ea58-abe0-4284-84d2-dc8d40ddbf49",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/0326e8a4-9001-4d92-853b-3a14dd2ea38a/arthurnarrativesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/0326e8a4-9001-4d92-853b-3a14dd2ea38a/arthurnarrativesaad/manifest.json",
    name: "Arthur (Narrative)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/arthur%20(narrative).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "9901f111-1ee2-485a-a4ca-386f14e94a31",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/3b7df114-e269-453a-8dc2-cbbce4c48f48/williamtrainingsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/3b7df114-e269-453a-8dc2-cbbce4c48f48/williamtrainingsaad/manifest.json",
    name: "William (Training)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/William+(training).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "5a0db895-488e-489a-aa4e-1d169c2eea52",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/dc23bb38-f568-4323-b6fb-7d64f685b97a/joseph/manifest.json",
    slug: "s3://voice-cloning-zero-shot/dc23bb38-f568-4323-b6fb-7d64f685b97a/joseph/manifest.json",
    name: "Joseph",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/joseph.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "ba4db5f4-14d9-44fd-995a-bf40947a5c72",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/7fdf51ea-c162-4e6e-876e-0473a0255bb8/lancesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/7fdf51ea-c162-4e6e-876e-0473a0255bb8/lancesaad/manifest.json",
    name: "Lance",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/lance.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "592116e1-c227-4882-b8af-320c085b4276",
    provider: "playht",
    providerId: "s3://mockingbird-prod/oliver_vo_training_6e3f604a-5605-4542-948d-347b0d7546fc/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/oliver_vo_training_6e3f604a-5605-4542-948d-347b0d7546fc/voices/speaker/manifest.json",
    name: "Oliver (Training)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "6b100d3a-9488-44a0-aae7-cf920698ba8a",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/aa753d26-bc20-479f-95af-5c3c1c970d93/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/aa753d26-bc20-479f-95af-5c3c1c970d93/original/manifest.json",
    name: "Finley",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Finley.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "07f626b9-2fa3-4814-8d69-e9e3a8a52ac2",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/33e6b76a-7554-48fa-9798-2e6823ab0a10/aylaadvertisingsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/33e6b76a-7554-48fa-9798-2e6823ab0a10/aylaadvertisingsaad/manifest.json",
    name: "Ayla (Advertising)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/ayla+(advertising).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "5b1a585b-dcb0-40aa-b1ed-bb51f514dbc0",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/37e5af8b-800a-4a76-8f31-4203315f8a9e/billysaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/37e5af8b-800a-4a76-8f31-4203315f8a9e/billysaad/manifest.json",
    name: "Billy",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/billy.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3c37cc27-ce07-406f-b1ab-ba7055d8eedf",
    provider: "playht",
    providerId: "s3://mockingbird-prod/hook_1_chico_a3e5e83f-08ae-4a9f-825c-7e48d32d2fd8/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/hook_1_chico_a3e5e83f-08ae-4a9f-825c-7e48d32d2fd8/voices/speaker/manifest.json",
    name: "Hook",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/editor-samples/hook.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "d22ead17-4890-4b03-a3c3-0454201b21a1",
    provider: "playht",
    providerId: "s3://peregrine-voices/mel22/manifest.json",
    slug: "will",
    name: "Will",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "18c28db4-29bf-45a2-874a-57f1395ee7a0",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/9fc626dc-f6df-4f47-a112-39461e8066aa/oliviaadvertisingsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/9fc626dc-f6df-4f47-a112-39461e8066aa/oliviaadvertisingsaad/manifest.json",
    name: "Olivia (Advertising)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/olivia+advertising.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "b7e803ee-a800-4d4c-a471-46b33c258603",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/92519089-3c97-4f31-8743-ab4f06047b88/larrysaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/92519089-3c97-4f31-8743-ab4f06047b88/larrysaad/manifest.json",
    name: "Larry (Narrative)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/larry+(narrative).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "387e05e8-4d46-478f-ae1a-1703a0f0db30",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/7142f297-c55f-451d-8863-108d5101b5ed/susantrainingsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/7142f297-c55f-451d-8863-108d5101b5ed/susantrainingsaad/manifest.json",
    name: "Susan (Training)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/susan+(training).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "59c6471f-7167-4023-97a3-2b4cba9ddf36",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f43cc4b4-b193-4a13-a903-e6b125c3d572/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f43cc4b4-b193-4a13-a903-e6b125c3d572/original/manifest.json",
    name: "Luna",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Luna.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "c1a96e30-c7cc-4ede-abe1-1247a5d9e56b",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/07dc6825-4fde-4430-8c86-54ead89e0c88/donovansaad2/manifest.json",
    slug: "s3://voice-cloning-zero-shot/07dc6825-4fde-4430-8c86-54ead89e0c88/donovansaad2/manifest.json",
    name: "Donovan",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/donovan.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3fc9ddb0-a65e-43a1-b553-32cae141f390",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/30884451-1eff-4fd8-9a24-d1ee3353b215/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/30884451-1eff-4fd8-9a24-d1ee3353b215/original/manifest.json",
    name: "Siobhán",
    language: "English (IE)",
    languageCode: "en-IE",
    gender: "Female",
    accent: "irish",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Siobhán.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "a378b709-01a1-45de-b618-f430c7e45cfd",
    provider: "playht",
    providerId: "s3://peregrine-voices/charlotte meditation 2 parrot saad/manifest.json",
    slug: "s3://peregrine-voices/charlotte meditation 2 parrot saad/manifest.json",
    name: "Charlotte (Meditation)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/charlotte+meditation.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "43335ba5-477a-460d-a013-a4b8fccc6d70",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/3a637c59-4f22-4ad1-bf73-f657dfbb1978/harrissaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/3a637c59-4f22-4ad1-bf73-f657dfbb1978/harrissaad/manifest.json",
    name: "Harris",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/8O129MfqJ7s70APpEN.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "970ea80c-9928-4c79-81b1-63d291e696d1",
    provider: "playht",
    providerId: "s3://peregrine-voices/nolan saad parrot/manifest.json",
    slug: "s3://peregrine-voices/nolan saad parrot/manifest.json",
    name: "Nolan",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/nolan.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "adc3d7d0-fbd2-459b-b95f-acc84ec74955",
    provider: "playht",
    providerId: "s3://mockingbird-prod/abigail_vo_6661b91f-4012-44e3-ad12-589fbdee9948/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/abigail_vo_6661b91f-4012-44e3-ad12-589fbdee9948/voices/speaker/manifest.json",
    name: "Abigail",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/abigail_vo.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "896a142f-a76c-47c4-90b2-9464d1e9184e",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/8007f637-9aec-4426-8c6a-7e713c032623/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/8007f637-9aec-4426-8c6a-7e713c032623/original/manifest.json",
    name: "Mitch",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Male",
    accent: "australian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Mitch.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "84e4e68d-6685-4eba-9091-f5ef2e42a756",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/2879ab87-3775-4992-a228-7e4f551658c2/fredericksaad2/manifest.json",
    slug: "s3://voice-cloning-zero-shot/2879ab87-3775-4992-a228-7e4f551658c2/fredericksaad2/manifest.json",
    name: "Frederick",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/rixIUBC2k3d0xHY4B2.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "053793e0-62d8-4357-aef8-0869cb73e6f1",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/44f32760-8f41-4dfb-b192-ca077fc501ea/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/44f32760-8f41-4dfb-b192-ca077fc501ea/original/manifest.json",
    name: "Furio",
    language: "English (IT)",
    languageCode: "en-IT",
    gender: "Male",
    accent: "italian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Furio.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "214e6d5e-d298-40ce-9397-d20318d71efa",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/6c9c01b7-8d38-47ae-8ce5-18a360b26cf3/oliversaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/6c9c01b7-8d38-47ae-8ce5-18a360b26cf3/oliversaad/manifest.json",
    name: "Oliver",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/qLdehPTUSon46nCgzL.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "89dec091-62c8-45ba-ba09-7a0d48983c77",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/a0fa25cc-5f42-4dd0-8a78-a950dd5297cd/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/a0fa25cc-5f42-4dd0-8a78-a950dd5297cd/original/manifest.json",
    name: "Isabella",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Female",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Isabella.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "05e3e89f-22dd-4e8f-baa5-56faba66d815",
    provider: "playht",
    providerId: "s3://mockingbird-prod/susan_vo_commercials_0f4fa663-6eba-4582-be1e-2d5bde798f1c/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/susan_vo_commercials_0f4fa663-6eba-4582-be1e-2d5bde798f1c/voices/speaker/manifest.json",
    name: "Susan (Advertising)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/susan_vo_commercials.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "84c7be7c-1ef8-4cc2-a0a4-efa4cd625630",
    provider: "playht",
    providerId: "s3://mockingbird-prod/william_vo_training_1b939b71-14fa-41f0-b1db-7d94f194ad0a/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/william_vo_training_1b939b71-14fa-41f0-b1db-7d94f194ad0a/voices/speaker/manifest.json",
    name: "William (Training)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/william_vo_training.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "471f288b-be65-473c-8d79-91917a0b5c82",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/028a32d4-6a79-4ca3-a303-d6559843114b/chris/manifest.json",
    slug: "chris",
    name: "Chris",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/chris.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "7b68929a-a0a0-467c-ba8e-4c3b956e4c3f",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/261923bd-a10a-4a90-bced-0ce2b0230398/hooksaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/261923bd-a10a-4a90-bced-0ce2b0230398/hooksaad/manifest.json",
    name: "Hook",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/hook.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "26f9f5b9-be6d-46e3-8a97-66f3a5e079c0",
    provider: "playht",
    providerId: "s3://peregrine-voices/olivia_ads3_parrot_saad/manifest.json",
    slug: "s3://peregrine-voices/olivia_ads3_parrot_saad/manifest.json",
    name: "Olivia (Narrative)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/olivia+narrative.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "750d360f-e42a-436d-a2b4-c78672ca4841",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/80ba8839-a6e6-470c-8f68-7c1e5d3ee2ff/abigailsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/80ba8839-a6e6-470c-8f68-7c1e5d3ee2ff/abigailsaad/manifest.json",
    name: "Abigail",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/hbGWSpDCmbkVnEGmAl.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "203c51e3-fd89-4191-9991-7030fc6f43d3",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/2d5053ed-635a-499a-aa66-e85aeaf46d3e/darnellsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/2d5053ed-635a-499a-aa66-e85aeaf46d3e/darnellsaad/manifest.json",
    name: "Darnell",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/91d90mOYy97yxMzha5.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "83f25932-d95b-4de7-8116-94ce88d46d66",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/e7e9514f-5ffc-4699-a958-3627151559d9/nolansaad2/manifest.json",
    slug: "s3://voice-cloning-zero-shot/e7e9514f-5ffc-4699-a958-3627151559d9/nolansaad2/manifest.json",
    name: "Nolan",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/c8PjtZ32eSYzWZDm3z.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "4d1cc502-dc12-4868-818f-9b2b5e04fa63",
    provider: "playht",
    providerId: "s3://mockingbird-prod/susan_vo_narrative_73051c90-460b-4e54-adab-9235f45c5e5f/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/susan_vo_narrative_73051c90-460b-4e54-adab-9235f45c5e5f/voices/speaker/manifest.json",
    name: "Susan (Narrative)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "f65e3047-f48e-4b26-b2f8-6f9312415cfd",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/b4a876c1-8730-435e-9595-141799868808/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/b4a876c1-8730-435e-9595-141799868808/original/manifest.json",
    name: "Waylon",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Waylon.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3a0aa043-55b5-4a3e-8838-cee3897b454b",
    provider: "playht",
    providerId: "s3://peregrine-voices/russell2_parrot_saad/manifest.json",
    slug: "s3://peregrine-voices/russell2_parrot_saad/manifest.json",
    name: "Russell",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Male",
    accent: "australian",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/russell.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "063ef4e5-12e0-4231-95e8-e229712e9364",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/dc90b58b-59a9-4e65-955d-c7620deb2d7a/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/dc90b58b-59a9-4e65-955d-c7620deb2d7a/original/manifest.json",
    name: "Richie",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Richie.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "43ae3c15-6a8b-448c-9a3c-77b8aa8dc6d6",
    provider: "playht",
    providerId: "s3://mockingbird-prod/eileen_vo_5d7b2bcc-d635-4301-97e8-d97c13768514/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/eileen_vo_5d7b2bcc-d635-4301-97e8-d97c13768514/voices/speaker/manifest.json",
    name: "Eileen",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Female",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/eileen_vo.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "93747058-a8b9-4a2e-ad45-68ed710c8830",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/cebaa3cf-d1d5-4625-ba20-03dcca3b379f/sargesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/cebaa3cf-d1d5-4625-ba20-03dcca3b379f/sargesaad/manifest.json",
    name: "Sarge",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/sarge.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "bf776917-8a0a-40c3-a5db-4734be9d68b6",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f3c22a65-87e8-441f-aea5-10a1c201e522/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f3c22a65-87e8-441f-aea5-10a1c201e522/original/manifest.json",
    name: "Sumita",
    language: "English (IN)",
    languageCode: "en-IN",
    gender: "Female",
    accent: "indian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Sumita.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "d67d61fc-41d8-4379-b1d9-10d26662e16c",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/e5df2eb3-5153-40fa-9f6e-6e27bbb7a38e/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/e5df2eb3-5153-40fa-9f6e-6e27bbb7a38e/original/manifest.json",
    name: "Navya",
    language: "English (IN)",
    languageCode: "en-IN",
    gender: "Female",
    accent: "indian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Navya.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "b3ca7163-061e-4b1f-979c-12c3617a21d1",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/fd35d956-b644-4513-9cd0-7aa6931dfd43/juliansaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/fd35d956-b644-4513-9cd0-7aa6931dfd43/juliansaad/manifest.json",
    name: "Julian",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/nHg2pd7S5L9ctKScRX.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "753af386-8246-4f42-a9e8-f5f26a934e50",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/7447d346-20d5-4939-b43c-bdf17e757e98/cliftonsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/7447d346-20d5-4939-b43c-bdf17e757e98/cliftonsaad/manifest.json",
    name: "Clifton",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/IQqwyNGPmk3cF7x5QG.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "95324a32-0a0b-478d-8d36-e9362e2b7ddc",
    provider: "playht",
    providerId: "s3://peregrine-voices/a10/manifest.json",
    slug: "davis",
    name: "Davis",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "0a7b9e81-974f-4fd1-a5a7-ebbde424c715",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/32b943f6-87cf-4e15-8e7a-d4cb848e3689/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/32b943f6-87cf-4e15-8e7a-d4cb848e3689/original/manifest.json",
    name: "Scarlett",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Female",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Scarlett.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "cfca8e75-14fc-4607-bb1c-b4c2e9ea90ec",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/65e66dc8-9273-43c8-889a-83e858bf2bb8/susansaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/65e66dc8-9273-43c8-889a-83e858bf2bb8/susansaad/manifest.json",
    name: "Susan",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/YMblwkl9VUBs1xTuA9.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "b5e1f724-9e25-4dd7-8a14-6aa7e0ae741f",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/087f79be-bb52-436a-93f3-cee27cd3b80d/huntersaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/087f79be-bb52-436a-93f3-cee27cd3b80d/huntersaad/manifest.json",
    name: "Hunter",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/hunter.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "6ab32f68-bdff-4492-a0b7-addfb4579c4e",
    provider: "playht",
    providerId: "s3://mockingbird-prod/ayla_vo_commercials_d66900d5-69f5-476f-9bd6-8eab2936dda3/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/ayla_vo_commercials_d66900d5-69f5-476f-9bd6-8eab2936dda3/voices/speaker/manifest.json",
    name: "Ayla (Advertising)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/ayla_vo_commercials.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "56ee16bb-f9f8-4c84-bc32-c456042ee0f4",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/4b2f8a9c-26e3-4e12-9136-7b6b97229074/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/4b2f8a9c-26e3-4e12-9136-7b6b97229074/original/manifest.json",
    name: "Elon",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: false,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "b2eedaee-25a3-495e-b443-1a9b4b640b7c",
    provider: "playht",
    providerId: "s3://mockingbird-prod/arthur_vo_narrative_a33fd610-73a9-4401-9a78-6b8219c68a9e/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/arthur_vo_narrative_a33fd610-73a9-4401-9a78-6b8219c68a9e/voices/speaker/manifest.json",
    name: "Arthur (Narrative)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/arthur_vo_narrative.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "a52510c3-4756-4531-b70f-10092daaeda2",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/0b29eab5-834f-4463-b3ad-4e6177d2b145/flynnsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/0b29eab5-834f-4463-b3ad-4e6177d2b145/flynnsaad/manifest.json",
    name: "Flynn",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/RWncfcaZlxafttC6PN.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3f432541-e17c-4a72-8711-b4d7721cbdea",
    provider: "playht",
    providerId: "s3://mockingbird-prod/ayla_vo_narrative_d8199dfd-b50f-40c7-9d99-e203ba5f4152/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/ayla_vo_narrative_d8199dfd-b50f-40c7-9d99-e203ba5f4152/voices/speaker/manifest.json",
    name: "Ayla (Narrative)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/ayla_vo_narrative.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "ad208719-f832-4abc-af15-4fbf25a82673",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/b2f5441d-354f-4c2f-8f32-390aaaabf42d/charlottesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/b2f5441d-354f-4c2f-8f32-390aaaabf42d/charlottesaad/manifest.json",
    name: "Charlotte",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/xLCfJmpzFIEwsi3dUx.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3ea5653a-48ba-4c4f-9243-ec118f1b6f30",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/9f1ee23a-9108-4538-90be-8e62efc195b6/charlessaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/9f1ee23a-9108-4538-90be-8e62efc195b6/charlessaad/manifest.json",
    name: "Charles",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/charles.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "ce9e0625-60bf-476f-97ff-c4061d7ade96",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/bb759cd0-edb0-43d9-8273-f0a7c048fb11/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/bb759cd0-edb0-43d9-8273-f0a7c048fb11/original/manifest.json",
    name: "Lachlan",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Male",
    accent: "australian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Lachlan.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "9e8f2dec-3325-4982-bb8a-2e645504943c",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/def0cd31-1b8c-486e-82d2-050a0ea2965d/axelsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/def0cd31-1b8c-486e-82d2-050a0ea2965d/axelsaad/manifest.json",
    name: "Axel",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/N5TqVLG8y2deM1e5bQ.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "78576310-b03e-43ab-9360-1196bda07eb3",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/75085d4f-839d-4ca5-a3e2-e329f04a591e/larrynarrativesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/75085d4f-839d-4ca5-a3e2-e329f04a591e/larrynarrativesaad/manifest.json",
    name: "Larry",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/LkIc6BF6Ivhf4GfR0T.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "f75e1934-d15d-414b-a31c-b0b266948412",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/72aafde0-8f4f-4a91-a483-21d76114ab17/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/72aafde0-8f4f-4a91-a483-21d76114ab17/original/manifest.json",
    name: "Ada",
    language: "English (ZA)",
    languageCode: "en-ZA",
    gender: "Male",
    accent: "south african",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Ada.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "a64ef93d-ea7a-44fc-a1de-01243102e52b",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/1afba232-fae0-4b69-9675-7f1aac69349f/delilahsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/1afba232-fae0-4b69-9675-7f1aac69349f/delilahsaad/manifest.json",
    name: "Delilah",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/UlI3kAAfgCOJNP2OMe.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "f670bd22-c09c-47a9-9ff1-e1cc26f9a0b9",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/0ed97923-dd04-449f-a497-6f1109fdd9cc/jordansaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/0ed97923-dd04-449f-a497-6f1109fdd9cc/jordansaad/manifest.json",
    name: "Jordan",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/jordan.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "9712f8c8-36a4-42ef-b1f6-97725785c1b1",
    provider: "playht",
    providerId: "s3://mockingbird-prod/arthur_vo_training_9281c8fd-c7f0-4445-a148-466292d3d329/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/arthur_vo_training_9281c8fd-c7f0-4445-a148-466292d3d329/voices/speaker/manifest.json",
    name: "Arthur (Training)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/arthur_vo_training.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "831d2ba0-fdcd-4ddf-bdb2-d0e62d7555d5",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/d9ff78ba-d016-47f6-b0ef-dd630f59414e/female-cs/manifest.json",
    slug: "ruby",
    name: "Ruby",
    language: "English (NZ)",
    languageCode: "en-NZ",
    gender: "Female",
    accent: "australian",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/ruby.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "7016e419-adf0-4f38-b61f-95c40224d760",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/5b81dc4c-bf98-469d-96b4-8f09836fb500/aurorasaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/5b81dc4c-bf98-469d-96b4-8f09836fb500/aurorasaad/manifest.json",
    name: "Aurora",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/CCxudBJ683rCXQviGa.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "bacd8332-dfc4-4aa4-afcf-be2f52d2b719",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/418a94fa-2395-4487-81d8-22daf107781f/george/manifest.json",
    slug: "s3://voice-cloning-zero-shot/418a94fa-2395-4487-81d8-22daf107781f/george/manifest.json",
    name: "George",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/george.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "6ffa7f9c-42dd-4ba2-9220-6a9fa896b5bf",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/5cd30986-f7f7-444b-ad82-d08ceb24ec5f/wilbertsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/5cd30986-f7f7-444b-ad82-d08ceb24ec5f/wilbertsaad/manifest.json",
    name: "Wilbert",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/wilbert.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "44afac9f-21cd-4b18-97ae-dc8aa9dfb6f0",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/b41d1a8c-2c99-4403-8262-5808bc67c3e0/bentonsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/b41d1a8c-2c99-4403-8262-5808bc67c3e0/bentonsaad/manifest.json",
    name: "Benton",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/fPBgu4E0dT9eMkxIe0.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "2b26de32-f9be-4112-a47d-8e4fcd9d5599",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/b709b944-9256-4578-b9d8-a1ce4d729022/eileensaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/b709b944-9256-4578-b9d8-a1ce4d729022/eileensaad/manifest.json",
    name: "Eileen",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/rtTTJKp1riEZnQQGgy.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3246f36e-4d70-452d-83e4-bcd084caa1be",
    provider: "playht",
    providerId: "s3://peregrine-voices/charlotte_training_parrot_saad/manifest.json",
    slug: "s3://peregrine-voices/charlotte_training_parrot_saad/manifest.json",
    name: "Charlotte (Training)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/charlotte+training.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "8f6d49cb-7bd1-43ad-a4f2-5a656673681d",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/90217770-a480-4a91-b1ea-df00f4d4c29d/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/90217770-a480-4a91-b1ea-df00f4d4c29d/original/manifest.json",
    name: "Samara",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Samara.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "7ebb4e88-f7a2-4dca-899c-092f96f28be0",
    provider: "playht",
    providerId: "s3://mockingbird-prod/larry_vo_narrative_4bd5c1bd-f662-4a38-b5b9-76563f7b92ec/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/larry_vo_narrative_4bd5c1bd-f662-4a38-b5b9-76563f7b92ec/voices/speaker/manifest.json",
    name: "Larry (Narrative)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/larry_vo_narrative.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "08846846-7b53-4cae-8b07-0be29a136dd6",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/d82d246c-148b-457f-9668-37b789520891/adolfosaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/d82d246c-148b-457f-9668-37b789520891/adolfosaad/manifest.json",
    name: "Adolfo",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/adolfo.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "947e166c-9036-43b6-91fa-2c7b2be2ee01",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/b2da9576-07a4-4e1d-b71f-684f1657516f/owensaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/b2da9576-07a4-4e1d-b71f-684f1657516f/owensaad/manifest.json",
    name: "Owen",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/HnEatRKIkdIV2d72SV.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "6be04d21-1071-4359-b86d-d10ba085631e",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/483ed913-2817-45cb-b1a2-b79d9bfb2cf8/aylanarrativesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/483ed913-2817-45cb-b1a2-b79d9bfb2cf8/aylanarrativesaad/manifest.json",
    name: "Ayla (Narrative)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/ayla+(narrative).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "f9758322-fbbd-4fb7-ab16-adc955e12cd7",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/4bcdf603-fc5f-4040-a6dd-f8d0446bca9d/arthurtrainingsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/4bcdf603-fc5f-4040-a6dd-f8d0446bca9d/arthurtrainingsaad/manifest.json",
    name: "Arthur (Training)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/arthur+(training).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "f4be028d-6d95-4f30-a89d-9c279791616d",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/3a831d1f-2183-49de-b6d8-33f16b2e9867/dylansaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/3a831d1f-2183-49de-b6d8-33f16b2e9867/dylansaad/manifest.json",
    name: "Dylan",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/AAc418f41vBRkHNOdi.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "913d15b3-70b1-426d-bb10-890ada66b077",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/36e9c53d-ca4e-4815-b5ed-9732be3839b4/samuelsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/36e9c53d-ca4e-4815-b5ed-9732be3839b4/samuelsaad/manifest.json",
    name: "Samuel",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/ww8lf6xg9KAGV6kfyg.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "cc42e525-87ab-4dfa-8950-f9ff6a9f6f17",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/640a6636-dc16-4911-b75a-1549daae2c71/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/640a6636-dc16-4911-b75a-1549daae2c71/original/manifest.json",
    name: "Lumi",
    language: "English (FI)",
    languageCode: "en-FI",
    gender: "Female",
    accent: "finnish",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Lumi.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "b282289c-acaa-4a1b-afeb-b7a76a3a4bfc",
    provider: "playht",
    providerId: "s3://mockingbird-prod/nathan_drake_carmelo_pampillonio_7d540ad6-7d32-41f6-8d53-2584901aa03d/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/nathan_drake_carmelo_pampillonio_7d540ad6-7d32-41f6-8d53-2584901aa03d/voices/speaker/manifest.json",
    name: "Billy",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/editor-samples/billy.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "46f0933f-c071-48dd-a923-14e377767d42",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/1f44b3e7-22ea-4c2e-87d0-b4d9c8f1d47d/sophia/manifest.json",
    slug: "s3://voice-cloning-zero-shot/1f44b3e7-22ea-4c2e-87d0-b4d9c8f1d47d/sophia/manifest.json",
    name: "Sophia",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/sophia.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "268b0fd5-ea6f-45d1-8232-7d6a92403301",
    provider: "playht",
    providerId: "s3://peregrine-voices/oliver_ads2_parrot_saad/manifest.json",
    slug: "s3://peregrine-voices/oliver_ads2_parrot_saad/manifest.json",
    name: "Oliver (Advertising)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/oliver+ads.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "c349ed14-9140-4de8-8c1c-9f919acc25d0",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/64ea0c61-25f7-42af-928f-83f8915692e5/frankiesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/64ea0c61-25f7-42af-928f-83f8915692e5/frankiesaad/manifest.json",
    name: "Frankie",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/frankie.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "714aa13d-2fec-42aa-82af-ef60ac6b03ea",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/74f59ea4-f07b-4d10-88d1-ca174adac3f3/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/74f59ea4-f07b-4d10-88d1-ca174adac3f3/original/manifest.json",
    name: "Pia",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Female",
    accent: "australian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Pia.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "5fae5e95-9e34-4b46-9b06-44225cc403ec",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f669de94-fea6-4af3-a8ea-193b75bb142b/darrellsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f669de94-fea6-4af3-a8ea-193b75bb142b/darrellsaad/manifest.json",
    name: "Darrell",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/AvIq3LAwQyaX4zKxfi.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "63aed3c3-a9ac-41eb-a810-d6e9634802d9",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f6c4ed76-1b55-4cd9-8896-31f7535f6cdb/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f6c4ed76-1b55-4cd9-8896-31f7535f6cdb/original/manifest.json",
    name: "Aaliyah",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Aaliyah.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "8626d4ec-074f-4ac5-bddf-07d569248dd8",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/0b423fde-02e2-4bea-91ce-c932162e4428/samanthasaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/0b423fde-02e2-4bea-91ce-c932162e4428/samanthasaad/manifest.json",
    name: "Samantha",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/GhNXWVGZW8TY4oKuij.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "6af5403e-f7bf-49cc-97b2-a02a213ce7b9",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/8ceaa707-4141-46c0-a610-4a2014d7ea97/audreysaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/8ceaa707-4141-46c0-a610-4a2014d7ea97/audreysaad/manifest.json",
    name: "Audrey",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/audrey.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "0e8385b1-3dcf-463d-b8c6-a9ba35c3068c",
    provider: "playht",
    providerId: "s3://mockingbird-prod/agent_47_carmelo_pampillonio_58e796e1-0b87-4f3e-8b36-7def6d65ce66/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/agent_47_carmelo_pampillonio_58e796e1-0b87-4f3e-8b36-7def6d65ce66/voices/speaker/manifest.json",
    name: "Sarge",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/editor-samples/sarge.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "02d35f44-cf4c-4299-bcbe-eb155cfc434d",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/a540a448-a9ca-446c-9538-d1bae6c506f1/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/a540a448-a9ca-446c-9538-d1bae6c506f1/original/manifest.json",
    name: "Mason",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Mason.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "9c618c78-c304-48a0-9b28-f5a0d1a4a8cb",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/7b97b543-7877-41b6-86ee-aa1e0b6c110e/dicksaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/7b97b543-7877-41b6-86ee-aa1e0b6c110e/dicksaad/manifest.json",
    name: "Dick",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/ydgska4ZaIJ3bmRy0t.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "ccbd8792-5e77-41e2-8c8d-e2acd5742a71",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/81111918-bf87-484b-9ffa-7be344c62223/danielsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/81111918-bf87-484b-9ffa-7be344c62223/danielsaad/manifest.json",
    name: "Daniel",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "canadian",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/3VYZRRbbNenuQDn59x.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "6721fc85-bedd-4637-b77c-5814bfb33b2a",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/9b559f3c-22e7-4b10-8158-3e11b333751e/dudleysaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/9b559f3c-22e7-4b10-8158-3e11b333751e/dudleysaad/manifest.json",
    name: "Dudley",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/TzWYqWMTlbSF11bYhd.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "acc8a873-82bf-4ff9-a3df-e598366d0418",
    provider: "playht",
    providerId: "s3://mockingbird-prod/ayla_vo_training_e6751ca5-e47c-4c4b-ad05-d3a194417600/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/ayla_vo_training_e6751ca5-e47c-4c4b-ad05-d3a194417600/voices/speaker/manifest.json",
    name: "Ayla (Training)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/ayla_vo_training.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "62c9c01b-3a46-4494-bc36-1cceb54f554f",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/c00f7eb4-fbbd-4a7d-a4c5-ff57e92faf99/williamsaad3/manifest.json",
    slug: "s3://voice-cloning-zero-shot/c00f7eb4-fbbd-4a7d-a4c5-ff57e92faf99/williamsaad3/manifest.json",
    name: "William",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/william.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "08ee3bcb-1a39-486b-b4bf-820738541e31",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f8af54e1-1534-4f68-ad8a-260cc132f820/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f8af54e1-1534-4f68-ad8a-260cc132f820/original/manifest.json",
    name: "Nigel",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Male",
    accent: "australian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Nigel.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "384b1b6a-b884-49aa-a1eb-db6b5ad0509a",
    provider: "playht",
    providerId: "s3://peregrine-voices/donna_parrot_saad/manifest.json",
    slug: "s3://peregrine-voices/donna_parrot_saad/manifest.json",
    name: "Donna (Narrative)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/donna+narrative.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "234e0ba4-4c19-46e8-bce0-721ee46e93c5",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/2a7ddfc5-d16a-423a-9441-5b13290998b8/novasaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/2a7ddfc5-d16a-423a-9441-5b13290998b8/novasaad/manifest.json",
    name: "Nova",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/H2u1NBpnzUYJD0pgDF.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "b3621229-299d-4629-847d-0a573242dd2f",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f741f871-63ad-4207-8278-907aec4e9e50/aylameditationsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f741f871-63ad-4207-8278-907aec4e9e50/aylameditationsaad/manifest.json",
    name: "Ayla (Meditation)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/pigeon/XckNBMI3SLc4MqTMcD_0.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "386190be-53d3-492c-b299-2ec6fb24b1f9",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/509221d8-9e2d-486c-9b3c-97e52d86e63d/arthuradvertisingsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/509221d8-9e2d-486c-9b3c-97e52d86e63d/arthuradvertisingsaad/manifest.json",
    name: "Arthur (Advertising)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/z05Mb3TymF75TcQnyV.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "1d5fb8bb-eebf-4d67-84ce-d42019856097",
    provider: "playht",
    providerId: "s3://mockingbird-prod/ayla_vo_meditation_d11dd9da-b5f1-4709-95a6-e6d5dc77614a/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/ayla_vo_meditation_d11dd9da-b5f1-4709-95a6-e6d5dc77614a/voices/speaker/manifest.json",
    name: "Ayla (Meditation)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/ayla_vo_meditation.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3a980def-318e-4482-b378-36a2874d609c",
    provider: "playht",
    providerId: "s3://peregrine-voices/oliver_narrative2_parrot_saad/manifest.json",
    slug: "s3://peregrine-voices/oliver_narrative2_parrot_saad/manifest.json",
    name: "Oliver (Narrative)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/oliver+narrative.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "9e02f511-7beb-42d1-8f35-237ca7db46c0",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f72dbbd1-1f99-4f5b-b0d0-de06f8854d05/earlesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f72dbbd1-1f99-4f5b-b0d0-de06f8854d05/earlesaad/manifest.json",
    name: "Earle",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/emmmDreIk41pZPmTzz.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "92e25163-0fcf-43f2-88e5-82d656d71b62",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f3600d9d-39ab-4494-b6ac-c6f5d122184d/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f3600d9d-39ab-4494-b6ac-c6f5d122184d/original/manifest.json",
    name: "Teddy",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Male",
    accent: "australian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Teddy.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "b43c6ddb-efc3-4e5a-aad3-7c664b7e5ef2",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/0d6641bd-b0c7-440b-bd94-981dbb846c2e/enhanced/manifest.json",
    slug: "s3://voice-cloning-zero-shot/0d6641bd-b0c7-440b-bd94-981dbb846c2e/enhanced/manifest.json",
    name: "Taylor",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: false,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3a67d122-7728-4ceb-a30c-94b71233d6a8",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/9b390a6b-7048-4dc4-a714-76ea2c3a6eab/oliviatrainingsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/9b390a6b-7048-4dc4-a714-76ea2c3a6eab/oliviatrainingsaad/manifest.json",
    name: "Olivia (Training)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/olivia+(training).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "9ff3fb0f-e561-42ab-a7ed-2674ce42a461",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/0a11e4f0-1656-48c9-bc4c-affcd718c414/madelynsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/0a11e4f0-1656-48c9-bc4c-affcd718c414/madelynsaad/manifest.json",
    name: "Madelyn",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/hFF3qzi7zRuub0K9T7.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "11fae5b8-7979-4cc1-837b-9c8c61113385",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/8ca8c44d-fb2b-4269-abca-4e6748a4a61e/arthursaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/8ca8c44d-fb2b-4269-abca-4e6748a4a61e/arthursaad/manifest.json",
    name: "Arthur",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/B7H8TzHvhzdEd9XG3y.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3f9e3c95-f16f-459f-a1d9-14018258da78",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/7bad42d5-52be-4687-9a07-7891f31daa6b/logansaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/7bad42d5-52be-4687-9a07-7891f31daa6b/logansaad/manifest.json",
    name: "Logan",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/SM3MSIXjfkCdz9qBSV.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "c377c339-4cd8-44d3-ab74-a2076a110d92",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/820da3d2-3a3b-42e7-844d-e68db835a206/sarah/manifest.json",
    slug: "s3://voice-cloning-zero-shot/820da3d2-3a3b-42e7-844d-e68db835a206/sarah/manifest.json",
    name: "Sarah",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Female",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/sarah.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "35127101-0145-42db-8686-b775ca6b42de",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/9a5deeda-3025-49c5-831a-ac98f86f2a37/aprilsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/9a5deeda-3025-49c5-831a-ac98f86f2a37/aprilsaad/manifest.json",
    name: "April",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/nbrn3tdfuXIxGn8TIl.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "acdf3ad6-c103-4749-bf27-532d21ed0179",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/d0d2afef-93f5-43c3-85c7-e6d824da6dad/olivertrainingsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/d0d2afef-93f5-43c3-85c7-e6d824da6dad/olivertrainingsaad/manifest.json",
    name: "Oliver (Training)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/ygyIH57v3KVbuA7Yjb.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "1f576ad6-7f04-4c00-8389-67d456c26d94",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/7c38b588-14e8-42b9-bacd-e03d1d673c3c/nicole/manifest.json",
    slug: "s3://voice-cloning-zero-shot/7c38b588-14e8-42b9-bacd-e03d1d673c3c/nicole/manifest.json",
    name: "Nicole",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/nicole.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "abe988c9-b1bd-444e-b176-61e00629b9e9",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/34eaa933-62cb-4e32-adb8-c1723ef85097/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/34eaa933-62cb-4e32-adb8-c1723ef85097/original/manifest.json",
    name: "Amelia",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Female",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Amelia.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "ba868fd2-580a-4222-b96a-438798ab56e3",
    provider: "playht",
    providerId: "s3://peregrine-voices/donna_meditation_saad/manifest.json",
    slug: "donna",
    name: "Donna (Meditation)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/donna+meditation.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "784948f2-06c8-40ad-a271-89a11e953d5c",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/1d26f4fe-1d08-4cfe-a7c1-d28e4e913ff9/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/1d26f4fe-1d08-4cfe-a7c1-d28e4e913ff9/original/manifest.json",
    name: "Baptiste",
    language: "English (FR)",
    languageCode: "en-FR",
    gender: "Male",
    accent: "french",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Baptiste.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "39c52fe7-2140-4b98-ae12-8fbab6c1bf95",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/e46b4027-b38d-4d24-b292-38fbca2be0ef/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/e46b4027-b38d-4d24-b292-38fbca2be0ef/original/manifest.json",
    name: "Atlas",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Atlas.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "26a19698-05f5-4781-bf7e-6aba7b063dc7",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/ff414883-0e32-4a92-a688-d7875922120d/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/ff414883-0e32-4a92-a688-d7875922120d/original/manifest.json",
    name: "Autumn",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Autumn.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "262c2421-1a6c-468b-a690-4918705aaaa0",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/32ae7ca0-634e-4fab-af74-0ec7c663e9da/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/32ae7ca0-634e-4fab-af74-0ec7c663e9da/original/manifest.json",
    name: "Leroy",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Leroy.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "b07317c1-f864-44b1-a149-777fcb1db4a1",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/743575eb-efdc-4c10-b185-a5018148822f/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/743575eb-efdc-4c10-b185-a5018148822f/original/manifest.json",
    name: "Calvin",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Calvin.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "811152c8-a1ea-4451-8de1-f6c3ee44bcf9",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/38a41ac2-f574-421c-adb9-ce1bcb6f4a84/arthurmeditationsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/38a41ac2-f574-421c-adb9-ce1bcb6f4a84/arthurmeditationsaad/manifest.json",
    name: "Arthur (Meditation)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/pigeon/1kdv17L92N1otdit39_0.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "aa86870e-1b2c-4198-b6f2-3d7eb6d38ba9",
    provider: "playht",
    providerId: "s3://mockingbird-prod/charlotte_vo_narrative_9290be17-ccea-4700-a7fd-a8fe5c49fb20/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/charlotte_vo_narrative_9290be17-ccea-4700-a7fd-a8fe5c49fb20/voices/speaker/manifest.json",
    name: "Charlotte (Narrative)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/charlotte_vo_narrative.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "1edefa8e-ad84-4084-b26f-347b4149e7ea",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/688d0200-7415-42b4-8726-e2f5693aaac8/williamnarrativesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/688d0200-7415-42b4-8726-e2f5693aaac8/williamnarrativesaad/manifest.json",
    name: "William (Narrative)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/BRbOe9n93ab1YI2Hot.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "949fe36e-06d5-4143-8ad9-6ab407ab0468",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/473c81b1-93ea-4662-9e63-7d65392e5f9b/madisonsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/473c81b1-93ea-4662-9e63-7d65392e5f9b/madisonsaad/manifest.json",
    name: "Madison",
    language: "English (IE)",
    languageCode: "en-IE",
    gender: "Female",
    accent: "irish",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/madison.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "cc2ae14d-2e77-440a-9542-7df49e62c016",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/801a663f-efd0-4254-98d0-5c175514c3e8/jennifer/manifest.json",
    slug: "jennifer",
    name: "Jennifer",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/jennifer.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "7916efa9-5f40-4c14-8a34-cedd953df07e",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/f2863f63-5334-4f65-9d30-438feb79c2ec/arianasaad2/manifest.json",
    slug: "s3://voice-cloning-zero-shot/f2863f63-5334-4f65-9d30-438feb79c2ec/arianasaad2/manifest.json",
    name: "Ariana",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/KMDCHQcypWHMSSpQUP.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "e9987f16-097d-408b-bdbc-84249e0fee5a",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/97580643-b568-4198-aaa4-3e07e4a06c47/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/97580643-b568-4198-aaa4-3e07e4a06c47/original/manifest.json",
    name: "Indigo",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Female",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Indigo.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "fa086c60-d399-4276-9706-bfaff7636da0",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/d712cad5-85db-44c6-8ee0-8f4361ed537b/eleanorsaad2/manifest.json",
    slug: "s3://voice-cloning-zero-shot/d712cad5-85db-44c6-8ee0-8f4361ed537b/eleanorsaad2/manifest.json",
    name: "Eleanor",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/eleanor-2.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3a03b5e8-2ba3-4aa7-87f1-cdce5820dcbd",
    provider: "playht",
    providerId: "s3://mockingbird-prod/susan_vo_training_46ffcc60-d630-42f6-acfe-4affd003ae7a/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/susan_vo_training_46ffcc60-d630-42f6-acfe-4affd003ae7a/voices/speaker/manifest.json",
    name: "Susan (Training)",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/susan_vo_training.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "2d9e96a1-cf0c-4923-a190-5d4c5f1a4eac",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/a5cc7dd9-069c-4fe8-9ae7-0c4bae4779c5/micahsaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/a5cc7dd9-069c-4fe8-9ae7-0c4bae4779c5/micahsaad/manifest.json",
    name: "Micah",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/micah.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "cbd212b8-b185-44c5-9819-a02ebe8cd0cd",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/d99d35e6-e625-4fa4-925a-d65172d358e1/adriansaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/d99d35e6-e625-4fa4-925a-d65172d358e1/adriansaad/manifest.json",
    name: "Adrian",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/0hjUhtQD61YXtW39OP.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "80a02ead-83e4-48c6-8260-e22eaad10f15",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/0c4c229f-7f99-4ed9-b904-223c701672b9/phoebesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/0c4c229f-7f99-4ed9-b904-223c701672b9/phoebesaad/manifest.json",
    name: "Phoebe",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "british",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/Phoebe.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "3c8649ad-2b2d-4e83-a2e8-25919ee95606",
    provider: "playht",
    providerId: "s3://mockingbird-prod/arthur_vo_meditatoin_211f702d-b185-4115-b8b4-801f8130a38d/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/arthur_vo_meditatoin_211f702d-b185-4115-b8b4-801f8130a38d/voices/speaker/manifest.json",
    name: "Arthur (Meditation)",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/arthur_vo_meditation.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "68c2a2b0-419a-406d-bcb9-40c288c4d740",
    provider: "playht",
    providerId: "s3://peregrine-voices/evelyn 2 saad parrot/manifest.json",
    slug: "s3://peregrine-voices/evelyn 2 saad parrot/manifest.json",
    name: "Evelyn",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Female",
    accent: "american",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/evelyn.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "443a9a1a-7b78-4d6f-a238-cb2b52f5b51c",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/fdb74aec-ede9-45f8-ad87-71cb45f01816/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/fdb74aec-ede9-45f8-ad87-71cb45f01816/original/manifest.json",
    name: "Carmen",
    language: "English (MX)",
    languageCode: "en-MX",
    gender: "Female",
    accent: "mexican",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Carmen.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "dba749c8-6e93-44b4-97a9-f35b6c87bf7b",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/ac9e2984-c7bb-44c8-8b6b-5c10728ad5cf/original/manifest.json",
    slug: "s3://voice-cloning-zero-shot/ac9e2984-c7bb-44c8-8b6b-5c10728ad5cf/original/manifest.json",
    name: "Archie",
    language: "English (AU)",
    languageCode: "en-AU",
    gender: "Male",
    accent: "australian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/gargamel/Archie.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "fc579da9-4b01-4629-8f4e-410c977dd5f5",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/a59cb96d-bba8-4e24-81f2-e60b888a0275/charlottenarrativesaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/a59cb96d-bba8-4e24-81f2-e60b888a0275/charlottenarrativesaad/manifest.json",
    name: "Charlotte (Narrative)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/charlotte+(narrative).wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "5a0c8e6d-5c26-4ec5-af1e-4ba642983b2c",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/0b5b2e4b-5103-425e-8aa0-510dd35226e2/mark/manifest.json",
    slug: "s3://voice-cloning-zero-shot/0b5b2e4b-5103-425e-8aa0-510dd35226e2/mark/manifest.json",
    name: "Mark",
    language: "English (GB)",
    languageCode: "en-GB",
    gender: "Male",
    accent: "british",
    previewUrl: "https://peregrine-samples.s3.amazonaws.com/parrot-samples/mark.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "a265669e-5dc6-4e8a-b956-cf1b425862d0",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/b3def996-302e-486f-a234-172fa0279f0e/anthonysaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/b3def996-302e-486f-a234-172fa0279f0e/anthonysaad/manifest.json",
    name: "Anthony",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/O7gXIqEZOQiwA1GStD.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "a0628847-1ecd-46db-a554-23207ce8818b",
    provider: "playht",
    providerId: "s3://mockingbird-prod/olivia_vo_training_4376204f-a411-4e5d-a5c0-ce6cc3908052/voices/speaker/manifest.json",
    slug: "s3://mockingbird-prod/olivia_vo_training_4376204f-a411-4e5d-a5c0-ce6cc3908052/voices/speaker/manifest.json",
    name: "Olivia (Training)",
    language: "English (CA)",
    languageCode: "en-CA",
    gender: "Female",
    accent: "canadian",
    previewUrl: "https://peregrine-samples.s3.us-east-1.amazonaws.com/editor-samples/olivia_vo_training.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "8d466bd3-f52e-47aa-b870-37e8faac2c30",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/a72a5d4c-d729-4045-8bc8-1786043a4537/cartersaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/a72a5d4c-d729-4045-8bc8-1786043a4537/cartersaad/manifest.json",
    name: "Carter",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://parrot-samples.s3.amazonaws.com/kettle/carter.wav",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "5aca2cfc-f556-4cd7-984b-70d15fef1b03",
    provider: "playht",
    providerId: "s3://voice-cloning-zero-shot/4c627545-b9c0-4791-ae8e-f48f5475247c/bryansaad/manifest.json",
    slug: "s3://voice-cloning-zero-shot/4c627545-b9c0-4791-ae8e-f48f5475247c/bryansaad/manifest.json",
    name: "Bryan",
    language: "English (US)",
    languageCode: "en-US",
    gender: "Male",
    accent: "american",
    previewUrl: "https://peregrine-results.s3.amazonaws.com/euekNIyvT2kURh1CJ3.mp3",
    createdAt: "2024-05-23T12:35:31.625Z",
    updatedAt: "2024-05-23T12:35:31.625Z",
    isPublic: true,
    isDeleted: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },

  {
    id: "560d3794-9be3-4e2c-a3a3-b478f2bf7b08",
    provider: "rime-ai",
    providerId: "aurora",
    slug: "aurora",
    name: "aurora",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Aurora.mp3",
    gender: "Female",
  },
  {
    id: "309d81ab-c2fd-4826-bc18-c0b72f06e2e2",
    provider: "rime-ai",
    providerId: "casey",
    slug: "casey",
    name: "casey",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Casey.mp3",
    gender: "Female",
  },
  {
    id: "db51ce67-f65a-480e-81f6-549cf9aebaf9",
    provider: "rime-ai",
    providerId: "cedric",
    slug: "cedric",
    name: "cedric",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Cedric.mp3",
    gender: "Male",
  },
  {
    id: "5e88e615-fad4-4b25-92d6-646f2728ca42",
    provider: "rime-ai",
    providerId: "charlotte",
    slug: "charlotte",
    name: "charlotte",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Charlotte.mp3",
    gender: "Female",
  },
  {
    id: "a928f7e8-1c29-414b-980b-c73d98644145",
    provider: "rime-ai",
    providerId: "chester",
    slug: "chester",
    name: "chester",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Chester.mp3",
    gender: "Male",
  },
  {
    id: "176ae196-8dcf-4e06-a556-79c1228576a6",
    provider: "rime-ai",
    providerId: "clarence",
    slug: "clarence",
    name: "clarence",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Clarence.mp3",
    gender: "Male",
  },
  {
    id: "69fda145-6e6d-4f45-8b2d-14b6f6847887",
    provider: "rime-ai",
    providerId: "clemintine",
    slug: "clemintine",
    name: "clemintine",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Clemintine.mp3",
    gender: "Female",
  },
  {
    id: "8632beb6-cc31-4f5d-b28b-728bc776a571",
    provider: "rime-ai",
    providerId: "colette",
    slug: "colette",
    name: "colette",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Colette.mp3",
    gender: "Female",
  },
  {
    id: "ac385521-6ac7-4027-a6c5-47ae5f9abb7c",
    provider: "rime-ai",
    providerId: "darien",
    slug: "darien",
    name: "darien",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Darien.mp3",
    gender: "Male",
  },
  {
    id: "f6db0400-f0ce-40a2-915d-d54651e3427f",
    provider: "rime-ai",
    providerId: "kyle",
    slug: "kyle",
    name: "kyle",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Kyle.mp3",
    gender: "Male",
  },
  {
    id: "44ab7dbc-56fd-4571-ab06-578875f2e570",
    provider: "rime-ai",
    providerId: "lamont",
    slug: "lamont",
    name: "lamont",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/LaMont.mp3",
    gender: "Male",
  },
  {
    id: "d06fa8fb-90db-4f9e-ab04-e00f1b25b0d1",
    provider: "rime-ai",
    providerId: "leroy",
    slug: "leroy",
    name: "leroy",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Leroy.mp3",
    gender: "Male",
  },
  {
    id: "7b486bc0-f951-41ee-9bb6-1cf485ff1f34",
    provider: "rime-ai",
    providerId: "gerald",
    slug: "gerald",
    name: "gerald",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Gerald.mp3",
    gender: "Male",
  },
  {
    id: "21312a4d-f659-499c-975c-8435e63372bf",
    provider: "rime-ai",
    providerId: "hank",
    slug: "hank",
    name: "hank",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Hank.mp3",
    gender: "Male",
  },
  {
    id: "ec4b0c7b-7a13-480e-8abd-a37227ecb57a",
    provider: "rime-ai",
    providerId: "helen",
    slug: "helen",
    name: "helen",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Helen.mp3",
    gender: "Female",
  },
  {
    id: "b81403ac-650f-41d2-90ac-a6dd263005b8",
    provider: "rime-ai",
    providerId: "grove",
    slug: "grove",
    name: "grove",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/grove.mp3",
    gender: "Female",
  },
  {
    id: "343109f1-ef7d-4c40-bcfb-558b0ffc5478",
    provider: "rime-ai",
    providerId: "rainforest",
    slug: "rainforest",
    name: "rainforest",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/rainforest.mp3",
    gender: "Female",
  },
  {
    id: "702901d8-da3d-4fff-8970-355d3c5a0c4c",
    provider: "rime-ai",
    providerId: "tj",
    slug: "tj",
    name: "tj",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/TJ.mp3",
    gender: "Male",
  },
  {
    id: "a85767c8-e163-40d5-9f17-1ed598fba598",
    provider: "rime-ai",
    providerId: "cove",
    slug: "cove",
    name: "cove",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cove.mp3",
    gender: "Female",
  },
  {
    id: "8f767635-cc66-42e3-8499-a9e1b860e34f",
    provider: "rime-ai",
    providerId: "lagoon",
    slug: "lagoon",
    name: "lagoon",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lagoon.mp3",
    gender: "Female",
  },
  {
    id: "6dc56a66-979a-42d0-af82-c650ed2fe7b2",
    provider: "rime-ai",
    providerId: "tundra",
    slug: "tundra",
    name: "tundra",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/tundra.mp3",
    gender: "Female",
  },
  {
    id: "6a07fb2e-6c96-4642-80f6-6fda33a0ea35",
    provider: "rime-ai",
    providerId: "steppe",
    slug: "steppe",
    name: "steppe",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/steppe.mp3",
    gender: "Female",
  },
  {
    id: "73f17006-3789-4704-b78b-3f54a3cb95c1",
    provider: "rime-ai",
    providerId: "mesa",
    slug: "mesa",
    name: "mesa",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/mesa.mp3",
    gender: "Female",
  },
  {
    id: "721b47cb-40c8-4982-869c-dc1539a65b00",
    provider: "rime-ai",
    providerId: "moraine",
    slug: "moraine",
    name: "moraine",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/moraine.mp3",
    gender: "Female",
  },
  {
    id: "5d8dd871-70de-41bc-863f-95a391ff010a",
    provider: "rime-ai",
    providerId: "wildflower",
    slug: "wildflower",
    name: "wildflower",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/wildflower.mp3",
    gender: "Female",
  },
  {
    id: "838b6560-3ac5-4102-afc1-b743a9e019e7",
    provider: "rime-ai",
    providerId: "peak",
    slug: "peak",
    name: "peak",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/peak.mp3",
    gender: "Female",
  },
  {
    id: "aa8f4f46-1018-4532-9dcf-4363d7df717e",
    provider: "rime-ai",
    providerId: "boulder",
    slug: "boulder",
    name: "boulder",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/boulder.mp3",
    gender: "Non-binary",
  },
  {
    id: "2ec35d35-1679-4887-8838-a75af50c29e6",
    provider: "rime-ai",
    providerId: "abbie",
    slug: "abbie",
    name: "abbie",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Abbie.mp3",
    gender: "Female",
  },
  {
    id: "4e33246d-1834-4c28-ae26-5a9b02e13f9b",
    provider: "rime-ai",
    providerId: "rohan",
    slug: "rohan",
    name: "rohan",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Rohan.mp3",
    gender: "Male",
  },
  {
    id: "c711f62b-2643-4d6c-ba95-e53186ad99cf",
    provider: "rime-ai",
    providerId: "alexa",
    slug: "alexa",
    name: "alexa",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Alexa.mp3",
    gender: "Female",
  },
  {
    id: "ccf351f5-21b0-4cfb-83ff-f58d39eade1d",
    provider: "rime-ai",
    providerId: "allison",
    slug: "allison",
    name: "allison",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Allison.mp3",
    gender: "Female",
  },
  {
    id: "761a1989-3468-4e44-914e-08358163c8be",
    provider: "rime-ai",
    providerId: "ally",
    slug: "ally",
    name: "ally",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Ally.mp3",
    gender: "Female",
  },
  {
    id: "d3ee0b7d-d043-4478-866f-bea93a8ecb65",
    provider: "rime-ai",
    providerId: "alona",
    slug: "alona",
    name: "alona",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Alona.mp3",
    gender: "Female",
  },
  {
    id: "c1b9daeb-9290-4002-9f7e-061cf0f71670",
    provider: "rime-ai",
    providerId: "alonzo",
    slug: "alonzo",
    name: "alonzo",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Alonzo.mp3",
    gender: "Male",
  },
  {
    id: "3b639545-06fd-4f22-8ead-174f47dfb786",
    provider: "rime-ai",
    providerId: "alpine",
    slug: "alpine",
    name: "alpine",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/alpine.mp3",
    gender: "Male",
  },
  {
    id: "45dc680a-3215-4400-8d80-ce1b8d031cab",
    provider: "rime-ai",
    providerId: "amari",
    slug: "amari",
    name: "amari",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Amari.mp3",
    gender: "Female",
  },
  {
    id: "0d8358f2-aa34-4df2-a148-4bb107c61ae3",
    provider: "rime-ai",
    providerId: "amber",
    slug: "amber",
    name: "amber",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Amber.mp3",
    gender: "Female",
  },
  {
    id: "5acd573f-f807-4858-b9f7-271921a017bd",
    provider: "rime-ai",
    providerId: "ana",
    slug: "ana",
    name: "ana",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Ana.mp3",
    gender: "Female",
  },
  {
    id: "d03dfa36-fa21-4d10-8a37-5c94ed4bdbfb",
    provider: "rime-ai",
    providerId: "andre",
    slug: "andre",
    name: "andre",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Andre.mp3",
    gender: "Male",
  },
  {
    id: "fae8c90f-f184-4c0e-86c2-d2f959cb2e8a",
    provider: "rime-ai",
    providerId: "antoine",
    slug: "antoine",
    name: "antoine",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Antoine.mp3",
    gender: "Male",
  },
  {
    id: "a4e2aa28-1600-47d3-a192-a96e96f14d8c",
    provider: "rime-ai",
    providerId: "antonia",
    slug: "antonia",
    name: "antonia",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Antonia.mp3",
    gender: "Female",
  },
  {
    id: "c10f4004-c80f-4a06-adbe-8f077c5f8881",
    provider: "rime-ai",
    providerId: "archibald",
    slug: "archibald",
    name: "archibald",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Archibald.mp3",
    gender: "Male",
  },
  {
    id: "82595a09-a6e9-4675-86f4-5714e1db84d8",
    provider: "rime-ai",
    providerId: "armon",
    slug: "armon",
    name: "armon",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Armon.mp3",
    gender: "Male",
  },
  {
    id: "ddf0b34e-56ed-47a8-a265-c927d99f2963",
    provider: "rime-ai",
    providerId: "asher",
    slug: "asher",
    name: "asher",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Asher.mp3",
    gender: "Male",
  },
  {
    id: "ccb81ff2-154b-4738-87ee-97c4cbe61c48",
    provider: "rime-ai",
    providerId: "athena",
    slug: "athena",
    name: "athena",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Athena.mp3",
    gender: "Female",
    language: "English (GB)",
    languageCode: "en-GB",
  },
  {
    id: "4869cd61-604b-4a3d-ad23-75b6b2db56f9",
    provider: "rime-ai",
    providerId: "azura",
    slug: "azura",
    name: "azura",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Azura.mp3",
    gender: "Female",
  },
  {
    id: "4ced4136-5203-434c-999c-f649ea3316f1",
    provider: "rime-ai",
    providerId: "bayou",
    slug: "bayou",
    name: "bayou",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/bayou.mp3",
    gender: "Male",
  },
  {
    id: "04ab9159-363c-4942-94dd-42401ff94f09",
    provider: "rime-ai",
    providerId: "benjamin",
    slug: "benjamin",
    name: "benjamin",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Benjamin.mp3",
    gender: "Male",
  },
  {
    id: "daa66a5a-d2e3-46e7-81e7-a384eda7de93",
    provider: "rime-ai",
    providerId: "benji",
    slug: "benji",
    name: "benji",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Benji.mp3",
    gender: "Male",
  },
  {
    id: "8d579011-7180-45c7-94a5-661d144ac6b7",
    provider: "rime-ai",
    providerId: "brenda",
    slug: "brenda",
    name: "brenda",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Brenda.mp3",
    gender: "Female",
  },
  {
    id: "c9ea7678-068a-40a0-b47a-8684b3f0199e",
    provider: "rime-ai",
    providerId: "brent",
    slug: "brent",
    name: "brent",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Brent.mp3",
    gender: "Male",
  },
  {
    id: "951a12a1-a8a3-4417-9af9-71c7c20f1caa",
    provider: "rime-ai",
    providerId: "brittany",
    slug: "brittany",
    name: "brittany",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Brittany.mp3",
    gender: "Female",
  },
  {
    id: "62e7bc8f-97a5-4873-b381-34a51e172ab9",
    provider: "rime-ai",
    providerId: "brook",
    slug: "brook",
    name: "brook",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/brook.mp3",
    gender: "Male",
  },
  {
    id: "2a204454-3704-4121-8c71-a493e5216547",
    provider: "rime-ai",
    providerId: "buford",
    slug: "buford",
    name: "buford",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Buford.mp3",
    gender: "Male",
  },
  {
    id: "078aacba-46e9-46ab-b049-35edafb332da",
    provider: "rime-ai",
    providerId: "caleb",
    slug: "caleb",
    name: "caleb",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Caleb.mp3",
    gender: "Male",
  },
  {
    id: "467d4f8a-fb6c-40b7-96eb-a3bddb53b945",
    provider: "rime-ai",
    providerId: "camila",
    slug: "camila",
    name: "camila",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Camila.mp3",
    gender: "Female",
  },
  {
    id: "f24d8886-248a-4f25-ab0e-c91a2de476ae",
    provider: "rime-ai",
    providerId: "carmen",
    slug: "carmen",
    name: "carmen",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Carmen.mp3",
    gender: "Female",
  },
  {
    id: "850e87f4-96a0-44a2-9e39-f33502d6b784",
    provider: "rime-ai",
    providerId: "carol",
    slug: "carol",
    name: "carol",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Carol.mp3",
    gender: "Female",
  },
  {
    id: "2f60127a-7898-4de9-969a-63d6d000daf4",
    provider: "rime-ai",
    providerId: "clarice",
    slug: "clarice",
    name: "clarice",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Clarice.mp3",
    gender: "Female",
  },
  {
    id: "b6324ef2-702b-4475-91a6-8d7dcc588f85",
    provider: "rime-ai",
    providerId: "clarissa",
    slug: "clarissa",
    name: "clarissa",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Clarissa.mp3",
    gender: "Female",
  },
  {
    id: "7249383a-0286-4a29-95fb-44aff9b9a7f6",
    provider: "rime-ai",
    providerId: "colin",
    slug: "colin",
    name: "colin",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Colin.mp3",
    gender: "Male",
  },
  {
    id: "54a16013-bfc1-4507-95cc-e6933f77fe5b",
    provider: "rime-ai",
    providerId: "consuela",
    slug: "consuela",
    name: "consuela",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Consuela.mp3",
    gender: "Female",
  },
  {
    id: "ce1298fd-8911-4a1d-a13f-646eed6d989c",
    provider: "rime-ai",
    providerId: "cornelius",
    slug: "cornelius",
    name: "cornelius",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Cornelius.mp3",
    gender: "Male",
  },
  {
    id: "96437108-c3da-4c8c-a72a-923bb1ef7684",
    provider: "rime-ai",
    providerId: "courtney",
    slug: "courtney",
    name: "courtney",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Courtney.mp3",
    gender: "Female",
  },
  {
    id: "04cd83f1-105d-42f7-a19a-f79003296829",
    provider: "rime-ai",
    providerId: "creek",
    slug: "creek",
    name: "creek",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/creek.mp3",
    gender: "Male",
  },
  {
    id: "83bc14e5-bc01-43b1-8163-346d3700a497",
    provider: "rime-ai",
    providerId: "curtis",
    slug: "curtis",
    name: "curtis",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Curtis.mp3",
    gender: "Male",
  },
  {
    id: "3f64b8e5-d311-48e3-b401-3a711a138378",
    provider: "rime-ai",
    providerId: "daphne",
    slug: "daphne",
    name: "daphne",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Daphne.mp3",
    gender: "Female",
  },
  {
    id: "90d6f409-0623-4a36-9181-5bfdd63d9e58",
    provider: "rime-ai",
    providerId: "darlene",
    slug: "darlene",
    name: "darlene",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Darlene.mp3",
    gender: "Female",
  },
  {
    id: "76b464af-a4c6-43e4-84fe-d951854a45d0",
    provider: "rime-ai",
    providerId: "darren",
    slug: "darren",
    name: "darren",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Darren.mp3",
    gender: "Male",
  },
  {
    id: "dfd11efd-c3cd-4eda-9265-1998432aa7d6",
    provider: "rime-ai",
    providerId: "derek",
    slug: "derek",
    name: "derek",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Derek.mp3",
    gender: "Male",
  },
  {
    id: "a2c1a8fa-ddf3-429b-9654-a705c7fb1ddb",
    provider: "rime-ai",
    providerId: "desmond",
    slug: "desmond",
    name: "desmond",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Desmond.mp3",
    gender: "Male",
  },
  {
    id: "e731dcfb-3f6c-4373-aade-cd7e2f41ce80",
    provider: "rime-ai",
    providerId: "dixie",
    slug: "dixie",
    name: "dixie",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Dixie.mp3",
    gender: "Female",
  },
  {
    id: "115663d9-66fa-48ea-8e03-daa783b8e341",
    provider: "rime-ai",
    providerId: "elena",
    slug: "elena",
    name: "elena",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Elena.mp3",
    gender: "Female",
  },
  {
    id: "dd65c8f5-731c-4dde-8ea0-5bcda2d507ef",
    provider: "rime-ai",
    providerId: "elliot",
    slug: "elliot",
    name: "elliot",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Elliot.mp3",
    gender: "Male",
  },
  {
    id: "aeb0d444-4f84-45fb-80d0-894c93e9c90f",
    provider: "rime-ai",
    providerId: "elouise",
    slug: "elouise",
    name: "elouise",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Elouise.mp3",
    gender: "Female",
  },
  {
    id: "91a68297-c876-4eec-9c50-fc2fbbfb2443",
    provider: "rime-ai",
    providerId: "ember",
    slug: "ember",
    name: "ember",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Ember.mp3",
    gender: "Female",
  },
  {
    id: "8831ba35-9120-486b-b02a-1f5a8dff2b2d",
    provider: "rime-ai",
    providerId: "ernestine",
    slug: "ernestine",
    name: "ernestine",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Ernestine.mp3",
    gender: "Female",
  },
  {
    id: "f9175e9e-4d8c-4119-b270-2fb306d13b6a",
    provider: "rime-ai",
    providerId: "eva",
    slug: "eva",
    name: "eva",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Eva.mp3",
    gender: "Female",
  },
  {
    id: "81f411eb-0c24-4f6c-aea8-f1ec8bf79241",
    provider: "rime-ai",
    providerId: "flower",
    slug: "flower",
    name: "flower",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/flower.mp3",
    gender: "Male",
  },
  {
    id: "61e03701-c8d4-4cee-a2a7-544f4c11fadb",
    provider: "rime-ai",
    providerId: "frances",
    slug: "frances",
    name: "frances",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Frances.mp3",
    gender: "Female",
  },
  {
    id: "4296b05f-4b35-4be4-bb8b-5901744f1f1a",
    provider: "rime-ai",
    providerId: "frank",
    slug: "frank",
    name: "frank",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Frank.mp3",
    gender: "Male",
  },
  {
    id: "0105bad6-54d2-492d-bdb6-51a696eae63d",
    provider: "rime-ai",
    providerId: "franklin",
    slug: "franklin",
    name: "franklin",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Franklin.mp3",
    gender: "Male",
  },
  {
    id: "e529280f-0b32-4fd0-8b8f-4134222ca8c5",
    provider: "rime-ai",
    providerId: "freya",
    slug: "freya",
    name: "freya",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Freya.mp3",
    gender: "Female",
  },
  {
    id: "6ad7bed3-df9b-4e3e-a4bc-e7cb5a19b67b",
    provider: "rime-ai",
    providerId: "gabriela",
    slug: "gabriela",
    name: "gabriela",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Gabriela.mp3",
    gender: "Female",
  },
  {
    id: "a8594e77-3c63-4fd3-88e4-eefc4c69b430",
    provider: "rime-ai",
    providerId: "geoff",
    slug: "geoff",
    name: "geoff",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Geoff.mp3",
    gender: "Male",
  },
  {
    id: "d1abd19d-1b1f-45ba-87f8-da16a2ad40cb",
    provider: "rime-ai",
    providerId: "gertrude",
    slug: "gertrude",
    name: "gertrude",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Gertrude.mp3",
    gender: "Female",
  },
  {
    id: "38c3875f-4412-4fb5-b8eb-9f8b95896c08",
    provider: "rime-ai",
    providerId: "glacier",
    slug: "glacier",
    name: "glacier",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/glacier.mp3",
    gender: "Male",
  },
  {
    id: "b151de31-eb4c-47d7-9fd4-9820fc5d0f34",
    provider: "rime-ai",
    providerId: "greta",
    slug: "greta",
    name: "greta",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Greta.mp3",
    gender: "Female",
  },
  {
    id: "d634a8c6-9b1d-4cdc-83f3-03adfab35b97",
    provider: "rime-ai",
    providerId: "gulch",
    slug: "gulch",
    name: "gulch",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/gulch.mp3",
    gender: "Male",
  },
  {
    id: "0c0f9d2e-3acc-4bea-91e9-a06b07e2d392",
    provider: "rime-ai",
    providerId: "hannah",
    slug: "hannah",
    name: "hannah",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Hannah.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "63282489-047f-41ea-84bb-c9fac2d85e3a",
    provider: "rime-ai",
    providerId: "hera",
    slug: "hera",
    name: "hera",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Hera.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "a76e86c7-02ba-4c26-84fe-0074f91d8988",
    provider: "rime-ai",
    providerId: "horace",
    slug: "horace",
    name: "horace",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Horace.mp3",
    gender: "Male",
  },
  {
    id: "17ff60b0-0f05-4d43-b7db-dc0d5777f657",
    provider: "rime-ai",
    providerId: "hudson",
    slug: "hudson",
    name: "hudson",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Hudson.mp3",
    gender: "Male",
  },
  {
    id: "5c5c4dc3-7400-46bc-95af-b2f313af6eae",
    provider: "rime-ai",
    providerId: "irving",
    slug: "irving",
    name: "irving",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Irving.mp3",
    gender: "Male",
  },
  {
    id: "53c64d8b-f9dd-45af-b68d-b84f674dac85",
    provider: "rime-ai",
    providerId: "irwin",
    slug: "irwin",
    name: "irwin",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Irwin.mp3",
    gender: "Male",
  },
  {
    id: "38818d7d-ce3b-4adc-a702-9706d5abeb88",
    provider: "rime-ai",
    providerId: "jackie",
    slug: "jackie",
    name: "jackie",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jackie.mp3",
    gender: "Female",
  },
  {
    id: "281f9de4-29bf-443b-8dc7-3ac973c46c8d",
    provider: "rime-ai",
    providerId: "jada",
    slug: "jada",
    name: "jada",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jada.mp3",
    gender: "Female",
  },
  {
    id: "698cc7f1-7c16-4476-92b2-d92bff3c4a0b",
    provider: "rime-ai",
    providerId: "jamel",
    slug: "jamel",
    name: "jamel",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jamel.mp3",
    gender: "Male",
  },
  {
    id: "f3889e13-2514-4b08-8cb6-3bb0b80b1362",
    provider: "rime-ai",
    providerId: "jayden",
    slug: "jayden",
    name: "jayden",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jayden.mp3",
    gender: "Male",
  },
  {
    id: "63a61c26-1577-4f59-bf22-e08d88546538",
    provider: "rime-ai",
    providerId: "jed",
    slug: "jed",
    name: "jed",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jed.mp3",
    gender: "Male",
  },
  {
    id: "d6768fe3-86ae-418a-a5a5-28a6fb6b3543",
    provider: "rime-ai",
    providerId: "jen",
    slug: "jen",
    name: "jen",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jen.mp3",
    gender: "Female",
  },
  {
    id: "804853ab-8e36-4a6a-8ed4-0d6361cb59ef",
    provider: "rime-ai",
    providerId: "jennifer",
    slug: "jennifer",
    name: "jennifer",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jennifer.mp3",
    gender: "Female",
  },
  {
    id: "c0dc0f91-4a64-4199-a8b2-10b2bec3ac90",
    provider: "rime-ai",
    providerId: "jerald",
    slug: "jerald",
    name: "jerald",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jerald.mp3",
    gender: "Male",
  },
  {
    id: "4060d9c3-136a-4ada-ab2f-d8c8b6ad6e0e",
    provider: "rime-ai",
    providerId: "jess",
    slug: "jess",
    name: "jess",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jess.mp3",
    gender: "Female",
  },
  {
    id: "704c3c2f-f700-45d4-9511-a42a98dc12aa",
    provider: "rime-ai",
    providerId: "jimmy",
    slug: "jimmy",
    name: "jimmy",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jimmy.mp3",
    gender: "Male",
  },
  {
    id: "8d280103-edd3-4297-adab-1b3f9373773b",
    provider: "rime-ai",
    providerId: "joe",
    slug: "joe",
    name: "joe",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Joe.mp3",
    gender: "Male",
  },
  {
    id: "748b5660-3184-4d58-8833-ade5a65b15ba",
    provider: "rime-ai",
    providerId: "jordan",
    slug: "jordan",
    name: "jordan",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Jordan.mp3",
    gender: "Female",
  },
  {
    id: "3eecf292-5c3f-40fa-a580-ad59949f9a7a",
    provider: "rime-ai",
    providerId: "joy",
    slug: "joy",
    name: "joy",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Joy.mp3",
    gender: "Female",
  },
  {
    id: "caa4902d-0acb-404f-9e55-3babcae1ee8d",
    provider: "rime-ai",
    providerId: "juan",
    slug: "juan",
    name: "juan",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Juan.mp3",
    gender: "Male",
  },
  {
    id: "68fb6488-85ac-4777-b686-1ff276ad3214",
    provider: "rime-ai",
    providerId: "kai",
    slug: "kai",
    name: "kai",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Kai.mp3",
    gender: "Male",
  },
  {
    id: "1d480799-9e90-4a3c-beb5-44298f49c51c",
    provider: "rime-ai",
    providerId: "karen",
    slug: "karen",
    name: "karen",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Karen.mp3",
    gender: "Female",
  },
  {
    id: "4fe8cc8f-e567-43ec-99f6-d274cc01fc5c",
    provider: "rime-ai",
    providerId: "keith",
    slug: "keith",
    name: "keith",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Keith.mp3",
    gender: "Male",
  },
  {
    id: "7fd1337d-231d-45ac-98eb-0c4ae444f675",
    provider: "rime-ai",
    providerId: "kendra",
    slug: "kendra",
    name: "kendra",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Kendra.mp3",
    gender: "Female",
  },
  {
    id: "90d201d8-00ab-43e3-a771-f9a925af77f8",
    provider: "rime-ai",
    providerId: "kendrick",
    slug: "kendrick",
    name: "kendrick",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Kendrick.mp3",
    gender: "Male",
  },
  {
    id: "2e10a376-1f5f-4aee-9bd5-3c674b918892",
    provider: "rime-ai",
    providerId: "kenneth",
    slug: "kenneth",
    name: "kenneth",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Kenneth.mp3",
    gender: "Male",
  },
  {
    id: "be5633e7-7060-440e-8b7e-759d9a491a2a",
    provider: "rime-ai",
    providerId: "kevin",
    slug: "kevin",
    name: "kevin",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Kevin.mp3",
    gender: "Male",
  },
  {
    id: "7f1e5b02-cdd9-419c-991c-80473baf7526",
    provider: "rime-ai",
    providerId: "kris",
    slug: "kris",
    name: "kris",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Kris.mp3",
    gender: "Male",
  },
  {
    id: "6c8ff8db-4ab7-49ce-ba26-6f6e7c3338a2",
    provider: "rime-ai",
    providerId: "lavinia",
    slug: "lavinia",
    name: "lavinia",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Lavinia.mp3",
    gender: "Female",
  },
  {
    id: "a518c639-152f-4b06-adc1-1ebdbb04705c",
    provider: "rime-ai",
    providerId: "lawrence",
    slug: "lawrence",
    name: "lawrence",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Lawrence.mp3",
    gender: "Male",
  },
  {
    id: "a1c62e07-91e8-41ba-8608-aac9e59815f5",
    provider: "rime-ai",
    providerId: "lenore",
    slug: "lenore",
    name: "lenore",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Lenore.mp3",
    gender: "Female",
  },
  {
    id: "bb6648d7-953a-4b79-b51e-ce88ad8dc7e9",
    provider: "rime-ai",
    providerId: "leonard",
    slug: "leonard",
    name: "leonard",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Leonard.mp3",
    gender: "Male",
  },
  {
    id: "3bf809ac-5613-4ca8-b732-4879757eae37",
    provider: "rime-ai",
    providerId: "linda",
    slug: "linda",
    name: "linda",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Linda.mp3",
    gender: "Female",
  },
  {
    id: "4cf7d0e9-ea7c-4cd9-8a26-3ab4bbab010c",
    provider: "rime-ai",
    providerId: "lloyd",
    slug: "lloyd",
    name: "lloyd",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Lloyd.mp3",
    gender: "Male",
  },
  {
    id: "63329bad-5ed4-4246-a584-69858c63f0cb",
    provider: "rime-ai",
    providerId: "lorraine",
    slug: "lorraine",
    name: "lorraine",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Lorraine.mp3",
    gender: "Female",
  },
  {
    id: "2af94e43-3ddb-4a27-beb1-07fee32505ff",
    provider: "rime-ai",
    providerId: "lucinda",
    slug: "lucinda",
    name: "lucinda",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Lucinda.mp3",
    gender: "Female",
  },
  {
    id: "ca637f5f-0e97-43bc-8760-5afbc5165bfa",
    provider: "rime-ai",
    providerId: "luna",
    slug: "luna",
    name: "luna",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Luna.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "3f7b0ce4-a022-4216-aaf5-29c21573919f",
    provider: "rime-ai",
    providerId: "lyric",
    slug: "lyric",
    name: "lyric",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Lyric.mp3",
    gender: "Female",
  },
  {
    id: "ed15fd72-a2fa-482c-94da-d2055f0c2cd0",
    provider: "rime-ai",
    providerId: "mabel",
    slug: "mabel",
    name: "mabel",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Mabel.mp3",
    gender: "Female",
  },
  {
    id: "ee7b4947-101c-4fbb-8d99-335a27944761",
    provider: "rime-ai",
    providerId: "madison",
    slug: "madison",
    name: "madison",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Madison.mp3",
    gender: "Female",
  },
  {
    id: "786f6a2e-29e5-46c7-a717-bb7b7da51230",
    provider: "rime-ai",
    providerId: "malik",
    slug: "malik",
    name: "malik",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Malik.mp3",
    gender: "Male",
  },
  {
    id: "b15fc78c-28e5-4aea-8292-5c2cf60e6d77",
    provider: "rime-ai",
    providerId: "marcella",
    slug: "marcella",
    name: "marcella",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Marcella.mp3",
    gender: "Female",
  },
  {
    id: "16199073-9b50-454f-ad83-cf7ad6362a56",
    provider: "rime-ai",
    providerId: "marge",
    slug: "marge",
    name: "marge",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Marge.mp3",
    gender: "Female",
  },
  {
    id: "2ded5ca9-3bf2-4d42-84c0-70c788ddf314",
    provider: "rime-ai",
    providerId: "marianne",
    slug: "marianne",
    name: "marianne",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Marianne.mp3",
    gender: "Female",
  },
  {
    id: "b38acb6c-0ce6-4006-bda9-29064ff94bac",
    provider: "rime-ai",
    providerId: "marina",
    slug: "marina",
    name: "marina",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Marina.mp3",
    gender: "Female",
  },
  {
    id: "4f730977-a77a-4e2b-a611-31f345ef2946",
    provider: "rime-ai",
    providerId: "marissa",
    slug: "marissa",
    name: "marissa",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Marissa.mp3",
    gender: "Female",
  },
  {
    id: "2adbedf3-7c6f-4195-9723-27e047e0e602",
    provider: "rime-ai",
    providerId: "marjorie",
    slug: "marjorie",
    name: "marjorie",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Marjorie.mp3",
    gender: "Female",
  },
  {
    id: "2a1494d3-b772-4b42-b46b-13ad785bbf18",
    provider: "rime-ai",
    providerId: "mark",
    slug: "mark",
    name: "mark",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Mark.mp3",
    gender: "Male",
  },
  {
    id: "23260762-76c2-4f9d-b874-27cf5a186184",
    provider: "rime-ai",
    providerId: "marsh",
    slug: "marsh",
    name: "marsh",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/marsh.mp3",
    gender: "Male",
  },
  {
    id: "8209178c-864c-4638-9ee7-dafaea7186d9",
    provider: "rime-ai",
    providerId: "marta",
    slug: "marta",
    name: "marta",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Marta.mp3",
    gender: "Female",
  },
  {
    id: "64b7b4a3-598b-4b5a-93f4-df0f008a61cc",
    provider: "rime-ai",
    providerId: "marvin",
    slug: "marvin",
    name: "marvin",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Marvin.mp3",
    gender: "Male",
  },
  {
    id: "dd5747c2-55b3-46de-b9cf-fcb9005c05eb",
    provider: "rime-ai",
    providerId: "mason",
    slug: "mason",
    name: "mason",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Mason.mp3",
    gender: "Male",
  },
  {
    id: "e08d8c6c-eb6d-4960-a110-d69dc94f5f2c",
    provider: "rime-ai",
    providerId: "maxine",
    slug: "maxine",
    name: "maxine",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Maxine.mp3",
    gender: "Female",
  },
  {
    id: "b2bfc87c-3c8e-4072-90f2-224fc018388d",
    provider: "rime-ai",
    providerId: "maya",
    slug: "maya",
    name: "maya",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Maya.mp3",
    gender: "Female",
  },
  {
    id: "d1300ae3-f99b-4aae-b006-6a5ccd69749e",
    provider: "rime-ai",
    providerId: "melvin",
    slug: "melvin",
    name: "melvin",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Melvin.mp3",
    gender: "Male",
  },
  {
    id: "da7f644e-78fc-44c5-a951-2419aa8d7c74",
    provider: "rime-ai",
    providerId: "mildred",
    slug: "mildred",
    name: "mildred",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Mildred.mp3",
    gender: "Female",
  },
  {
    id: "0c9fed08-c1f6-4cad-925e-b6606a11f876",
    provider: "rime-ai",
    providerId: "milton",
    slug: "milton",
    name: "milton",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Milton.mp3",
    gender: "Male",
  },
  {
    id: "b964d157-c7b9-4cf0-89e7-8815b36864b7",
    provider: "rime-ai",
    providerId: "mitch",
    slug: "mitch",
    name: "mitch",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Mitch.mp3",
    gender: "Male",
  },
  {
    id: "f2784db5-1dc2-4834-9966-d1621c4583ae",
    provider: "rime-ai",
    providerId: "morgan",
    slug: "morgan",
    name: "morgan",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Morgan.mp3",
    gender: "Female",
  },
  {
    id: "50a964b2-e1b6-4e22-b699-cb95d68230c8",
    provider: "rime-ai",
    providerId: "nia",
    slug: "nia",
    name: "nia",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Nia.mp3",
    gender: "Female",
  },
  {
    id: "881b2806-8f09-4b51-bd5e-783a22385f50",
    provider: "rime-ai",
    providerId: "nicholas",
    slug: "nicholas",
    name: "nicholas",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Nicholas.mp3",
    gender: "Male",
  },
  {
    id: "2a639c1c-e371-4312-b3b9-32d937cc776a",
    provider: "rime-ai",
    providerId: "nova",
    slug: "nova",
    name: "nova",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Nova.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "ef5d3169-df4e-43eb-aeb9-67e9363ec35c",
    provider: "rime-ai",
    providerId: "nyles",
    slug: "nyles",
    name: "nyles",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Nyles.mp3",
    gender: "Male",
  },
  {
    id: "6e1f1fe6-cde1-497e-9bac-4bc258c0b1ab",
    provider: "rime-ai",
    providerId: "owen",
    slug: "owen",
    name: "owen",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Owen.mp3",
    gender: "Male",
  },
  {
    id: "28fbc2a1-cb17-4b1d-a92d-2fdafef6f190",
    provider: "rime-ai",
    providerId: "paul",
    slug: "paul",
    name: "paul",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Paul.mp3",
    gender: "Male",
  },
  {
    id: "7ea63753-3d56-497b-9866-6b627e9748ca",
    provider: "rime-ai",
    providerId: "phil",
    slug: "phil",
    name: "phil",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Phil.mp3",
    gender: "Male",
  },
  {
    id: "f718b7c6-d2c9-4f7a-8017-e58097bc33f7",
    provider: "rime-ai",
    providerId: "quentin",
    slug: "quentin",
    name: "quentin",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Quentin.mp3",
    gender: "Male",
  },
  {
    id: "dec4dfd0-8aaf-44e9-acee-d48b02bf65eb",
    provider: "rime-ai",
    providerId: "randolph",
    slug: "randolph",
    name: "randolph",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Randolph.mp3",
    gender: "Male",
  },
  {
    id: "0b64bf2a-f781-485f-a9a7-3f4d59b30183",
    provider: "rime-ai",
    providerId: "reba",
    slug: "reba",
    name: "reba",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Reba.mp3",
    gender: "Female",
  },
  {
    id: "d8ec1824-4492-4fcf-910a-97d0e7bd28bc",
    provider: "rime-ai",
    providerId: "rex",
    slug: "rex",
    name: "rex",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Rex.mp3",
    gender: "Male",
  },
  {
    id: "a8503280-f33a-4ca7-afa8-ec351830d54b",
    provider: "rime-ai",
    providerId: "rick",
    slug: "rick",
    name: "rick",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Rick.mp3",
    gender: "Male",
  },
  {
    id: "b45b337e-2ad5-470f-92a3-e076d9ff35ae",
    provider: "rime-ai",
    providerId: "ritu",
    slug: "ritu",
    name: "ritu",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Ritu.mp3",
    gender: "Female",
  },
  {
    id: "558b3c21-39b0-44a4-a917-11fb642cdf28",
    provider: "rime-ai",
    providerId: "rob",
    slug: "rob",
    name: "rob",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Rob.mp3",
    gender: "Male",
  },
  {
    id: "a37bcaad-1f4b-4224-8116-2114d81485ad",
    provider: "rime-ai",
    providerId: "rodney",
    slug: "rodney",
    name: "rodney",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Rodney.mp3",
    gender: "Male",
  },
  {
    id: "1cb41153-c3cb-4784-aad5-0a02e2ae4bef",
    provider: "rime-ai",
    providerId: "rosa",
    slug: "rosa",
    name: "rosa",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Rosa.mp3",
    gender: "Female",
  },
  {
    id: "e7f42523-d1fd-4386-bae6-2539321e468a",
    provider: "rime-ai",
    providerId: "rosalind",
    slug: "rosalind",
    name: "rosalind",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Rosalind.mp3",
    gender: "Female",
  },
  {
    id: "469b9453-c974-43c9-94d4-1b9e7e2c3fb5",
    provider: "rime-ai",
    providerId: "rosalinda",
    slug: "rosalinda",
    name: "rosalinda",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Rosalinda.mp3",
    gender: "Female",
  },
  {
    id: "eeaa4f2e-a454-4752-8117-ac920e13d6dc",
    provider: "rime-ai",
    providerId: "rosco",
    slug: "rosco",
    name: "rosco",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Rosco.mp3",
    gender: "Male",
  },
  {
    id: "a596a7b8-1791-451b-97e9-a186ce6009c1",
    provider: "rime-ai",
    providerId: "samantha",
    slug: "samantha",
    name: "samantha",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Samantha.mp3",
    gender: "Female",
  },
  {
    id: "b7d8b361-fc24-4b6a-850b-57555b42fb00",
    provider: "rime-ai",
    providerId: "sandy",
    slug: "sandy",
    name: "sandy",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Sandy.mp3",
    gender: "Female",
  },
  {
    id: "38066f92-7dd2-48c6-ab29-ceeb1a5afe5d",
    provider: "rime-ai",
    providerId: "saniyah",
    slug: "saniyah",
    name: "saniyah",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Saniyah.mp3",
    gender: "Female",
  },
  {
    id: "789b2905-1080-41c0-8f67-7555a7eb4fb4",
    provider: "rime-ai",
    providerId: "scarlett",
    slug: "scarlett",
    name: "scarlett",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Scarlett.mp3",
    gender: "Female",
  },
  {
    id: "8fc4405d-47d1-4f43-ab3b-1dcd4a998323",
    provider: "rime-ai",
    providerId: "selena",
    slug: "selena",
    name: "selena",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Selena.mp3",
    gender: "Female",
  },
  {
    id: "faf92528-a364-45bc-9a2c-f4fed9b539ef",
    provider: "rime-ai",
    providerId: "serenity",
    slug: "serenity",
    name: "serenity",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Serenity.mp3",
    gender: "Female",
  },
  {
    id: "2889a447-ff84-4a9c-846d-348bb1241bb6",
    provider: "rime-ai",
    providerId: "seth",
    slug: "seth",
    name: "seth",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Seth.mp3",
    gender: "Male",
  },
  {
    id: "5d11e3d5-fa89-446f-8b17-b71e9b0013bf",
    provider: "rime-ai",
    providerId: "sharon",
    slug: "sharon",
    name: "sharon",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Sharon.mp3",
    gender: "Female",
  },
  {
    id: "1bdfd25e-ef5c-4c0a-a077-641f15e88571",
    provider: "rime-ai",
    providerId: "shirley",
    slug: "shirley",
    name: "shirley",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Shirley.mp3",
    gender: "Female",
  },
  {
    id: "fe8bf25f-f7f0-4ad9-8cad-8f9c8c72f3d9",
    provider: "rime-ai",
    providerId: "sofia",
    slug: "sofia",
    name: "sofia",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Sofia.mp3",
    gender: "Female",
  },
  {
    id: "1f744e51-2b87-4f7e-b6de-2731e7f81d34",
    provider: "rime-ai",
    providerId: "spencer",
    slug: "spencer",
    name: "spencer",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Spencer.mp3",
    gender: "Male",
  },
  {
    id: "e5efa97f-7930-4f47-9837-23d62fc68c85",
    provider: "rime-ai",
    providerId: "spore",
    slug: "spore",
    name: "spore",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/spore.mp3",
    gender: "Male",
  },
  {
    id: "7470959f-bc78-4e7c-8674-141e695ffe63",
    provider: "rime-ai",
    providerId: "stan",
    slug: "stan",
    name: "stan",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Stan.mp3",
    gender: "Male",
  },
  {
    id: "66ab8116-48ec-4b65-8313-799773141fc0",
    provider: "rime-ai",
    providerId: "stanley",
    slug: "stanley",
    name: "stanley",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Stanley.mp3",
    gender: "Male",
  },
  {
    id: "317a67f8-3079-40c1-8671-1d522d29e5ea",
    provider: "rime-ai",
    providerId: "sterling",
    slug: "sterling",
    name: "sterling",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Sterling.mp3",
    gender: "Male",
  },
  {
    id: "87bb317b-21d2-4ae0-904e-d8e1ba573c5d",
    provider: "rime-ai",
    providerId: "susan",
    slug: "susan",
    name: "susan",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Susan.mp3",
    gender: "Female",
  },
  {
    id: "42326efb-1042-4d3c-8e72-8383e22b7e1c",
    provider: "rime-ai",
    providerId: "sybil",
    slug: "sybil",
    name: "sybil",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Sybil.mp3",
    gender: "Female",
  },
  {
    id: "5f92c7f2-7d87-4a74-a7ba-4e7409235bb0",
    provider: "rime-ai",
    providerId: "tabitha",
    slug: "tabitha",
    name: "tabitha",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Tabitha.mp3",
    gender: "Female",
  },
  {
    id: "e58cd610-a7f9-4dd8-97db-41183a17d063",
    provider: "rime-ai",
    providerId: "tamra",
    slug: "tamra",
    name: "tamra",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Tamra.mp3",
    gender: "Female",
  },
  {
    id: "2152207b-0810-4261-b056-04f9de207230",
    provider: "rime-ai",
    providerId: "tanya",
    slug: "tanya",
    name: "tanya",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Tanya.mp3",
    gender: "Female",
  },
  {
    id: "32677951-bfd2-4122-a695-c18bd1521847",
    provider: "rime-ai",
    providerId: "taylor",
    slug: "taylor",
    name: "taylor",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Taylor.mp3",
    gender: "Male",
  },
  {
    id: "19cfa519-be99-4192-8fa2-9fb6db2a7acd",
    provider: "rime-ai",
    providerId: "terrance",
    slug: "terrance",
    name: "terrance",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Terrance.mp3",
    gender: "Male",
  },
  {
    id: "5d3afc16-0c8a-4742-bb92-581dea1486bc",
    provider: "rime-ai",
    providerId: "terrence",
    slug: "terrence",
    name: "terrence",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Terrence.mp3",
    gender: "Male",
  },
  {
    id: "114f4138-cc84-4f82-a3d2-41c49858d72d",
    provider: "rime-ai",
    providerId: "theresa",
    slug: "theresa",
    name: "theresa",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Theresa.mp3",
    gender: "Female",
  },
  {
    id: "ee373cc6-3cf1-4907-bfad-ea56c4cb9181",
    provider: "rime-ai",
    providerId: "tibur",
    slug: "tibur",
    name: "tibur",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Tibur.mp3",
    gender: "Male",
  },
  {
    id: "4c02fd59-51eb-44e1-b50b-5907613292ab",
    provider: "rime-ai",
    providerId: "tim",
    slug: "tim",
    name: "tim",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Tim.mp3",
    gender: "Male",
  },
  {
    id: "752e68f2-df9d-4539-a9c2-699100c19dd4",
    provider: "rime-ai",
    providerId: "tyler",
    slug: "tyler",
    name: "tyler",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Tyler.mp3",
    gender: "Male",
  },
  {
    id: "0c034ebc-edcd-4acd-b8f8-fe7f7a8d7e2c",
    provider: "rime-ai",
    providerId: "tyrone",
    slug: "tyrone",
    name: "tyrone",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Tyrone.mp3",
    gender: "Male",
  },
  {
    id: "57489296-2771-4f9a-a30e-ad4824ba1c84",
    provider: "rime-ai",
    providerId: "vernon",
    slug: "vernon",
    name: "vernon",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Vernon.mp3",
    gender: "Male",
  },
  {
    id: "c2233f2c-21d9-43b5-a3be-c79c547058d3",
    provider: "rime-ai",
    providerId: "viv",
    slug: "viv",
    name: "viv",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Viv.mp3",
    gender: "Female",
  },
  {
    id: "7075d78d-8f09-4f30-88e5-438fd7e69171",
    provider: "rime-ai",
    providerId: "wallace",
    slug: "wallace",
    name: "wallace",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Wallace.mp3",
    gender: "Male",
  },
  {
    id: "3f52784a-db81-4052-946f-fbcc8bcccf89",
    provider: "rime-ai",
    providerId: "wilbur",
    slug: "wilbur",
    name: "wilbur",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Wilbur.mp3",
    gender: "Male",
  },
  {
    id: "045a0005-af1a-477f-8c5d-836b5fcf10d6",
    provider: "rime-ai",
    providerId: "willow",
    slug: "willow",
    name: "willow",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Willow.mp3",
    gender: "Female",
  },
  {
    id: "b789b786-7c85-4ee0-8358-cd639413188e",
    provider: "rime-ai",
    providerId: "winifred",
    slug: "winifred",
    name: "winifred",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Winifred.mp3",
    gender: "Female",
  },
  {
    id: "f9522f91-e3f2-48dc-94a2-c1e8983f08a2",
    provider: "rime-ai",
    providerId: "wyatt",
    slug: "wyatt",
    name: "wyatt",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Wyatt.mp3",
    gender: "Male",
  },
  {
    id: "228fbc28-5927-4a65-88c4-3047f4d82637",
    provider: "rime-ai",
    providerId: "yadira",
    slug: "yadira",
    name: "yadira",
    model: "mist",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Yadira.mp3",
    gender: "Female",
  },
  {
    id: "2834394a-f95b-4f30-8e35-c48151fcf731",
    provider: "rime-ai",
    providerId: "yolanda",
    slug: "yolanda",
    name: "yolanda",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Yolanda.mp3",
    gender: "Female",
  },
  {
    id: "9eea4153-db9f-4275-94fd-3c722d8d483f",
    provider: "rime-ai",
    providerId: "yvette",
    slug: "yvette",
    name: "yvette",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Yvette.mp3",
    gender: "Female",
  },
  {
    id: "fa841b4c-fcb5-4a02-985a-75ddf2981a45",
    provider: "rime-ai",
    providerId: "zion",
    slug: "zion",
    name: "zion",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Zion.mp3",
    gender: "Male",
  },
  {
    id: "9bfd06f3-b730-457d-ac7b-4df7564abcf0",
    provider: "rime-ai",
    providerId: "zora",
    slug: "zora",
    name: "zora",
    model: "v1",
    createdAt: "2024-05-22T18:39:09.150Z",
    updatedAt: "2024-05-22T18:39:09.150Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/Zora.mp3",
    gender: "Female",
  },
  {
    id: "de01067f-3645-4aa3-b7b7-7f37dfe7e0bb",
    provider: "azure",
    providerId: "af-ZA-AdriNeural",
    slug: "af-ZA-AdriNeural",
    name: "Adri",
    language: "Afrikaans (South Africa)",
    languageCode: "af-ZA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/62f32b49-2e73-42ec-81e9-a5750c2fb81f.wav",
  },
  {
    id: "80a28ce3-76f6-465a-a300-1ca9490d24ef",
    provider: "azure",
    providerId: "af-ZA-WillemNeural",
    slug: "af-ZA-WillemNeural",
    name: "Willem",
    language: "Afrikaans (South Africa)",
    languageCode: "af-ZA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/252a1c5d-93a1-46bd-b271-2ff8fbb43719.wav",
  },
  {
    id: "e5a1414f-dccd-4cbe-8db3-97a082c8eb4a",
    provider: "azure",
    providerId: "am-ET-AmehaNeural",
    slug: "am-ET-AmehaNeural",
    name: "Ameha",
    language: "Amharic (Ethiopia)",
    languageCode: "am-ET",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f285fb08-0858-4815-af18-a621473ca46b.wav",
  },
  {
    id: "411cfeb0-e911-4f62-ae1d-2a43c6e32275",
    provider: "azure",
    providerId: "am-ET-MekdesNeural",
    slug: "am-ET-MekdesNeural",
    name: "Mekdes",
    language: "Amharic (Ethiopia)",
    languageCode: "am-ET",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e3633ea9-b525-41d6-ba36-69e456dc01ec.wav",
  },
  {
    id: "b30190bf-9ce0-468d-be56-09b3b57cbca3",
    provider: "azure",
    providerId: "ar-AE-FatimaNeural",
    slug: "ar-AE-FatimaNeural",
    name: "Fatima",
    language: "Arabic (United Arab Emirates)",
    languageCode: "ar-AE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/45bbb58b-4643-483d-9055-2bf98be73ff7.wav",
  },
  {
    id: "0d520075-9e2c-465b-8b30-823a1a738dff",
    provider: "azure",
    providerId: "ar-AE-HamdanNeural",
    slug: "ar-AE-HamdanNeural",
    name: "Hamdan",
    language: "Arabic (United Arab Emirates)",
    languageCode: "ar-AE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/863eda28-51fe-47a7-84f2-1e560c5ab55e.wav",
  },
  {
    id: "0cfcf642-c275-4530-88e1-42056bc5d69c",
    provider: "azure",
    providerId: "ar-BH-AliNeural",
    slug: "ar-BH-AliNeural",
    name: "Ali",
    language: "Arabic (Bahrain)",
    languageCode: "ar-BH",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/725ddd99-a217-43cd-bf2c-922be2f4835a.wav",
  },
  {
    id: "5d787741-5399-4557-9639-f36f0ac95ff6",
    provider: "azure",
    providerId: "ar-BH-LailaNeural",
    slug: "ar-BH-LailaNeural",
    name: "Laila",
    language: "Arabic (Bahrain)",
    languageCode: "ar-BH",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6596437c-8f37-4f84-ab11-0cc76baff431.wav",
  },
  {
    id: "0f98b331-44ff-4bec-bb23-5547bddd4960",
    provider: "azure",
    providerId: "ar-DZ-AminaNeural",
    slug: "ar-DZ-AminaNeural",
    name: "Amina",
    language: "Arabic (Algeria)",
    languageCode: "ar-DZ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1538ec1b-5e44-46eb-bb0e-96f5ac0b091d.wav",
  },
  {
    id: "775121ff-ba32-40f2-b5f7-dbaa4baae736",
    provider: "azure",
    providerId: "ar-DZ-IsmaelNeural",
    slug: "ar-DZ-IsmaelNeural",
    name: "Ismael",
    language: "Arabic (Algeria)",
    languageCode: "ar-DZ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c858c794-ce43-47fd-95bb-bac6d0d3461d.wav",
  },
  {
    id: "d4a220e2-419e-4e52-a4e5-f8ad634e8eaf",
    provider: "azure",
    providerId: "ar-EG-SalmaNeural",
    slug: "ar-EG-SalmaNeural",
    name: "Salma",
    language: "Arabic (Egypt)",
    languageCode: "ar-EG",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d582f482-4a7a-4f69-b431-8459a5c329bc.wav",
  },
  {
    id: "7e5c78f9-99cb-49a4-a1c6-986609d6ea98",
    provider: "azure",
    providerId: "ar-EG-ShakirNeural",
    slug: "ar-EG-ShakirNeural",
    name: "Shakir",
    language: "Arabic (Egypt)",
    languageCode: "ar-EG",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/81c66b11-3283-49e1-9ba3-17260bce5953.wav",
  },
  {
    id: "b189d8b7-93f2-4a8c-bbff-43dc15ab62ad",
    provider: "azure",
    providerId: "ar-IQ-BasselNeural",
    slug: "ar-IQ-BasselNeural",
    name: "Bassel",
    language: "Arabic (Iraq)",
    languageCode: "ar-IQ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/79ceb0f2-4e3f-44ea-85f2-864f40be79dc.wav",
  },
  {
    id: "f2e4c18d-0453-4d4a-a7bd-15050098a362",
    provider: "azure",
    providerId: "ar-IQ-RanaNeural",
    slug: "ar-IQ-RanaNeural",
    name: "Rana",
    language: "Arabic (Iraq)",
    languageCode: "ar-IQ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e07efb39-cb54-4476-a303-ce10121fb305.wav",
  },
  {
    id: "02475695-56a2-4fc0-ab8d-518bd058596a",
    provider: "azure",
    providerId: "ar-JO-SanaNeural",
    slug: "ar-JO-SanaNeural",
    name: "Sana",
    language: "Arabic (Jordan)",
    languageCode: "ar-JO",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/67694e20-2610-4d5b-a54e-7d017048a2b9.wav",
  },
  {
    id: "141060be-3ed6-49d3-a7ec-cd465646fa61",
    provider: "azure",
    providerId: "ar-JO-TaimNeural",
    slug: "ar-JO-TaimNeural",
    name: "Taim",
    language: "Arabic (Jordan)",
    languageCode: "ar-JO",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/09540a11-2c1e-4cc1-901a-b483fe15b4e2.wav",
  },
  {
    id: "c3d048ee-2554-4b00-aa34-f2f8dbc94d01",
    provider: "azure",
    providerId: "ar-KW-FahedNeural",
    slug: "ar-KW-FahedNeural",
    name: "Fahed",
    language: "Arabic (Kuwait)",
    languageCode: "ar-KW",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0c954675-1495-4736-9f14-6e4d0ddfb913.wav",
  },
  {
    id: "7920a445-6aa6-4830-aed3-bb39a26ce1a7",
    provider: "azure",
    providerId: "ar-KW-NouraNeural",
    slug: "ar-KW-NouraNeural",
    name: "Noura",
    language: "Arabic (Kuwait)",
    languageCode: "ar-KW",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/26c2074b-08ab-4c87-937d-f54de568c7d6.wav",
  },
  {
    id: "5e0affdf-582a-4128-96aa-5dc1ba25b2d2",
    provider: "azure",
    providerId: "ar-LB-LaylaNeural",
    slug: "ar-LB-LaylaNeural",
    name: "Layla",
    language: "Arabic (Lebanon)",
    languageCode: "ar-LB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a3e6a876-f32d-4890-8239-bd7ce1183dfc.wav",
  },
  {
    id: "f0d6de83-20bf-4931-92de-a7cb7de4806f",
    provider: "azure",
    providerId: "ar-LB-RamiNeural",
    slug: "ar-LB-RamiNeural",
    name: "Rami",
    language: "Arabic (Lebanon)",
    languageCode: "ar-LB",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2eee8060-55e5-4b93-81a8-0f37fc1396fd.wav",
  },
  {
    id: "d37876d7-c54d-489d-9e69-ee3349a3c20f",
    provider: "azure",
    providerId: "ar-LY-ImanNeural",
    slug: "ar-LY-ImanNeural",
    name: "Iman",
    language: "Arabic (Libya)",
    languageCode: "ar-LY",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/06b687ba-4bcd-446c-bd37-1237ce8615d4.wav",
  },
  {
    id: "22afda3c-3f78-41b9-bbd5-6fc2d68e41ee",
    provider: "azure",
    providerId: "ar-LY-OmarNeural",
    slug: "ar-LY-OmarNeural",
    name: "Omar",
    language: "Arabic (Libya)",
    languageCode: "ar-LY",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7ff332c3-7336-46f3-8acf-2a68d5869eb6.wav",
  },
  {
    id: "1cc380cc-7351-40e9-8dc6-af5e0075be1f",
    provider: "azure",
    providerId: "ar-MA-JamalNeural",
    slug: "ar-MA-JamalNeural",
    name: "Jamal",
    language: "Arabic (Morocco)",
    languageCode: "ar-MA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1e140656-35d4-465a-acf1-b14439f6c90c.wav",
  },
  {
    id: "feb0de0f-171e-4584-9e25-d2a335ac73bb",
    provider: "azure",
    providerId: "ar-MA-MounaNeural",
    slug: "ar-MA-MounaNeural",
    name: "Mouna",
    language: "Arabic (Morocco)",
    languageCode: "ar-MA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/38ec32ca-18a4-47e7-a5b0-4f70f44fb279.wav",
  },
  {
    id: "cbc0e1da-550d-4a9f-9cbb-242379516e57",
    provider: "azure",
    providerId: "ar-OM-AbdullahNeural",
    slug: "ar-OM-AbdullahNeural",
    name: "Abdullah",
    language: "Arabic (Oman)",
    languageCode: "ar-OM",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/bbff3629-5977-45f3-9a9c-855200523c3a.wav",
  },
  {
    id: "b1f4f167-07a8-492e-91af-3ddd378f9e7a",
    provider: "azure",
    providerId: "ar-OM-AyshaNeural",
    slug: "ar-OM-AyshaNeural",
    name: "Aysha",
    language: "Arabic (Oman)",
    languageCode: "ar-OM",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3452bf20-b8c8-4843-8da6-25607fef69f1.wav",
  },
  {
    id: "f0461636-d702-4711-839b-970c25202723",
    provider: "azure",
    providerId: "ar-QA-AmalNeural",
    slug: "ar-QA-AmalNeural",
    name: "Amal",
    language: "Arabic (Qatar)",
    languageCode: "ar-QA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f339447d-4ce3-4f16-9d06-ee19038f25f9.wav",
  },
  {
    id: "7ea173b8-321d-4c4c-9e04-827873ad26e3",
    provider: "azure",
    providerId: "ar-QA-MoazNeural",
    slug: "ar-QA-MoazNeural",
    name: "Moaz",
    language: "Arabic (Qatar)",
    languageCode: "ar-QA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/39e5eb4c-1f09-43ee-8b6c-8306dcc526d3.wav",
  },
  {
    id: "5687bbd4-b59b-4856-b5c1-4deedc697fa9",
    provider: "azure",
    providerId: "ar-SA-HamedNeural",
    slug: "ar-SA-HamedNeural",
    name: "Hamed",
    language: "Arabic (Saudi Arabia)",
    languageCode: "ar-SA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6607bba4-651d-4e96-bd83-cf3a0417e9ef.wav",
  },
  {
    id: "4c04b600-1825-4f85-95c4-674a1d1e9a31",
    provider: "azure",
    providerId: "ar-SA-ZariyahNeural",
    slug: "ar-SA-ZariyahNeural",
    name: "Zariyah",
    language: "Arabic (Saudi Arabia)",
    languageCode: "ar-SA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/abfadf81-81af-4726-b1f6-02b744d298d0.wav",
  },
  {
    id: "94cf9685-a6ca-4260-9d8d-e5f3bba05ac0",
    provider: "azure",
    providerId: "ar-SY-AmanyNeural",
    slug: "ar-SY-AmanyNeural",
    name: "Amany",
    language: "Arabic (Syria)",
    languageCode: "ar-SY",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5381c742-3a8e-4045-a774-26f07798c90c.wav",
  },
  {
    id: "5acd3a2d-9366-4748-a619-570595fcb990",
    provider: "azure",
    providerId: "ar-SY-LaithNeural",
    slug: "ar-SY-LaithNeural",
    name: "Laith",
    language: "Arabic (Syria)",
    languageCode: "ar-SY",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/780585b8-903c-4ce9-a226-d26622a28318.wav",
  },
  {
    id: "daa289e6-051e-4435-a233-3b324a1c622f",
    provider: "azure",
    providerId: "ar-TN-HediNeural",
    slug: "ar-TN-HediNeural",
    name: "Hedi",
    language: "Arabic (Tunisia)",
    languageCode: "ar-TN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e06ef33c-14d0-4414-a2a1-0bd0ca87df08.wav",
  },
  {
    id: "5bf23237-aa2a-4ecb-863a-d3b300addded",
    provider: "azure",
    providerId: "ar-TN-ReemNeural",
    slug: "ar-TN-ReemNeural",
    name: "Reem",
    language: "Arabic (Tunisia)",
    languageCode: "ar-TN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/05ebcfbc-f09b-48f3-9bd0-414bc29de597.wav",
  },
  {
    id: "f577ce79-9830-4688-842a-669120847135",
    provider: "azure",
    providerId: "ar-YE-MaryamNeural",
    slug: "ar-YE-MaryamNeural",
    name: "Maryam",
    language: "Arabic (Yemen)",
    languageCode: "ar-YE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1fd20393-bb00-480c-981d-47338d84f226.wav",
  },
  {
    id: "98624aa2-fc8c-4c66-9539-cce4904e49ea",
    provider: "azure",
    providerId: "ar-YE-SalehNeural",
    slug: "ar-YE-SalehNeural",
    name: "Saleh",
    language: "Arabic (Yemen)",
    languageCode: "ar-YE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cfcb010f-919e-4d18-9f67-28828184398b.wav",
  },
  {
    id: "73f8df5a-7ad9-48ab-ab07-cffabd0cb57b",
    provider: "azure",
    providerId: "az-AZ-BabekNeural",
    slug: "az-AZ-BabekNeural",
    name: "Babek",
    language: "Azerbaijani (Latin, Azerbaijan)",
    languageCode: "az-AZ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0fbd8fa1-bad5-4286-918b-858b6a658f7e.wav",
  },
  {
    id: "f1dc4118-c6b8-458d-9c87-ae825dfede3a",
    provider: "azure",
    providerId: "az-AZ-BanuNeural",
    slug: "az-AZ-BanuNeural",
    name: "Banu",
    language: "Azerbaijani (Latin, Azerbaijan)",
    languageCode: "az-AZ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/069d9e5e-afde-494e-bc1b-bb41481dac48.wav",
  },
  {
    id: "3e4a516f-28f1-450f-b44d-1f640dabca59",
    provider: "azure",
    providerId: "bg-BG-BorislavNeural",
    slug: "bg-BG-BorislavNeural",
    name: "Borislav",
    language: "Bulgarian (Bulgaria)",
    languageCode: "bg-BG",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8e5e2532-abf2-40b3-bc65-ba435f95d300.wav",
  },
  {
    id: "4d1ed37c-ba05-436a-81d7-bef9161be92a",
    provider: "azure",
    providerId: "bg-BG-KalinaNeural",
    slug: "bg-BG-KalinaNeural",
    name: "Kalina",
    language: "Bulgarian (Bulgaria)",
    languageCode: "bg-BG",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/dc931ada-1711-410b-8a35-98076b90d561.wav",
  },
  {
    id: "46411bac-d825-45a1-b5f4-2e2468d63ac8",
    provider: "azure",
    providerId: "bn-BD-NabanitaNeural",
    slug: "bn-BD-NabanitaNeural",
    name: "Nabanita",
    language: "Bangla (Bangladesh)",
    languageCode: "bn-BD",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/31f5508c-1c17-4967-bf2f-9f69209f6cc1.wav",
  },
  {
    id: "c10861ba-802a-4865-9495-0f7924e3eca4",
    provider: "azure",
    providerId: "bn-BD-PradeepNeural",
    slug: "bn-BD-PradeepNeural",
    name: "Pradeep",
    language: "Bangla (Bangladesh)",
    languageCode: "bn-BD",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5b1cc7e5-872d-44c1-b3e3-c9ed36c5aeaa.wav",
  },
  {
    id: "d5cf6f82-9e5f-41c0-a5c5-40a577b8f2eb",
    provider: "azure",
    providerId: "bn-IN-BashkarNeural",
    slug: "bn-IN-BashkarNeural",
    name: "Bashkar",
    language: "Bengali (India)",
    languageCode: "bn-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1efe1472-640d-43a2-be44-ebe0f5d59b3f.wav",
  },
  {
    id: "56b42a02-17a6-4296-90e0-84661463fe10",
    provider: "azure",
    providerId: "bn-IN-TanishaaNeural",
    slug: "bn-IN-TanishaaNeural",
    name: "Tanishaa",
    language: "Bengali (India)",
    languageCode: "bn-IN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1a9fbacd-e610-4979-a552-860180142fa7.wav",
  },
  {
    id: "825ee09d-3746-4ffd-b84b-b0e62a27b74f",
    provider: "azure",
    providerId: "bs-BA-GoranNeural",
    slug: "bs-BA-GoranNeural",
    name: "Goran",
    language: "Bosnian (Bosnia and Herzegovina)",
    languageCode: "bs-BA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0c69ff1a-3e70-4397-bf8c-4d0e7be97bc0.wav",
  },
  {
    id: "b6bbede0-afc8-4150-813c-fa5cd64cef8f",
    provider: "azure",
    providerId: "bs-BA-VesnaNeural",
    slug: "bs-BA-VesnaNeural",
    name: "Vesna",
    language: "Bosnian (Bosnia and Herzegovina)",
    languageCode: "bs-BA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7d875bcc-03e2-45d5-90e8-329f76987e24.wav",
  },
  {
    id: "db644c34-d61e-430a-9767-3193e83312da",
    provider: "azure",
    providerId: "ca-ES-AlbaNeural",
    slug: "ca-ES-AlbaNeural",
    name: "Alba",
    language: "Catalan (Spain)",
    languageCode: "ca-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/78dc74a9-c422-4111-aab8-f98baaa2c3c9.wav",
  },
  {
    id: "c417894b-e776-4731-b567-ac634837fb71",
    provider: "azure",
    providerId: "ca-ES-EnricNeural",
    slug: "ca-ES-EnricNeural",
    name: "Enric",
    language: "Catalan (Spain)",
    languageCode: "ca-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5facd27f-ca38-4258-b18b-0b2e4f761924.wav",
  },
  {
    id: "871766b6-5dfe-456f-b605-65c2615e4e56",
    provider: "azure",
    providerId: "ca-ES-JoanaNeural",
    slug: "ca-ES-JoanaNeural",
    name: "Joana",
    language: "Catalan (Spain)",
    languageCode: "ca-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c2768c5e-8fb1-4935-84d1-ac3428f717c1.wav",
  },
  {
    id: "d0d3176d-d593-48fe-bbac-6a641b7f4f2f",
    provider: "azure",
    providerId: "cs-CZ-AntoninNeural",
    slug: "cs-CZ-AntoninNeural",
    name: "Antonin",
    language: "Czech (Czechia)",
    languageCode: "cs-CZ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f5c6fc80-1761-45f3-8f16-4ebe027be40b.wav",
  },
  {
    id: "04a0959c-c98b-4f65-a1e0-6a864564029c",
    provider: "azure",
    providerId: "cs-CZ-VlastaNeural",
    slug: "cs-CZ-VlastaNeural",
    name: "Vlasta",
    language: "Czech (Czechia)",
    languageCode: "cs-CZ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/71e2a0b6-a074-463a-86b9-c37c7f1a93a7.wav",
  },
  {
    id: "af8b6811-fed9-464e-8e24-22146dfaa5c5",
    provider: "azure",
    providerId: "cy-GB-AledNeural",
    slug: "cy-GB-AledNeural",
    name: "Aled",
    language: "Welsh (United Kingdom)",
    languageCode: "cy-GB",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c4ae0a75-9f16-4ca3-b417-34fe5881cd0d.wav",
  },
  {
    id: "371a8b04-cab8-4b5f-8ed2-44504c615e6d",
    provider: "azure",
    providerId: "cy-GB-NiaNeural",
    slug: "cy-GB-NiaNeural",
    name: "Nia",
    language: "Welsh (United Kingdom)",
    languageCode: "cy-GB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/96488e8c-df1a-470e-ad47-dd5a5e6da497.wav",
  },
  {
    id: "a4d6b01b-93aa-4f8e-abc4-313c19295a07",
    provider: "azure",
    providerId: "da-DK-ChristelNeural",
    slug: "da-DK-ChristelNeural",
    name: "Christel",
    language: "Danish (Denmark)",
    languageCode: "da-DK",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3928291a-dd16-494e-8234-851de5c0c5e3.wav",
  },
  {
    id: "e1479a19-4c5c-4ed3-88e7-fbd48d47b3fe",
    provider: "azure",
    providerId: "da-DK-JeppeNeural",
    slug: "da-DK-JeppeNeural",
    name: "Jeppe",
    language: "Danish (Denmark)",
    languageCode: "da-DK",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/811102a2-61b5-4acd-98c3-468539610dc0.wav",
  },
  {
    id: "187f00ce-5720-4546-9034-11ec44a6dbe3",
    provider: "azure",
    providerId: "de-AT-IngridNeural",
    slug: "de-AT-IngridNeural",
    name: "Ingrid",
    language: "German (Austria)",
    languageCode: "de-AT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f818f2e1-1187-44ff-a162-a2013849cc31.wav",
  },
  {
    id: "f3339c33-5ae0-40fd-8ad1-4dab618044e0",
    provider: "azure",
    providerId: "de-AT-JonasNeural",
    slug: "de-AT-JonasNeural",
    name: "Jonas",
    language: "German (Austria)",
    languageCode: "de-AT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8cac22be-e08c-4920-af19-ffb8cfdfb37a.wav",
  },
  {
    id: "f86d3af6-67f6-4965-bc3d-10d2c79931e1",
    provider: "azure",
    providerId: "de-CH-JanNeural",
    slug: "de-CH-JanNeural",
    name: "Jan",
    language: "German (Switzerland)",
    languageCode: "de-CH",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e621881c-9cfe-41b3-a7a6-4b2c01cb4305.wav",
  },
  {
    id: "81b288c5-8bf0-40a0-a3ca-30bba8431785",
    provider: "azure",
    providerId: "de-CH-LeniNeural",
    slug: "de-CH-LeniNeural",
    name: "Leni",
    language: "German (Switzerland)",
    languageCode: "de-CH",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/feb1cab1-1216-428b-9bce-4b19f379738c.wav",
  },
  {
    id: "d37e4a71-6fdd-4d2c-9c9d-e8e0808bed34",
    provider: "azure",
    providerId: "de-DE-AmalaNeural",
    slug: "de-DE-AmalaNeural",
    name: "Amala",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/649e140a-4eac-4b66-9a26-a1953a7ad589.wav",
  },
  {
    id: "2a938346-efac-4423-8fb1-8a774b63bdeb",
    provider: "azure",
    providerId: "de-DE-BerndNeural",
    slug: "de-DE-BerndNeural",
    name: "Bernd",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0bce3cc0-7d9b-4de4-a013-edb20a075acf.wav",
  },
  {
    id: "ebd0e35f-4e0f-4b02-b2b5-61929ed13b78",
    provider: "azure",
    providerId: "de-DE-ChristophNeural",
    slug: "de-DE-ChristophNeural",
    name: "Christoph",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f49d3d2c-d5ef-4a77-86cd-714eaee9af75.wav",
  },
  {
    id: "b91726ea-2377-4bde-a143-e30d83b59b30",
    provider: "azure",
    providerId: "de-DE-ConradNeural",
    slug: "de-DE-ConradNeural",
    name: "Conrad",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6a851986-fa28-463f-900f-d1856d2c8e11.wav",
  },
  {
    id: "40201821-d399-4c33-b95a-42329a364afa",
    provider: "azure",
    providerId: "de-DE-ElkeNeural",
    slug: "de-DE-ElkeNeural",
    name: "Elke",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4fc24fdd-7302-42c0-8e4f-b84996797f20.wav",
  },
  {
    id: "28b5b7cc-2959-475c-bd99-5ec63375c965",
    provider: "azure",
    providerId: "de-DE-FlorianMultilingualNeural",
    slug: "de-DE-FlorianMultilingualNeural",
    name: "Florian Multilingual",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cb595d69-bb26-494d-aff1-0867831f9c57.wav",
  },
  {
    id: "83b9701d-2eec-4d11-a97f-dc5394d59c67",
    provider: "azure",
    providerId: "de-DE-GiselaNeural",
    slug: "de-DE-GiselaNeural",
    name: "Gisela",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e79e87ca-48e2-431b-85be-250e459c0d7e.wav",
  },
  {
    id: "04227202-e552-4137-9eee-7ef444a2ee70",
    provider: "azure",
    providerId: "de-DE-KasperNeural",
    slug: "de-DE-KasperNeural",
    name: "Kasper",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/32efe0c7-9a01-4452-8bac-ec42a5ec3185.wav",
  },
  {
    id: "34525561-c0fc-4197-a331-3fde9acb7996",
    provider: "azure",
    providerId: "de-DE-KatjaNeural",
    slug: "de-DE-KatjaNeural",
    name: "Katja",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1eab83e7-7e40-4710-8773-a3484dcfa254.wav",
  },
  {
    id: "1a3975cf-ba10-45c1-8ee2-cce74b483c38",
    provider: "azure",
    providerId: "de-DE-KillianNeural",
    slug: "de-DE-KillianNeural",
    name: "Killian",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1cdc07c4-16c2-446e-935c-c1d03efc6718.wav",
  },
  {
    id: "8495a1be-1532-4bb7-a03f-52b68aa2f6fc",
    provider: "azure",
    providerId: "de-DE-KlarissaNeural",
    slug: "de-DE-KlarissaNeural",
    name: "Klarissa",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cf986675-1928-47d4-8a56-197475ba34b8.wav",
  },
  {
    id: "aa0db145-4a73-413c-b49f-4a010d4b939d",
    provider: "azure",
    providerId: "de-DE-KlausNeural",
    slug: "de-DE-KlausNeural",
    name: "Klaus",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9e07d006-3060-4849-893d-73f2e59761f9.wav",
  },
  {
    id: "99474121-7ed5-4185-8b5f-da068728c06a",
    provider: "azure",
    providerId: "de-DE-LouisaNeural",
    slug: "de-DE-LouisaNeural",
    name: "Louisa",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2dc41262-fd6a-4975-ac9e-707c76a7cb29.wav",
  },
  {
    id: "a1deb2a4-7628-4f96-b1fa-d0ce3b7f4a4c",
    provider: "azure",
    providerId: "de-DE-MajaNeural",
    slug: "de-DE-MajaNeural",
    name: "Maja",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/58ee69f3-2026-44ca-909f-39e522775314.wav",
  },
  {
    id: "e63e24b2-8338-4476-b36b-1dfe87c707f6",
    provider: "azure",
    providerId: "de-DE-RalfNeural",
    slug: "de-DE-RalfNeural",
    name: "Ralf",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0bfcea35-745a-4cf1-951c-fac509068883.wav",
  },
  {
    id: "613614ee-5b4d-4a37-b5bd-58bbcff22508",
    provider: "azure",
    providerId: "de-DE-SeraphinaMultilingualNeural",
    slug: "de-DE-SeraphinaMultilingualNeural",
    name: "Seraphina Multilingual",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ae32430b-567d-4875-9df2-7c2c1cffed8b.wav",
  },
  {
    id: "7370521c-25a2-43f5-8cdb-ccefe996149a",
    provider: "azure",
    providerId: "de-DE-TanjaNeural",
    slug: "de-DE-TanjaNeural",
    name: "Tanja",
    language: "German (Germany)",
    languageCode: "de-DE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/832ff709-c25c-4881-a724-f84d9856fb99.wav",
  },
  {
    id: "22822a7f-1bd5-44ed-a6ee-739ab9dbe0d9",
    provider: "azure",
    providerId: "el-GR-AthinaNeural",
    slug: "el-GR-AthinaNeural",
    name: "Athina",
    language: "Greek (Greece)",
    languageCode: "el-GR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/18c70d1d-86d6-4298-8ed8-829d2fa539b8.wav",
  },
  {
    id: "dfc24ff9-0b9b-442c-acd4-7932b30d4029",
    provider: "azure",
    providerId: "el-GR-NestorasNeural",
    slug: "el-GR-NestorasNeural",
    name: "Nestoras",
    language: "Greek (Greece)",
    languageCode: "el-GR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c2f0a28c-9043-4c4e-a9dc-76012a7143e9.wav",
  },
  {
    id: "78edc64d-cfc2-4b94-9338-9c09a7b97a1e",
    provider: "azure",
    providerId: "en-AU-AnnetteNeural",
    slug: "en-AU-AnnetteNeural",
    name: "Annette",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7e0820c2-eb29-4147-a368-14be2b484f9c.wav",
  },
  {
    id: "8f59c3e5-ccb6-4d90-a340-3e56360f8828",
    provider: "azure",
    providerId: "en-AU-CarlyNeural",
    slug: "en-AU-CarlyNeural",
    name: "Carly",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ab0af5a5-596c-4689-b49a-b21eee72bea3.wav",
  },
  {
    id: "b7fa52a9-9abd-40ed-986b-c2b3edc160fb",
    provider: "azure",
    providerId: "en-AU-DarrenNeural",
    slug: "en-AU-DarrenNeural",
    name: "Darren",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f467111d-71b1-4337-a2ec-2e83e14728a1.wav",
  },
  {
    id: "d30c77ae-2b9b-4926-acdb-4af724125b24",
    provider: "azure",
    providerId: "en-AU-DuncanNeural",
    slug: "en-AU-DuncanNeural",
    name: "Duncan",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/dba9e7fb-ac0d-4b23-ace1-17a91e79c1d1.wav",
  },
  {
    id: "ef5e803e-fe8e-4c9c-a603-cbcf0e608169",
    provider: "azure",
    providerId: "en-AU-ElsieNeural",
    slug: "en-AU-ElsieNeural",
    name: "Elsie",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/38fd987a-89a9-4d8f-a1a9-d9fb32336905.wav",
  },
  {
    id: "4af0fb1a-0f8e-4b13-a89d-3c6378ff1ddc",
    provider: "azure",
    providerId: "en-AU-FreyaNeural",
    slug: "en-AU-FreyaNeural",
    name: "Freya",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e057de8e-8fa0-4cfd-beba-f1f1f145b2bd.wav",
  },
  {
    id: "bfd05449-39c5-4a5e-af27-cfc536e04b90",
    provider: "azure",
    providerId: "en-AU-JoanneNeural",
    slug: "en-AU-JoanneNeural",
    name: "Joanne",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5051d156-c9a0-46f3-8b47-9889f019ba41.wav",
  },
  {
    id: "df02bb61-83c0-4aa5-9d6d-8aff5bf399e6",
    provider: "azure",
    providerId: "en-AU-KenNeural",
    slug: "en-AU-KenNeural",
    name: "Ken",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a05db58b-2275-4867-99d9-bc0b484f5286.wav",
  },
  {
    id: "67de62ba-739e-4100-b43b-312d6d2dfc82",
    provider: "azure",
    providerId: "en-AU-KimNeural",
    slug: "en-AU-KimNeural",
    name: "Kim",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c2f76f9b-df8b-4656-9fd2-274bb74501d9.wav",
  },
  {
    id: "994eca53-7a50-43e9-9458-c9e67aeca766",
    provider: "azure",
    providerId: "en-AU-NatashaNeural",
    slug: "en-AU-NatashaNeural",
    name: "Natasha",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/329fe0ca-1833-400f-835b-ab66ab2441e9.wav",
  },
  {
    id: "e9775779-01a6-4e70-9111-bf787d0ea1d6",
    provider: "azure",
    providerId: "en-AU-NeilNeural",
    slug: "en-AU-NeilNeural",
    name: "Neil",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/068693d9-97b1-41e5-90c1-522f6acc88c1.wav",
  },
  {
    id: "d7d8a0c8-13a0-4a47-8f8c-c71ba063ef9b",
    provider: "azure",
    providerId: "en-AU-TimNeural",
    slug: "en-AU-TimNeural",
    name: "Tim",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1569384e-7e79-46ea-b888-483bb795ffe9.wav",
  },
  {
    id: "be874ed3-0e60-4fe8-ad35-0d0c6b96409a",
    provider: "azure",
    providerId: "en-AU-TinaNeural",
    slug: "en-AU-TinaNeural",
    name: "Tina",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/aaee2bd8-96ca-4cef-a84b-b8cd17ccd864.wav",
  },
  {
    id: "34804c4f-e45f-4259-8136-1191e3500b0b",
    provider: "azure",
    providerId: "en-AU-WilliamNeural",
    slug: "en-AU-WilliamNeural",
    name: "William",
    language: "English (Australia)",
    languageCode: "en-AU",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9029e669-ca42-4a49-8c4d-1b96175215e0.wav",
  },
  {
    id: "1ae5a0a2-49a3-4e88-8d40-99e5a0ab8774",
    provider: "azure",
    providerId: "en-CA-ClaraNeural",
    slug: "en-CA-ClaraNeural",
    name: "Clara",
    language: "English (Canada)",
    languageCode: "en-CA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d9319c91-2292-42aa-8276-88be5f3abc94.wav",
  },
  {
    id: "99ea271e-a468-4d5e-bd58-c80bcc5f8e4e",
    provider: "azure",
    providerId: "en-CA-LiamNeural",
    slug: "en-CA-LiamNeural",
    name: "Liam",
    language: "English (Canada)",
    languageCode: "en-CA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f1b978a6-7a42-4471-b6ec-a92fc1d92ad8.wav",
  },
  {
    id: "c8af9ba6-2293-44ac-bb37-fbde170c6c06",
    provider: "azure",
    providerId: "en-GB-AbbiNeural",
    slug: "en-GB-AbbiNeural",
    name: "Abbi",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/bbaab200-438c-4f63-a50e-264601f3e97f.wav",
  },
  {
    id: "c97fd101-5589-43a2-94ec-261bb3cfaf98",
    provider: "azure",
    providerId: "en-GB-AlfieNeural",
    slug: "en-GB-AlfieNeural",
    name: "Alfie",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/dbdf4a81-c8fa-48ad-913c-954735e55795.wav",
  },
  {
    id: "9c423606-1e9f-4116-8954-2729e264ea7b",
    provider: "azure",
    providerId: "en-GB-BellaNeural",
    slug: "en-GB-BellaNeural",
    name: "Bella",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/87182ba1-c382-468f-98f0-1f3945e651ee.wav",
  },
  {
    id: "999de72a-2cb1-404a-ae6a-dcb1bfddac4b",
    provider: "azure",
    providerId: "en-GB-ElliotNeural",
    slug: "en-GB-ElliotNeural",
    name: "Elliot",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/86d8811d-a759-414a-8827-36e4d22f9fb4.wav",
  },
  {
    id: "63ee29d5-016e-4e9a-9082-cc2b5253d3aa",
    provider: "azure",
    providerId: "en-GB-EthanNeural",
    slug: "en-GB-EthanNeural",
    name: "Ethan",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/42db03f1-ac95-4036-b9b7-dd93be36c6b8.wav",
  },
  {
    id: "bc990d67-a57a-4f2d-8917-d68dc85d0259",
    provider: "azure",
    providerId: "en-GB-HollieNeural",
    slug: "en-GB-HollieNeural",
    name: "Hollie",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/790d288d-d17e-4383-a071-5e7de8be35b6.wav",
  },
  {
    id: "5d372351-6399-4e9d-8890-941c45a4fb35",
    provider: "azure",
    providerId: "en-GB-LibbyNeural",
    slug: "en-GB-LibbyNeural",
    name: "Libby",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a9d95b6b-9074-47fe-9e03-e425770c6bcc.wav",
  },
  {
    id: "015c892d-a1ba-4e78-b274-6d5dc592bb6b",
    provider: "azure",
    providerId: "en-GB-MaisieNeural",
    slug: "en-GB-MaisieNeural",
    name: "Maisie",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4885a9f3-f048-4122-81a9-a9e5bdd006e2.wav",
  },
  {
    id: "2bee8bc9-abaa-4b03-979f-73d0c63f5f81",
    provider: "azure",
    providerId: "en-GB-MiaNeural",
    slug: "en-GB-MiaNeural",
    name: "Mia",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5559aab4-7226-4f02-a92c-464c4d0fdddc.wav",
  },
  {
    id: "b0396cef-95de-43d2-b532-a8b961b7ee8f",
    provider: "azure",
    providerId: "en-GB-NoahNeural",
    slug: "en-GB-NoahNeural",
    name: "Noah",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5faaa64f-2334-4c08-a0e8-f8b6739d6a3a.wav",
  },
  {
    id: "e2c96ad8-41f0-47c6-8d00-126cc00f272a",
    provider: "azure",
    providerId: "en-GB-OliverNeural",
    slug: "en-GB-OliverNeural",
    name: "Oliver",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3f9dbb0d-ddcf-4c11-b57e-0448afc4bc62.wav",
  },
  {
    id: "f746cdbf-5dad-49fa-8199-46f7d3aae485",
    provider: "azure",
    providerId: "en-GB-OliviaNeural",
    slug: "en-GB-OliviaNeural",
    name: "Olivia",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/083c9f4c-0fa1-4c9b-86c6-0acbe1004a19.wav",
  },
  {
    id: "5fdeade0-ca19-4148-9c21-42145af92474",
    provider: "azure",
    providerId: "en-GB-RyanNeural",
    slug: "en-GB-RyanNeural",
    name: "Ryan",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/15229d56-9bc7-4d6d-a2fc-aa0c747f6f06.wav",
  },
  {
    id: "6dcc3a72-0bd6-41bd-888b-bb3d1c5c4897",
    provider: "azure",
    providerId: "en-GB-SoniaNeural",
    slug: "en-GB-SoniaNeural",
    name: "Sonia",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9ddfa386-a256-4b5b-a64f-f3fbfd761851.wav",
  },
  {
    id: "56a7809b-f6e2-4a5a-a86d-f8264bbaada9",
    provider: "azure",
    providerId: "en-GB-ThomasNeural",
    slug: "en-GB-ThomasNeural",
    name: "Thomas",
    language: "English (United Kingdom)",
    languageCode: "en-GB",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7ebb8132-23af-455f-abfd-31e1708537cd.wav",
  },
  {
    id: "dcc7536b-2bbb-41d3-8b56-94a03f200d0f",
    provider: "azure",
    providerId: "en-HK-SamNeural",
    slug: "en-HK-SamNeural",
    name: "Sam",
    language: "English (Hong Kong SAR)",
    languageCode: "en-HK",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/71dbda59-b281-4104-9ef1-52bb0dac7135.wav",
  },
  {
    id: "97c6ed50-240b-4fc5-8fa8-756ec4af8f3e",
    provider: "azure",
    providerId: "en-HK-YanNeural",
    slug: "en-HK-YanNeural",
    name: "Yan",
    language: "English (Hong Kong SAR)",
    languageCode: "en-HK",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/719e9030-f655-4b8e-a4af-7489e187945b.wav",
  },
  {
    id: "ac633aac-c445-4256-b392-510b38441269",
    provider: "azure",
    providerId: "en-IE-ConnorNeural",
    slug: "en-IE-ConnorNeural",
    name: "Connor",
    language: "English (Ireland)",
    languageCode: "en-IE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5550f9ce-e467-4e4e-9b07-da7b32b9b0b0.wav",
  },
  {
    id: "fa31f3e8-8708-4893-b972-dd5daa2937fd",
    provider: "azure",
    providerId: "en-IE-EmilyNeural",
    slug: "en-IE-EmilyNeural",
    name: "Emily",
    language: "English (Ireland)",
    languageCode: "en-IE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3d749aeb-35bb-41cf-a9af-ad1f10ebd68b.wav",
  },
  {
    id: "43879cfc-b55e-40b1-aa13-64d4272ed776",
    provider: "azure",
    providerId: "en-IN-PrabhatNeural",
    slug: "en-IN-PrabhatNeural",
    name: "Prabhat",
    language: "English (India)",
    languageCode: "en-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e9511f9f-4d69-4962-85ea-e79164cbec08.wav",
  },
  {
    id: "ae9678d7-b508-4ede-a63b-834fa4dcb5d5",
    provider: "azure",
    providerId: "en-KE-AsiliaNeural",
    slug: "en-KE-AsiliaNeural",
    name: "Asilia",
    language: "English (Kenya)",
    languageCode: "en-KE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/394faf85-937c-486b-b830-2a280955ce30.wav",
  },
  {
    id: "3e5f9ebd-d451-42db-a5e6-4d999c1cbb19",
    provider: "azure",
    providerId: "en-KE-ChilembaNeural",
    slug: "en-KE-ChilembaNeural",
    name: "Chilemba",
    language: "English (Kenya)",
    languageCode: "en-KE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/41bfe479-bfa4-454b-9eba-80ae6c5fb063.wav",
  },
  {
    id: "d91ace87-8918-4d1d-b64f-d96ff86989f2",
    provider: "azure",
    providerId: "en-NG-AbeoNeural",
    slug: "en-NG-AbeoNeural",
    name: "Abeo",
    language: "English (Nigeria)",
    languageCode: "en-NG",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/56c1309b-ca72-40ed-9176-5d7fefd15c78.wav",
  },
  {
    id: "3501aba9-b1eb-41b1-b614-4b8c90f6e958",
    provider: "azure",
    providerId: "en-NG-EzinneNeural",
    slug: "en-NG-EzinneNeural",
    name: "Ezinne",
    language: "English (Nigeria)",
    languageCode: "en-NG",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/abceb7e0-b6da-43b1-bbf1-718f7718f8be.wav",
  },
  {
    id: "7b28a122-b8c7-482a-803c-a8159d37790e",
    provider: "azure",
    providerId: "en-NZ-MitchellNeural",
    slug: "en-NZ-MitchellNeural",
    name: "Mitchell",
    language: "English (New Zealand)",
    languageCode: "en-NZ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4323156e-af5b-42a8-bfda-3543dd12bd3d.wav",
  },
  {
    id: "32a1f0dc-0cbb-46a8-be14-d79cfd9cc3d3",
    provider: "azure",
    providerId: "en-NZ-MollyNeural",
    slug: "en-NZ-MollyNeural",
    name: "Molly",
    language: "English (New Zealand)",
    languageCode: "en-NZ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9007fce8-2db5-4dfb-a4c3-344367206994.wav",
  },
  {
    id: "953fdb82-c1a9-4403-88b1-dead9d831a6c",
    provider: "azure",
    providerId: "en-PH-JamesNeural",
    slug: "en-PH-JamesNeural",
    name: "James",
    language: "English (Philippines)",
    languageCode: "en-PH",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5472efdd-9bc0-4928-96c5-bc5b1b2512c3.wav",
  },
  {
    id: "9c68c8c0-1adc-4c38-8eff-beb9cbe81f2d",
    provider: "azure",
    providerId: "en-PH-RosaNeural",
    slug: "en-PH-RosaNeural",
    name: "Rosa",
    language: "English (Philippines)",
    languageCode: "en-PH",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/53e4a7a0-878a-4d80-ac6e-f2b08200128d.wav",
  },
  {
    id: "c4d0873d-5b94-4d9a-97d4-2f44a81d5f83",
    provider: "azure",
    providerId: "en-SG-LunaNeural",
    slug: "en-SG-LunaNeural",
    name: "Luna",
    language: "English (Singapore)",
    languageCode: "en-SG",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/38504a7a-c07b-4823-9755-490e9d4879c6.wav",
  },
  {
    id: "25633d05-497b-4863-9db1-1aaa47c090b1",
    provider: "azure",
    providerId: "en-SG-WayneNeural",
    slug: "en-SG-WayneNeural",
    name: "Wayne",
    language: "English (Singapore)",
    languageCode: "en-SG",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/158bd981-b563-4b9b-aaef-6c731c839499.wav",
  },
  {
    id: "faaac53a-77de-48d9-b897-b5628ef00085",
    provider: "azure",
    providerId: "en-TZ-ElimuNeural",
    slug: "en-TZ-ElimuNeural",
    name: "Elimu",
    language: "English (Tanzania)",
    languageCode: "en-TZ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1e5885bf-b1ee-4edc-8099-e1b3b1823855.wav",
  },
  {
    id: "bbd0d2b5-494f-476d-9efb-767079e7a9ef",
    provider: "azure",
    providerId: "en-TZ-ImaniNeural",
    slug: "en-TZ-ImaniNeural",
    name: "Imani",
    language: "English (Tanzania)",
    languageCode: "en-TZ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c5c2704a-8af5-4690-81d3-32cd4579b896.wav",
  },
  {
    id: "58002da8-439e-48d8-9d8b-22dce64bd6b3",
    provider: "azure",
    providerId: "en-US-AIGenerate1Neural",
    slug: "en-US-AIGenerate1Neural",
    name: "AIGenerate1",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "8eca70c7-d8ab-4118-ba92-8579d58be560",
    provider: "azure",
    providerId: "en-US-AIGenerate2Neural",
    slug: "en-US-AIGenerate2Neural",
    name: "AIGenerate2",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "29ad0331-12db-47ad-b5bd-ab6fd10899ee",
    provider: "azure",
    providerId: "en-US-AmberNeural",
    slug: "en-US-AmberNeural",
    name: "Amber",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0ab80d62-da25-474d-8373-fbb6bacc7695.wav",
  },
  {
    id: "947e9a16-61a8-4438-93cc-100cfb16431f",
    provider: "azure",
    providerId: "en-US-AnaNeural",
    slug: "en-US-AnaNeural",
    name: "Ana",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d84892a1-a196-43bb-8661-55136be1d15c.wav",
  },
  {
    id: "283a9d69-9f6f-4c0e-83b3-d29e19df4574",
    provider: "azure",
    providerId: "en-US-AndrewMultilingualNeural",
    slug: "en-US-AndrewMultilingualNeural",
    name: "Andrew Multilingual",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/681bbd9a-e332-429b-9b98-96e44780ac70.wav",
  },
  {
    id: "16d3e4a5-30f8-45bd-815f-1210be35b8e8",
    provider: "azure",
    providerId: "en-US-AndrewNeural",
    slug: "andrew",
    name: "Andrew",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/729938a4-f36a-419a-876f-bb611cc50c89.wav",
  },
  {
    id: "c794ce16-9e91-462d-89ea-767bba3ffa1f",
    provider: "azure",
    providerId: "en-US-AriaNeural",
    slug: "en-US-AriaNeural",
    name: "Aria",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/23e52a48-2282-4fb0-8c57-948b2bba5b1e.wav",
  },
  {
    id: "860165b7-9cf3-4c3d-afd7-1caf7ec4ee66",
    provider: "azure",
    providerId: "en-US-AshleyNeural",
    slug: "en-US-AshleyNeural",
    name: "Ashley",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f2ffd38a-5268-4a0a-8950-eb6f849c55b0.wav",
  },
  {
    id: "b2912614-84e2-41d6-ba50-4a492075b635",
    provider: "azure",
    providerId: "en-US-AvaMultilingualNeural",
    slug: "en-US-AvaMultilingualNeural",
    name: "Ava Multilingual",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ad28d794-b675-49cc-a234-d77f4baab9c8.wav",
  },
  {
    id: "840c5008-d34c-4ac8-b9e8-3f791280d439",
    provider: "azure",
    providerId: "en-US-AvaNeural",
    slug: "en-US-AvaNeural",
    name: "Ava",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/89e3dd95-09bb-4a0f-b4b8-5f8cd1df0747.wav",
  },
  {
    id: "8aaa7d8c-365c-4a9a-8885-e4caa33496db",
    provider: "azure",
    providerId: "en-US-BlueNeural",
    slug: "en-US-BlueNeural",
    name: "Blue",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "neutral",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "c8e441da-fd7c-4edc-8ab6-2f34cf230830",
    provider: "azure",
    providerId: "en-US-BrandonNeural",
    slug: "en-US-BrandonNeural",
    name: "Brandon",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/dcddce5d-7ae0-449c-8dc9-d169212cae5e.wav",
  },
  {
    id: "96ff1d3f-6875-4947-b6ac-3033b296a154",
    provider: "azure",
    providerId: "en-US-BrianMultilingualNeural",
    slug: "en-US-BrianMultilingualNeural",
    name: "Brian Multilingual",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1b089cb2-93ad-4185-b996-737a3d67cb0c.wav",
  },
  {
    id: "e43ab58d-b570-4847-940a-d64a36fc0d7a",
    provider: "azure",
    providerId: "en-US-BrianNeural",
    slug: "brian",
    name: "Brian",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/095a1eb8-b021-4350-8656-a7c44847c744.wav",
  },
  {
    id: "d80ffef4-3c1f-44a5-a2d2-01c2498b818b",
    provider: "azure",
    providerId: "en-US-ChristopherNeural",
    slug: "en-US-ChristopherNeural",
    name: "Christopher",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/648e7d7e-1a25-4a77-99c6-194585d8b4f3.wav",
  },
  {
    id: "4c1e3d38-bf66-4392-8dad-a6d34b7a2f93",
    provider: "azure",
    providerId: "en-US-CoraNeural",
    slug: "en-US-CoraNeural",
    name: "Cora",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ef283209-a6bd-4f73-8cd6-19f1aa7ef441.wav",
  },
  {
    id: "4f9a430e-7652-4dc3-a145-2f8124e1b539",
    provider: "azure",
    providerId: "en-US-DavisNeural",
    slug: "en-US-DavisNeural",
    name: "Davis",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/60af31c8-806b-4ebc-8fee-f3311956c72d.wav",
  },
  {
    id: "0db8693f-961f-4d16-94bf-c2da2a019e1a",
    provider: "azure",
    providerId: "en-US-ElizabethNeural",
    slug: "en-US-ElizabethNeural",
    name: "Elizabeth",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9fdba972-20d6-45c1-a1ed-c4fd31773a90.wav",
  },
  {
    id: "a0674a6c-6b1d-4b66-ae93-bb64c8a3413c",
    provider: "azure",
    providerId: "en-US-EmmaMultilingualNeural",
    slug: "en-US-EmmaMultilingualNeural",
    name: "Emma Multilingual",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/78001cf4-ed85-4294-92e3-36f0b5abb031.wav",
  },
  {
    id: "a916f87a-55a5-4947-966e-73af3ae766a4",
    provider: "azure",
    providerId: "en-US-EmmaNeural",
    slug: "emma",
    name: "Emma",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8d7c4113-2537-4d0f-b5b5-7a417cef0b7a.wav",
  },
  {
    id: "59036696-deaa-4915-ae0e-0d0d35258f96",
    provider: "azure",
    providerId: "en-US-EricNeural",
    slug: "en-US-EricNeural",
    name: "Eric",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/66934113-ed52-4fc0-b05b-a8b6f19594ba.wav",
  },
  {
    id: "2a5f9edd-7f6c-4917-924a-5111f520bc17",
    provider: "azure",
    providerId: "en-US-GuyNeural",
    slug: "en-US-GuyNeural",
    name: "Guy",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7299d020-c929-448c-b6ae-7ab279ca515e.wav",
  },
  {
    id: "25720ee5-30e0-4d41-8637-5134b4705652",
    provider: "azure",
    providerId: "en-US-JacobNeural",
    slug: "en-US-JacobNeural",
    name: "Jacob",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6fef5fc3-17e2-47bd-8ad1-946cdade4717.wav",
  },
  {
    id: "14e92d58-fee8-4c36-8760-31ec97f675fa",
    provider: "azure",
    providerId: "en-US-JaneNeural",
    slug: "en-US-JaneNeural",
    name: "Jane",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/60855e98-276f-45c9-8346-c96ad08775c5.wav",
  },
  {
    id: "dec0b4bf-994e-4ac6-a255-72ad368812cf",
    provider: "azure",
    providerId: "en-US-JasonNeural",
    slug: "en-US-JasonNeural",
    name: "Jason",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b257252b-8642-4f9d-b551-b240ea746f5a.wav",
  },
  {
    id: "e1f9272b-89bc-432b-9678-669e12312450",
    provider: "azure",
    providerId: "en-US-JennyMultilingualNeural",
    slug: "en-US-JennyMultilingualNeural",
    name: "Jenny Multilingual",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ee4cd417-f9a5-45d9-9491-97d5acdb3117.wav",
  },
  {
    id: "a3442329-a486-4ab0-be18-da9795b3f1e4",
    provider: "azure",
    providerId: "en-US-JennyNeural",
    slug: "en-US-JennyNeural",
    name: "Jenny",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0537cb69-1f22-4706-bfa2-37cad2278324.wav",
  },
  {
    id: "bfa56825-3815-4ef1-a5cd-4e63c54c503e",
    provider: "azure",
    providerId: "en-US-MichelleNeural",
    slug: "en-US-MichelleNeural",
    name: "Michelle",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b026d116-15c8-4dda-9341-b05b6a1090f4.wav",
  },
  {
    id: "2069675f-385f-41b9-8c1d-a2e02732bf90",
    provider: "azure",
    providerId: "en-US-MonicaNeural",
    slug: "en-US-MonicaNeural",
    name: "Monica",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9e40876f-1c32-4e34-a6e5-46468afef462.wav",
  },
  {
    id: "322e1649-b5dd-421f-a587-f63c093f3a3b",
    provider: "azure",
    providerId: "en-US-NancyNeural",
    slug: "en-US-NancyNeural",
    name: "Nancy",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e16a9d1b-d646-4726-b3ea-c5a57e6119df.wav",
  },
  {
    id: "714a38c5-0794-44e7-84df-bbf74e9d3097",
    provider: "azure",
    providerId: "en-US-RogerNeural",
    slug: "en-US-RogerNeural",
    name: "Roger",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/48a9f2a3-60e3-4d7a-8044-f236e2481a56.wav",
  },
  {
    id: "17224a81-8d20-4e00-b521-a11794af491e",
    provider: "azure",
    providerId: "en-US-RyanMultilingualNeural",
    slug: "en-US-RyanMultilingualNeural",
    name: "Ryan Multilingual",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9328f644-1813-41d9-9fb8-ffa1ba27e0ce.wav",
  },
  {
    id: "da44f7cc-85eb-4bbd-a747-cac20d3bb733",
    provider: "azure",
    providerId: "en-US-SaraNeural",
    slug: "en-US-SaraNeural",
    name: "Sara",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/501d1c1b-74d6-4f28-8f5b-3bf882573888.wav",
  },
  {
    id: "2e1349c0-739d-4c38-9d7e-930e9ede5881",
    provider: "azure",
    providerId: "en-US-SteffanNeural",
    slug: "en-US-SteffanNeural",
    name: "Steffan",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/fa74cc2c-d4e8-40dc-a4aa-d5e6f9144ad3.wav",
  },
  {
    id: "c5b2e53f-ad8b-42c3-ad8e-5cbb8e5f8dcb",
    provider: "azure",
    providerId: "en-US-TonyNeural",
    slug: "en-US-TonyNeural",
    name: "Tony",
    language: "English (United States)",
    languageCode: "en-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2497d1c6-aaaf-4e17-a725-3b430f668b80.wav",
  },
  {
    id: "b17b09d7-52e2-4503-8010-e7a1804fe0f0",
    provider: "azure",
    providerId: "en-ZA-LeahNeural",
    slug: "en-ZA-LeahNeural",
    name: "Leah",
    language: "English (South Africa)",
    languageCode: "en-ZA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0e0b02d1-d35c-48aa-b9f2-bd16cc17fa5c.wav",
  },
  {
    id: "aa0fc339-47bb-4418-9f6a-e1bb6c592492",
    provider: "azure",
    providerId: "en-ZA-LukeNeural",
    slug: "en-ZA-LukeNeural",
    name: "Luke",
    language: "English (South Africa)",
    languageCode: "en-ZA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/64cccc45-ff40-412e-9db9-527f1f7fd46d.wav",
  },
  {
    id: "0be4abce-6814-43b9-bb56-2e3634cc1bc9",
    provider: "azure",
    providerId: "es-AR-ElenaNeural",
    slug: "es-AR-ElenaNeural",
    name: "Elena",
    language: "Spanish (Argentina)",
    languageCode: "es-AR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/373a9c8f-8a99-49ea-9ad1-1d38ae091424.wav",
  },
  {
    id: "8b0adffd-5202-4dde-b569-346ab143051b",
    provider: "azure",
    providerId: "es-AR-TomasNeural",
    slug: "es-AR-TomasNeural",
    name: "Tomas",
    language: "Spanish (Argentina)",
    languageCode: "es-AR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/642a8327-ee59-45d5-9f1e-72f661b092c9.wav",
  },
  {
    id: "3a9481cd-59fa-4efb-b97d-15417f00b593",
    provider: "azure",
    providerId: "es-BO-MarceloNeural",
    slug: "es-BO-MarceloNeural",
    name: "Marcelo",
    language: "Spanish (Bolivia)",
    languageCode: "es-BO",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6645b67a-b67e-45b7-9100-09afe5514b65.wav",
  },
  {
    id: "7fbee81f-81b4-4ddf-b289-37147f548762",
    provider: "azure",
    providerId: "es-BO-SofiaNeural",
    slug: "es-BO-SofiaNeural",
    name: "Sofia",
    language: "Spanish (Bolivia)",
    languageCode: "es-BO",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cf25ecdd-cf64-4809-94c5-ad24ebba920f.wav",
  },
  {
    id: "24068439-5fae-43bf-a119-baf36aef6f63",
    provider: "azure",
    providerId: "es-CL-CatalinaNeural",
    slug: "es-CL-CatalinaNeural",
    name: "Catalina",
    language: "Spanish (Chile)",
    languageCode: "es-CL",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4870a9c7-9d9a-47c3-8ce0-ef2f58cf6599.wav",
  },
  {
    id: "d3d1d290-ea5f-48e7-b8de-8297e748faa7",
    provider: "azure",
    providerId: "es-CL-LorenzoNeural",
    slug: "es-CL-LorenzoNeural",
    name: "Lorenzo",
    language: "Spanish (Chile)",
    languageCode: "es-CL",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a2a10fb9-582b-4e9f-ad5e-f38605ed7e15.wav",
  },
  {
    id: "5aa6f525-b044-46f3-a965-2adf8d4c8cb4",
    provider: "azure",
    providerId: "es-CO-GonzaloNeural",
    slug: "es-CO-GonzaloNeural",
    name: "Gonzalo",
    language: "Spanish (Colombia)",
    languageCode: "es-CO",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a11bb4c8-d5da-4b41-8f35-373cb07757af.wav",
  },
  {
    id: "920dcdf4-c69e-4fba-b86e-c6415f604045",
    provider: "azure",
    providerId: "es-CO-SalomeNeural",
    slug: "es-CO-SalomeNeural",
    name: "Salome",
    language: "Spanish (Colombia)",
    languageCode: "es-CO",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a23c2c4e-34a6-46a0-b42e-c84f80b5f565.wav",
  },
  {
    id: "6b75796d-fe35-4ae9-b7c3-ff56b68fe0d0",
    provider: "azure",
    providerId: "es-CR-JuanNeural",
    slug: "es-CR-JuanNeural",
    name: "Juan",
    language: "Spanish (Costa Rica)",
    languageCode: "es-CR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2c909ee2-7a65-45a2-ba4e-86ad23227769.wav",
  },
  {
    id: "fae96208-b5f2-4573-be1f-9143eda45499",
    provider: "azure",
    providerId: "es-CR-MariaNeural",
    slug: "es-CR-MariaNeural",
    name: "Maria",
    language: "Spanish (Costa Rica)",
    languageCode: "es-CR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8e47b644-d54b-40b2-ae20-30bee7713b90.wav",
  },
  {
    id: "977041a2-f1d2-402c-9da4-ceff14c4a5d0",
    provider: "azure",
    providerId: "es-CU-BelkysNeural",
    slug: "es-CU-BelkysNeural",
    name: "Belkys",
    language: "Spanish (Cuba)",
    languageCode: "es-CU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/573c8f02-c57b-49b2-849c-c7a03f4429fb.wav",
  },
  {
    id: "8936060a-da25-496b-ad9e-c64b31bbfee4",
    provider: "azure",
    providerId: "es-CU-ManuelNeural",
    slug: "es-CU-ManuelNeural",
    name: "Manuel",
    language: "Spanish (Cuba)",
    languageCode: "es-CU",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3142a1b5-ff36-45dd-b656-633927d7cbaa.wav",
  },
  {
    id: "abd80653-b6f2-4bc3-ba64-487ebb136fe6",
    provider: "azure",
    providerId: "es-DO-EmilioNeural",
    slug: "es-DO-EmilioNeural",
    name: "Emilio",
    language: "Spanish (Dominican Republic)",
    languageCode: "es-DO",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/48653f4f-b247-4162-825f-0b20d27a0947.wav",
  },
  {
    id: "52be5424-eb99-4184-aa32-afc6222a7c27",
    provider: "azure",
    providerId: "es-DO-RamonaNeural",
    slug: "es-DO-RamonaNeural",
    name: "Ramona",
    language: "Spanish (Dominican Republic)",
    languageCode: "es-DO",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/246f2c0e-052a-4aa2-989c-4ec022f9f630.wav",
  },
  {
    id: "1883e8ff-464a-4ce7-ad78-6ffb18537cfd",
    provider: "azure",
    providerId: "es-EC-AndreaNeural",
    slug: "es-EC-AndreaNeural",
    name: "Andrea",
    language: "Spanish (Ecuador)",
    languageCode: "es-EC",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/be31889b-61e3-44ec-8fd1-9156a1e8e5cb.wav",
  },
  {
    id: "00c50a3b-b285-4b3e-a446-2ca054a9313f",
    provider: "azure",
    providerId: "es-EC-LuisNeural",
    slug: "es-EC-LuisNeural",
    name: "Luis",
    language: "Spanish (Ecuador)",
    languageCode: "es-EC",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/605a39a4-28c2-4dbd-b36c-27ccc44f92d1.wav",
  },
  {
    id: "6b474fc1-feeb-4824-9a21-dfcd8667494b",
    provider: "azure",
    providerId: "es-ES-AbrilNeural",
    slug: "es-ES-AbrilNeural",
    name: "Abril",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3a933ac0-a850-4625-91a8-b68f161ecf0c.wav",
  },
  {
    id: "db4507f6-9c74-42b3-a35c-a08181f64a53",
    provider: "azure",
    providerId: "es-ES-AlvaroNeural",
    slug: "es-ES-AlvaroNeural",
    name: "Alvaro",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/12085f0c-75c1-44fb-a661-93de5e8e97e3.wav",
  },
  {
    id: "afe07745-6a49-42c7-b279-07c369d372c3",
    provider: "azure",
    providerId: "es-ES-ArnauNeural",
    slug: "es-ES-ArnauNeural",
    name: "Arnau",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7cfedb60-12df-45ba-a287-70541e20ee1d.wav",
  },
  {
    id: "cde8c109-065a-477d-a689-2739a6900545",
    provider: "azure",
    providerId: "es-ES-DarioNeural",
    slug: "es-ES-DarioNeural",
    name: "Dario",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/61e3aa65-290e-4ecb-9f5f-10a82d2ae244.wav",
  },
  {
    id: "3438ad17-0c6c-444c-a8b4-5e5f66da989b",
    provider: "azure",
    providerId: "es-ES-EliasNeural",
    slug: "es-ES-EliasNeural",
    name: "Elias",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9eedd9d1-4c8d-4683-baff-c6c7e4959cc9.wav",
  },
  {
    id: "77cf128c-d303-48ac-afff-97ff0d3586c1",
    provider: "azure",
    providerId: "es-ES-ElviraNeural",
    slug: "es-ES-ElviraNeural",
    name: "Elvira",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/576138b7-7c87-44fa-9190-b59fbb63c9fb.wav",
  },
  {
    id: "801db81d-5df7-427d-b518-09d1ceb62f13",
    provider: "azure",
    providerId: "es-ES-EstrellaNeural",
    slug: "es-ES-EstrellaNeural",
    name: "Estrella",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3010993e-f12e-4118-ab74-64e200ebe8c9.wav",
  },
  {
    id: "d76ec934-861d-46f8-975a-4cc63bec006e",
    provider: "azure",
    providerId: "es-ES-IreneNeural",
    slug: "es-ES-IreneNeural",
    name: "Irene",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b6321174-aa98-4756-96fc-ea7c6599aeb3.wav",
  },
  {
    id: "db7f17a4-d6c8-444a-9435-3d54a7bfad81",
    provider: "azure",
    providerId: "es-ES-LaiaNeural",
    slug: "es-ES-LaiaNeural",
    name: "Laia",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/431e6ccd-2e71-4758-9eca-06f063b8e449.wav",
  },
  {
    id: "b553c5a0-a99e-485f-ac89-b449ee444bac",
    provider: "azure",
    providerId: "es-ES-LiaNeural",
    slug: "es-ES-LiaNeural",
    name: "Lia",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/647df83f-081d-4156-8ac5-96c8b87b065e.wav",
  },
  {
    id: "c6a745bc-52b3-4a6f-a59f-a52ff24878b2",
    provider: "azure",
    providerId: "es-ES-NilNeural",
    slug: "es-ES-NilNeural",
    name: "Nil",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/26620e56-b515-421b-9609-8bb09adedc5f.wav",
  },
  {
    id: "d0e5291a-c6a3-4a53-92c2-0afce29f2347",
    provider: "azure",
    providerId: "es-ES-SaulNeural",
    slug: "es-ES-SaulNeural",
    name: "Saul",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/73a7c6fd-70d7-4b49-bbca-42307597455a.wav",
  },
  {
    id: "81f35e52-0e3d-4175-a5c9-2a3a88ee1cc1",
    provider: "azure",
    providerId: "es-ES-TeoNeural",
    slug: "es-ES-TeoNeural",
    name: "Teo",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/700b2407-ba14-4d32-aae6-85bbcc017bf6.wav",
  },
  {
    id: "3b87799a-5575-4868-94eb-c449d66f4e87",
    provider: "azure",
    providerId: "es-ES-TrianaNeural",
    slug: "es-ES-TrianaNeural",
    name: "Triana",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9b6d0627-a507-485f-bc05-2fb8b1dc82a5.wav",
  },
  {
    id: "c23a5249-f9c4-4215-a054-b145c4f0b202",
    provider: "azure",
    providerId: "es-ES-VeraNeural",
    slug: "es-ES-VeraNeural",
    name: "Vera",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/123727c8-fa6f-48b3-b9a4-0d562e8ca2c7.wav",
  },
  {
    id: "f7d78853-cef6-4447-8baf-ce736a7071eb",
    provider: "azure",
    providerId: "es-ES-XimenaNeural",
    slug: "es-ES-XimenaNeural",
    name: "Ximena",
    language: "Spanish (Spain)",
    languageCode: "es-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3cc21944-0e9b-4ded-a896-d9c40c40a271.wav",
  },
  {
    id: "b654012a-e4ad-492d-b248-d95b4fb7602b",
    provider: "azure",
    providerId: "es-GQ-JavierNeural",
    slug: "es-GQ-JavierNeural",
    name: "Javier",
    language: "Spanish (Equatorial Guinea)",
    languageCode: "es-GQ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b18c942a-737e-45a4-a024-247bf46986ab.wav",
  },
  {
    id: "e40b5124-f22b-4029-8774-1b556a99c03b",
    provider: "azure",
    providerId: "es-GQ-TeresaNeural",
    slug: "es-GQ-TeresaNeural",
    name: "Teresa",
    language: "Spanish (Equatorial Guinea)",
    languageCode: "es-GQ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9bde5209-00a4-4987-8f14-3d870f798d6c.wav",
  },
  {
    id: "8a33c9d5-7532-4b63-9b97-ec99a7d61623",
    provider: "azure",
    providerId: "es-GT-AndresNeural",
    slug: "es-GT-AndresNeural",
    name: "Andres",
    language: "Spanish (Guatemala)",
    languageCode: "es-GT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4dafb389-e10a-4ebe-9740-36d6475b81de.wav",
  },
  {
    id: "233d67fe-fed0-421d-97c7-a4c11d30ecc6",
    provider: "azure",
    providerId: "es-GT-MartaNeural",
    slug: "es-GT-MartaNeural",
    name: "Marta",
    language: "Spanish (Guatemala)",
    languageCode: "es-GT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/957d4296-39d9-46c9-97d0-033f2ed03bac.wav",
  },
  {
    id: "d95c1e43-3661-42f0-93f1-0dd0ed04c989",
    provider: "azure",
    providerId: "es-HN-CarlosNeural",
    slug: "es-HN-CarlosNeural",
    name: "Carlos",
    language: "Spanish (Honduras)",
    languageCode: "es-HN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/fd687a6e-43ed-4409-ba9e-435ca211c8ee.wav",
  },
  {
    id: "31aebeb4-0be4-4d34-b30d-6a1eddd234b6",
    provider: "azure",
    providerId: "es-HN-KarlaNeural",
    slug: "es-HN-KarlaNeural",
    name: "Karla",
    language: "Spanish (Honduras)",
    languageCode: "es-HN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5edfc5a2-b7df-4897-a494-1023c36eab99.wav",
  },
  {
    id: "65f26060-e4d8-4583-b209-5cbe5003d132",
    provider: "azure",
    providerId: "es-MX-BeatrizNeural",
    slug: "es-MX-BeatrizNeural",
    name: "Beatriz",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/71a6a80e-b742-402a-883e-d7a8feb1c3c1.wav",
  },
  {
    id: "9aefad67-5f14-4146-a2f2-7d464fd1ec56",
    provider: "azure",
    providerId: "es-MX-CandelaNeural",
    slug: "es-MX-CandelaNeural",
    name: "Candela",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cc5c1391-35e9-43ef-922e-c1a0348e16d0.wav",
  },
  {
    id: "503216bf-1acd-4c95-b079-6e8fa7f52cfc",
    provider: "azure",
    providerId: "es-MX-CarlotaNeural",
    slug: "es-MX-CarlotaNeural",
    name: "Carlota",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8d17ff69-ed4e-4d2c-bc30-c9e735094ab9.wav",
  },
  {
    id: "75cdbc2e-9ebf-461e-9c7f-39e6151cf55e",
    provider: "azure",
    providerId: "es-MX-CecilioNeural",
    slug: "es-MX-CecilioNeural",
    name: "Cecilio",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7c53f355-2433-4807-9dd4-6ab100d7f3d3.wav",
  },
  {
    id: "2d593b1b-b4c4-4c42-88bb-4d9d266cba21",
    provider: "azure",
    providerId: "es-MX-DaliaNeural",
    slug: "es-MX-DaliaNeural",
    name: "Dalia",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/90a1e254-edab-4121-a4ab-73e10d6799a6.wav",
  },
  {
    id: "3da3ca16-d78b-4e3e-8d17-79f9ad3810d5",
    provider: "azure",
    providerId: "es-MX-GerardoNeural",
    slug: "es-MX-GerardoNeural",
    name: "Gerardo",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ff3aa485-f92f-4a85-9acf-5c603a8f2bca.wav",
  },
  {
    id: "fff35598-03e4-4365-9b8b-812a619589fa",
    provider: "azure",
    providerId: "es-MX-JorgeNeural",
    slug: "es-MX-JorgeNeural",
    name: "Jorge",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cb7ee4aa-329a-4415-acbb-5a255897d825.wav",
  },
  {
    id: "1e7c7441-4002-4498-9e16-9c1773e14b3d",
    provider: "azure",
    providerId: "es-MX-LarissaNeural",
    slug: "es-MX-LarissaNeural",
    name: "Larissa",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0e24cbc6-acfa-4289-9e46-7b4f99cb5996.wav",
  },
  {
    id: "a6b9b806-5804-4372-aad7-80f2c75b9927",
    provider: "azure",
    providerId: "es-MX-LibertoNeural",
    slug: "es-MX-LibertoNeural",
    name: "Liberto",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0bf42115-3c96-4dae-b6d3-02ab92b56542.wav",
  },
  {
    id: "a3a48082-826b-44d0-93cb-9c165aaeeddc",
    provider: "azure",
    providerId: "es-MX-LucianoNeural",
    slug: "es-MX-LucianoNeural",
    name: "Luciano",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ef5ca91f-b149-4b7b-9630-a78949375230.wav",
  },
  {
    id: "f886481a-59cd-400b-9366-76b46d22b3db",
    provider: "azure",
    providerId: "es-MX-MarinaNeural",
    slug: "es-MX-MarinaNeural",
    name: "Marina",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2d7d1c53-b4d4-4a92-8633-9c3164207346.wav",
  },
  {
    id: "33d53211-06e0-4f65-8dac-16bcadf5516a",
    provider: "azure",
    providerId: "es-MX-NuriaNeural",
    slug: "es-MX-NuriaNeural",
    name: "Nuria",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/220948e0-49b8-4a78-bc23-7891f02ecf89.wav",
  },
  {
    id: "af645b6d-a1c6-45fd-b844-9490fa6d3441",
    provider: "azure",
    providerId: "es-MX-PelayoNeural",
    slug: "es-MX-PelayoNeural",
    name: "Pelayo",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0c08f816-3255-4f58-b3e7-b4b3b4631640.wav",
  },
  {
    id: "bbff1151-760d-4515-87f4-c29df4bb607c",
    provider: "azure",
    providerId: "es-MX-RenataNeural",
    slug: "es-MX-RenataNeural",
    name: "Renata",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/749a44b8-b507-4458-8ada-6e3b7ce0e9dc.wav",
  },
  {
    id: "9f99b5fc-61ef-46f8-bd7f-a206d0ba471b",
    provider: "azure",
    providerId: "es-MX-YagoNeural",
    slug: "es-MX-YagoNeural",
    name: "Yago",
    language: "Spanish (Mexico)",
    languageCode: "es-MX",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/72595609-a714-47d7-ac3d-67f5367f4c58.wav",
  },
  {
    id: "59853e0a-7cdf-4aa5-9e30-a1e4c6f6c44e",
    provider: "azure",
    providerId: "es-NI-FedericoNeural",
    slug: "es-NI-FedericoNeural",
    name: "Federico",
    language: "Spanish (Nicaragua)",
    languageCode: "es-NI",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/471fddfc-0481-43da-8383-23d8eba25e33.wav",
  },
  {
    id: "1b232f93-2e92-4a27-ba56-58c3b329cf18",
    provider: "azure",
    providerId: "es-NI-YolandaNeural",
    slug: "es-NI-YolandaNeural",
    name: "Yolanda",
    language: "Spanish (Nicaragua)",
    languageCode: "es-NI",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c6650d8b-4719-4fc9-846f-781a68cd1860.wav",
  },
  {
    id: "83fc8752-30d8-47c0-a538-9190a8d28316",
    provider: "azure",
    providerId: "es-PA-MargaritaNeural",
    slug: "es-PA-MargaritaNeural",
    name: "Margarita",
    language: "Spanish (Panama)",
    languageCode: "es-PA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d79ef089-059e-4b1b-81e8-d115c7d89237.wav",
  },
  {
    id: "00a95927-6ec2-424d-965e-5cd724edcdaf",
    provider: "azure",
    providerId: "es-PA-RobertoNeural",
    slug: "es-PA-RobertoNeural",
    name: "Roberto",
    language: "Spanish (Panama)",
    languageCode: "es-PA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d3e0ffcc-9ea9-46fb-9025-fe5dc1593289.wav",
  },
  {
    id: "a7fe1ea3-49b0-409b-bc36-982e40e23f98",
    provider: "azure",
    providerId: "es-PE-AlexNeural",
    slug: "es-PE-AlexNeural",
    name: "Alex",
    language: "Spanish (Peru)",
    languageCode: "es-PE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1b9acbe4-68ba-45de-baad-4b25d0b2d4fa.wav",
  },
  {
    id: "18512d6d-465c-4cc8-8534-4b804163f210",
    provider: "azure",
    providerId: "es-PE-CamilaNeural",
    slug: "es-PE-CamilaNeural",
    name: "Camila",
    language: "Spanish (Peru)",
    languageCode: "es-PE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a3d8d528-8167-470b-8db6-f251b015d56e.wav",
  },
  {
    id: "170813fa-6df9-4b34-b36a-a4e786ce348a",
    provider: "azure",
    providerId: "es-PR-KarinaNeural",
    slug: "es-PR-KarinaNeural",
    name: "Karina",
    language: "Spanish (Puerto Rico)",
    languageCode: "es-PR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9f97ecca-e2ec-4322-9af2-3a47a3448867.wav",
  },
  {
    id: "1f42b8fc-04b0-4c1d-8299-033e5246b478",
    provider: "azure",
    providerId: "es-PR-VictorNeural",
    slug: "es-PR-VictorNeural",
    name: "Victor",
    language: "Spanish (Puerto Rico)",
    languageCode: "es-PR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4172818a-4e76-4c08-99a0-f09a22f59438.wav",
  },
  {
    id: "fce18659-3b48-4811-8d67-32bab0985b5e",
    provider: "azure",
    providerId: "es-PY-MarioNeural",
    slug: "es-PY-MarioNeural",
    name: "Mario",
    language: "Spanish (Paraguay)",
    languageCode: "es-PY",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/57f8e7ce-887a-4c79-a6c7-ac5ebbd05b42.wav",
  },
  {
    id: "8ccc1d56-f65c-45d9-924c-2e56485ec670",
    provider: "azure",
    providerId: "es-PY-TaniaNeural",
    slug: "es-PY-TaniaNeural",
    name: "Tania",
    language: "Spanish (Paraguay)",
    languageCode: "es-PY",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b53a01c2-4980-48d4-94b3-e5e0b903ff3c.wav",
  },
  {
    id: "452a21d8-6630-4d68-94cb-ff25d21ab5dc",
    provider: "azure",
    providerId: "es-SV-LorenaNeural",
    slug: "es-SV-LorenaNeural",
    name: "Lorena",
    language: "Spanish (El Salvador)",
    languageCode: "es-SV",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/61548b0e-5942-4c27-b958-5f98f3031d26.wav",
  },
  {
    id: "7d293317-a824-4c0e-a24a-a4c734323cc6",
    provider: "azure",
    providerId: "es-SV-RodrigoNeural",
    slug: "es-SV-RodrigoNeural",
    name: "Rodrigo",
    language: "Spanish (El Salvador)",
    languageCode: "es-SV",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/348bd47d-51da-4fb2-b981-ae49a841220d.wav",
  },
  {
    id: "b3a99089-7b00-4ade-af6e-cc14af00cd28",
    provider: "azure",
    providerId: "es-US-AlonsoNeural",
    slug: "es-US-AlonsoNeural",
    name: "Alonso",
    language: "Spanish (United States)",
    languageCode: "es-US",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/becda44a-deea-496c-bb0c-bf5861c2b598.wav",
  },
  {
    id: "047f2ce0-4e19-4b15-8099-695f8afd3792",
    provider: "azure",
    providerId: "es-US-PalomaNeural",
    slug: "es-US-PalomaNeural",
    name: "Paloma",
    language: "Spanish (United States)",
    languageCode: "es-US",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e86ea57b-d8bc-42c5-84f5-07fbe2841d19.wav",
  },
  {
    id: "b4982d8d-17a1-4823-bcae-74e1aeb0a664",
    provider: "azure",
    providerId: "es-UY-MateoNeural",
    slug: "es-UY-MateoNeural",
    name: "Mateo",
    language: "Spanish (Uruguay)",
    languageCode: "es-UY",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d24fcc8e-977b-422e-a1ae-d4ae036234d6.wav",
  },
  {
    id: "0a472813-3628-452a-826f-c1d235ade32d",
    provider: "azure",
    providerId: "es-UY-ValentinaNeural",
    slug: "es-UY-ValentinaNeural",
    name: "Valentina",
    language: "Spanish (Uruguay)",
    languageCode: "es-UY",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/94fe46a7-87e6-47de-9392-ab7065af50c8.wav",
  },
  {
    id: "4c313306-bf78-4ee4-86fa-4bdbc93010de",
    provider: "azure",
    providerId: "es-VE-PaolaNeural",
    slug: "es-VE-PaolaNeural",
    name: "Paola",
    language: "Spanish (Venezuela)",
    languageCode: "es-VE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0aa31b58-d8e8-4d53-b103-6f841b34f0e4.wav",
  },
  {
    id: "b5e66322-f336-420b-b72c-7252cb5c5e14",
    provider: "azure",
    providerId: "es-VE-SebastianNeural",
    slug: "es-VE-SebastianNeural",
    name: "Sebastian",
    language: "Spanish (Venezuela)",
    languageCode: "es-VE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6048623b-a2c2-42b8-af94-7f37cb48a880.wav",
  },
  {
    id: "6fc204df-2896-489e-9ff2-2bcbb10dfce8",
    provider: "azure",
    providerId: "et-EE-AnuNeural",
    slug: "et-EE-AnuNeural",
    name: "Anu",
    language: "Estonian (Estonia)",
    languageCode: "et-EE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1c1854a1-b0f7-4238-af02-bfee0124e00c.wav",
  },
  {
    id: "40a74b2a-4efe-421c-bda6-383359c2a676",
    provider: "azure",
    providerId: "et-EE-KertNeural",
    slug: "et-EE-KertNeural",
    name: "Kert",
    language: "Estonian (Estonia)",
    languageCode: "et-EE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3a07da34-d947-4558-81b9-c229145f5a0c.wav",
  },
  {
    id: "d20c0443-cdfa-4322-9f9b-4ccf864bf0b3",
    provider: "azure",
    providerId: "eu-ES-AinhoaNeural",
    slug: "eu-ES-AinhoaNeural",
    name: "Ainhoa",
    language: "Basque",
    languageCode: "eu-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ce0c3533-e0e4-40e1-a6ac-9f1eb95b2008.wav",
  },
  {
    id: "5aee20cb-d7f1-453e-abcb-e39c1820b1ea",
    provider: "azure",
    providerId: "eu-ES-AnderNeural",
    slug: "eu-ES-AnderNeural",
    name: "Ander",
    language: "Basque",
    languageCode: "eu-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/244fac74-c3ee-418c-8dca-0dfdadd36212.wav",
  },
  {
    id: "31ff3f2f-a096-4bb3-9dbf-d641f0c3ea78",
    provider: "azure",
    providerId: "fa-IR-DilaraNeural",
    slug: "fa-IR-DilaraNeural",
    name: "Dilara",
    language: "Persian (Iran)",
    languageCode: "fa-IR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f22188a8-0271-4eba-995f-943d28e3b905.wav",
  },
  {
    id: "4130a867-0011-4754-a70d-437c49e504bd",
    provider: "azure",
    providerId: "fa-IR-FaridNeural",
    slug: "fa-IR-FaridNeural",
    name: "Farid",
    language: "Persian (Iran)",
    languageCode: "fa-IR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/17f6fbed-4b1f-4fcd-b415-dfbfe5c2a0e5.wav",
  },
  {
    id: "cc3a1b5d-6634-410d-8690-c824c8ddb265",
    provider: "azure",
    providerId: "fi-FI-HarriNeural",
    slug: "fi-FI-HarriNeural",
    name: "Harri",
    language: "Finnish (Finland)",
    languageCode: "fi-FI",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/64515da8-c0cf-403f-a796-d9ff2a428944.wav",
  },
  {
    id: "e88a6d89-cf16-405c-8089-7c5c43b272a2",
    provider: "azure",
    providerId: "fi-FI-NooraNeural",
    slug: "fi-FI-NooraNeural",
    name: "Noora",
    language: "Finnish (Finland)",
    languageCode: "fi-FI",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/027a3fe2-25a7-4416-9c9f-bb83f007b7ce.wav",
  },
  {
    id: "0b9ae7d7-7141-4e6d-aec5-597f1ed34336",
    provider: "azure",
    providerId: "fi-FI-SelmaNeural",
    slug: "fi-FI-SelmaNeural",
    name: "Selma",
    language: "Finnish (Finland)",
    languageCode: "fi-FI",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/bf01e8d2-e760-42c2-8f9f-10be1bcf9de3.wav",
  },
  {
    id: "d98d5453-8dc3-45e6-9d71-6037ead352b7",
    provider: "azure",
    providerId: "fil-PH-AngeloNeural",
    slug: "fil-PH-AngeloNeural",
    name: "Angelo",
    language: "Filipino (Philippines)",
    languageCode: "fil-PH",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/fcc1c42c-9d3e-4952-b6b4-b723b4aacb20.wav",
  },
  {
    id: "3ea3b0bf-d4ae-48b8-a97a-3f8fec5251a9",
    provider: "azure",
    providerId: "fil-PH-BlessicaNeural",
    slug: "fil-PH-BlessicaNeural",
    name: "Blessica",
    language: "Filipino (Philippines)",
    languageCode: "fil-PH",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cbeca82a-b049-49e0-b2a0-7e2a371e738c.wav",
  },
  {
    id: "2c9dab3e-52ea-4889-816c-8ff003993f30",
    provider: "azure",
    providerId: "fr-BE-CharlineNeural",
    slug: "fr-BE-CharlineNeural",
    name: "Charline",
    language: "French (Belgium)",
    languageCode: "fr-BE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f5dfc830-0fc3-4385-8f55-621c694c2eaa.wav",
  },
  {
    id: "adfb1039-796a-4995-8d10-1c62c75b7d30",
    provider: "azure",
    providerId: "fr-BE-GerardNeural",
    slug: "fr-BE-GerardNeural",
    name: "Gerard",
    language: "French (Belgium)",
    languageCode: "fr-BE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1073d472-41c8-4adf-b8d7-0e8c4a793c71.wav",
  },
  {
    id: "bed11948-354e-47f1-afee-f0f13701b008",
    provider: "azure",
    providerId: "fr-CA-AntoineNeural",
    slug: "fr-CA-AntoineNeural",
    name: "Antoine",
    language: "French (Canada)",
    languageCode: "fr-CA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0b3dea3a-cb7a-4856-a5b0-1e169fc9b451.wav",
  },
  {
    id: "65934c60-9c31-464a-b6c7-eea0a883105c",
    provider: "azure",
    providerId: "fr-CA-JeanNeural",
    slug: "fr-CA-JeanNeural",
    name: "Jean",
    language: "French (Canada)",
    languageCode: "fr-CA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/083a0b78-12bb-4837-9982-2e5e2e280c3d.wav",
  },
  {
    id: "ebcae30f-e6ea-4624-822a-9f5c4c65e203",
    provider: "azure",
    providerId: "fr-CA-SylvieNeural",
    slug: "fr-CA-SylvieNeural",
    name: "Sylvie",
    language: "French (Canada)",
    languageCode: "fr-CA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ee21fc31-bbb6-414a-9734-f489ce459db1.wav",
  },
  {
    id: "eaf54d3c-ac0d-492b-9af6-74d8abba4bad",
    provider: "azure",
    providerId: "fr-CA-ThierryNeural",
    slug: "fr-CA-ThierryNeural",
    name: "Thierry",
    language: "French (Canada)",
    languageCode: "fr-CA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/91b20b91-f6d9-471f-8c01-cb83154378e4.wav",
  },
  {
    id: "b0638e41-1fa0-49fd-9cbc-34ffb3a21669",
    provider: "azure",
    providerId: "fr-CH-ArianeNeural",
    slug: "fr-CH-ArianeNeural",
    name: "Ariane",
    language: "French (Switzerland)",
    languageCode: "fr-CH",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6095274b-3b18-4c14-a618-5898cf579477.wav",
  },
  {
    id: "f9f64323-48e8-4da7-b80e-2df04d87f150",
    provider: "azure",
    providerId: "fr-CH-FabriceNeural",
    slug: "fr-CH-FabriceNeural",
    name: "Fabrice",
    language: "French (Switzerland)",
    languageCode: "fr-CH",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/41265ffd-e89c-45a8-b87e-6b6455ba560e.wav",
  },
  {
    id: "fde47b9d-e14c-412f-bed5-0380b4855edd",
    provider: "azure",
    providerId: "fr-FR-AlainNeural",
    slug: "fr-FR-AlainNeural",
    name: "Alain",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/574678c8-17c8-476d-a2a7-a0d033e60cb1.wav",
  },
  {
    id: "19a2efad-4434-47c4-a1d2-2b4f0f68d94e",
    provider: "azure",
    providerId: "fr-FR-BrigitteNeural",
    slug: "fr-FR-BrigitteNeural",
    name: "Brigitte",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/aae46f9e-63fa-42c1-87c9-5c066fd8b717.wav",
  },
  {
    id: "8a08dec3-6b8c-4937-b193-19bb50bdca40",
    provider: "azure",
    providerId: "fr-FR-CelesteNeural",
    slug: "fr-FR-CelesteNeural",
    name: "Celeste",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/03a81d30-a206-467b-9b0e-f19df0d4ae34.wav",
  },
  {
    id: "b55365c6-f6b9-41fe-9b37-240714f59798",
    provider: "azure",
    providerId: "fr-FR-ClaudeNeural",
    slug: "fr-FR-ClaudeNeural",
    name: "Claude",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/91831804-45e0-4c34-9251-1b817080bd5d.wav",
  },
  {
    id: "a6e68f35-8304-4476-a448-978e40c7be25",
    provider: "azure",
    providerId: "fr-FR-CoralieNeural",
    slug: "fr-FR-CoralieNeural",
    name: "Coralie",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e778cce8-ce52-4774-a110-99a0e8818efa.wav",
  },
  {
    id: "2f80ea9e-72a2-4270-bf82-78c9ea0adb1a",
    provider: "azure",
    providerId: "fr-FR-DeniseNeural",
    slug: "fr-FR-DeniseNeural",
    name: "Denise",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/fbd348dc-f840-4224-9e36-368de2d1ab8f.wav",
  },
  {
    id: "646187c9-7902-4d25-b691-0844f00ff623",
    provider: "azure",
    providerId: "fr-FR-EloiseNeural",
    slug: "fr-FR-EloiseNeural",
    name: "Eloise",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1602c17d-6d3a-414f-9e7a-e21280bdf9f0.wav",
  },
  {
    id: "9e516d7a-d329-48a2-9484-a487a4882a54",
    provider: "azure",
    providerId: "fr-FR-HenriNeural",
    slug: "fr-FR-HenriNeural",
    name: "Henri",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/041ec286-ff85-4fb0-9e03-26ac14eefda0.wav",
  },
  {
    id: "8c2afcee-e369-41fe-baa3-be64dc4f588d",
    provider: "azure",
    providerId: "fr-FR-JacquelineNeural",
    slug: "fr-FR-JacquelineNeural",
    name: "Jacqueline",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8829dd34-8e30-4efc-b27b-453db5b5ed45.wav",
  },
  {
    id: "3764df7d-8b6b-4a26-8a08-68ddb646c410",
    provider: "azure",
    providerId: "fr-FR-JeromeNeural",
    slug: "fr-FR-JeromeNeural",
    name: "Jerome",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5f40903d-9cd5-4681-9339-bbff35f93195.wav",
  },
  {
    id: "157af1fb-b698-431d-909f-f56641bfbbff",
    provider: "azure",
    providerId: "fr-FR-JosephineNeural",
    slug: "fr-FR-JosephineNeural",
    name: "Josephine",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c9c55998-a202-4313-9635-f36332964522.wav",
  },
  {
    id: "932c7d2a-a83f-4b5a-acfa-0bd98fa4db0e",
    provider: "azure",
    providerId: "fr-FR-MauriceNeural",
    slug: "fr-FR-MauriceNeural",
    name: "Maurice",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1ece9029-e916-4f06-8914-efd4cf45f29e.wav",
  },
  {
    id: "b896112f-e4a5-47f2-b494-1089db0cf244",
    provider: "azure",
    providerId: "fr-FR-RemyMultilingualNeural",
    slug: "fr-FR-RemyMultilingualNeural",
    name: "Remy Multilingual",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2361b17e-4d44-4028-94c9-cbfe1abe12ea.wav",
  },
  {
    id: "b695fd3b-7375-4ef2-8a65-a31fffd0b404",
    provider: "azure",
    providerId: "fr-FR-VivienneMultilingualNeural",
    slug: "fr-FR-VivienneMultilingualNeural",
    name: "Vivienne Multilingual",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e8f930e7-c59d-4b81-bde2-dfa6908ba0e8.wav",
  },
  {
    id: "e54560b7-ae57-461a-af6e-6a5234a384bf",
    provider: "azure",
    providerId: "fr-FR-YvesNeural",
    slug: "fr-FR-YvesNeural",
    name: "Yves",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/094d4da8-26dc-496e-91a3-a3cd1909885c.wav",
  },
  {
    id: "b5efc538-696f-4ce0-961b-a0198760ec4a",
    provider: "azure",
    providerId: "fr-FR-YvetteNeural",
    slug: "fr-FR-YvetteNeural",
    name: "Yvette",
    language: "French (France)",
    languageCode: "fr-FR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6b6e8330-046b-4212-8acc-0011dd382daa.wav",
  },
  {
    id: "220d9219-3571-48bb-a5ff-bf03a89edebc",
    provider: "azure",
    providerId: "ga-IE-ColmNeural",
    slug: "ga-IE-ColmNeural",
    name: "Colm",
    language: "Irish (Ireland)",
    languageCode: "ga-IE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f4a482eb-2b66-4d2f-a62f-7afc459353a9.wav",
  },
  {
    id: "a008c3df-139c-4d58-b0b0-e83ab685c7b7",
    provider: "azure",
    providerId: "ga-IE-OrlaNeural",
    slug: "ga-IE-OrlaNeural",
    name: "Orla",
    language: "Irish (Ireland)",
    languageCode: "ga-IE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/885339b1-625a-440d-b4e0-0b87b9ab2d8d.wav",
  },
  {
    id: "885f3655-8fdb-41f7-b511-a5ca928e8f27",
    provider: "azure",
    providerId: "gl-ES-RoiNeural",
    slug: "gl-ES-RoiNeural",
    name: "Roi",
    language: "Galician",
    languageCode: "gl-ES",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cc007a6f-06ae-4f31-afc9-4cb44223743f.wav",
  },
  {
    id: "ade94ff7-d330-46bc-92e2-86898efa41ee",
    provider: "azure",
    providerId: "gl-ES-SabelaNeural",
    slug: "gl-ES-SabelaNeural",
    name: "Sabela",
    language: "Galician",
    languageCode: "gl-ES",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3b323070-1dbe-45c5-81a7-6f29afef9251.wav",
  },
  {
    id: "9aceaea0-c275-4d7d-b5b8-c9f98b9dcd23",
    provider: "azure",
    providerId: "gu-IN-DhwaniNeural",
    slug: "gu-IN-DhwaniNeural",
    name: "Dhwani",
    language: "Gujarati (India)",
    languageCode: "gu-IN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1961f0c0-6dfc-4568-81bf-ecb8034431f5.wav",
  },
  {
    id: "8003edc6-f7e5-4b7a-b4e9-f9fb9f841db1",
    provider: "azure",
    providerId: "gu-IN-NiranjanNeural",
    slug: "gu-IN-NiranjanNeural",
    name: "Niranjan",
    language: "Gujarati (India)",
    languageCode: "gu-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ab661707-c63d-4972-bf6d-eaac7d951125.wav",
  },
  {
    id: "75ef3e93-c11e-46ac-be85-b46c9bccc0ee",
    provider: "azure",
    providerId: "he-IL-AvriNeural",
    slug: "he-IL-AvriNeural",
    name: "Avri",
    language: "Hebrew (Israel)",
    languageCode: "he-IL",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6d7ee95d-eef4-4256-be8e-b802597fe6e7.wav",
  },
  {
    id: "f73cd44f-cce5-4fc1-99b1-8cd9116fdb2d",
    provider: "azure",
    providerId: "he-IL-HilaNeural",
    slug: "he-IL-HilaNeural",
    name: "Hila",
    language: "Hebrew (Israel)",
    languageCode: "he-IL",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3a7886c2-d084-48de-8f0e-eaa301e9a539.wav",
  },
  {
    id: "1dbcc4d7-a49d-4482-ab3a-926e2baf2622",
    provider: "azure",
    providerId: "hi-IN-MadhurNeural",
    slug: "hi-IN-MadhurNeural",
    name: "Madhur",
    language: "Hindi (India)",
    languageCode: "hi-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/af116611-7e00-4d82-b722-0e3e1f71be46.wav",
  },
  {
    id: "067ed149-5cf3-4424-9e50-61bb05133c38",
    provider: "azure",
    providerId: "hi-IN-SwaraNeural",
    slug: "hi-IN-SwaraNeural",
    name: "Swara",
    language: "Hindi (India)",
    languageCode: "hi-IN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/69be1c6d-46b1-47fd-93c3-4c994279f54a.wav",
  },
  {
    id: "0061d8aa-5f23-4350-ad8d-a6d8a3c27717",
    provider: "azure",
    providerId: "hr-HR-GabrijelaNeural",
    slug: "hr-HR-GabrijelaNeural",
    name: "Gabrijela",
    language: "Croatian (Croatia)",
    languageCode: "hr-HR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f953ce97-f113-4da8-82d1-4128365c5430.wav",
  },
  {
    id: "b0d6e781-dfbd-48c5-a034-8ebf24961a00",
    provider: "azure",
    providerId: "hr-HR-SreckoNeural",
    slug: "hr-HR-SreckoNeural",
    name: "Srecko",
    language: "Croatian (Croatia)",
    languageCode: "hr-HR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/692c9bff-ee26-44f1-b196-be02393d5c2f.wav",
  },
  {
    id: "b96d6d38-a843-488e-827a-af580cc8016f",
    provider: "azure",
    providerId: "hu-HU-NoemiNeural",
    slug: "hu-HU-NoemiNeural",
    name: "Noemi",
    language: "Hungarian (Hungary)",
    languageCode: "hu-HU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5a8f42f1-987a-4808-9333-4bad8ea0b447.wav",
  },
  {
    id: "ad3f806b-64ae-4cc0-9357-fa76fcd4140d",
    provider: "azure",
    providerId: "hu-HU-TamasNeural",
    slug: "hu-HU-TamasNeural",
    name: "Tamas",
    language: "Hungarian (Hungary)",
    languageCode: "hu-HU",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/56a00cc9-2c04-4790-97d3-b7a7ef280ad8.wav",
  },
  {
    id: "1c6f2b97-92d4-450b-ba12-43516ba1fe95",
    provider: "azure",
    providerId: "hy-AM-AnahitNeural",
    slug: "hy-AM-AnahitNeural",
    name: "Anahit",
    language: "Armenian (Armenia)",
    languageCode: "hy-AM",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/79f1eb68-23d8-4ee0-b3ed-c0db3e42476b.wav",
  },
  {
    id: "2e53b427-d452-49a8-bf75-9c9a7224068e",
    provider: "azure",
    providerId: "hy-AM-HaykNeural",
    slug: "hy-AM-HaykNeural",
    name: "Hayk",
    language: "Armenian (Armenia)",
    languageCode: "hy-AM",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c5e82343-00fe-4c79-9080-59ffbb51f501.wav",
  },
  {
    id: "7442b3d5-99c4-46a8-9880-b7ebe86024b5",
    provider: "azure",
    providerId: "id-ID-ArdiNeural",
    slug: "id-ID-ArdiNeural",
    name: "Ardi",
    language: "Indonesian (Indonesia)",
    languageCode: "id-ID",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a7702bdc-abe9-479d-8f04-a330ca59f3f9.wav",
  },
  {
    id: "b84a5819-7d69-48cc-940d-9a9ae70436e4",
    provider: "azure",
    providerId: "id-ID-GadisNeural",
    slug: "id-ID-GadisNeural",
    name: "Gadis",
    language: "Indonesian (Indonesia)",
    languageCode: "id-ID",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4ae30c71-bdb5-4f49-ab9d-55736e415cc1.wav",
  },
  {
    id: "f8bd93e3-fded-4c71-b05e-284e0429200c",
    provider: "azure",
    providerId: "is-IS-GudrunNeural",
    slug: "is-IS-GudrunNeural",
    name: "Gudrun",
    language: "Icelandic (Iceland)",
    languageCode: "is-IS",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b1902697-e552-413e-8f75-78646ba2e03c.wav",
  },
  {
    id: "66e7a182-ba04-42e0-b841-524f63d644d0",
    provider: "azure",
    providerId: "is-IS-GunnarNeural",
    slug: "is-IS-GunnarNeural",
    name: "Gunnar",
    language: "Icelandic (Iceland)",
    languageCode: "is-IS",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cdb965c6-681e-4a27-a247-d4b059491ae6.wav",
  },
  {
    id: "0bf2c56f-0a27-41cb-b6e0-b96d4bbfa2ad",
    provider: "azure",
    providerId: "it-IT-BenignoNeural",
    slug: "it-IT-BenignoNeural",
    name: "Benigno",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/05d70cb7-7300-4cf0-baac-2fe90718225c.wav",
  },
  {
    id: "99aca608-68e1-4e2b-badd-f8012c14f0a8",
    provider: "azure",
    providerId: "it-IT-CalimeroNeural",
    slug: "it-IT-CalimeroNeural",
    name: "Calimero",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ed9c1d2b-94e7-4770-99de-0b31e35b33aa.wav",
  },
  {
    id: "4714f3c8-2e6a-48de-9cae-d2757bd34855",
    provider: "azure",
    providerId: "it-IT-CataldoNeural",
    slug: "it-IT-CataldoNeural",
    name: "Cataldo",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/696b8d47-331c-4fad-b13d-176de197be0a.wav",
  },
  {
    id: "0cf7c7d4-ebf4-46ea-a4d1-265a9439d9c4",
    provider: "azure",
    providerId: "it-IT-DiegoNeural",
    slug: "it-IT-DiegoNeural",
    name: "Diego",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/64dc645b-a38a-438c-b9c7-60372ee3c0be.wav",
  },
  {
    id: "502812aa-f23d-4de6-9e74-fe11b41e0ec2",
    provider: "azure",
    providerId: "it-IT-ElsaNeural",
    slug: "it-IT-ElsaNeural",
    name: "Elsa",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/18abb2ad-8887-438d-924b-ff11afbaaa1a.wav",
  },
  {
    id: "8524270c-98ed-4fbe-8357-da0478f8c8c8",
    provider: "azure",
    providerId: "it-IT-FabiolaNeural",
    slug: "it-IT-FabiolaNeural",
    name: "Fabiola",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0c34d9d6-6e66-4943-a094-1039667096b0.wav",
  },
  {
    id: "03e8458c-ca4e-4ec8-a54d-39dcb042b210",
    provider: "azure",
    providerId: "it-IT-FiammaNeural",
    slug: "it-IT-FiammaNeural",
    name: "Fiamma",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c6fd3273-2c41-4fe1-8445-3bf0fd0a3a89.wav",
  },
  {
    id: "3c3d93ce-1002-4d37-b942-582ac95c61ca",
    provider: "azure",
    providerId: "it-IT-GianniNeural",
    slug: "it-IT-GianniNeural",
    name: "Gianni",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/50c74845-c66c-42cb-9109-8cb3410a1a18.wav",
  },
  {
    id: "d1e44769-e55d-4274-97e6-f666ae5f6a78",
    provider: "azure",
    providerId: "it-IT-GiuseppeNeural",
    slug: "it-IT-GiuseppeNeural",
    name: "Giuseppe",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c7efb239-7697-4e7d-85df-20577f03c4aa.wav",
  },
  {
    id: "f56b5712-3bf2-4020-8a2a-035af0e92fed",
    provider: "azure",
    providerId: "it-IT-ImeldaNeural",
    slug: "it-IT-ImeldaNeural",
    name: "Imelda",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cc2c3263-ca38-4c1a-9b28-36c4c64ff46c.wav",
  },
  {
    id: "756bd224-0283-4ad9-b0d6-f166700fd750",
    provider: "azure",
    providerId: "it-IT-IrmaNeural",
    slug: "it-IT-IrmaNeural",
    name: "Irma",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/aff41dee-cfd3-4771-aece-660e4275d8b1.wav",
  },
  {
    id: "57d5a76d-8abc-4e99-8ccf-aa420a9178e7",
    provider: "azure",
    providerId: "it-IT-IsabellaNeural",
    slug: "it-IT-IsabellaNeural",
    name: "Isabella",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a9f2b6f5-b25e-447c-9291-ba6f6f627684.wav",
  },
  {
    id: "5308c630-6f40-47dd-8bcc-7c689cf3a07b",
    provider: "azure",
    providerId: "it-IT-LisandroNeural",
    slug: "it-IT-LisandroNeural",
    name: "Lisandro",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/50a73b83-ce09-468c-a79f-0d499c9ce1e5.wav",
  },
  {
    id: "6870f7e4-d42c-41b4-b137-86827ea61dee",
    provider: "azure",
    providerId: "it-IT-PalmiraNeural",
    slug: "it-IT-PalmiraNeural",
    name: "Palmira",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ca5cc8f5-4018-48c8-b48d-c9c8bd8a6a29.wav",
  },
  {
    id: "312da5ed-77c9-41be-8034-636b31ae3f90",
    provider: "azure",
    providerId: "it-IT-PierinaNeural",
    slug: "it-IT-PierinaNeural",
    name: "Pierina",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3d145c63-def8-40c5-ae5d-ff3e9979f5c4.wav",
  },
  {
    id: "d03f6bc1-ed7f-4d37-9edf-bf7b7936514a",
    provider: "azure",
    providerId: "it-IT-RinaldoNeural",
    slug: "it-IT-RinaldoNeural",
    name: "Rinaldo",
    language: "Italian (Italy)",
    languageCode: "it-IT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/69cf0e7d-1542-4173-b227-a5ff5e1e0b39.wav",
  },
  {
    id: "401e715d-ef13-4ba2-8a2f-554e84e59de3",
    provider: "azure",
    providerId: "ja-JP-AoiNeural",
    slug: "ja-JP-AoiNeural",
    name: "Aoi",
    language: "Japanese (Japan)",
    languageCode: "ja-JP",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2b8dd225-b07f-4aa2-beac-93c1c9849d69.wav",
  },
  {
    id: "17a1a6d1-cec2-4bea-a0f4-2d66d7c1b205",
    provider: "azure",
    providerId: "ja-JP-DaichiNeural",
    slug: "ja-JP-DaichiNeural",
    name: "Daichi",
    language: "Japanese (Japan)",
    languageCode: "ja-JP",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3be7f5d9-9b27-4296-a3e8-839c97d6cb36.wav",
  },
  {
    id: "e409fc27-cc18-48d8-bfb3-ab359a4619d7",
    provider: "azure",
    providerId: "ja-JP-KeitaNeural",
    slug: "ja-JP-KeitaNeural",
    name: "Keita",
    language: "Japanese (Japan)",
    languageCode: "ja-JP",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1b3f2958-9141-4157-88f2-ae551d577c57.wav",
  },
  {
    id: "1ef08334-d5c1-44f1-bc9d-bb585c1dcc70",
    provider: "azure",
    providerId: "ja-JP-MasaruMultilingualNeural",
    slug: "ja-JP-MasaruMultilingualNeural",
    name: "Masaru Multilingual",
    language: "Japanese (Japan)",
    languageCode: "ja-JP",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "a7d9662f-46cf-4bf0-ad7d-60e1e290f49d",
    provider: "azure",
    providerId: "ja-JP-MayuNeural",
    slug: "ja-JP-MayuNeural",
    name: "Mayu",
    language: "Japanese (Japan)",
    languageCode: "ja-JP",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ad604892-d893-4d8d-be68-aa15e7124385.wav",
  },
  {
    id: "90b8a29d-fa4c-442c-a6a8-56b89bc3bbf5",
    provider: "azure",
    providerId: "ja-JP-NanamiNeural",
    slug: "ja-JP-NanamiNeural",
    name: "Nanami",
    language: "Japanese (Japan)",
    languageCode: "ja-JP",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/887aa1df-7533-4aef-a428-f33241b0e63e.wav",
  },
  {
    id: "b5030dc4-c52a-4e78-b18e-bab7ab1770ea",
    provider: "azure",
    providerId: "ja-JP-NaokiNeural",
    slug: "ja-JP-NaokiNeural",
    name: "Naoki",
    language: "Japanese (Japan)",
    languageCode: "ja-JP",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1e4ba043-9aba-4df1-87e1-7490ce18ab5a.wav",
  },
  {
    id: "2bd918a7-0048-4790-8d6a-2b996e89e57d",
    provider: "azure",
    providerId: "ja-JP-ShioriNeural",
    slug: "ja-JP-ShioriNeural",
    name: "Shiori",
    language: "Japanese (Japan)",
    languageCode: "ja-JP",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a586d840-f11c-461c-9671-00105b1c3b56.wav",
  },
  {
    id: "728fe5dc-38cd-49ce-8cfe-670f888ed9b6",
    provider: "azure",
    providerId: "jv-ID-DimasNeural",
    slug: "jv-ID-DimasNeural",
    name: "Dimas",
    language: "Javanese (Latin, Indonesia)",
    languageCode: "jv-ID",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/fa1003af-20e5-4f14-9be0-07b4192dfb12.wav",
  },
  {
    id: "e62fda26-f9c0-4d0f-81ca-6a6b8c77a4d9",
    provider: "azure",
    providerId: "jv-ID-SitiNeural",
    slug: "jv-ID-SitiNeural",
    name: "Siti",
    language: "Javanese (Latin, Indonesia)",
    languageCode: "jv-ID",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f561be2d-9a1a-4d77-b3ae-c3f500fc52a7.wav",
  },
  {
    id: "0dbbd6f0-8d06-4ee1-b092-03ca3ff2bdf9",
    provider: "azure",
    providerId: "ka-GE-EkaNeural",
    slug: "ka-GE-EkaNeural",
    name: "Eka",
    language: "Georgian (Georgia)",
    languageCode: "ka-GE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6edbfb39-f956-4926-a2bc-038b518dcbed.wav",
  },
  {
    id: "1a58518f-dfc6-4969-af86-d5a30c30c0cb",
    provider: "azure",
    providerId: "ka-GE-GiorgiNeural",
    slug: "ka-GE-GiorgiNeural",
    name: "Giorgi",
    language: "Georgian (Georgia)",
    languageCode: "ka-GE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/614434d6-c6c6-4bc6-8a6c-bd1660eff3c5.wav",
  },
  {
    id: "edbd99d0-0350-425a-ad1f-8590d429858b",
    provider: "azure",
    providerId: "kk-KZ-AigulNeural",
    slug: "kk-KZ-AigulNeural",
    name: "Aigul",
    language: "Kazakh (Kazakhstan)",
    languageCode: "kk-KZ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1ba84b59-6342-4967-8a00-4fec48e2e044.wav",
  },
  {
    id: "619294ff-4528-44d7-8125-536df7875360",
    provider: "azure",
    providerId: "kk-KZ-DauletNeural",
    slug: "kk-KZ-DauletNeural",
    name: "Daulet",
    language: "Kazakh (Kazakhstan)",
    languageCode: "kk-KZ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4ec69c62-374c-402f-8a2b-ffed348b0739.wav",
  },
  {
    id: "1dc61bf8-a7fd-4ed5-b3cf-2e9e95c4ffdb",
    provider: "azure",
    providerId: "km-KH-PisethNeural",
    slug: "km-KH-PisethNeural",
    name: "Piseth",
    language: "Khmer (Cambodia)",
    languageCode: "km-KH",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b2729a85-847a-4aeb-88aa-afd134000b5e.wav",
  },
  {
    id: "25fac1b2-5223-4dca-ad23-c2f672d36e9c",
    provider: "azure",
    providerId: "km-KH-SreymomNeural",
    slug: "km-KH-SreymomNeural",
    name: "Sreymom",
    language: "Khmer (Cambodia)",
    languageCode: "km-KH",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/501144dc-da75-4f77-b7a0-07c2a6d97f7c.wav",
  },
  {
    id: "93ba9c19-8480-48a1-908b-9d06800bb35c",
    provider: "azure",
    providerId: "kn-IN-GaganNeural",
    slug: "kn-IN-GaganNeural",
    name: "Gagan",
    language: "Kannada (India)",
    languageCode: "kn-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3a67aad0-d25b-40ad-81dc-fb1315c489d6.wav",
  },
  {
    id: "3225aba7-f133-45e0-b32b-20f6c5440c27",
    provider: "azure",
    providerId: "kn-IN-SapnaNeural",
    slug: "kn-IN-SapnaNeural",
    name: "Sapna",
    language: "Kannada (India)",
    languageCode: "kn-IN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8a474963-2286-46f6-b44e-95a9a83d6eee.wav",
  },
  {
    id: "4e921516-72d8-40c9-a6db-255ca07275a6",
    provider: "azure",
    providerId: "ko-KR-BongJinNeural",
    slug: "ko-KR-BongJinNeural",
    name: "BongJin",
    language: "Korean (Korea)",
    languageCode: "ko-KR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e4dd44cc-9c73-4e2a-a01d-a18f1fb43eb4.wav",
  },
  {
    id: "74bb71ed-1d28-424d-8fc7-1445b56c40bb",
    provider: "azure",
    providerId: "ko-KR-GookMinNeural",
    slug: "ko-KR-GookMinNeural",
    name: "GookMin",
    language: "Korean (Korea)",
    languageCode: "ko-KR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/85fe2cfb-4a6a-40fc-805d-926b89ddda90.wav",
  },
  {
    id: "bd451235-c930-48ec-9bec-9b03a951f9e6",
    provider: "azure",
    providerId: "ko-KR-HyunsuNeural",
    slug: "ko-KR-HyunsuNeural",
    name: "Hyunsu",
    language: "Korean (Korea)",
    languageCode: "ko-KR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ebdd4216-bd22-48d5-b969-3e4422f26e10.wav",
  },
  {
    id: "bd46341e-e60f-410b-9f6d-7129fe7b0cb8",
    provider: "azure",
    providerId: "ko-KR-InJoonNeural",
    slug: "ko-KR-InJoonNeural",
    name: "InJoon",
    language: "Korean (Korea)",
    languageCode: "ko-KR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1aee4389-b2bb-4800-9d08-705caf23ce91.wav",
  },
  {
    id: "9180003d-fd5c-4d93-a652-23b632f4862c",
    provider: "azure",
    providerId: "ko-KR-JiMinNeural",
    slug: "ko-KR-JiMinNeural",
    name: "JiMin",
    language: "Korean (Korea)",
    languageCode: "ko-KR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/434c18de-caa8-44af-814e-8a1ac796e59d.wav",
  },
  {
    id: "80c2dc65-ebcc-4e83-b584-ac1d0996a4f4",
    provider: "azure",
    providerId: "ko-KR-SeoHyeonNeural",
    slug: "ko-KR-SeoHyeonNeural",
    name: "SeoHyeon",
    language: "Korean (Korea)",
    languageCode: "ko-KR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/40bc8dc3-6633-446f-9d69-464cd68b53a3.wav",
  },
  {
    id: "48e70f4e-6dab-4203-9bb9-802e1dd53525",
    provider: "azure",
    providerId: "ko-KR-SoonBokNeural",
    slug: "ko-KR-SoonBokNeural",
    name: "SoonBok",
    language: "Korean (Korea)",
    languageCode: "ko-KR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/71a3e805-fefa-4ab0-98e5-d593ea4df737.wav",
  },
  {
    id: "1460548d-52fc-4273-927d-a53d6c9e0d4f",
    provider: "azure",
    providerId: "ko-KR-SunHiNeural",
    slug: "ko-KR-SunHiNeural",
    name: "Sun-Hi",
    language: "Korean (Korea)",
    languageCode: "ko-KR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/00ccc1e3-dfbb-4f03-bbe5-4cfd922b17ff.wav",
  },
  {
    id: "db7ce119-bf1c-4378-a74c-5725f2a95646",
    provider: "azure",
    providerId: "ko-KR-YuJinNeural",
    slug: "ko-KR-YuJinNeural",
    name: "YuJin",
    language: "Korean (Korea)",
    languageCode: "ko-KR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/83b476ab-282f-4fba-b0c1-1c15c2909124.wav",
  },
  {
    id: "a8b56f17-da13-4230-9e97-dbbbafe747d1",
    provider: "azure",
    providerId: "lo-LA-ChanthavongNeural",
    slug: "lo-LA-ChanthavongNeural",
    name: "Chanthavong",
    language: "Lao (Laos)",
    languageCode: "lo-LA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/96ea8d1c-ff2d-48c5-883b-0756b01aedda.wav",
  },
  {
    id: "d122fe8d-cb1a-4899-9c35-9cfbe0c631ee",
    provider: "azure",
    providerId: "lo-LA-KeomanyNeural",
    slug: "lo-LA-KeomanyNeural",
    name: "Keomany",
    language: "Lao (Laos)",
    languageCode: "lo-LA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d494bda9-7366-42ed-97cd-5fb6faaa28a0.wav",
  },
  {
    id: "c41624e9-0115-4741-98aa-2b2cdec617f2",
    provider: "azure",
    providerId: "lt-LT-LeonasNeural",
    slug: "lt-LT-LeonasNeural",
    name: "Leonas",
    language: "Lithuanian (Lithuania)",
    languageCode: "lt-LT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/30facba7-777b-4ad4-9da0-d15f819d2747.wav",
  },
  {
    id: "014f5868-76ab-4737-baf8-879e53137437",
    provider: "azure",
    providerId: "lt-LT-OnaNeural",
    slug: "lt-LT-OnaNeural",
    name: "Ona",
    language: "Lithuanian (Lithuania)",
    languageCode: "lt-LT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a9943e6d-1c92-4968-9e6b-382a64683a2d.wav",
  },
  {
    id: "4bcd6d8c-73e0-4485-9cf2-4a6f755e728e",
    provider: "azure",
    providerId: "lv-LV-EveritaNeural",
    slug: "lv-LV-EveritaNeural",
    name: "Everita",
    language: "Latvian (Latvia)",
    languageCode: "lv-LV",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/708ca819-725d-473f-a631-1fb53a3b16b8.wav",
  },
  {
    id: "a94c3c44-4e0f-441c-97ea-6365e893fdcd",
    provider: "azure",
    providerId: "lv-LV-NilsNeural",
    slug: "lv-LV-NilsNeural",
    name: "Nils",
    language: "Latvian (Latvia)",
    languageCode: "lv-LV",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/98093057-70b6-4d83-9da9-43d73fff6a5e.wav",
  },
  {
    id: "52277fd2-8a47-4ff7-a155-43da4ad02b5c",
    provider: "azure",
    providerId: "mk-MK-AleksandarNeural",
    slug: "mk-MK-AleksandarNeural",
    name: "Aleksandar",
    language: "Macedonian (North Macedonia)",
    languageCode: "mk-MK",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f1df5f2b-cc7f-406f-9ac6-ba6564d74cd1.wav",
  },
  {
    id: "fa70139a-5139-4874-a109-1b305bd4552d",
    provider: "azure",
    providerId: "mk-MK-MarijaNeural",
    slug: "mk-MK-MarijaNeural",
    name: "Marija",
    language: "Macedonian (North Macedonia)",
    languageCode: "mk-MK",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7cef72ba-94f9-454a-8932-37e28b5e922a.wav",
  },
  {
    id: "2042f888-5e8e-4312-9468-da84bda1397d",
    provider: "azure",
    providerId: "ml-IN-MidhunNeural",
    slug: "ml-IN-MidhunNeural",
    name: "Midhun",
    language: "Malayalam (India)",
    languageCode: "ml-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cd8c061b-9697-4e52-9dbd-190e6ce002b7.wav",
  },
  {
    id: "cefa3649-a4de-41be-835d-0ea49085b23e",
    provider: "azure",
    providerId: "ml-IN-SobhanaNeural",
    slug: "ml-IN-SobhanaNeural",
    name: "Sobhana",
    language: "Malayalam (India)",
    languageCode: "ml-IN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/818105e5-0b93-4224-afa1-76928fa9835b.wav",
  },
  {
    id: "23e583cb-c39b-4a46-be23-2e5f40795065",
    provider: "azure",
    providerId: "mn-MN-BataaNeural",
    slug: "mn-MN-BataaNeural",
    name: "Bataa",
    language: "Mongolian (Mongolia)",
    languageCode: "mn-MN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/17f293e4-1b37-4441-b289-c6d326a18832.wav",
  },
  {
    id: "df964b5e-ad8e-4d2a-ae0d-967130ce5b3e",
    provider: "azure",
    providerId: "mn-MN-YesuiNeural",
    slug: "mn-MN-YesuiNeural",
    name: "Yesui",
    language: "Mongolian (Mongolia)",
    languageCode: "mn-MN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/939b025c-cb24-423a-9735-c5189aa3b483.wav",
  },
  {
    id: "40af8688-c934-4ddb-b6f1-28c8dd36bd97",
    provider: "azure",
    providerId: "mr-IN-AarohiNeural",
    slug: "mr-IN-AarohiNeural",
    name: "Aarohi",
    language: "Marathi (India)",
    languageCode: "mr-IN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6a1e5a5e-3fb1-443d-bbc7-dad16315a3ec.wav",
  },
  {
    id: "78109af3-1d68-4508-a5df-51c254e63ba7",
    provider: "azure",
    providerId: "mr-IN-ManoharNeural",
    slug: "mr-IN-ManoharNeural",
    name: "Manohar",
    language: "Marathi (India)",
    languageCode: "mr-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0012ed4b-690a-4a43-b8c1-ac39edc924ec.wav",
  },
  {
    id: "ffe9f6eb-0cb6-4ed0-a63a-8e6781d97c27",
    provider: "azure",
    providerId: "ms-MY-OsmanNeural",
    slug: "ms-MY-OsmanNeural",
    name: "Osman",
    language: "Malay (Malaysia)",
    languageCode: "ms-MY",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/49ba22e8-d215-4bf5-8ad8-126dd7ba3477.wav",
  },
  {
    id: "2c7c775b-b24e-4fc5-b441-05672db74b1e",
    provider: "azure",
    providerId: "ms-MY-YasminNeural",
    slug: "ms-MY-YasminNeural",
    name: "Yasmin",
    language: "Malay (Malaysia)",
    languageCode: "ms-MY",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7477b85a-ec70-4b15-a00d-26bd65bcb35e.wav",
  },
  {
    id: "680e1f90-ccf0-4542-bc73-5b89180d00b2",
    provider: "azure",
    providerId: "mt-MT-GraceNeural",
    slug: "mt-MT-GraceNeural",
    name: "Grace",
    language: "Maltese (Malta)",
    languageCode: "mt-MT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ef765fdd-8415-4185-80db-6dd044141996.wav",
  },
  {
    id: "40bef7b3-4120-41a1-ae09-e9465d24a99d",
    provider: "azure",
    providerId: "mt-MT-JosephNeural",
    slug: "mt-MT-JosephNeural",
    name: "Joseph",
    language: "Maltese (Malta)",
    languageCode: "mt-MT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/10ac3fa2-6637-4e0c-98a8-fef08f0c617a.wav",
  },
  {
    id: "cdd54d23-7d3b-4302-bd7e-c5ca9395dc7f",
    provider: "azure",
    providerId: "my-MM-NilarNeural",
    slug: "my-MM-NilarNeural",
    name: "Nilar",
    language: "Burmese (Myanmar)",
    languageCode: "my-MM",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/65cc5f4e-3107-4f06-82b9-8448e287453d.wav",
  },
  {
    id: "45761b2c-d6a4-4c62-b219-3dcb7a1ba26a",
    provider: "azure",
    providerId: "my-MM-ThihaNeural",
    slug: "my-MM-ThihaNeural",
    name: "Thiha",
    language: "Burmese (Myanmar)",
    languageCode: "my-MM",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/1269ad38-b458-454d-a6a0-d0ca1716e451.wav",
  },
  {
    id: "c468bcfc-d583-4a36-a57f-db4ed19d88ab",
    provider: "azure",
    providerId: "nb-NO-FinnNeural",
    slug: "nb-NO-FinnNeural",
    name: "Finn",
    language: "Norwegian Bokmål (Norway)",
    languageCode: "nb-NO",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/82c69552-1b0e-4dfe-88ab-8b6815ec7cb1.wav",
  },
  {
    id: "ca7236c5-eda7-403a-83ca-83aecb4d61f4",
    provider: "azure",
    providerId: "nb-NO-IselinNeural",
    slug: "nb-NO-IselinNeural",
    name: "Iselin",
    language: "Norwegian Bokmål (Norway)",
    languageCode: "nb-NO",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f8d5acbf-3fb3-4054-8f88-6677c7f35df1.wav",
  },
  {
    id: "d233db9a-5648-4cc4-9385-f0ca9c8588d9",
    provider: "azure",
    providerId: "nb-NO-PernilleNeural",
    slug: "nb-NO-PernilleNeural",
    name: "Pernille",
    language: "Norwegian Bokmål (Norway)",
    languageCode: "nb-NO",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/dcf3d812-e180-49c6-8833-5dabcc8b7059.wav",
  },
  {
    id: "72307139-ae79-4d72-b16b-583c66dfdf93",
    provider: "azure",
    providerId: "ne-NP-HemkalaNeural",
    slug: "ne-NP-HemkalaNeural",
    name: "Hemkala",
    language: "Nepali (Nepal)",
    languageCode: "ne-NP",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4b3f940e-b2b6-44c8-bb12-21ff1e9a0eaf.wav",
  },
  {
    id: "9fc5c336-3ec3-4792-8e90-330144e3d0f2",
    provider: "azure",
    providerId: "ne-NP-SagarNeural",
    slug: "ne-NP-SagarNeural",
    name: "Sagar",
    language: "Nepali (Nepal)",
    languageCode: "ne-NP",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/153acad0-3e07-48a2-802b-44dda062b6b2.wav",
  },
  {
    id: "7a266f79-9f75-419a-930f-7dfafe463d42",
    provider: "azure",
    providerId: "nl-BE-ArnaudNeural",
    slug: "nl-BE-ArnaudNeural",
    name: "Arnaud",
    language: "Dutch (Belgium)",
    languageCode: "nl-BE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/867f4236-5922-45ba-b7ec-20a66f0a12ba.wav",
  },
  {
    id: "07cf0b2c-0998-4a40-a81e-6bff33975e51",
    provider: "azure",
    providerId: "nl-BE-DenaNeural",
    slug: "nl-BE-DenaNeural",
    name: "Dena",
    language: "Dutch (Belgium)",
    languageCode: "nl-BE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e959167c-2206-4d75-aa66-036382beec4e.wav",
  },
  {
    id: "6f544a11-a93a-4d87-a0b6-21927a6a2dc2",
    provider: "azure",
    providerId: "nl-NL-ColetteNeural",
    slug: "nl-NL-ColetteNeural",
    name: "Colette",
    language: "Dutch (Netherlands)",
    languageCode: "nl-NL",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/813d8255-7d39-4a98-b354-a4d684003ba1.wav",
  },
  {
    id: "183e6134-bf92-4ff3-8d5e-135466d64b57",
    provider: "azure",
    providerId: "nl-NL-FennaNeural",
    slug: "nl-NL-FennaNeural",
    name: "Fenna",
    language: "Dutch (Netherlands)",
    languageCode: "nl-NL",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/fd0d038a-8256-4b1d-ab3d-dcfceb88bcdd.wav",
  },
  {
    id: "8fa9d1a7-1f9f-400f-9e4b-63a2751054d4",
    provider: "azure",
    providerId: "nl-NL-MaartenNeural",
    slug: "nl-NL-MaartenNeural",
    name: "Maarten",
    language: "Dutch (Netherlands)",
    languageCode: "nl-NL",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b308d4c7-4f0d-4097-973b-bf3d990b65ef.wav",
  },
  {
    id: "bd5d9a55-6340-43dc-a1b8-03e9984a29da",
    provider: "azure",
    providerId: "pl-PL-AgnieszkaNeural",
    slug: "pl-PL-AgnieszkaNeural",
    name: "Agnieszka",
    language: "Polish (Poland)",
    languageCode: "pl-PL",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b3f4b63c-a483-47f1-80fe-1b00db67e262.wav",
  },
  {
    id: "76e16f55-ec3b-4102-90a9-56c8187bdbf7",
    provider: "azure",
    providerId: "pl-PL-MarekNeural",
    slug: "pl-PL-MarekNeural",
    name: "Marek",
    language: "Polish (Poland)",
    languageCode: "pl-PL",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0a6f4b63-376b-46d8-989d-5832393fdba8.wav",
  },
  {
    id: "0dff128a-8f0b-4a0e-9f25-2993af6058f2",
    provider: "azure",
    providerId: "pl-PL-ZofiaNeural",
    slug: "pl-PL-ZofiaNeural",
    name: "Zofia",
    language: "Polish (Poland)",
    languageCode: "pl-PL",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/589c4db8-bdc6-427b-8da5-d3e45793d473.wav",
  },
  {
    id: "e9b7ada8-9982-4757-a2a8-0b8bf2948c01",
    provider: "azure",
    providerId: "ps-AF-GulNawazNeural",
    slug: "ps-AF-GulNawazNeural",
    name: "Gul Nawaz",
    language: "Pashto (Afghanistan)",
    languageCode: "ps-AF",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c8b7d0b9-8258-449d-adf7-056b25668461.wav",
  },
  {
    id: "9fa5b44d-ffd3-45b3-861a-b289f8b69d33",
    provider: "azure",
    providerId: "ps-AF-LatifaNeural",
    slug: "ps-AF-LatifaNeural",
    name: "Latifa",
    language: "Pashto (Afghanistan)",
    languageCode: "ps-AF",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/93d2cbb5-ca87-456d-9163-fd7898144ed5.wav",
  },
  {
    id: "8d1538d3-ad4e-49c6-82e5-21dd3afe000a",
    provider: "azure",
    providerId: "pt-BR-AntonioNeural",
    slug: "pt-BR-AntonioNeural",
    name: "Antonio",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/983ad763-beb3-4f95-ba60-7d269c0eba64.wav",
  },
  {
    id: "19c6c4ce-f835-40e0-957a-788ebb87b252",
    provider: "azure",
    providerId: "pt-BR-BrendaNeural",
    slug: "pt-BR-BrendaNeural",
    name: "Brenda",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7477577a-c649-4139-bd51-f794bf3c6395.wav",
  },
  {
    id: "d0053cdd-d304-415c-acdc-7a541a0b974b",
    provider: "azure",
    providerId: "pt-BR-DonatoNeural",
    slug: "pt-BR-DonatoNeural",
    name: "Donato",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6df7f25d-3cc5-44f8-a6db-e0ce51140e71.wav",
  },
  {
    id: "fc583016-0571-4215-b25c-00bc0d79cf35",
    provider: "azure",
    providerId: "pt-BR-ElzaNeural",
    slug: "pt-BR-ElzaNeural",
    name: "Elza",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/dc83bc43-a294-449c-a7fa-93da84bce4e9.wav",
  },
  {
    id: "4f095688-1b0a-40cd-b673-e8907084fc6f",
    provider: "azure",
    providerId: "pt-BR-FabioNeural",
    slug: "pt-BR-FabioNeural",
    name: "Fabio",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7f39e61a-0422-4246-b578-737d20158ec3.wav",
  },
  {
    id: "ec9dcf75-4485-4cdb-97cc-db302bb91dfe",
    provider: "azure",
    providerId: "pt-BR-FranciscaNeural",
    slug: "pt-BR-FranciscaNeural",
    name: "Francisca",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b4bfcc9e-81fe-436d-be4e-007335a055f5.wav",
  },
  {
    id: "fae96ad1-47da-4af0-a88c-a270fd668cda",
    provider: "azure",
    providerId: "pt-BR-GiovannaNeural",
    slug: "pt-BR-GiovannaNeural",
    name: "Giovanna",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f204c1a1-1001-4699-a677-c9fa6cc7b5aa.wav",
  },
  {
    id: "cb7443f3-1aff-4fb0-bf25-5ca668e6e1c8",
    provider: "azure",
    providerId: "pt-BR-HumbertoNeural",
    slug: "pt-BR-HumbertoNeural",
    name: "Humberto",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5deaebfd-c5ef-4865-be33-da1a1c198be7.wav",
  },
  {
    id: "04eb3c9a-0d4e-47f7-9b66-bcc365cad155",
    provider: "azure",
    providerId: "pt-BR-JulioNeural",
    slug: "pt-BR-JulioNeural",
    name: "Julio",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/afc8f1ab-a157-4167-b5bb-5c9439f77424.wav",
  },
  {
    id: "2d15322e-ec70-461f-bde5-11569dce40a6",
    provider: "azure",
    providerId: "pt-BR-LeilaNeural",
    slug: "pt-BR-LeilaNeural",
    name: "Leila",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/525bd637-9a0c-4b99-8f08-c269146780b2.wav",
  },
  {
    id: "d274f699-e5ab-4a92-8afc-2c9d0df6941e",
    provider: "azure",
    providerId: "pt-BR-LeticiaNeural",
    slug: "pt-BR-LeticiaNeural",
    name: "Leticia",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b9423770-d1c1-41b1-bf9f-22fe96300f99.wav",
  },
  {
    id: "fd7469f0-ff63-4bfa-aacf-4e2e513881c3",
    provider: "azure",
    providerId: "pt-BR-ManuelaNeural",
    slug: "pt-BR-ManuelaNeural",
    name: "Manuela",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a26ed6e1-542d-419c-afa3-6d8af099edc5.wav",
  },
  {
    id: "f062b8be-0808-4ebc-9ea6-31cb9c521a40",
    provider: "azure",
    providerId: "pt-BR-NicolauNeural",
    slug: "pt-BR-NicolauNeural",
    name: "Nicolau",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2c550777-5836-4ebe-a96d-66be569ab325.wav",
  },
  {
    id: "992e21b5-2026-4b9f-935c-e00bb20a3e42",
    provider: "azure",
    providerId: "pt-BR-ThalitaNeural",
    slug: "pt-BR-ThalitaNeural",
    name: "Thalita",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ef386932-3fc8-40cf-8f9d-d0c677aa2ded.wav",
  },
  {
    id: "d916fadb-be07-4450-8faa-fce828e427dd",
    provider: "azure",
    providerId: "pt-BR-ValerioNeural",
    slug: "pt-BR-ValerioNeural",
    name: "Valerio",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/46b868ee-ce6e-414f-89a0-e6736b5c4836.wav",
  },
  {
    id: "d81d2bb6-c95a-4cfb-8317-6f52a24bbff2",
    provider: "azure",
    providerId: "pt-BR-YaraNeural",
    slug: "pt-BR-YaraNeural",
    name: "Yara",
    language: "Portuguese (Brazil)",
    languageCode: "pt-BR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/70c3d630-ee3d-4b80-84f8-3910e70de838.wav",
  },
  {
    id: "c9e29e61-a2ee-41fa-83e8-f7fe5fb5a825",
    provider: "azure",
    providerId: "pt-PT-DuarteNeural",
    slug: "pt-PT-DuarteNeural",
    name: "Duarte",
    language: "Portuguese (Portugal)",
    languageCode: "pt-PT",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5c2f36b5-00cb-4b58-8b92-8893b7194a7c.wav",
  },
  {
    id: "fa83c6a5-5481-4ea9-ae31-2e3c1d7ff257",
    provider: "azure",
    providerId: "pt-PT-FernandaNeural",
    slug: "pt-PT-FernandaNeural",
    name: "Fernanda",
    language: "Portuguese (Portugal)",
    languageCode: "pt-PT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/63a5d510-893a-4a4b-b14c-0aebf3b6cf9c.wav",
  },
  {
    id: "0eb3371b-c29a-43a6-86b9-60f65b6ec735",
    provider: "azure",
    providerId: "pt-PT-RaquelNeural",
    slug: "pt-PT-RaquelNeural",
    name: "Raquel",
    language: "Portuguese (Portugal)",
    languageCode: "pt-PT",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d7ded386-97f7-4b5f-9ff8-ffa640c58c48.wav",
  },
  {
    id: "5fff2048-2311-4d39-9390-cef197c14137",
    provider: "azure",
    providerId: "ro-RO-AlinaNeural",
    slug: "ro-RO-AlinaNeural",
    name: "Alina",
    language: "Romanian (Romania)",
    languageCode: "ro-RO",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0dc0bce0-7ad1-471b-bd27-a53e0f32e19e.wav",
  },
  {
    id: "4646278f-847d-456f-8972-ad4f18cbfa82",
    provider: "azure",
    providerId: "ro-RO-EmilNeural",
    slug: "ro-RO-EmilNeural",
    name: "Emil",
    language: "Romanian (Romania)",
    languageCode: "ro-RO",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8c6b411c-6a01-4e51-9f75-b3bd9b3737b1.wav",
  },
  {
    id: "fc74bc45-3328-4680-ab53-645e23b00249",
    provider: "azure",
    providerId: "ru-RU-DariyaNeural",
    slug: "ru-RU-DariyaNeural",
    name: "Dariya",
    language: "Russian (Russia)",
    languageCode: "ru-RU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/14ac994a-e978-49fe-bf11-a15d3842cc73.wav",
  },
  {
    id: "a5d4c205-e89d-4c8e-a651-ef2f20215c9c",
    provider: "azure",
    providerId: "ru-RU-DmitryNeural",
    slug: "ru-RU-DmitryNeural",
    name: "Dmitry",
    language: "Russian (Russia)",
    languageCode: "ru-RU",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/fcdd2239-746f-4688-8061-3ab27cad80bd.wav",
  },
  {
    id: "bf34dd38-70f2-49f2-8bc2-849153dae86a",
    provider: "azure",
    providerId: "ru-RU-SvetlanaNeural",
    slug: "ru-RU-SvetlanaNeural",
    name: "Svetlana",
    language: "Russian (Russia)",
    languageCode: "ru-RU",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d8e73f40-2bd9-4c08-88c6-f3d697298a36.wav",
  },
  {
    id: "8de008fb-9ae7-46d4-ba1d-3e60a2bbee62",
    provider: "azure",
    providerId: "si-LK-SameeraNeural",
    slug: "si-LK-SameeraNeural",
    name: "Sameera",
    language: "Sinhala (Sri Lanka)",
    languageCode: "si-LK",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5b79b9f5-e379-4c04-be19-6db464fbfe57.wav",
  },
  {
    id: "f494408a-28ac-4d26-8f9a-19c68d96fac3",
    provider: "azure",
    providerId: "si-LK-ThiliniNeural",
    slug: "si-LK-ThiliniNeural",
    name: "Thilini",
    language: "Sinhala (Sri Lanka)",
    languageCode: "si-LK",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d8001555-bc0d-43a2-ba7a-fd9fbf2fde78.wav",
  },
  {
    id: "ba54e778-f708-4439-994b-125c872eebe9",
    provider: "azure",
    providerId: "sk-SK-LukasNeural",
    slug: "sk-SK-LukasNeural",
    name: "Lukas",
    language: "Slovak (Slovakia)",
    languageCode: "sk-SK",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3105f638-f862-4c50-9ead-0b8d32b31073.wav",
  },
  {
    id: "b8d86c91-ac4b-4a0d-a46a-769cfcbd83ab",
    provider: "azure",
    providerId: "sk-SK-ViktoriaNeural",
    slug: "sk-SK-ViktoriaNeural",
    name: "Viktoria",
    language: "Slovak (Slovakia)",
    languageCode: "sk-SK",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/25484921-b270-4183-ba16-e1d99be9fa50.wav",
  },
  {
    id: "ba229431-1b92-4b3b-8538-9e858880ab50",
    provider: "azure",
    providerId: "sl-SI-PetraNeural",
    slug: "sl-SI-PetraNeural",
    name: "Petra",
    language: "Slovenian (Slovenia)",
    languageCode: "sl-SI",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/474d5587-32c8-48ef-bec1-780d22b8606c.wav",
  },
  {
    id: "e5db61bf-2a5b-4edf-b1e2-371f942092cd",
    provider: "azure",
    providerId: "sl-SI-RokNeural",
    slug: "sl-SI-RokNeural",
    name: "Rok",
    language: "Slovenian (Slovenia)",
    languageCode: "sl-SI",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cc4ef53e-348a-4496-83df-58090a26eca7.wav",
  },
  {
    id: "aa418c3a-5c79-4e7b-a763-74b6683b20e3",
    provider: "azure",
    providerId: "so-SO-MuuseNeural",
    slug: "so-SO-MuuseNeural",
    name: "Muuse",
    language: "Somali (Somalia)",
    languageCode: "so-SO",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d32433b8-7f73-4f57-8585-d95b7c1d15c6.wav",
  },
  {
    id: "c2720982-ee55-407c-a61f-879534832ce3",
    provider: "azure",
    providerId: "so-SO-UbaxNeural",
    slug: "so-SO-UbaxNeural",
    name: "Ubax",
    language: "Somali (Somalia)",
    languageCode: "so-SO",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/10c9d71b-1cd7-4bfa-9e56-e19808fa5537.wav",
  },
  {
    id: "964781b6-0871-4a1d-826e-46bb1e4be9a6",
    provider: "azure",
    providerId: "sq-AL-AnilaNeural",
    slug: "sq-AL-AnilaNeural",
    name: "Anila",
    language: "Albanian (Albania)",
    languageCode: "sq-AL",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2e963feb-0839-4278-ba7b-594df53851fd.wav",
  },
  {
    id: "0f33caad-9d82-4058-85a8-0335cc2c09fd",
    provider: "azure",
    providerId: "sq-AL-IlirNeural",
    slug: "sq-AL-IlirNeural",
    name: "Ilir",
    language: "Albanian (Albania)",
    languageCode: "sq-AL",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5d164511-7995-40ce-82ab-42692d46af62.wav",
  },
  {
    id: "b109fea5-3e67-4c09-96c7-1a6f3bd6eb85",
    provider: "azure",
    providerId: "sr-Latn-RS-NicholasNeural",
    slug: "sr-Latn-RS-NicholasNeural",
    name: "Nicholas",
    language: "Serbian (Latin, Serbia)",
    languageCode: "sr-Latn-RS",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "7994fb73-90c2-406c-b6d8-68409145f970",
    provider: "azure",
    providerId: "sr-Latn-RS-SophieNeural",
    slug: "sr-Latn-RS-SophieNeural",
    name: "Sophie",
    language: "Serbian (Latin, Serbia)",
    languageCode: "sr-Latn-RS",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "8b716508-0f2a-4dce-a78a-d7aa840794dd",
    provider: "azure",
    providerId: "sr-RS-NicholasNeural",
    slug: "sr-RS-NicholasNeural",
    name: "Nicholas",
    language: "Serbian (Cyrillic, Serbia)",
    languageCode: "sr-RS",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "0fd28654-3d88-4859-981b-d69e5185b4dd",
    provider: "azure",
    providerId: "sr-RS-SophieNeural",
    slug: "sr-RS-SophieNeural",
    name: "Sophie",
    language: "Serbian (Cyrillic, Serbia)",
    languageCode: "sr-RS",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
  },
  {
    id: "adc67063-f290-4446-b28a-e594c2c53247",
    provider: "azure",
    providerId: "su-ID-JajangNeural",
    slug: "su-ID-JajangNeural",
    name: "Jajang",
    language: "Sundanese (Indonesia)",
    languageCode: "su-ID",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2697c2d6-0cab-4bf2-9ec1-1551e33ab9c9.wav",
  },
  {
    id: "4b9c788d-a421-450f-a324-6395799e84d5",
    provider: "azure",
    providerId: "su-ID-TutiNeural",
    slug: "su-ID-TutiNeural",
    name: "Tuti",
    language: "Sundanese (Indonesia)",
    languageCode: "su-ID",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/be7cf735-37d0-4b9c-8021-30447c02bee6.wav",
  },
  {
    id: "f71d3121-0c3e-4163-ac48-ac2e769aa7fb",
    provider: "azure",
    providerId: "sv-SE-HilleviNeural",
    slug: "sv-SE-HilleviNeural",
    name: "Hillevi",
    language: "Swedish (Sweden)",
    languageCode: "sv-SE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e94dd12e-a068-4cc7-a380-6cd594ba67ac.wav",
  },
  {
    id: "16e0d37c-b13e-49d0-95ea-70d0e59961fe",
    provider: "azure",
    providerId: "sv-SE-MattiasNeural",
    slug: "sv-SE-MattiasNeural",
    name: "Mattias",
    language: "Swedish (Sweden)",
    languageCode: "sv-SE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/da0ee899-6bdd-468a-a7dd-c9a5633d024f.wav",
  },
  {
    id: "86d1d33b-2757-4620-8a76-62ddaa997b77",
    provider: "azure",
    providerId: "sv-SE-SofieNeural",
    slug: "sv-SE-SofieNeural",
    name: "Sofie",
    language: "Swedish (Sweden)",
    languageCode: "sv-SE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0bf28ef2-a359-4f43-8a05-341c9a2dd66c.wav",
  },
  {
    id: "0f213409-547f-40a3-9f6b-59c5a122a87d",
    provider: "azure",
    providerId: "sw-KE-RafikiNeural",
    slug: "sw-KE-RafikiNeural",
    name: "Rafiki",
    language: "Swahili (Kenya)",
    languageCode: "sw-KE",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/437eab57-cc0d-4ce0-abe4-d9c59e121942.wav",
  },
  {
    id: "83cc9b97-8f50-44e9-8c6e-a799db513bc2",
    provider: "azure",
    providerId: "sw-KE-ZuriNeural",
    slug: "sw-KE-ZuriNeural",
    name: "Zuri",
    language: "Swahili (Kenya)",
    languageCode: "sw-KE",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b38364a6-615b-4ec6-9b36-f15bf98c23a0.wav",
  },
  {
    id: "4dcdbadc-e28e-4f22-910a-4733d81d6367",
    provider: "azure",
    providerId: "sw-TZ-DaudiNeural",
    slug: "sw-TZ-DaudiNeural",
    name: "Daudi",
    language: "Swahili (Tanzania)",
    languageCode: "sw-TZ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/de970d57-5cc9-4e9e-9d35-5b9a4227fdcb.wav",
  },
  {
    id: "421e5644-07b2-489b-a496-aeecb7ee7844",
    provider: "azure",
    providerId: "sw-TZ-RehemaNeural",
    slug: "sw-TZ-RehemaNeural",
    name: "Rehema",
    language: "Swahili (Tanzania)",
    languageCode: "sw-TZ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3f1109fa-d648-4818-9b4c-725008f705bb.wav",
  },
  {
    id: "26fa264a-0f61-407f-9ca6-981066fe86d1",
    provider: "azure",
    providerId: "ta-IN-PallaviNeural",
    slug: "ta-IN-PallaviNeural",
    name: "Pallavi",
    language: "Tamil (India)",
    languageCode: "ta-IN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/98556d3e-b550-41b5-b2e7-a0ae0c2f1d1d.wav",
  },
  {
    id: "d1dd5ce3-25d8-4366-9fc3-590eaa43a180",
    provider: "azure",
    providerId: "ta-IN-ValluvarNeural",
    slug: "ta-IN-ValluvarNeural",
    name: "Valluvar",
    language: "Tamil (India)",
    languageCode: "ta-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6be0b178-bdfe-4e44-ac3a-47aebba5b9c1.wav",
  },
  {
    id: "3f90e358-ed17-4c89-82f3-249aadd9167e",
    provider: "azure",
    providerId: "ta-LK-KumarNeural",
    slug: "ta-LK-KumarNeural",
    name: "Kumar",
    language: "Tamil (Sri Lanka)",
    languageCode: "ta-LK",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ab9e978b-2617-46d8-85d1-a42fbcae0f73.wav",
  },
  {
    id: "8d017e79-6f13-42bc-91b6-67714331242b",
    provider: "azure",
    providerId: "ta-LK-SaranyaNeural",
    slug: "ta-LK-SaranyaNeural",
    name: "Saranya",
    language: "Tamil (Sri Lanka)",
    languageCode: "ta-LK",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/30d413da-1847-437e-b5f0-31c0c97a30a0.wav",
  },
  {
    id: "3c6131cb-19bc-4a84-9f26-73eac7e0f048",
    provider: "azure",
    providerId: "ta-MY-KaniNeural",
    slug: "ta-MY-KaniNeural",
    name: "Kani",
    language: "Tamil (Malaysia)",
    languageCode: "ta-MY",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5038ed2c-0718-4ffb-83d9-a8fded3977da.wav",
  },
  {
    id: "b8f44031-2fee-4742-b78a-be9e4715ca79",
    provider: "azure",
    providerId: "ta-MY-SuryaNeural",
    slug: "ta-MY-SuryaNeural",
    name: "Surya",
    language: "Tamil (Malaysia)",
    languageCode: "ta-MY",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/210f483f-2eae-44a4-92af-21c63a7d9b25.wav",
  },
  {
    id: "8fdce3f2-45dc-4c0a-80b7-0e548248d484",
    provider: "azure",
    providerId: "ta-SG-AnbuNeural",
    slug: "ta-SG-AnbuNeural",
    name: "Anbu",
    language: "Tamil (Singapore)",
    languageCode: "ta-SG",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d5d6c920-f7f8-47be-b223-2511e1327102.wav",
  },
  {
    id: "bd6d663d-bc15-444a-baff-7039f11cfbb5",
    provider: "azure",
    providerId: "ta-SG-VenbaNeural",
    slug: "ta-SG-VenbaNeural",
    name: "Venba",
    language: "Tamil (Singapore)",
    languageCode: "ta-SG",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6c44e65a-87e4-4207-aa20-b8dfa51aae8c.wav",
  },
  {
    id: "481716a0-ae1d-468c-8f6a-eb25c8df1411",
    provider: "azure",
    providerId: "te-IN-MohanNeural",
    slug: "te-IN-MohanNeural",
    name: "Mohan",
    language: "Telugu (India)",
    languageCode: "te-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/fd8d2409-e1a6-4836-939b-95d29657b9fd.wav",
  },
  {
    id: "c439df0a-5725-4d8a-99ed-da1a99cbe06f",
    provider: "azure",
    providerId: "te-IN-ShrutiNeural",
    slug: "te-IN-ShrutiNeural",
    name: "Shruti",
    language: "Telugu (India)",
    languageCode: "te-IN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0959d68b-e018-455e-a5d4-e6a65b840ea9.wav",
  },
  {
    id: "417a4797-4f2e-4a3a-aece-e042a689b071",
    provider: "azure",
    providerId: "th-TH-AcharaNeural",
    slug: "th-TH-AcharaNeural",
    name: "Achara",
    language: "Thai (Thailand)",
    languageCode: "th-TH",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5fcd022b-e3e9-4101-bc1c-42d051bc9ac1.wav",
  },
  {
    id: "8764974c-b8bf-4acb-8526-87c90905a01b",
    provider: "azure",
    providerId: "th-TH-NiwatNeural",
    slug: "th-TH-NiwatNeural",
    name: "Niwat",
    language: "Thai (Thailand)",
    languageCode: "th-TH",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/cd627f9c-2a63-4138-8904-b79b6e7c6a9d.wav",
  },
  {
    id: "672c93f0-6ba7-4022-9d51-17dd198d594e",
    provider: "azure",
    providerId: "th-TH-PremwadeeNeural",
    slug: "th-TH-PremwadeeNeural",
    name: "Premwadee",
    language: "Thai (Thailand)",
    languageCode: "th-TH",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/049314fe-10c7-4a8d-a14b-65a67afec18d.wav",
  },
  {
    id: "4a8b65d3-dd91-4c21-9e8b-de6c6d5b2626",
    provider: "azure",
    providerId: "tr-TR-AhmetNeural",
    slug: "tr-TR-AhmetNeural",
    name: "Ahmet",
    language: "Turkish (Turkey)",
    languageCode: "tr-TR",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ec8de195-3dd6-4789-a608-335596a4cffb.wav",
  },
  {
    id: "849c7078-59ae-49cf-ad76-47bd8a56e97a",
    provider: "azure",
    providerId: "tr-TR-EmelNeural",
    slug: "tr-TR-EmelNeural",
    name: "Emel",
    language: "Turkish (Turkey)",
    languageCode: "tr-TR",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b0dbffc0-40f3-411c-882a-cc39f520a9db.wav",
  },
  {
    id: "017b92a1-54a7-437c-9af2-86d40523dc61",
    provider: "azure",
    providerId: "uk-UA-OstapNeural",
    slug: "uk-UA-OstapNeural",
    name: "Ostap",
    language: "Ukrainian (Ukraine)",
    languageCode: "uk-UA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/09c61419-9db0-4817-a8a0-93dfb7da2c75.wav",
  },
  {
    id: "3f44776c-0a7b-4b1f-a345-1c8b353926c5",
    provider: "azure",
    providerId: "uk-UA-PolinaNeural",
    slug: "uk-UA-PolinaNeural",
    name: "Polina",
    language: "Ukrainian (Ukraine)",
    languageCode: "uk-UA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/241b78db-f09b-46bc-bf1d-f26f1ac59902.wav",
  },
  {
    id: "bca49343-9ab0-4a18-abca-f42f6460de76",
    provider: "azure",
    providerId: "ur-IN-GulNeural",
    slug: "ur-IN-GulNeural",
    name: "Gul",
    language: "Urdu (India)",
    languageCode: "ur-IN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6caa4e9f-4b66-4da8-bfb6-f7e2cccd0c39.wav",
  },
  {
    id: "b3b4af45-7b63-4e6d-9fea-cefef4346382",
    provider: "azure",
    providerId: "ur-IN-SalmanNeural",
    slug: "ur-IN-SalmanNeural",
    name: "Salman",
    language: "Urdu (India)",
    languageCode: "ur-IN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a509fa47-b84f-4cf1-9d52-273980ccde4c.wav",
  },
  {
    id: "1d429125-080a-473a-8ca6-6297afb4d60d",
    provider: "azure",
    providerId: "ur-PK-AsadNeural",
    slug: "ur-PK-AsadNeural",
    name: "Asad",
    language: "Urdu (Pakistan)",
    languageCode: "ur-PK",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/63f3bbd8-7d1a-4cab-ba37-3a660a1af414.wav",
  },
  {
    id: "401670c3-533b-4544-b838-cfe2b205f757",
    provider: "azure",
    providerId: "ur-PK-UzmaNeural",
    slug: "ur-PK-UzmaNeural",
    name: "Uzma",
    language: "Urdu (Pakistan)",
    languageCode: "ur-PK",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6d1336c3-c4a9-4079-adfc-edbca719b170.wav",
  },
  {
    id: "9bacda80-3b95-4885-b952-0951045fa0f2",
    provider: "azure",
    providerId: "uz-UZ-MadinaNeural",
    slug: "uz-UZ-MadinaNeural",
    name: "Madina",
    language: "Uzbek (Latin, Uzbekistan)",
    languageCode: "uz-UZ",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/5e6cea88-4ce8-4bfd-a82f-9c9df2f9b8f4.wav",
  },
  {
    id: "2d79f2bc-a665-416a-b414-1a7724e6c934",
    provider: "azure",
    providerId: "uz-UZ-SardorNeural",
    slug: "uz-UZ-SardorNeural",
    name: "Sardor",
    language: "Uzbek (Latin, Uzbekistan)",
    languageCode: "uz-UZ",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4e0040af-a668-4a5f-b2d3-bec9f672e2d9.wav",
  },
  {
    id: "6a51e347-fe1f-4abe-92cd-9ae950bdfb73",
    provider: "azure",
    providerId: "vi-VN-HoaiMyNeural",
    slug: "vi-VN-HoaiMyNeural",
    name: "HoaiMy",
    language: "Vietnamese (Vietnam)",
    languageCode: "vi-VN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/f783145a-5087-46a2-974e-4b2c6ab0db44.wav",
  },
  {
    id: "c8593d0e-ce14-4425-a83c-294e6e77cac8",
    provider: "azure",
    providerId: "vi-VN-NamMinhNeural",
    slug: "vi-VN-NamMinhNeural",
    name: "NamMinh",
    language: "Vietnamese (Vietnam)",
    languageCode: "vi-VN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e291f25a-192b-4937-ac71-17da362fdf26.wav",
  },
  {
    id: "fbaa06a7-4332-4db3-9edc-e85216928bfc",
    provider: "azure",
    providerId: "wuu-CN-XiaotongNeural",
    slug: "wuu-CN-XiaotongNeural",
    name: "Xiaotong",
    language: "Chinese (Wu, Simplified)",
    languageCode: "wuu-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7c996d1d-d309-4288-adc4-3ffd1c1911de.wav",
  },
  {
    id: "09c14fda-edc3-4db8-aa0c-064c36898528",
    provider: "azure",
    providerId: "wuu-CN-YunzheNeural",
    slug: "wuu-CN-YunzheNeural",
    name: "Yunzhe",
    language: "Chinese (Wu, Simplified)",
    languageCode: "wuu-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/7d3176a9-ec45-49c1-a462-7801719e4304.wav",
  },
  {
    id: "0bc52642-54cd-4b81-9938-093429040073",
    provider: "azure",
    providerId: "yue-CN-XiaoMinNeural",
    slug: "yue-CN-XiaoMinNeural",
    name: "XiaoMin",
    language: "Chinese (Cantonese, Simplified)",
    languageCode: "yue-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a20f1ce8-e7f8-48ed-97b4-26373d42eb9b.wav",
  },
  {
    id: "a292ae13-8920-4a24-954e-291a6d675426",
    provider: "azure",
    providerId: "yue-CN-YunSongNeural",
    slug: "yue-CN-YunSongNeural",
    name: "YunSong",
    language: "Chinese (Cantonese, Simplified)",
    languageCode: "yue-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/878650e5-09e7-4b35-8f97-53b24275eeb7.wav",
  },
  {
    id: "ddf33a76-2a79-47ae-8e90-b40b1ae2c694",
    provider: "azure",
    providerId: "zh-CN-XiaochenMultilingualNeural",
    slug: "zh-CN-XiaochenMultilingualNeural",
    name: "Xiaochen Multilingual",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/c2e80ba3-71cc-4fab-afc3-57068e4d3314.wav",
  },
  {
    id: "787a8565-218b-40f5-b396-e24f243be56b",
    provider: "azure",
    providerId: "zh-CN-XiaochenNeural",
    slug: "zh-CN-XiaochenNeural",
    name: "Xiaochen",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/26720f78-6105-439b-b59d-f001f9812350.wav",
  },
  {
    id: "4079d75e-2b38-4a9e-9ff9-aef98ef2799c",
    provider: "azure",
    providerId: "zh-CN-XiaohanNeural",
    slug: "zh-CN-XiaohanNeural",
    name: "Xiaohan",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/64aab135-6185-4043-bffa-304e28b9ed83.wav",
  },
  {
    id: "e3efd6dc-40a5-4d2a-973f-ad3117021299",
    provider: "azure",
    providerId: "zh-CN-XiaomengNeural",
    slug: "zh-CN-XiaomengNeural",
    name: "Xiaomeng",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/acd2a209-5a13-44f1-8b2f-d9c2c6f2c005.wav",
  },
  {
    id: "63b65451-202a-4992-a74c-8711138be89b",
    provider: "azure",
    providerId: "zh-CN-XiaomoNeural",
    slug: "zh-CN-XiaomoNeural",
    name: "Xiaomo",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0a1bf24a-9120-46e6-ad0d-60dc6e1d18f5.wav",
  },
  {
    id: "e52d4dec-3ee1-448c-b149-91ab1bb9cae5",
    provider: "azure",
    providerId: "zh-CN-XiaoqiuNeural",
    slug: "zh-CN-XiaoqiuNeural",
    name: "Xiaoqiu",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ced13faa-246e-4d21-a4e2-56254b6d6d15.wav",
  },
  {
    id: "e2b41c42-999c-4515-aa64-e81aa844a457",
    provider: "azure",
    providerId: "zh-CN-XiaorouNeural",
    slug: "zh-CN-XiaorouNeural",
    name: "Xiaorou",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8d7d1388-51d5-4765-aa6b-f20778e6fefb.wav",
  },
  {
    id: "2f218448-a20f-4ea4-9c52-05864ae74fda",
    provider: "azure",
    providerId: "zh-CN-XiaoruiNeural",
    slug: "zh-CN-XiaoruiNeural",
    name: "Xiaorui",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d17c08dd-c82c-4d9a-b904-414548dfc9a4.wav",
  },
  {
    id: "38e2880a-0232-45b0-a340-c2602b9786ed",
    provider: "azure",
    providerId: "zh-CN-XiaoshuangNeural",
    slug: "zh-CN-XiaoshuangNeural",
    name: "Xiaoshuang",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6d7d2a0a-72b6-4865-8477-c058720daa03.wav",
  },
  {
    id: "462ba436-cc7b-4f09-8b4e-be5b5a34d9f3",
    provider: "azure",
    providerId: "zh-CN-XiaoxiaoDialectsNeural",
    slug: "zh-CN-XiaoxiaoDialectsNeural",
    name: "Xiaoxiao Dialects",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d82f656f-79ce-4060-bf10-0c67f795ee11.wav",
  },
  {
    id: "47ab7f32-691b-4dee-b8b5-cb2f9b74d618",
    provider: "azure",
    providerId: "zh-CN-XiaoxiaoMultilingualNeural",
    slug: "zh-CN-XiaoxiaoMultilingualNeural",
    name: "Xiaoxiao Multilingual",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/82f8dfd3-b742-425d-aeef-ce41e2e096f4.wav",
  },
  {
    id: "342ba4c1-debf-4cd5-aff9-dd90ee17e7fa",
    provider: "azure",
    providerId: "zh-CN-XiaoxiaoNeural",
    slug: "zh-CN-XiaoxiaoNeural",
    name: "Xiaoxiao",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/26b4ffb7-aae8-466a-8e30-848be3b1b6d4.wav",
  },
  {
    id: "1fe4bf02-d0c2-4fe0-9022-ac09800fd87e",
    provider: "azure",
    providerId: "zh-CN-XiaoxuanNeural",
    slug: "zh-CN-XiaoxuanNeural",
    name: "Xiaoxuan",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/caea237a-47e9-4c53-8b12-bb9893b21337.wav",
  },
  {
    id: "2728a795-5fc4-407d-9192-f517bd157e28",
    provider: "azure",
    providerId: "zh-CN-XiaoyanNeural",
    slug: "zh-CN-XiaoyanNeural",
    name: "Xiaoyan",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/a379b593-3de8-4333-aea0-7c107c10b568.wav",
  },
  {
    id: "a9910b76-65ed-4677-9e81-28d1bb1202a1",
    provider: "azure",
    providerId: "zh-CN-XiaoyiNeural",
    slug: "zh-CN-XiaoyiNeural",
    name: "Xiaoyi",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e18e5f4d-fb68-40ef-9e80-647696542b41.wav",
  },
  {
    id: "7da1ed6a-f3a7-481b-af03-8ed7414fdf61",
    provider: "azure",
    providerId: "zh-CN-XiaoyouNeural",
    slug: "zh-CN-XiaoyouNeural",
    name: "Xiaoyou",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/87b99c6a-7ba0-42dd-9fca-739edfab9e46.wav",
  },
  {
    id: "a41b107b-616e-4cf6-9a24-08539809f88d",
    provider: "azure",
    providerId: "zh-CN-XiaoyuMultilingualNeural",
    slug: "zh-CN-XiaoyuMultilingualNeural",
    name: "Xiaoyu Multilingual",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/9f9904c1-3681-4799-9d15-92ccd676bfb7.wav",
  },
  {
    id: "0689e011-1db7-4886-8da3-3b4894e61193",
    provider: "azure",
    providerId: "zh-CN-XiaozhenNeural",
    slug: "zh-CN-XiaozhenNeural",
    name: "Xiaozhen",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b183f169-da3c-4dd0-8015-64e8bfe9db60.wav",
  },
  {
    id: "935872df-db16-4c6c-8609-dbbf42771aef",
    provider: "azure",
    providerId: "zh-CN-YunfengNeural",
    slug: "zh-CN-YunfengNeural",
    name: "Yunfeng",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/53f6f719-c656-441d-8191-8debcab8aa0d.wav",
  },
  {
    id: "00afd4dc-fd3b-45cb-a4b9-88a7d0ae6262",
    provider: "azure",
    providerId: "zh-CN-YunhaoNeural",
    slug: "zh-CN-YunhaoNeural",
    name: "Yunhao",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b531a96c-7db2-4cdc-939f-ca7851dbc4d2.wav",
  },
  {
    id: "a344c9b0-f220-4ef7-82c8-7dc5d4aa2582",
    provider: "azure",
    providerId: "zh-CN-YunjianNeural",
    slug: "zh-CN-YunjianNeural",
    name: "Yunjian",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/91e68ead-086c-4a50-891c-2b447d5246a3.wav",
  },
  {
    id: "9fa96d7e-3e80-42a8-9f99-db14b56a0798",
    provider: "azure",
    providerId: "zh-CN-YunjieNeural",
    slug: "zh-CN-YunjieNeural",
    name: "Yunjie",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/486493a6-f2be-41df-9745-b312e9daff00.wav",
  },
  {
    id: "411bd674-5b94-4753-8886-b0d910d12ba1",
    provider: "azure",
    providerId: "zh-CN-YunxiNeural",
    slug: "zh-CN-YunxiNeural",
    name: "Yunxi",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3395cf10-a795-426f-bb9d-b4154a8f4bc1.wav",
  },
  {
    id: "5fb1f3ec-4f60-4bfd-a930-63c0decf8766",
    provider: "azure",
    providerId: "zh-CN-YunxiaNeural",
    slug: "zh-CN-YunxiaNeural",
    name: "Yunxia",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/814aeb7b-b263-4d44-9975-7b23e1681c85.wav",
  },
  {
    id: "61d0dbfe-efd1-4eb7-9f14-2e977c056a62",
    provider: "azure",
    providerId: "zh-CN-YunyangNeural",
    slug: "zh-CN-YunyangNeural",
    name: "Yunyang",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/dc5a8e54-baa1-4875-9461-48f78fe70d74.wav",
  },
  {
    id: "7ddfc53f-effd-4197-aca6-5d0404ccb491",
    provider: "azure",
    providerId: "zh-CN-YunyeNeural",
    slug: "zh-CN-YunyeNeural",
    name: "Yunye",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/e9b97b0b-a0b3-49b8-97ab-5fe714face84.wav",
  },
  {
    id: "cb4e1faf-0537-4e08-aaf2-ec66589e073f",
    provider: "azure",
    providerId: "zh-CN-YunyiMultilingualNeural",
    slug: "zh-CN-YunyiMultilingualNeural",
    name: "Yunyi Multilingual",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/ed2bfa83-fb78-46db-9782-0177f0d0e1ec.wav",
  },
  {
    id: "7008b9f3-3eb0-402e-8218-aa0845afef25",
    provider: "azure",
    providerId: "zh-CN-YunzeNeural",
    slug: "zh-CN-YunzeNeural",
    name: "Yunze",
    language: "Chinese (Mandarin, Simplified)",
    languageCode: "zh-CN",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/b36e93c3-8425-4585-8a01-a713ace5c700.wav",
  },
  {
    id: "a94c3ca0-46cc-4f55-a7a0-e4bd988e8849",
    provider: "azure",
    providerId: "zh-CN-guangxi-YunqiNeural",
    slug: "zh-CN-guangxi-YunqiNeural",
    name: "Yunqi",
    language: "Chinese (Guangxi Accent Mandarin, Simplified)",
    languageCode: "zh-CN-guangxi",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d016956f-59c6-4efd-83cd-344fd1075908.wav",
  },
  {
    id: "7144b552-91c6-4dc9-bbb6-aa517a2d49bb",
    provider: "azure",
    providerId: "zh-CN-henan-YundengNeural",
    slug: "zh-CN-henan-YundengNeural",
    name: "Yundeng",
    language: "Chinese (Zhongyuan Mandarin Henan, Simplified)",
    languageCode: "zh-CN-henan",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/59350dad-53ea-490f-9bea-8692bf62f2b6.wav",
  },
  {
    id: "63c1c60b-767e-4317-913e-475d500423d5",
    provider: "azure",
    providerId: "zh-CN-liaoning-XiaobeiNeural",
    slug: "zh-CN-liaoning-XiaobeiNeural",
    name: "Xiaobei",
    language: "Chinese (Northeastern Mandarin, Simplified)",
    languageCode: "zh-CN-liaoning",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6b427cd7-092b-4722-a9e1-184040029352.wav",
  },
  {
    id: "f8d017c2-2375-4ace-90f4-c292663ec27b",
    provider: "azure",
    providerId: "zh-CN-liaoning-YunbiaoNeural",
    slug: "zh-CN-liaoning-YunbiaoNeural",
    name: "Yunbiao",
    language: "Chinese (Northeastern Mandarin, Simplified)",
    languageCode: "zh-CN-liaoning",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/73f0ab33-5946-417f-b611-2d507c6f1dd2.wav",
  },
  {
    id: "a59581c4-b833-45c5-bc9b-484421dab1c7",
    provider: "azure",
    providerId: "zh-CN-shaanxi-XiaoniNeural",
    slug: "zh-CN-shaanxi-XiaoniNeural",
    name: "Xiaoni",
    language: "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)",
    languageCode: "zh-CN-shaanxi",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8b55930a-73d5-417a-b3c2-127255d0d076.wav",
  },
  {
    id: "250f694f-5aa1-41a0-bf91-49adb05a8d5c",
    provider: "azure",
    providerId: "zh-CN-shandong-YunxiangNeural",
    slug: "zh-CN-shandong-YunxiangNeural",
    name: "Yunxiang",
    language: "Chinese (Jilu Mandarin, Simplified)",
    languageCode: "zh-CN-shandong",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d7f93a3b-276a-43c2-84ac-1b7e650b6506.wav",
  },
  {
    id: "b6368f57-cffc-4c22-ad40-5ff2ded8b63e",
    provider: "azure",
    providerId: "zh-CN-sichuan-YunxiNeural",
    slug: "zh-CN-sichuan-YunxiNeural",
    name: "Yunxi",
    language: "Chinese (Southwestern Mandarin, Simplified)",
    languageCode: "zh-CN-sichuan",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/3395cf10-a795-426f-bb9d-b4154a8f4bc1.wav",
  },
  {
    id: "e413cfb1-ae78-45e1-8b4e-c7712922f262",
    provider: "azure",
    providerId: "zh-HK-HiuGaaiNeural",
    slug: "zh-HK-HiuGaaiNeural",
    name: "HiuGaai",
    language: "Chinese (Cantonese, Traditional)",
    languageCode: "zh-HK",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/d22d92d4-dae2-454b-8f0f-579094d313da.wav",
  },
  {
    id: "32584e18-537b-41a1-9cab-ca6614842b79",
    provider: "azure",
    providerId: "zh-HK-HiuMaanNeural",
    slug: "zh-HK-HiuMaanNeural",
    name: "HiuMaan",
    language: "Chinese (Cantonese, Traditional)",
    languageCode: "zh-HK",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/4c9c1bf5-7ce5-49ac-ac2f-f09ba75fffef.wav",
  },
  {
    id: "b821ea56-c16b-484b-9318-11799639013f",
    provider: "azure",
    providerId: "zh-HK-WanLungNeural",
    slug: "zh-HK-WanLungNeural",
    name: "WanLung",
    language: "Chinese (Cantonese, Traditional)",
    languageCode: "zh-HK",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/389ef7f8-1f7f-420d-89d3-da79d59cc8eb.wav",
  },
  {
    id: "e533d185-1290-4136-a95b-47eea9acb8df",
    provider: "azure",
    providerId: "zh-TW-HsiaoChenNeural",
    slug: "zh-TW-HsiaoChenNeural",
    name: "HsiaoChen",
    language: "Chinese (Taiwanese Mandarin, Traditional)",
    languageCode: "zh-TW",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/2e63f21c-5324-4f35-aacd-41fefcfde473.wav",
  },
  {
    id: "40d61203-9e98-4d5c-8ba9-cf028b5ca75e",
    provider: "azure",
    providerId: "zh-TW-HsiaoYuNeural",
    slug: "zh-TW-HsiaoYuNeural",
    name: "HsiaoYu",
    language: "Chinese (Taiwanese Mandarin, Traditional)",
    languageCode: "zh-TW",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/0834ac45-a53c-482e-83da-dc5d7a3e8426.wav",
  },
  {
    id: "d6d84993-fb4f-463e-bfef-bfb1fc658e91",
    provider: "azure",
    providerId: "zh-TW-YunJheNeural",
    slug: "zh-TW-YunJheNeural",
    name: "YunJhe",
    language: "Chinese (Taiwanese Mandarin, Traditional)",
    languageCode: "zh-TW",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/6e81c46a-040d-488a-a223-8e6a94505c99.wav",
  },
  {
    id: "9e84e9f0-ebe5-4582-a0f6-a13e15ede97b",
    provider: "azure",
    providerId: "zu-ZA-ThandoNeural",
    slug: "zu-ZA-ThandoNeural",
    name: "Thando",
    language: "Zulu (South Africa)",
    languageCode: "zu-ZA",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/595547a3-d7ef-460a-91d5-c24f14779585.wav",
  },
  {
    id: "5157c79c-b0c9-42e7-b2b5-3a7cf6a90d76",
    provider: "azure",
    providerId: "zu-ZA-ThembaNeural",
    slug: "zu-ZA-ThembaNeural",
    name: "Themba",
    language: "Zulu (South Africa)",
    languageCode: "zu-ZA",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "https://cvoicemcche2.blob.core.chinacloudapi.cn/acc-public-files/98dd58cde9f14d9e930a9ac464a3b0e4/8b16749f-b4ba-43e4-a287-d27d51aaadb2.wav",
  },
  {
    id: "27a7efd8-0e41-444d-b888-7781e2d02b0e",
    provider: "deepgram",
    providerId: "aura-angus-en",
    slug: "angus",
    name: "angus",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-angus-male irish.mp3",
    gender: "Male",
    language: "English (Irish)",
  },
  {
    id: "16396192-43da-49b4-8966-806e7200553a",
    provider: "deepgram",
    providerId: "aura-arcas-en",
    slug: "arcas",
    name: "arcas",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-arcas-male american.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "16dbb1a2-e0c5-4299-8ede-4dd55a64b169",
    provider: "deepgram",
    providerId: "aura-asteria-en",
    slug: "asteria",
    name: "asteria",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-asteria-female american.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "eb6dc073-831b-44fc-8a8e-2a909d03bf9d",
    provider: "deepgram",
    providerId: "aura-athena-en",
    slug: "athena",
    name: "athena",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-athena-female british.mp3",
    gender: "Female",
    language: "English (GB)",
    languageCode: "en-GB",
  },
  {
    id: "1b2c6665-e8c1-4b17-8f0d-03d534d1468d",
    provider: "deepgram",
    providerId: "aura-helios-en",
    slug: "helios",
    name: "helios",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-helios-male british.mp3",
    gender: "Male",
    language: "English (GB)",
    languageCode: "en-GB",
  },
  {
    id: "4485e41b-b131-4ce6-8a14-06fe0f3788d3",
    provider: "deepgram",
    providerId: "aura-hera-en",
    slug: "hera",
    name: "hera",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-hera-female american.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "294bebe0-298f-4c9d-beb7-958dd091981a",
    provider: "deepgram",
    providerId: "aura-luna-en",
    slug: "luna",
    name: "luna",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-luna-female american.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "fac9ca8b-5cbe-47d3-8da4-b0e3765744c8",
    provider: "deepgram",
    providerId: "aura-orion-en",
    slug: "orion",
    name: "orion",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-orion-male american.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "68ce6a2e-e4dd-4a92-863e-d511ece27062",
    provider: "deepgram",
    providerId: "aura-orpheus-en",
    slug: "orpheus",
    name: "orpheus",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-orpheus-male american.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "9f3ec828-a753-48da-a746-d009dce17012",
    provider: "deepgram",
    providerId: "aura-perseus-en",
    slug: "perseus",
    name: "perseus",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-perseus-male american.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "f69a6022-f1d1-4deb-a181-95745b87369c",
    provider: "deepgram",
    providerId: "aura-stella-en",
    slug: "stella",
    name: "stella",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-stella-female american.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "6eb682b9-90c2-499b-a648-6274a61dd081",
    provider: "deepgram",
    providerId: "aura-zeus-en",
    slug: "zeus",
    name: "zeus",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/deepgram-zeus-male american.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "75f484b9-9a18-432b-948b-1c128abb387b",
    provider: "openai",
    providerId: "alloy",
    slug: "alloy",
    name: "alloy",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/openai-alloy.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "829512df-00fe-4756-87c2-33e942a7ab38",
    provider: "openai",
    providerId: "echo",
    slug: "echo",
    name: "echo",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/openai-echo.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "fad33df4-53ea-4e0a-87bd-3bd7581c5138",
    provider: "openai",
    providerId: "fable",
    slug: "fable",
    name: "fable",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/openai-fable.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "4a93e8fb-9c58-4c5a-a491-fb572376a2c3",
    provider: "openai",
    providerId: "nova",
    slug: "nova",
    name: "nova",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/openai-nova.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "567daf5a-7546-403b-bc0d-c3132df95e8a",
    provider: "openai",
    providerId: "onyx",
    slug: "onyx",
    name: "onyx",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/openai-onyx.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "a4576f6a-11a2-4ca9-ac16-dd9bcae1e5b0",
    provider: "openai",
    providerId: "shimmer",
    slug: "shimmer",
    name: "shimmer",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/openai-shimmer.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "a6b028d6-1d1f-4538-8567-a604a50f1fe0",
    provider: "cartesia",
    providerId: "3b554273-4299-48b9-9aaf-eefd438e3941",
    slug: "3b554273-4299-48b9-9aaf-eefd438e3941",
    name: "Indian Lady",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is young, rich, and curious, perfect for a narrator or fictional character",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Indian Lady.mp3",
    gender: "Female",
    language: "English (Indian)",
    languageCode: "en-IN",
  },
  {
    id: "12b68588-b076-4697-ae93-d471fb3c96a6",
    provider: "cartesia",
    providerId: "63ff761f-c1e8-414b-b969-d1833d1c870c",
    slug: "63ff761f-c1e8-414b-b969-d1833d1c870c",
    name: "Confident British Man",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is disciplined with a British accent, perfect for a commanding character or narrator",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Confident British Man.mp3",
    gender: "Male",
    language: "English (GB)",
    languageCode: "en-GB",
  },
  {
    id: "f723917e-5c21-4557-ab5d-83844547a68c",
    provider: "cartesia",
    providerId: "daf747c6-6bc2-4083-bd59-aa94dce23f5d",
    slug: "daf747c6-6bc2-4083-bd59-aa94dce23f5d",
    name: "Middle Eastern Woman",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is clear with a Middle Eastern Accent, perfect for a narrator",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Middle Eastern Women.mp3",
    gender: "Female",
    language: "English (Middle Eastern)",
  },
  {
    id: "a5cec64c-cfbb-439f-8e75-6f8f83681f50",
    provider: "cartesia",
    providerId: "ed81fd13-2016-4a49-8fe3-c0d2761695fc",
    slug: "ed81fd13-2016-4a49-8fe3-c0d2761695fc",
    name: "Sportsman",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is energetic and enthusiastic, perfect for a sports broadcaster",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Sportsman.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "afb07eff-5853-410c-a600-3d9700151e6c",
    provider: "cartesia",
    providerId: "f114a467-c40a-4db8-964d-aaba89cd08fa",
    slug: "f114a467-c40a-4db8-964d-aaba89cd08fa",
    name: "Yogaman",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is calm, soothing, and stable, perfect for a yoga instructor",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Yogaman.mp3",
    gender: "Male",
    language: "English (GB)",
    languageCode: "en-GB",
  },
  {
    id: "b244011f-7ac5-4a04-a471-c50813b1ed14",
    provider: "cartesia",
    providerId: "c45bc5ec-dc68-4feb-8829-6e6b2748095d",
    slug: "c45bc5ec-dc68-4feb-8829-6e6b2748095d",
    name: "Movieman",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is deep, resonant, and assertive, perfect for a movie narrator",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Movie Man.mp3",
    gender: "Male",
    language: "English (GB)",
    languageCode: "en-GB",
  },
  {
    id: "33768c2f-b8d0-475c-9b94-90592d26d9c9",
    provider: "cartesia",
    providerId: "87748186-23bb-4158-a1eb-332911b0b708",
    slug: "87748186-23bb-4158-a1eb-332911b0b708",
    name: "Wizardman",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is wise and mysterious, perfect for a Wizard character",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Wizardman.mp3",
    gender: "Male",
    language: "English (GB)",
    languageCode: "en-GB",
  },
  {
    id: "1c2108cc-f2b5-4d82-9ed4-c696b975554e",
    provider: "cartesia",
    providerId: "98a34ef2-2140-4c28-9c71-663dc4dd7022",
    slug: "98a34ef2-2140-4c28-9c71-663dc4dd7022",
    name: "Southern Man",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is warm with a Southern accent, perfect for a narrator",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Southern Man.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "98081f4b-5d78-450a-9d1a-15726bd7b3ad",
    provider: "cartesia",
    providerId: "79f8b5fb-2cc8-479a-80df-29f7a7cf1a3e",
    slug: "79f8b5fb-2cc8-479a-80df-29f7a7cf1a3e",
    name: "Nonfiction Man",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is smooth, confident, and resonant, perfect for narrating educational content",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Nonfiction Man.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "afcca2a0-f758-4ff2-bb9e-46bbd5acd04a",
    provider: "cartesia",
    providerId: "36b42fcb-60c5-4bec-b077-cb1a00a92ec6",
    slug: "36b42fcb-60c5-4bec-b077-cb1a00a92ec6",
    name: "Pilot over Intercom",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice sounds like a British Pilot character speaking over an Intercom",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Pilot Over Intercom.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "35484c3c-1b2e-470e-b17e-01ca43444009",
    provider: "cartesia",
    providerId: "69267136-1bdc-412f-ad78-0caad210fb40",
    slug: "69267136-1bdc-412f-ad78-0caad210fb40",
    name: "Friendly Reading Man",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is energetic and friendly, like having your friend read his favorite book to you",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Friendly Reading man.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "8f1f37d7-df41-4ed0-91a2-5aff74ccd19f",
    provider: "cartesia",
    providerId: "15a9cd88-84b0-4a8b-95f2-5d583b54c72e",
    slug: "15a9cd88-84b0-4a8b-95f2-5d583b54c72e",
    name: "Reading Lady",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is monotone and deliberate, perfect for a slower-paced and more serious reading voice",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Reading lady.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "26c71408-270f-4e00-8ee1-f399e534f91c",
    provider: "cartesia",
    providerId: "95856005-0332-41b0-935f-352e296aa0df",
    slug: "95856005-0332-41b0-935f-352e296aa0df",
    name: "Classy British Man",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is light and smooth with a British accent, perfect for casual conversation",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Classy British Man.mp3",
    gender: "Male",
    language: "English (GB)",
    languageCode: "en-GB",
  },
  {
    id: "20442782-9c36-4e85-b44d-64c7062ed02d",
    provider: "cartesia",
    providerId: "d46abd1d-2d02-43e8-819f-51fb652c1c61",
    slug: "d46abd1d-2d02-43e8-819f-51fb652c1c61",
    name: "Newsman",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is neutral and educational, perfect for a news anchor",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Newsman.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "8765bad7-5660-4f00-a21e-aa46f7ae4e59",
    provider: "cartesia",
    providerId: "2ee87190-8f84-4925-97da-e52547f9462c",
    slug: "2ee87190-8f84-4925-97da-e52547f9462c",
    name: "Child",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is young and full, perfect for a child",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Child.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "9d99c398-b974-44ac-aa26-8d9dfcc95f08",
    provider: "cartesia",
    providerId: "c2ac25f9-ecc4-4f56-9095-651354df60c0",
    slug: "c2ac25f9-ecc4-4f56-9095-651354df60c0",
    name: "Commercial Lady",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is inviting, enthusiastic, and relatable, perfect for a commercial or advertisement",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Commercial Lady.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "44d7cad9-e956-40b3-a433-f0fe8dd1d942",
    provider: "cartesia",
    providerId: "5345cf08-6f37-424d-a5d9-8ae1101b9377",
    slug: "5345cf08-6f37-424d-a5d9-8ae1101b9377",
    name: "Maria",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is laid back, natural, and conversational, like you're catching up with a good friend",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Maria.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "36c89ac0-a0ea-471b-bd23-f2636613f1b3",
    provider: "cartesia",
    providerId: "a3520a8f-226a-428d-9fcd-b0a4711a6829",
    slug: "a3520a8f-226a-428d-9fcd-b0a4711a6829",
    name: "Reflective Woman",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is even, full, and reflective, perfect for a young narrator for an audiobook or movie",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Reflective Women.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "328c181c-550b-4da2-a905-59cd7b9e412d",
    provider: "cartesia",
    providerId: "e3827ec5-697a-4b7c-9704-1a23041bbc51",
    slug: "e3827ec5-697a-4b7c-9704-1a23041bbc51",
    name: "Sweet Lady",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is sweet and passionate, perfect for a character in a game or book",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Sweet Lady.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "2a62bf12-7d06-402b-8bff-b074c4f0b414",
    provider: "cartesia",
    providerId: "a0e99841-438c-4a64-b679-ae501e7d6091",
    slug: "a0e99841-438c-4a64-b679-ae501e7d6091",
    name: "Barbershop Man",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is smooth and relaxing, perfect for a casual conversation",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Barbershop man.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "47100263-c41a-46cb-b2a6-7dbe45e2ed1f",
    provider: "cartesia",
    providerId: "cd17ff2d-5ea4-4695-be8f-42193949b946",
    slug: "cd17ff2d-5ea4-4695-be8f-42193949b946",
    name: "Meditation Lady",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is calm, soothing, and relaxing, perfect for meditation",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Meditation Lady.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "a7b2a91f-d056-47f5-9e5a-2aee01436cd5",
    provider: "cartesia",
    providerId: "bf991597-6c13-47e4-8411-91ec2de5c466",
    slug: "bf991597-6c13-47e4-8411-91ec2de5c466",
    name: "Newslady",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is authoritative and educational, perfect for a news anchor",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Newslady.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "3bd024b2-d11f-472d-b86d-4c81757ee72c",
    provider: "cartesia",
    providerId: "41534e16-2966-4c6b-9670-111411def906",
    slug: "41534e16-2966-4c6b-9670-111411def906",
    name: "1920's Radioman",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is energetic and confident, great for an entertainer or radio host",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-1920s Radio Man.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "7501e225-fcb2-42de-bbf4-248204dc3c40",
    provider: "cartesia",
    providerId: "79a125e8-cd45-4c13-8a67-188112f4dd22",
    slug: "79a125e8-cd45-4c13-8a67-188112f4dd22",
    name: "British Lady",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is elegant with a slight British accent, perfect for storytelling and narrating",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-British Lady.mp3",
    gender: "Female",
    language: "English (GB)",
    languageCode: "en-GB",
  },
  {
    id: "55381f5a-59a6-4f85-8ca5-5fa15395ed64",
    provider: "cartesia",
    providerId: "a167e0f3-df7e-4d52-a9c3-f949145efdab",
    slug: "a167e0f3-df7e-4d52-a9c3-f949145efdab",
    name: "Ted",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is clear and calm, perfect for a call center",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Ted.mp3",
    gender: "Male",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "d2a77d4c-330f-454a-902e-0c9db82b9774",
    provider: "cartesia",
    providerId: "248be419-c632-4f23-adf1-5324ed7dbf1d",
    slug: "248be419-c632-4f23-adf1-5324ed7dbf1d",
    name: "Hannah",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is neutral and calm, perfect for a call center",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Hannah.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "626e3e13-4ff0-4d12-bc27-afe36469884b",
    provider: "cartesia",
    providerId: "c8605446-247c-4d39-acd4-8f4c28aa363c",
    slug: "c8605446-247c-4d39-acd4-8f4c28aa363c",
    name: "Wise Lady",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is wise and authoritative, perfect for a confident narrator",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Wise Lady.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "5250cb77-8607-49f5-be3a-c49cb96468e5",
    provider: "cartesia",
    providerId: "00a77add-48d5-4ef6-8157-71e5437b282d",
    slug: "00a77add-48d5-4ef6-8157-71e5437b282d",
    name: "Calm Lady",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is calm and nurturing, perfect for a narrator",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Calm Lady.mp3",
    gender: "Female",
    language: "English (US)",
    languageCode: "en-US",
  },
  {
    id: "1a971477-9840-49c2-a598-55844806ce00",
    provider: "cartesia",
    providerId: "638efaaa-4d0c-442e-b701-3fae16aad012",
    slug: "638efaaa-4d0c-442e-b701-3fae16aad012",
    name: "Indian Man",
    createdAt: "2024-05-29T13:07:49.456Z",
    updatedAt: "2024-05-29T13:07:49.456Z",
    description: "This voice is smooth with an Indian accent, perfect for a narrator",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/cartesia-Indian Man.mp3",
    gender: "Male",
    language: "English (Indian)",
    languageCode: "en-IN",
  },
  {
    id: "b3ea7b33-f449-4828-a987-f1d5b337f56d",
    provider: "lmnt",
    providerId: "mrnmrz72",
    slug: "mrnmrz72",
    name: "Marzia",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Marzia.mp3",
  },
  {
    id: "874d7a7f-ec1b-439c-9f8f-ec68217bda34",
    provider: "lmnt",
    providerId: "daniel",
    slug: "daniel",
    name: "Daniel",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Daniel.mp3",
  },
  {
    id: "b2cd4355-2143-4b4b-9ac8-0437bb518c16",
    provider: "lmnt",
    providerId: "lily",
    slug: "lily",
    name: "Lily",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Lily.mp3",
  },
  {
    id: "80fc1a8e-4e29-4046-b383-602bce19c43c",
    provider: "lmnt",
    providerId: "maurice",
    slug: "maurice",
    name: "Maurice",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Maurice.mp3",
  },
  {
    id: "59931fd5-c0c1-4d63-8e9d-26d8dfda022b",
    provider: "lmnt",
    providerId: "034b632b-df71-46c8-b440-86a42ffc3cf3",
    slug: "034b632b-df71-46c8-b440-86a42ffc3cf3",
    name: "Henry",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Henry.mp3",
  },
  {
    id: "9a7a8608-db48-4c41-a9cc-125e35cbb419",
    provider: "lmnt",
    providerId: "4e584062-ad8d-4cde-a9df-8f9743046a3d",
    slug: "4e584062-ad8d-4cde-a9df-8f9743046a3d",
    name: "Eleanor",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Eleanor.mp3",
  },
  {
    id: "80f1c3af-3adb-49bb-a870-ab24f4631113",
    provider: "lmnt",
    providerId: "amy",
    slug: "amy",
    name: "Amy",
    gender: "Female",
    createdAt: "2024-04-25T03:08:35.394Z",
    updatedAt: "2024-04-25T03:08:35.394Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Amy.mp3",
  },
  {
    id: "54613251-5f26-474f-a915-77b2a741c83b",
    provider: "lmnt",
    providerId: "e8d31f4e-e8af-4c72-9fc9-f4f6f535c6cc",
    slug: "e8d31f4e-e8af-4c72-9fc9-f4f6f535c6cc",
    name: "Natalie",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Natalie.mp3",
  },
  {
    id: "77836830-256e-4c33-bf6b-a362a867ffff",
    provider: "lmnt",
    providerId: "nathan",
    slug: "nathan",
    name: "Nathan",
    gender: "Male",
    createdAt: "2024-04-25T03:08:35.394Z",
    updatedAt: "2024-04-25T03:08:35.394Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Nathan.mp3",
  },
  {
    id: "22909c2e-fcde-44ab-bb9d-a33062ffc941",
    provider: "lmnt",
    providerId: "598edd09-4fb2-460c-9ad1-d9f8081079d3",
    slug: "598edd09-4fb2-460c-9ad1-d9f8081079d3",
    name: "Priya",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Priya.mp3",
  },
  {
    id: "5f2b90fd-3db8-4f46-a6b1-fa5e04c222f4",
    provider: "lmnt",
    providerId: "rbalogh",
    slug: "rbalogh",
    name: "Reba",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Reba.mp3",
  },
  {
    id: "6f453a94-e5ec-4653-8c83-b799f8b0cd75",
    provider: "lmnt",
    providerId: "sophie",
    slug: "sophie",
    name: "Sophie",
    gender: "Female",
    createdAt: "2024-04-25T03:08:35.394Z",
    updatedAt: "2024-04-25T03:08:35.394Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Sophie.mp3",
  },
  {
    id: "97c75f35-6565-4215-83d6-893863ccfdc0",
    provider: "lmnt",
    providerId: "a904f97c-ff5f-4099-98c8-b5effe00c9a6",
    slug: "a904f97c-ff5f-4099-98c8-b5effe00c9a6",
    name: "Szymon",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Szymon.mp3",
  },
  {
    id: "a191e8e2-9b4a-4c97-99de-1cec7631adb4",
    provider: "lmnt",
    providerId: "4e95c4a7-95aa-4b1d-bc23-00f7d1d484ea",
    slug: "4e95c4a7-95aa-4b1d-bc23-00f7d1d484ea",
    name: "Oliver",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Oliver.mp3",
  },
  {
    id: "2f7a7f76-f3d7-4553-95bc-7e0265adb049",
    provider: "lmnt",
    providerId: "9db02220-4029-40f1-a807-55d645386d2b",
    slug: "9db02220-4029-40f1-a807-55d645386d2b",
    name: "Kathrine",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Kathrine.mp3",
  },
  {
    id: "9a7a2309-110a-46b1-9e73-0e365b57d064",
    provider: "lmnt",
    providerId: "c8ea4f2a-06e6-4d7b-9484-db941bf7c657",
    slug: "c8ea4f2a-06e6-4d7b-9484-db941bf7c657",
    name: "Joe",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Joe.mp3",
  },
  {
    id: "326e8f21-8071-4247-afea-af1ae6bdef37",
    provider: "lmnt",
    providerId: "caleb",
    slug: "caleb",
    name: "Caleb",
    gender: "Male",
    createdAt: "2024-04-25T03:08:35.394Z",
    updatedAt: "2024-04-25T03:08:35.394Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Caleb.mp3",
  },
  {
    id: "14e8db02-7148-4253-9c61-3f705b461448",
    provider: "lmnt",
    providerId: "chloe",
    slug: "chloe",
    name: "Chloe",
    gender: "Female",
    createdAt: "2024-04-25T03:08:35.394Z",
    updatedAt: "2024-04-25T03:08:35.394Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Chloe.mp3",
  },
  {
    id: "827fdf1d-61ad-4c28-adcc-cd3a121f1c23",
    provider: "lmnt",
    providerId: "curtis",
    slug: "curtis",
    name: "Curtis",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Curtis.mp3",
  },
  {
    id: "4b532642-1216-4c7b-9dd1-55f9190a7198",
    provider: "lmnt",
    providerId: "dalton",
    slug: "dalton",
    name: "Dalton",
    gender: "Male",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Dalton.mp3",
  },
  {
    id: "44a1754b-48a3-4d86-9008-6705bf3798f1",
    provider: "lmnt",
    providerId: "morgan",
    slug: "morgan",
    name: "Morgan",
    gender: "Female",
    createdAt: "2024-04-25T03:08:35.394Z",
    updatedAt: "2024-04-25T03:08:35.394Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Morgan.mp3",
  },
  {
    id: "3b1a78d4-85f5-4603-bda9-9e57b3bedae5",
    provider: "lmnt",
    providerId: "olivia",
    slug: "olivia",
    name: "Olivia",
    gender: "Female",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Olivia.mp3",
  },
  {
    id: "ae17d10a-4604-471a-aae8-e7580012c2cd",
    provider: "lmnt",
    providerId: "terrence",
    slug: "terrence",
    name: "Terrence",
    gender: "Male",
    createdAt: "2024-05-08T18:01:38.435Z",
    updatedAt: "2024-05-08T18:01:38.435Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    previewUrl: "/rimeai_voices/lmnt-Terrence.mp3",
  },
  {
    id: "468e9fec-b289-4705-ad46-b6c7cabc76a8",
    provider: "neets",
    providerId: "vits-ara-1",
    slug: "vits-ara",
    name: "vits-ara-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-ara-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Arabic",
    languageCode: "ar",
  },
  {
    id: "20f115e9-f9a9-485e-ab2a-0eba6181e357",
    provider: "neets",
    providerId: "vits-kin-1",
    slug: "vits-kin-1",
    name: "vits-kin-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-kin-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Kinyarwanda",
    languageCode: "rw",
  },
  {
    id: "d148c02c-9a73-4c45-b894-fc85eda54bd6",
    provider: "neets",
    providerId: "vits-nep-1",
    slug: "vits-nep-1",
    name: "vits-nep-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-nep-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Nepali",
    languageCode: "ne",
  },
  {
    id: "f4541c4e-4961-45b1-b60f-b25902867ebf",
    provider: "neets",
    providerId: "vits-nld-1",
    slug: "vits-nld-1",
    name: "vits-nld-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-nld2-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Dutch",
    languageCode: "nl",
  },
  {
    id: "34a700ba-c017-4a6d-abac-ecde51cd3afa",
    provider: "neets",
    providerId: "vits-nor-1",
    slug: "vits-nor-1",
    name: "vits-nor-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-nor-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Norwegian",
    languageCode: "no",
  },
  {
    id: "9310894e-0ffc-48fd-8f38-97c267759746",
    provider: "neets",
    providerId: "vits-nya-1",
    slug: "vits-nya-1",
    name: "vits-nya-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-nya-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Nyanja (Chewa)",
    languageCode: "ny",
  },
  {
    id: "a492c7ad-95fd-4ee4-888a-2ae781db4cda",
    provider: "neets",
    providerId: "vits-ben-1",
    slug: "vits-ben-1",
    name: "vits-ben-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-ben-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Bengali",
    languageCode: "bn",
  },
  {
    id: "63442d83-6637-4561-8612-a3eee90f7232",
    provider: "neets",
    providerId: "vits-bul-1",
    slug: "vits-bul-1",
    name: "vits-bul-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-bul-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Bulgarian",
    languageCode: "bg",
  },
  {
    id: "cba68742-6a73-46b4-aba3-8634e0e9890b",
    provider: "neets",
    providerId: "vits-cat-1",
    slug: "vits-cat-1",
    name: "vits-cat-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-cat-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Catalan",
    languageCode: "ca",
  },
  {
    id: "d57cc463-6d2f-45f7-8dde-179f172e9da9",
    provider: "neets",
    providerId: "vits-cze-1",
    slug: "vits-cze-1",
    name: "vits-cze-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-cze-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Czech",
    languageCode: "cs",
  },
  {
    id: "a7091679-184f-4fe6-a1da-c5b8c2df4cf2",
    provider: "neets",
    providerId: "vits-dan-1",
    slug: "vits-dan-1",
    name: "vits-dan-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-dan-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Danish",
    languageCode: "da",
  },
  {
    id: "80c1b36c-331f-4f3b-bc01-e07f3c806c33",
    provider: "neets",
    providerId: "vits-deu-1",
    slug: "vits-deu-1",
    name: "vits-deu-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-deu-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "German",
    languageCode: "de",
  },
  {
    id: "9bde5ad0-268a-465a-8c30-56cbfa9f70f6",
    provider: "neets",
    providerId: "vits-dzo-1",
    slug: "vits-dzo-1",
    name: "vits-dzo-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-dzo-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Dzongkha",
    languageCode: "dz",
  },
  {
    id: "3796c499-1922-4944-a6ec-3e18895731b5",
    provider: "neets",
    providerId: "vits-ell-1",
    slug: "vits-ell-1",
    name: "vits-ell-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-ell-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Greek",
    languageCode: "el",
  },
  {
    id: "17af1ea8-b1f1-40a3-b4d4-b2d3eff43fa5",
    provider: "neets",
    providerId: "vits-fra-1",
    slug: "vits-fra-1",
    name: "vits-fra-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-fra-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "French",
    languageCode: "fr",
  },
  {
    id: "6ebff8f4-0ce6-4663-bb47-fe15f517267f",
    provider: "neets",
    providerId: "vits-guj-1",
    slug: "vits-guj-1",
    name: "vits-guj-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-guj-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Gujarati",
    languageCode: "gu",
  },
  {
    id: "e64e0f69-6a66-4854-b24a-f0710f89516f",
    provider: "neets",
    providerId: "vits-hau-1",
    slug: "vits-hau-1",
    name: "vits-hau-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-hau-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Hausa",
    languageCode: "ha",
  },
  {
    id: "df8aa5f5-6882-48a8-b77b-4f617811b6ce",
    provider: "neets",
    providerId: "vits-heb-1",
    slug: "vits-heb-1",
    name: "vits-heb-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-heb-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Hebrew",
    languageCode: "he",
  },
  {
    id: "1d7525aa-5377-4015-80f0-e16ff8a5e0d0",
    provider: "neets",
    providerId: "vits-hay-1",
    slug: "vits-hay-1",
    name: "vits-hay-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-hay-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Armenian",
    languageCode: "hy",
  },
  {
    id: "d49f81e4-74e9-451e-acec-8d114be185db",
    provider: "neets",
    providerId: "vits-hin-1",
    slug: "vits-hin-1",
    name: "vits-hin-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-hin-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Hindi",
    languageCode: "hi",
  },
  {
    id: "d7038c57-4c92-4c53-be24-b504a9745608",
    provider: "neets",
    providerId: "vits-khm-1",
    slug: "vits-khm-1",
    name: "vits-khm-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-khm-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Khmer",
    languageCode: "km",
  },
  {
    id: "1e9c7444-855f-4a00-83ab-7193c264727c",
    provider: "neets",
    providerId: "vits-kir-1",
    slug: "vits-kir-1",
    name: "vits-kir-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-kir-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Kirundi",
    languageCode: "rn",
  },
  {
    id: "46515d68-5286-43ee-9cd3-7dd427223506",
    provider: "neets",
    providerId: "vits-kor-1",
    slug: "vits-kor-1",
    name: "vits-kor-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-kor-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Korean",
    languageCode: "ko",
  },
  {
    id: "cb751cb4-485b-49f3-9fdd-f19616af7848",
    provider: "neets",
    providerId: "vits-kri-1",
    slug: "vits-kri-1",
    name: "vits-kri-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-kri-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Krio",
    languageCode: "kri",
  },
  {
    id: "8daf4023-6d4e-497b-9d16-c4c3904d4736",
    provider: "neets",
    providerId: "vits-shn-1",
    slug: "vits-shn-1",
    name: "vits-shn-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-shn-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Shan",
    languageCode: "shn",
  },
  {
    id: "9c4ff425-3e82-4e9f-b5cf-5fe5db0de8df",
    provider: "neets",
    providerId: "vits-slk-1",
    slug: "vits-slk-1",
    name: "vits-slk-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-slk-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Slovak",
    languageCode: "sk",
  },
  {
    id: "a2245254-b724-4aa8-846d-1992f6edfd5e",
    provider: "neets",
    providerId: "vits-slv-1",
    slug: "vits-slv-1",
    name: "vits-slv-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-slv-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Slovenian",
    languageCode: "sl",
  },
  {
    id: "c06c91c5-e231-45cf-b92c-2acec26b16df",
    provider: "neets",
    providerId: "vits-sna-1",
    slug: "vits-sna-1",
    name: "vits-sna-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-sna-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Shona",
    languageCode: "sn",
  },
  {
    id: "fe50b318-4c87-44ed-8907-8b9fc1374a7b",
    provider: "neets",
    providerId: "vits-som-1",
    slug: "vits-som-1",
    name: "vits-som-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-som-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Somali",
    languageCode: "so",
  },
  {
    id: "2a5fc650-068f-48ae-b7b2-996d32279b84",
    provider: "neets",
    providerId: "vits-spa-1",
    slug: "vits-spa-1",
    name: "vits-spa-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-spa-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Spanish",
    languageCode: "es",
  },
  {
    id: "d77a2bd9-9e4f-49f5-9487-2ef5b6b9b907",
    provider: "neets",
    providerId: "vits-sqi-1",
    slug: "vits-sqi-1",
    name: "vits-sqi-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-sqi-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Albanian",
    languageCode: "sq",
  },
  {
    id: "504d183e-b049-4b70-98d5-065e405c8954",
    provider: "neets",
    providerId: "vits-srp-1",
    slug: "vits-srp-1",
    name: "vits-srp-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-srp-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Serbian",
    languageCode: "sr",
  },
  {
    id: "361b4860-bd36-4b6a-b93d-fda1f8fc36fe",
    provider: "neets",
    providerId: "vits-sun-1",
    slug: "vits-sun-1",
    name: "vits-sun-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-sun-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Sundanese",
    languageCode: "su",
  },
  {
    id: "a4ab7312-d249-4305-b7a0-48a650471cac",
    provider: "neets",
    providerId: "vits-swe-1",
    slug: "vits-swe-1",
    name: "vits-swe-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-swe-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Swedish",
    languageCode: "sv",
  },
  {
    id: "feaee497-4e4c-4829-bfa5-813987d149f8",
    provider: "neets",
    providerId: "vits-tam-1",
    slug: "vits-tam-1",
    name: "vits-tam-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-tam-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Tamil",
    languageCode: "ta",
  },
  {
    id: "e439812c-515c-4899-a4f4-9ea5aec35ec1",
    provider: "neets",
    providerId: "vits-tel-1",
    slug: "vits-tel-1",
    name: "vits-tel-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-tel-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Telugu",
    languageCode: "te",
  },
  {
    id: "2cd277b2-3728-4191-a8cf-6d910c3ca787",
    provider: "neets",
    providerId: "vits-tgk-1",
    slug: "vits-tgk-1",
    name: "vits-tgk-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-tgk-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Tajik",
    languageCode: "tg",
  },
  {
    id: "95e31ac1-5ed1-4c52-8ebd-15bc4b40b08d",
    provider: "neets",
    providerId: "vits-tgl-1",
    slug: "vits-tgl-1",
    name: "vits-tgl-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-tgl-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Tagalog",
    languageCode: "tl",
  },
  {
    id: "1bd9dc94-2a81-446d-8e2d-f7e2b61ac032",
    provider: "neets",
    providerId: "vits-afr-1",
    slug: "vits-afr-1",
    name: "vits-afr-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-afr-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Afrikaans",
    languageCode: "af",
  },
  {
    id: "78f6b48e-ede4-40a6-b0a5-2326c9d75a4a",
    provider: "neets",
    providerId: "vits-aka-1",
    slug: "vits-aka-1",
    name: "vits-aka-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-aka-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Akan",
    languageCode: "ak",
  },
  {
    id: "a1f04af0-1cf6-4dc0-9ed1-73154fafa7b0",
    provider: "neets",
    providerId: "vits-bam-1",
    slug: "vits-bam-1",
    name: "vits-bam-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-bam-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Bambara",
    languageCode: "bm",
  },
  {
    id: "622057fd-7e9c-47d3-8212-98da9eae3642",
    provider: "neets",
    providerId: "vits-bem-1",
    slug: "vits-bem-1",
    name: "vits-bem-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-bem-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Bemba",
    languageCode: "bem",
  },
  {
    id: "87e8da4a-0e2a-4670-9c05-1a067c5d32b9",
    provider: "neets",
    providerId: "vits-cym-1",
    slug: "vits-cym-1",
    name: "vits-cym-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-cym-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Welsh",
    languageCode: "cy",
  },
  {
    id: "5392ae0c-980b-44d7-a557-1b4ee87a7253",
    provider: "neets",
    providerId: "vits-eng-1",
    slug: "vits-eng",
    name: "vits-eng-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-eng-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "English",
    languageCode: "en",
  },
  {
    id: "9de407d6-5d12-431c-b33f-bb55e698ecb9",
    provider: "neets",
    providerId: "vits-est-1",
    slug: "vits-est-1",
    name: "vits-est-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-est-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Estonian",
    languageCode: "et",
  },
  {
    id: "02d1620c-2ec1-4bd9-a377-d5b93d2e3b82",
    provider: "neets",
    providerId: "vits-fas-1",
    slug: "vits-fas-1",
    name: "vits-fas-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-fas-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Persian (Farsi)",
    languageCode: "fa",
  },
  {
    id: "1d780a39-21b9-4ef8-bdc6-0e0fc3fece98",
    provider: "neets",
    providerId: "vits-fij-1",
    slug: "vits-fij-1",
    name: "vits-fij-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-fij-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Fijian",
    languageCode: "fj",
  },
  {
    id: "11cd2552-05e7-46ad-8439-1acdf5ed52e2",
    provider: "neets",
    providerId: "vits-fin-1",
    slug: "vits-fin-1",
    name: "vits-fin-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-fin-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Finnish",
    languageCode: "fi",
  },
  {
    id: "f1658341-e57c-49c6-a4e6-bde1b839ac2e",
    provider: "neets",
    providerId: "vits-hat-1",
    slug: "vits-hat-1",
    name: "vits-hat-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-hat-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Haitian Creole",
    languageCode: "ht",
  },
  {
    id: "eb9a16cd-94ab-49de-a739-ae959202175b",
    provider: "neets",
    providerId: "vits-hun-1",
    slug: "vits-hun-1",
    name: "vits-hun-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-hun-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Hungarian",
    languageCode: "hu",
  },
  {
    id: "aae3119b-300f-4df3-9fee-9f5eadd7d71c",
    provider: "neets",
    providerId: "vits-ind-1",
    slug: "vits-ind-1",
    name: "vits-ind-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-ind-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Indonesian",
    languageCode: "id",
  },
  {
    id: "4ac24884-6d31-4dae-9251-6def19f9f108",
    provider: "neets",
    providerId: "vits-isl-1",
    slug: "vits-isl-1",
    name: "vits-isl-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-isl-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Icelandic",
    languageCode: "is",
  },
  {
    id: "54e82de9-b01e-409c-9454-4e642052a5e7",
    provider: "neets",
    providerId: "vits-ita-1",
    slug: "vits-ita-1",
    name: "vits-ita-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-ita-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Italian",
    languageCode: "it",
  },
  {
    id: "dfa7a0cc-dc99-4c03-a1d5-534af8d89ab8",
    provider: "neets",
    providerId: "vits-jam-1",
    slug: "vits-jam-1",
    name: "vits-jam-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-jam-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Jamaican Creole",
    languageCode: "jam",
  },
  {
    id: "ea87e15c-83aa-428e-99c9-480671abc3c7",
    provider: "neets",
    providerId: "vits-jav-1",
    slug: "vits-jav-1",
    name: "vits-jav-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-jav-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Javanese",
    languageCode: "jv",
  },
  {
    id: "7c2cd433-b4d3-4bbb-8f61-6f5a7a0ee545",
    provider: "neets",
    providerId: "vits-kat-1",
    slug: "vits-kat-1",
    name: "vits-kat-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-kat-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Georgian",
    languageCode: "ka",
  },
  {
    id: "e005a6b5-5ba1-4a60-8d80-c0a8d5ec625e",
    provider: "neets",
    providerId: "vits-kaz-1",
    slug: "vits-kaz-1",
    name: "vits-kaz-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-kaz-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Kazakh",
    languageCode: "kk",
  },
  {
    id: "e1d7f6d5-60a9-4ab4-b773-22427d739791",
    provider: "neets",
    providerId: "vits-lao-1",
    slug: "vits-lao-1",
    name: "vits-lao-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-lao-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Lao",
    languageCode: "lo",
  },
  {
    id: "ab7ae107-8eca-4e31-b254-c1426adbcd61",
    provider: "neets",
    providerId: "vits-lav-1",
    slug: "vits-lav-1",
    name: "vits-lav-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-lav-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Latvian",
    languageCode: "lv",
  },
  {
    id: "838e2b16-39c2-4404-a61f-2fd4feeb9d1f",
    provider: "neets",
    providerId: "vits-lit-1",
    slug: "vits-lit-1",
    name: "vits-lit-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-lit-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Lithuanian",
    languageCode: "lt",
  },
  {
    id: "256b108e-d5c1-47fb-9652-b8921bc5e7d4",
    provider: "neets",
    providerId: "vits-lug-1",
    slug: "vits-lug-1",
    name: "vits-lug-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-lug-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Luganda",
    languageCode: "lg",
  },
  {
    id: "c1a83190-3867-48cc-85ab-89a233f5fbbd",
    provider: "neets",
    providerId: "vits-mai-1",
    slug: "vits-mai-1",
    name: "vits-mai-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-mai-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Maithili",
    languageCode: "mai",
  },
  {
    id: "6d474218-6c29-429f-9678-dbf757d54f58",
    provider: "neets",
    providerId: "vits-mar-1",
    slug: "vits-mar-1",
    name: "vits-mar-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-mar-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Marathi",
    languageCode: "mr",
  },
  {
    id: "595d2dd3-99aa-46e3-ae1b-0ecb4be5b377",
    provider: "neets",
    providerId: "vits-mlg-1",
    slug: "vits-mlg-1",
    name: "vits-mlg-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-mlg-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Malagasy",
    languageCode: "mg",
  },
  {
    id: "ffa3cc88-aa83-48aa-8d53-b81dc1538107",
    provider: "neets",
    providerId: "vits-mlt-1",
    slug: "vits-mlt-1",
    name: "vits-mlt-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-mlt-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Maltese",
    languageCode: "mt",
  },
  {
    id: "42fcd656-69d7-41b7-888c-19cae6816f3c",
    provider: "neets",
    providerId: "vits-mnk-1",
    slug: "vits-mnk-1",
    name: "vits-mnk-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-mnk-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Mandinka",
    languageCode: "mnk",
  },
  {
    id: "bd2008da-fab8-4bed-91ee-a5018cafe6ff",
    provider: "neets",
    providerId: "vits-mon-1",
    slug: "vits-mon-1",
    name: "vits-mon-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-mon-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Mongolian",
    languageCode: "mn",
  },
  {
    id: "b1592823-6c64-4e5a-8829-e9e3aec64e57",
    provider: "neets",
    providerId: "vits-mos-1",
    slug: "vits-mos-1",
    name: "vits-mos-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-mos-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Mossi",
    languageCode: "mos",
  },
  {
    id: "6c272186-8561-473b-93c2-376bee262add",
    provider: "neets",
    providerId: "vits-mya-1",
    slug: "vits-mya-1",
    name: "vits-mya-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-mya-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Burmese",
    languageCode: "my",
  },
  {
    id: "d808f095-6e37-4add-87e0-a41e53fa3375",
    provider: "neets",
    providerId: "vits-orm-1",
    slug: "vits-orm-1",
    name: "vits-orm-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-orm-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Oromo",
    languageCode: "om",
  },
  {
    id: "44878792-7ed1-4524-a1a6-f459c4f6f533",
    provider: "neets",
    providerId: "vits-pan-1",
    slug: "vits-pan-1",
    name: "vits-pan-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-pan-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Punjabi",
    languageCode: "pa",
  },
  {
    id: "b71ff324-071c-4ef8-814d-b9a2edf44013",
    provider: "neets",
    providerId: "vits-pcm-1",
    slug: "vits-pcm-1",
    name: "vits-pcm-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-pcm-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Nigerian Pidgin",
    languageCode: "pcm",
  },
  {
    id: "cd2f2003-2545-461e-8df7-84febd0b9a96",
    provider: "neets",
    providerId: "vits-pis-1",
    slug: "vits-pis-1",
    name: "vits-pis-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-pis-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Pijin",
    languageCode: "pis",
  },
  {
    id: "f6aae3f1-46eb-44f9-9935-962008523a61",
    provider: "neets",
    providerId: "vits-pol-1",
    slug: "vits-pol-1",
    name: "vits-pol-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-pol-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Polish",
    languageCode: "pl",
  },
  {
    id: "38c768ff-a447-427a-80cb-1d9006ae9f25",
    provider: "neets",
    providerId: "vits-por-1",
    slug: "vits-por-1",
    name: "vits-por-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-por-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Portuguese",
    languageCode: "pt",
  },
  {
    id: "e104ec86-f177-4a97-bd3a-33d6d6c2f763",
    provider: "neets",
    providerId: "vits-ron-1",
    slug: "vits-ron-1",
    name: "vits-ron-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-ron-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Romanian",
    languageCode: "ro",
  },
  {
    id: "13141ec1-e608-4a3f-a3f6-85a38de0bdf9",
    provider: "neets",
    providerId: "vits-run-1",
    slug: "vits-run-1",
    name: "vits-run-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-run-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Rundi",
    languageCode: "rn",
  },
  {
    id: "d9665680-80d0-45d2-91a1-7df767baa81e",
    provider: "neets",
    providerId: "vits-rus-1",
    slug: "vits-rus-1",
    name: "vits-rus-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-rus-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Russian",
    languageCode: "ru",
  },
  {
    id: "9be9c304-5cab-46b2-aaad-3b8cd6f31fe9",
    provider: "neets",
    providerId: "vits-swa-1",
    slug: "vits-swa-1",
    name: "vits-swa-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-swa-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Swahili",
    languageCode: "sw",
  },
  {
    id: "78dc51b5-8bcc-45ac-8805-988385be7643",
    provider: "neets",
    providerId: "vits-tha-1",
    slug: "vits-tha-1",
    name: "vits-tha-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-tha-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Thai",
    languageCode: "th",
  },
  {
    id: "098a0aba-161a-4baf-9fc0-9bd3292f7ea1",
    provider: "neets",
    providerId: "vits-tpi-1",
    slug: "vits-tpi-1",
    name: "vits-tpi-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-tpi-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Tok Pisin",
    languageCode: "tpi",
  },
  {
    id: "a782532f-9dab-47ac-8f32-3e1147f52bbc",
    provider: "neets",
    providerId: "vits-tsn-1",
    slug: "vits-tsn-1",
    name: "vits-tsn-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-tsn-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Tswana",
    languageCode: "tn",
  },
  {
    id: "febc3a42-5e97-4338-bfe9-9c1a4383f940",
    provider: "neets",
    providerId: "vits-tur-1",
    slug: "vits-tur-1",
    name: "vits-tur-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-tur-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Turkish",
    languageCode: "tr",
  },
  {
    id: "d9df8ea7-50a8-4ed7-ae10-0205c63da3f5",
    provider: "neets",
    providerId: "vits-ukr-1",
    slug: "vits-ukr-1",
    name: "vits-ukr-1",
    gender: "Male",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-ukr-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Ukrainian",
    languageCode: "uk",
  },
  {
    id: "7f3a0ac3-723e-47b4-ab7e-c7c2a68e5668",
    provider: "neets",
    providerId: "vits-vie-1",
    slug: "vits-vie-1",
    name: "vits-vie-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-vie2-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Vietnamese",
    languageCode: "vi",
  },
  {
    id: "339d07d3-76d0-4683-8cca-5884a086cc6e",
    provider: "neets",
    providerId: "vits-zho-1",
    slug: "vits-zho-1",
    name: "vits-zho-1",
    gender: "Female",
    previewUrl: "https://neets.s3.amazonaws.com/neets-web/samples/vits/vits-zho-1.mp3",
    createdAt: "2024-03-23T16:49:49.276Z",
    updatedAt: "2024-03-23T16:49:49.276Z",
    isPublic: true,
    orgId: "aa4c36ba-db21-4ce0-9c6e-bb55a8d2b188",
    language: "Chinese",
    languageCode: "zh",
  },
];
