import React, { useState } from "react";
import Popup from "./Popup";

const DeleteConfirmation = ({ isOpen, onClose, title = "Are you sure, you want to delete this item?", id, fetchDataFromApi, deleteMethod }) => {
  console.log("inside delete confirmation");
  const [error, setError] = useState(null);

  const handleNoClick = async () => {
    onClose();
  };

  const handleYesClick = async () => {
    try {
      const responseData = await deleteMethod(id);
      // console.log("responseData", responseData);
      if (responseData && responseData.status === "success") {
        onClose();
        fetchDataFromApi();
      }
    } catch (error) {
      console.log("error", error);
      setError(error);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title={title} mdWidth="max-w-md">
      <div className="flex flex-row gap-4 justify-center">
        <button className={`text-white justify-end mt-5 bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2 `} type="button" onClick={handleNoClick}>
          No
        </button>
        <button type="button" className={`text-white justify-end mt-5 bg-red-700 hover:bg-red-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5  py-2   `} onClick={handleYesClick}>
          Yes
        </button>
      </div>
      <div className="flex text-red-600 justify-center mt-4">{error?.message}</div>
    </Popup>
  );
};

export default DeleteConfirmation;
