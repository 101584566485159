import React, { useState } from "react";
import StaffUsers from "./StaffUsers";
import DeleteConfirmation from "../../../components/DeleteConfirmation";
import { deleteAssistantAPI } from "../../../services/assistant";
import { useNavigate } from "react-router-dom";

const OneAssistant = ({ assistant, staffUsers, fetchDataFromApi }) => {
  const navigate = useNavigate();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(null);

  const colorStatus = (status) => {
    switch (status) {
      case "not_assigned":
        return <div className="px-2 py-1 text-center inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-800 text-gray-400">Not Assigned</div>;
      case "assigned":
        return <div className="px-2 py-1 text-center inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Assigned</div>;
      default:
        return <div className="px-2 py-1 text-center inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-800 text-gray-400">Not Assigned</div>;
    }
  };

  const toggleDeletePopup = (id) => {
    setSelectedAssistant(id);
    setIsDeleteOpen(!isDeleteOpen);
  };

  const handleUpdateClick = (id) => {
    navigate(`/dashboard/save-assistant/${id}`);
  };

  const handleCopyClick = (id) => {
    navigate(`/dashboard/copy-assistant/${id}`);
  };

  return (
    <div className="flex justify-between items-left">
      <div className="flex items-center">
        <div className="w-16 h-16 flex justify-center items-center rounded-full bg-gray-300 border-2 border-blue-500 mr-4">
          <p className="text-2xl text-blue-500 font-bold">
            {assistant.name
              .split(" ")
              .map((word) => word.charAt(0))
              .slice(0, 2)
              .join("")}
          </p>
        </div>
        <div>
          <p className="text-lg">{assistant.name}</p>
          {/* <p className="text-sm">Model: {assistant.model}</p> */}
          <p className="text-sm">Number: {assistant.phone_number}</p>
          <p className="text-sm">Voice: {assistant?.assistant_voice?.name}</p>
          <p className="text-sm">Intelligence: {assistant?.assistant_metum?.title}</p>
          <p className="text-sm">
            Call Prompt:{" "}
            {assistant?.prompts &&
              assistant?.prompts?.length > 0 &&
              assistant.prompts
                .map((onePrompt) => onePrompt.name)
                .filter((name) => name !== "")
                .join(", ")}
          </p>
          <p className="text-sm">Speed: {assistant.speed}</p>
          <p className="text-sm">Creativity: {assistant.temperature}</p>
          <p className="text-sm">Background sound: {assistant?.bg_sound}</p>
        </div>
      </div>
      <div className="text-xl text-right">
        <div>
          <div>{colorStatus(assistant.status)}</div>
          <p className="text-sm text-end mt-2">Price: ${assistant.price} / min</p>
          <div>
            <StaffUsers assistant={assistant} staffUsers={staffUsers} />
          </div>
        </div>
        {/* Buttons */}
        <div className="flex flex-row gap-2 justify-end">
          <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4  font-medium rounded-lg text-sm px-3  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={() => handleCopyClick(assistant.id)}>
            Copy
          </button>
          <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4  font-medium rounded-lg text-sm px-3  py-2  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" onClick={() => handleUpdateClick(assistant.id)}>
            Update
          </button>
          <button className="text-white bg-red-700 hover:bg-red-800 focus:ring-4  font-medium rounded-lg text-sm px-3  py-2  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800" type="button" onClick={() => toggleDeletePopup(assistant.id)}>
            Delete
          </button>
        </div>
      </div>

      {/* Delete assistant popup */}
      {selectedAssistant && <DeleteConfirmation isOpen={isDeleteOpen} onClose={toggleDeletePopup} title={"Are you sure, you want to delete this assistant?"} id={selectedAssistant} fetchDataFromApi={fetchDataFromApi} deleteMethod={deleteAssistantAPI} />}
    </div>
  );
};

export default OneAssistant;
