import React, { useEffect, useState } from "react";
import { fetchAssistants, assignPromptToAssistant } from "../../../../services/assistant";
import { useParams } from "react-router-dom";

const AssistantDropDown = ({ singlePrompt }) => {
  const { promptId } = useParams();
  // console.log("promptId", promptId);
  const [assignedAssistant, setAssignedAssistant] = useState(singlePrompt.assistants.length > 0 ? singlePrompt.assistants[0].id : 0);
  // console.log("assignedAssistant", assignedAssistant);
  const [assistants, setAssistants] = useState([]);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchAssistants();
      // console.log("responseData", responseData);
      setAssistants(responseData?.data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const handleAssignAssistantChange = async (event) => {
    setSuccess(null);
    setError(null);
    let assistant_id = event.target.value;
    if (!assistant_id || !promptId) {
      // console.log("both ids not provided");
      return;
    }
    let data = {
      assistant_id,
      prompt_id: promptId,
    };

    // console.log("data", data);
    try {
      const responseData = await assignPromptToAssistant(data);
      setAssignedAssistant(assistant_id);
      // console.log("responseData", responseData);
      setSuccess(responseData);
      // window.location.reload();
    } catch (error) {
      setError(error);
    }
    // finally {
    //   setSuccess(null);
    //   setError(null);
    // }
  };

  console.log("assignedAssistant", assignedAssistant);

  return (
    <>
      {assistants && assistants.length > 0 ? (
        <div className="flex flex-col pb-4">
          <span className="text-lg">Assistants assigned: </span>
          <select id="assistantdropdown" value={assignedAssistant} className="border rounded px-4 py-1 bg-black w-full text-white text-sm" onChange={handleAssignAssistantChange}>
            <option value="">Assign To</option>
            {assistants.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </select>
          {error && <div className="py-2 text-red-600">{error?.message}</div>}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AssistantDropDown;
