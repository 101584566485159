import React from "react";

const TermsCondions = () => {
  let divClasses = "my-4";
  let headingClasses = "text-2xl font-bold mb-4";
  let paragraphClasses = "text-md";
  return (
    <div className="w-full mt-40">
      <div className="max-w-screen-lg mx-auto">
        <div className={divClasses}>
          <h2 className={`flex justify-center ${headingClasses}`}>Terms & Conditions</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Additional Terms and Order of Precedence</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Access and Account Security</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={`${divClasses}`}>
          <h2 className="text-xl font-semibold mb-2">Hyperlinking To Our Content</h2>

          <ul className="list-inside list-disc">
            <li>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. 1</li>
            <li>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. 2</li>
            <li>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. 3</li>
          </ul>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Privacy</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Services Updates and Availability</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Third-Party Intellectual Property</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">License to your content and feedback</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Changes to the Terms of Use</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Limitation of Liability</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Entire Agreement</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Waiver</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <h2 className="text-xl font-semibold mb-2">Limitation of Liability</h2>
          <p className={paragraphClasses}>Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo.</p>
        </div>

        <div className={divClasses}>
          <p className={paragraphClasses}>Last Updated: June 06, 2024</p>
        </div>
      </div>
    </div>
  );
};

export default TermsCondions;
