import React, { useState } from "react";
import { assignStaffToAssistant } from "../../../services/assistant";

const StaffUsers = ({ assistant, staffUsers }) => {
  // const [staffUsers, setStaffUsers] = useState([]);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // const fetchDataFromApi = async () => {
  //   try {
  //     const responseData = await fetchStaffUsers();
  //     // console.log("responseData", responseData);
  //     setStaffUsers(responseData?.data);
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchDataFromApi();
  // }, []);

  const handleStaffChange = async (event) => {
    setSuccess(null);
    setError(null);
    let staff_id = event.target.value;
    if (!assistant.id || !staff_id) {
      // console.log("both ids not provided");
      return;
    }
    let data = {
      assistant_id: assistant.id,
      staff_id,
    };
    try {
      const responseData = await assignStaffToAssistant(data);
      // console.log("responseData", responseData);
      setSuccess(responseData);
    } catch (error) {
      setError(error);
    }
    // finally {
    //   setSuccess(null);
    //   setError(null);
    // }
  };

  return (
    <>
      {staffUsers && staffUsers.length > 0 ? (
        <div className="flex flex-col mt-2 items-end justify-items-end">
          {/* <select id="staffusersdropdown" className="border rounded px-4 py-1 bg-black w-full text-white text-sm" defaultValue={assistant.staff_id} onChange={handleStaffChange}> */}
          <select id="staffusersdropdown" className="border rounded px-4 py-1 bg-black text-white text-sm max-w-min justify-end" defaultValue={staffUsers.find((user) => user.assistant_id === assistant.id)?.staff_id} onChange={handleStaffChange}>
            <option value="">Assign To</option>
            {staffUsers.map((user) => (
              <option key={user.id} value={user.staff_id}>
                {user.staff_name}
              </option>
            ))}
          </select>
          <div className="pt-2 text-red-600">{error?.message}</div>
          <div className="pt-2 text-green-600">{success?.message}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default StaffUsers;
