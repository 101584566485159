import React, { useState } from "react";
import Popup from "../../../../components/Popup";
import { useForm } from "react-hook-form";
import { createPromptWithMetaAPI, createSimplePromptWithMetaAPI } from "../../../../services/prompt";

const CreatePromptWithMeta = ({ isOpen, onClose, fetchPromptsDataFromApi }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [secondCallPrompt, setSecondCallPrompt] = useState();
  const [promptType, setPromptType] = useState("structural_prompt");

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    data.type = promptType;
    // console.log("data", data);
    setLoading(true);
    // call server api here
    try {
      let responseData = null;
      if (promptType === "simple_prompt") {
        responseData = await createSimplePromptWithMetaAPI(data);
      } else {
        responseData = await createPromptWithMetaAPI(data);
      }
      // console.log("responseData", responseData);
      if (responseData && responseData.status === "success") {
        fetchPromptsDataFromApi();
        onClose();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (checked) => {
    setSecondCallPrompt(!!checked);
  };

  const handlePromptTypeChange = (event) => {
    // console.log(event.target.value);
    setPromptType(event.target.value);
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title="Create New Prompt" mdWidth="md:w-1/2">
      <form className="mt-6 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="remember" defaultValue="true" />

        <div className="pb-5">
          <label className="block text-sm font-medium text-gray-300">Select Option</label>
          <div className="flex items-center space-x-4 mt-2">
            <label className="inline-flex items-center">
              <input type="radio" name="type" value="simple_prompt" checked={promptType === "simple_prompt"} onChange={handlePromptTypeChange} className="form-radio text-indigo-600 focus:ring-indigo-500 border-gray-300" />
              <span className="ml-2">Simple Prompt</span>
            </label>
            <label className="inline-flex items-center">
              <input type="radio" name="type" value="structural_prompt" checked={promptType === "structural_prompt"} onChange={handlePromptTypeChange} className="form-radio text-indigo-600 focus:ring-indigo-500 border-gray-300" />
              <span className="ml-2">Structural Prompt</span>
            </label>
          </div>
        </div>
        {/* {{ promptType }} */}

        {/* Subsequent calls prompt  */}
        <div className="">
          <label className="flex items-center">
            <input type="checkbox" checked={!!secondCallPrompt} {...register("second_call")} onChange={(e) => handleCheckboxChange(e.target.checked)} className="mr-2" />
            Use this for subsequent calls
          </label>
        </div>
        <div className="rounded-md shadow-sm -space-y-px">
          <div className="space-y-2">
            <label htmlFor="" className="">
              Prompt name
            </label>
            <input id="name" name="name" type="text" autoComplete="name" required {...register("name")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Name" />
          </div>
        </div>
        {promptType === "structural_prompt" ? (
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="flex flex-row w-full gap-4">
              {/* Prompt 1 form */}
              <div className="w-full">
                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Identity: Who will assitant act as?
                  </label>
                  <textarea id="assistant_backstory" name="assistant_backstory" type="text" required {...register("assistant_backstory")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="ex: You are a helpful and knowledgeable virtual assistant for a travel booking platform." cols={4} rows={6} />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Style: What should conversation style be?
                  </label>
                  <textarea
                    id="assistant_style"
                    name="assistant_style"
                    type="text"
                    required
                    {...register("assistant_style")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  - Be informative and comprehensive.
                - Maintain a professional and polite tone.
                - Be concise, as you are currently operating as a Voice Conversation."
                    cols={4}
                    rows={6}
                  />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Response Guideline: How should assistant response on certain questions?
                  </label>
                  <textarea
                    id="assistant_act"
                    name="assistant_act"
                    type="text"
                    required
                    {...register("assistant_act")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  - Present dates in a clear format (e.g., January 15, 2024).
                - Offer up to three travel options based on user preferences."
                    cols={4}
                    rows={6}
                  />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Task: What is the goal of the call?
                  </label>
                  <textarea
                    id="assistant_goal"
                    name="assistant_goal"
                    type="text"
                    required
                    {...register("assistant_goal")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  1. Greet the user and inquire about their desired travel destination.
                2. Ask about travel dates and preferences (e.g., budget, interests).
                3. Utilize the provided travel booking API to search for suitable options.
                4. Present the top three options to the user, highlighting key features."
                    cols={4}
                    rows={6}
                  />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    If contact does not answer and voicemail starts, assistant will say this as voicemail message (leave empty to hang up without message):
                  </label>
                  <textarea
                    id="assistant_voicemail"
                    name="assistant_voicemail"
                    type="text"
                    {...register("assistant_voicemail")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  I have a great proposition for you, call me back."
                    cols={4}
                    rows={3}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="flex flex-row w-full gap-4">
              {/* Prompt 1 form */}
              <div className="w-full">
                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    Simple prompt text
                  </label>
                  <textarea id="simple_prompt_text" name="simple_prompt_text" type="text" required {...register("simple_prompt_text")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="ex: You are a helpful and knowledgeable virtual assistant for a travel booking platform." cols={4} rows={6} />
                </div>

                <div className="space-y-2 mt-6">
                  <label htmlFor="" className="">
                    If contact does not answer and voicemail starts, assistant will say this as voicemail message (leave empty to hang up without message):
                  </label>
                  <textarea
                    id="assistant_voicemail"
                    name="assistant_voicemail"
                    type="text"
                    {...register("assistant_voicemail")}
                    className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="ex:
                  I have a great proposition for you, call me back."
                    cols={4}
                    rows={3}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          <button
            type="submit"
            disabled={loading}
            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                      ${loading ? "bg-gray-400 cursor-not-allowed" : "hover:bg-indigo-700 focus:ring-indigo-500"}`}
          >
            Submit
          </button>
          <div className="mt-5 text-center text-red-500">{error?.message}</div>
        </div>
      </form>
    </Popup>
  );
};

export default CreatePromptWithMeta;
