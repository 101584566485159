import React, { useEffect, useState } from "react";
import { fetchCallsPerDateAPI } from "../../../services/call";
import BarChart from "../../../components/BarChart";
import Loader from "../../../components/Loader";
import CalendarComponent from "../../../components/CalendarComponent";
import AssistantDropDown from "./AssistantDropDown";
import { fetchAssistants } from "../../../services/assistant";
const Calls = () => {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState("");

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const startDateISO = date.toISOString().split("T")[0];
    if (endDate && startDateISO) {
      const endDateISO = endDate.toISOString().split("T")[0];

      let postData = {
        startDate: startDateISO,
        endDate: endDateISO,
      };
      if (selectedAssistant) {
        postData.assistant_id = selectedAssistant;
      }
      // console.log("postData", postData);

      fetchCallsPerDate(postData);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    const endDateISO = date.toISOString().split("T")[0];
    if (startDate && endDateISO) {
      const startDateISO = startDate.toISOString().split("T")[0];
      let postData = {
        startDate: startDateISO,
        endDate: endDateISO,
      };
      if (selectedAssistant) {
        postData.assistant_id = selectedAssistant;
      }
      // console.log("postData", postData);
      fetchCallsPerDate(postData);
    }
  };

  const handleAssistantChange = async (event) => {
    let assistant_id = event.target.value;
    if (!assistant_id) {
      // console.log("assistant not provided");
      return;
    }
    setSelectedAssistant(assistant_id);

    const startDateISO = startDate.toISOString().split("T")[0];
    const endDateISO = endDate.toISOString().split("T")[0];

    let postData = {
      startDate: startDateISO,
      endDate: endDateISO,
      assistant_id: assistant_id,
    };

    // console.log("postData", postData);
    fetchCallsPerDate(postData);
  };
  const fetchCallsPerDate = async (postData) => {
    setIsLoading(true);
    try {
      const responseData = await fetchCallsPerDateAPI(postData);
      // console.log("respdata", responseData);
      if (responseData.status === "success") {
        const labels = await responseData.data.map((item) => item.date);
        const callsData = await responseData.data.map((item) => item.calls);
        setChartLabels(labels);
        setChartData(callsData);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssistantsFromApi = async () => {
    try {
      const responseData = await fetchAssistants();
      // console.log("responseData", responseData);
      if (responseData.status === "success") setAssistants(responseData.data);
    } catch (error) {
      //  setError(error);
    } finally {
      //  setIsLoading(false);
    }
  };
  useEffect(() => {
    // Get the current date
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const startDateISO = startDate.toISOString().split("T")[0];
    const endDateISO = endDate.toISOString().split("T")[0];
    setStartDate(startDate);
    setEndDate(endDate);
    let postData = {
      startDate: startDateISO,
      endDate: endDateISO,
    };
    fetchCallsPerDate(postData);

    // fetch assistants from api
    fetchAssistantsFromApi();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Error: {error?.message}</div>;
  }

  return (
    <div className="flex flex-col w-full mt-5">
      <div className="flex flex-row pb-8">
        <CalendarComponent startDate={startDate} endDate={endDate} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange} />

        <div className="w-80">
          <label className="block mb-2 ">Assistant</label>
          <div>{assistants && <AssistantDropDown selectedAssistant={selectedAssistant} assistants={assistants} handleAssistantChange={handleAssistantChange} />}</div>
        </div>
      </div>

      {chartLabels && chartData && <BarChart chartLabels={chartLabels} chartData={chartData} />}
    </div>
  );
};

export default Calls;
