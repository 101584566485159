export const getAccessToken = () => {
  let token = localStorage.getItem("token");
  // token = JSON.parse(token);
  return token;
};

export const logOut = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  window.location.href = "/signin";
};
