import React, { useState, useEffect } from "react";

const NumberSlider = ({ min = 1, max = 2, step = 0.1, handleChange, defaultValue }) => {
  const initialValue = defaultValue !== undefined ? defaultValue : min;
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(defaultValue !== undefined ? defaultValue : min);
  }, [defaultValue, min]);

  const handleSliderChange = (event) => {
    handleChange(event.target.value);
    setValue(event.target.value);
  };

  return (
    <div className="w-full max-w-md flex flex-row gap-4 items-center">
      <span className="text-lg font-normal">{value}</span>
      <input type="range" min={min} max={max} step={step} value={value} onChange={handleSliderChange} className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
    </div>
  );
};

export default NumberSlider;
