import React, { useState, useEffect } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import Popup from "../../../../components/Popup";
import { createPaymentIntentAPI } from "../../../../services/payment";

const stripe = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY);

const StripePayment = ({ isOpen, onClose, amount }) => {
  const [clientSecret, setClientSecret] = useState(null);

  const createPaymentIntent = async () => {
    try {
      let resp = await createPaymentIntentAPI({ amount: amount });
      if (resp.status === "success") setClientSecret(resp.clientSecret);
    } catch (error) {
      console.log("error", error);
    } finally {
    }
    // fetch(`${process.env.REACT_APP_BASE_URL}/api/payment/create_payment_intent`, {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   method: "POST",
    //   body: JSON.stringify({ amount: amount }),
    // }).then(async (resp) => {
    //   resp = await resp.json();
    //   console.log("resp", resp.clientSecret);
    //   setClientSecret(resp.clientSecret);
    // });
  };

  useEffect(() => {
    createPaymentIntent();
  }, []);

  const options = {
    clientSecret,
    theme: "stripe",
  };

  return (
    clientSecret && (
      <Popup isOpen={isOpen} onClose={onClose} title="Add Payment Method" mdWidth="max-w-md">
        <Elements stripe={stripe} options={options}>
          <PaymentForm></PaymentForm>
        </Elements>
      </Popup>
    )
  );
};

export default StripePayment;
