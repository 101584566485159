import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CalendarComponent({ startDate, endDate, handleStartDateChange, handleEndDateChange }) {
  return (
    <div className="flex justify-center">
      <div className="w-80">
        <label className="block mb-2 ">Start Date</label>
        <DatePicker selected={startDate} onChange={handleStartDateChange} selectsStart startDate={startDate} endDate={endDate} placeholderText="Select Start Date" className="text-white  block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm" />
      </div>
      <div className="w-80">
        <label className="block mb-2">End Date</label>
        <DatePicker selected={endDate} onChange={handleEndDateChange} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} placeholderText="Select End Date" className="text-white  block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500  sm:text-sm" />
      </div>
    </div>
  );
}

export default CalendarComponent;
