import React, { useEffect, useState } from "react";
import { fetchSingleCallAPI } from "../../../services/call";
import { useParams } from "react-router-dom";
import { CircleArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

const CallTranscript = () => {
  const { call_id } = useParams();
  // const [call, setCall] = useState(null);
  const [callTranscript, setCallTranscript] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchSingleCallAPI(call_id);
      if (responseData?.data?.transcript) {
        let trnscriptData = responseData.data.transcript.split("\n");
        // console.log("trnscriptData", trnscriptData);
        setCallTranscript(trnscriptData);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleBackClick = () => {
    navigate(`/dashboard/calls`);
  };
  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90"></div>
        </div>
      ) : (
        <div>
          <div className="px-5 py-5">
            <div className="flex flex-row items-center cursor-pointer w-fit bg-slate-500 hover:bg-slate-600 rounded-md p-4" onClick={handleBackClick}>
              <CircleArrowLeft size={28} />
              <span className="ml-2">Back</span>
            </div>
          </div>
          {callTranscript ? (
            <>
              {/* <div className="w-full flex justify-end">
                <div className="py-5 flex gap-3 px-5"></div>
              </div> */}

              <div className="px-5">
                {callTranscript.map((transcript, index) => (
                  <div key={index} className="py-2">
                    {transcript}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center text-center text-xl pt-10">
              <div>Call transcript not found.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CallTranscript;
