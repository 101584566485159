import React, { useEffect, useState } from "react";
import PromptMetaForm from "./PromptMetaForm";
import { useParams } from "react-router-dom";
import { fetchSinglePrompt } from "../../../../services/prompt";
import Loader from "../../../../components/Loader";

const PromptMeta = () => {
  const { promptId } = useParams();
  const [singlePrompt, setSinglePrompt] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchSinglePrompt(promptId);
      // console.log("responseData", responseData);
      setSinglePrompt(responseData.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="w-full">
      {singlePrompt ? (
        <>
          <div className="w-full flex justify-between">
            <div className="py-5 flex gap-3 px-10 text-lg">{singlePrompt?.name}</div>
          </div>
          <div className="flex flex-row gap-4 px-10 pb-24">
            <PromptMetaForm singlePrompt={singlePrompt} />
          </div>
        </>
      ) : (
        <p>Something went wrong</p>
      )}
    </div>
  );
};

export default PromptMeta;
