import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";

const PrivateRoute = () => {
  const auth = useAuth();

  const currentUser = auth.getCurrentUser();

  if (!currentUser?.accessToken) return <Navigate to="/signin" />;
  return <Outlet />;
};

export default PrivateRoute;
