import { useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  // const [user, setUser] = useState(null);
  // const [token, setToken] = useState(localStorage.getItem("user") || "");
  const navigate = useNavigate();
  const loginAction = async (data) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.status === "success") {
        // setUser(res);
        // setToken(res.accessToken);
        localStorage.setItem("user", JSON.stringify(res));
        localStorage.setItem("token", res.accessToken);
        navigate("/dashboard");
      }
      return res;
    } catch (err) {
      return { status: "error", message: err.message };
    }
  };

  const logOut = () => {
    // setUser(null);
    // setToken("");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const getCurrentUser = () => {
    let currentUser = localStorage.getItem("user");
    currentUser = JSON.parse(currentUser);
    return currentUser;
  };

  const getAccessToken = () => {
    let token = localStorage.getItem("token");
    // token = JSON.parse(token);
    return token;
  };

  return <AuthContext.Provider value={{ getAccessToken, getCurrentUser, loginAction, logOut }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
