import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { createCampaign } from "../../../services/campaign";
import Popup from "../../../components/Popup";

const CreateCampaign = ({ isOpen, onClose, fetchDataFromApi }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    // call server api here
    try {
      const responseData = await createCampaign(data);
      if (responseData && responseData.status === "success") {
        onClose();
        fetchDataFromApi();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title="Create New Campaign" mdWidth="max-w-md">
      <form className="mt-6 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="remember" defaultValue="true" />
        <div className="rounded-md shadow-sm -space-y-px">
          <div className="pb-5">
            <label htmlFor="name" className="sr-only">
              Name
            </label>
            <input id="name" name="name" type="text" autoComplete="name" required {...register("name")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Name" />
          </div>
        </div>
        <div>
          <button
            type="submit"
            disabled={loading}
            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                      ${loading ? "bg-gray-400 cursor-not-allowed" : "hover:bg-indigo-700 focus:ring-indigo-500"}`}
          >
            Submit
          </button>
          <div className="mt-5 text-center text-red-500">{error?.message}</div>
        </div>
      </form>
    </Popup>
  );
};

export default CreateCampaign;
