import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { createSMSProviderAPI, getSMSProviderByTypeAPI, importSMSProviderPhone } from "../../../services/sms_provider";
import { Settings } from "lucide-react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Vonage = ({ reloadTop }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [showEdit, setShowEdit] = useState(false);

  const { register, handleSubmit } = useForm();
  const [vonagePhone, setVonagePhone] = useState();

  const [currentProvider, setCurrentProvider] = useState(null);

  const fetchDataFromApi = async () => {
    try {
      const responseData = await getSMSProviderByTypeAPI("vonage");
      // console.log("responseData", responseData);
      setCurrentProvider(responseData?.data);
    } catch (error) {
      // setError(error);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const onSubmit = async (data) => {
    data.type = "vonage";
    if (currentProvider) {
      data.id = currentProvider.id;
    }
    // console.log("data", data);
    setIsLoading(true);
    setSuccess(null);
    setError(null);

    // call server api here
    try {
      const responseData = await createSMSProviderAPI(data);
      // console.log("responseData", responseData);
      setSuccess(responseData);
      setShowEdit(false);
      fetchDataFromApi();
    } catch (error) {
      // console.log("errorData", error);

      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitNumber = (event) => {
    event.preventDefault();
    onSubmitNumber();
  };

  const onSubmitNumber = async () => {
    let phoneNumber = vonagePhone.startsWith("+") ? vonagePhone : "+" + vonagePhone;
    var data = { type: "vonage", number: phoneNumber };

    setIsLoading(true);
    setSuccess(null);
    setError(null);

    // call server api here
    try {
      const responseData = await importSMSProviderPhone(data);
      // console.log("responseData", responseData);
      setSuccess(responseData);
      setShowEdit(false);
      fetchDataFromApi();
      reloadTop();
    } catch (error) {
      // console.log("errorData", error);

      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mb-4 bg-back-dark p-5 rounded-lg">
      <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-xl pb-2 flex">
          <div className="w-1/2">Vonage</div>
          <div className="w-1/2 text-right">
            {!!currentProvider && !!currentProvider.vonage_api_key && (
              <Settings
                onClick={() => {
                  setShowEdit(!showEdit);
                }}
                className="inline-block cursor-pointer"
              />
            )}
          </div>
        </h2>
        <div className={!currentProvider || !currentProvider.vonage_api_key || showEdit === true ? "hidden" : ""}>
          <div className="text-xs pb-2">Vonage API Key: &nbsp;&nbsp;&nbsp; {currentProvider?.vonage_api_key?.substr(0, 5)}...</div>
          <div className="text-xs pb-2">Vonage API Secret: &nbsp;&nbsp;&nbsp; {currentProvider?.vonage_api_secret?.substr(0, 5)}...</div>
        </div>
        <div className={!currentProvider || !currentProvider.vonage_api_key || showEdit === true ? "" : "hidden"}>
          <label htmlFor="vonage_api_key">Vonage API Key</label>
          <input id="vonage_api_key" name="vonage_api_key" type="text" autoComplete="vonage_api_key" required defaultValue={currentProvider?.vonage_api_key} {...register("vonage_api_key")} className="relative block w-full px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm mt-4 mb-8" placeholder="Vonage vonage_api_key" />

          <label htmlFor="vonage_api_secret">Vonage API Secret</label>
          <input id="vonage_api_secret" name="vonage_api_secret" type="text" autoComplete="vonage_api_secret" required defaultValue={currentProvider?.vonage_api_secret} {...register("vonage_api_secret")} className="relative block w-full px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm  mt-4 mb-8" placeholder="Vonage vonage_api_secret" />
          <label htmlFor="vonage_brand_name">Vonage API Secret</label>
          <input id="vonage_brand_name" name="vonage_brand_name" type="text" autoComplete="vonage_brand_name" required defaultValue={currentProvider?.vonage_brand_name} {...register("vonage_brand_name")} className="relative block w-full px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm  mt-4 mb-8" placeholder="Vonage vonage_brand_name" />
          <div>
            <button type="submit" className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
              Save Keys
            </button>
          </div>
        </div>
      </form>

      {!!currentProvider && !!currentProvider.vonage_api_key && !!currentProvider?.vonage_api_secret && (
        <div className="mt-2">
          <form className="space-y-2" onSubmit={handleSubmitNumber}>
            <label htmlFor="" className="">
              Import Vonage Phone Number
            </label>
            <div className="phone-input-container">
              <PhoneInput name="vonagePhoneNumber" copyNumbersOnly={false} placeholder="Enter vonage phone number" country={"us"} value={vonagePhone} onChange={setVonagePhone} />
            </div>

            <div>
              <button type="submit" className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
                Import
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="pt-2 text-red-600">{error?.message}</div>
      <div className="pt-2 text-green-600">{success?.message}</div>
    </div>
  );
};

export default Vonage;
