//PaymentForm.js

import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";

const PAYMENT_SUCESS_URL = `${process.env.REACT_APP_FE_URL}/dashboard/success`;
const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);
    setMessage("Payment in Progress");

    console.log("PAYMENT_SUCESS_URL", PAYMENT_SUCESS_URL);

    const resp = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: PAYMENT_SUCESS_URL,
      },
    });

    if (resp.error) setMessage("Some went wrong!");
    setIsLoading(false);
  };

  return (
    <div className="container mx-auto ">
      <form onSubmit={handleSubmit}>
        <div className="card w-100 bg-base-100 bg-gray-200 shadow-2xl rounded-lg">
          <div className="card-body p-6">
            <h1 className="card-title font-bold text-2xl mb-4 justify-center text-black">Complete your payment here!</h1>
            <PaymentElement />
            <div className="card-actions flex justify-center">
              <button className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`} disabled={isLoading || !stripe || !elements}>
                {isLoading ? "Loading..." : "Pay now"}
              </button>
            </div>

            {message && <div className="flex justify-center pt-2 text-red-600">{message}</div>}
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaymentForm;
