import { Bar } from "react-chartjs-2";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ chartLabels, chartData }) => {
  const barChartData = {
    // labels: ["2024-05-08", "2024-05-08", "2024-05-10" ],
    labels: chartLabels,
    datasets: [
      {
        label: "Calls",
        // data: [3000, 500, 800, 1000, 1200],
        data: chartData,
        backgroundColor: "rgb(47, 117, 255)",
        // borderColor: "rgb(75, 192, 192)",
        // borderWidth: 1,
      },
    ],
  };

  const options = {};
  const data = barChartData;
  return (
    <div className="flex flex-col justify-center items-center">
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarChart;
