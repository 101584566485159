import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchMeUser, updateEmail } from "../../../services/user";

const UpdateEmail = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, getValues } = useForm();

  const [currentUser, setCurrentUser] = useState(null);
  const fetchDataFromApi = async () => {
    try {
      const responseData = await fetchMeUser();
      // console.log("responseData", responseData);
      setCurrentUser(responseData?.data);
    } catch (error) {
      // setError(error);
    }
  };

  useEffect(() => {
    fetchDataFromApi();
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setSuccess(null);
    setError(null);

    // Get the current value of newEmail from the form data
    const newEmailValue = getValues("newEmail");

    // Check if the newEmailValue is empty or unchanged
    if (!newEmailValue || newEmailValue === currentUser.email) {
      // If it's empty or unchanged, set the error message and return
      setError({ message: "Please enter a new email address." });
      setIsLoading(false);
      return;
    }
    // call server api here
    try {
      const responseData = await updateEmail(data);
      // console.log("responseData", responseData);
      setSuccess(responseData);
      fetchDataFromApi();
    } catch (error) {
      // console.log("errorData", error);

      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mb-4 bg-back-dark p-5 rounded-lg">
      <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-xl pb-2">Email</h2>
        <div className="">
          <input id="newEmail" name="newEmail" type="email" autoComplete="newEmail" required defaultValue={currentUser?.email} {...register("newEmail")} className="relative block w-full px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email Address" />
          <div>
            <button type="submit" className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
              Update Email
            </button>
          </div>
        </div>
      </form>
      <div className="pt-2 text-red-600">{error?.message}</div>
      <div className="pt-2 text-green-600">{success?.message}</div>
    </div>
  );
};

export default UpdateEmail;
