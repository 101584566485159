import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/AuthProvider";
import { Eye, EyeOff } from "lucide-react";

function Signup() {
  const auth = useAuth();
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    // call server api here
    // console.log("Signup:", data);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const resp = await response.json();
      // console.log("res", resp);
      if (resp.status === "success") {
        //navigate("/signin");
        await auth.loginAction(data);
      } else {
        setError(resp.message);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className="min-h-screen flex items-center justify-center  py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 border border-gray-800  p-10 rounded-md bg-gray-950">
        <div>
          <h2 className="text-center text-3xl font-bold ">Sign Up</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="pb-5">
              <label htmlFor="username" className="sr-only">
                Full Name
              </label>
              <input id="username" name="username" type="text" autoComplete="username" required {...register("username")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Full Name" />
            </div>
            <div className="pb-5">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input id="email-address" name="email" type="email" autoComplete="email" required {...register("email")} className="relative block w-full px-3 py-3 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address" />
            </div>
            <div className="relative">
              {/* <label htmlFor="password" className="sr-only">
                Password
              </label> */}
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"} // Toggle password visibility
                // type="password"
                autoComplete="current-password"
                required
                {...register("password")}
                className="relative block w-full px-3 py-3 border bg-gray-900 rounded-md outline-none border-gray-800 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm pr-12"
                placeholder="Password"
              />
              <button type="button" className="absolute inset-y-0 right-0 pr-3 flex items-center" onClick={togglePasswordVisibility}>
                {showPassword ? <EyeOff className="text-gray-400" /> : <Eye className="text-gray-400" />}
              </button>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
  ${isLoading ? "bg-gray-400 cursor-not-allowed" : "hover:bg-indigo-700 focus:ring-indigo-500"}`}
            >
              Sign up
            </button>
            <div className="flex pt-5 h-10 justify-center">
              {!!isLoading && <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10 opacity-90"></div>}
              {!!error && !isLoading && <div className="text-center text-red-500">{error}</div>}
            </div>
          </div>
        </form>
        <div className="text-sm">
          Already have an account?{" "}
          <Link to="/signin" className="text-blue-500 hover:text-blue-700">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;
