import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";

const PubliceRoute = () => {
  const auth = useAuth();

  const currentUser = auth.getCurrentUser();

  if (currentUser?.accessToken) return <Navigate to="/dashboard" />;
  return <Outlet />;
};

export default PubliceRoute;
