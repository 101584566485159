import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { createAvailabilityAPI, fetchAvailabilitiesAPI } from "../../../../services/availability";
import moment from "moment-timezone";

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const Availability = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [availability, setAvailability] = useState({});

  const [timeZone, setTimeZone] = useState("");

  const defaultTime = { startTime: "00:00", endTime: "23:59" };

  // useEffect(() => {
  //   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   setTimeZone(userTimeZone);
  // }, []);

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(userTimeZone);
    fetchDataFromApi();
  }, []);

  const fetchDataFromApi = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const responseData = await fetchAvailabilitiesAPI();
      // console.log("responseData", responseData);
      if (responseData && responseData.status === "success") {
        const availabilities = responseData.data.reduce((acc, item) => {
          acc[item.dayOfWeek] = {
            startTime: convertToUserTimeZone(item.startTime, item.timeZone),
            endTime: convertToUserTimeZone(item.endTime, item.timeZone),
          };
          return acc;
        }, {});
        setAvailability(availabilities);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const convertToUserTimeZone = (utcTime, userTimeZone) => {
    return moment.utc(utcTime, "HH:mm:ss").tz(userTimeZone).format("HH:mm:ss");
  };

  const handleInputChange = (day, field, value) => {
    setAvailability((prevAvailability) => ({
      ...prevAvailability,
      [day]: {
        ...prevAvailability[day],
        [field]: value,
      },
    }));
  };

  const handleCheckboxChange = (day, checked) => {
    if (checked) {
      setAvailability((prevAvailability) => ({
        ...prevAvailability,
        [day]: defaultTime,
      }));
    } else {
      setAvailability((prevAvailability) => {
        const { [day]: _, ...rest } = prevAvailability;
        return rest;
      });
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    const availabilityData = Object.entries(availability).map(([day, times]) => ({
      dayOfWeek: day,
      ...times,
    }));

    for (let oneAvail of availabilityData) {
      if (!oneAvail.startTime || !oneAvail.endTime) {
        setError({ message: "start time and end time cannot be empty." });
        setIsLoading(false);
        return;
      }
    }
    const payload = {
      availability: availabilityData,
      timeZone, // Include the time zone in the payload
    };
    try {
      const responseData = await createAvailabilityAPI(payload);
      // console.log("responseData", responseData);
      if (responseData && responseData.status === "success") {
        setSuccess({
          message: responseData.message,
        });
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mb-4 bg-back-dark p-5 rounded-lg">
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-xl pb-2">Availability</h2>
        {daysOfWeek.map((day) => (
          <div key={day} className="space-y-2">
            <label className="flex items-center">
              <input type="checkbox" {...register(day)} checked={availability[day] !== undefined} onChange={(e) => handleCheckboxChange(day, e.target.checked)} className="mr-2" />
              {day}
            </label>
            {availability[day] && (
              <div className="flex space-x-2">
                <input type="time" value={availability[day].startTime} onChange={(e) => handleInputChange(day, "startTime", e.target.value)} className="w-full px-3 py-2 border bg-gray-700 rounded-md outline-none border-gray-800 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                <input type="time" value={availability[day].endTime} onChange={(e) => handleInputChange(day, "endTime", e.target.value)} className="w-full px-3 py-2 border bg-gray-700 rounded-md outline-none border-gray-800 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              </div>
            )}
          </div>
        ))}
        <div>
          <button type="submit" className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
            {isLoading ? "Updating..." : "Update Availability"}
          </button>
        </div>
      </form>
      {error && <div className="pt-2 text-red-600">{error?.message}</div>}
      {success && <div className="pt-2 text-green-600">{success?.message}</div>}
    </div>
  );
};

export default Availability;
