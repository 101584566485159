import React, { useState } from "react";
import Popup from "../../../../components/Popup";
import { useForm } from "react-hook-form";
import { createPaymentTopUpAPI } from "../../../../services/payment";

const PaymentTopUp = ({ isOpen, onClose }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const handleTopUpSubmit = async (data) => {
    if (!data || !data.topUpAmount || data.topUpAmount < 10) {
      // If it's empty or unchanged, set the error message and return
      setError({ message: "Minimum amount should be $10" });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setSuccess(null);
    setError(null);

    try {
      let responseData = await createPaymentTopUpAPI(data);
      // console.log("responseData", responseData);
      if (responseData && responseData.status === "success") {
        setSuccess(responseData);
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setError(responseData);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Popup isOpen={isOpen} onClose={onClose} title="Top up your account" mdWidth="max-w-md">
        <form className="space-y-2" onSubmit={handleSubmit(handleTopUpSubmit)}>
          <h2 className="text-xl">Amount</h2>
          <div className="">
            <input id="topUpAmount" name="topUpAmount" type="number" min="10" autoComplete="topUpAmount" required {...register("topUpAmount")} className="relative block w-full px-3 py-2 border  bg-gray-900  rounded-md  outline-none border-gray-800 focus:outline-none  focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="$ 10" />
            <div className="flex justify-center">
              <button type="submit" disabled={isLoading} className={`group relative mt-4 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-indigo-600"}`}>
                Top Up
              </button>
            </div>
          </div>
        </form>
        <div className="flex justify-center">
          <div className="pt-2 text-red-600">{error?.message}</div>
          <div className="pt-2 text-green-600">{success?.message}</div>
        </div>
      </Popup>
    </div>
  );
};

export default PaymentTopUp;
